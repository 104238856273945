"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UtmLinkRender = exports.ListRender = exports.ListItemRender = exports.ButtonMarkRender = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _CommonStyles = require("./CommonStyles");
var _Theme = _interopRequireDefault(require("../../theme/Theme"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const UtmLinkRender = props => {
  var _props$mark;
  return (0, _jsxRuntime.jsx)(_material.Link, {
    href: props === null || props === void 0 || (_props$mark = props.mark) === null || _props$mark === void 0 ? void 0 : _props$mark.href,
    color: 'secondary',
    underline: "hover",
    children: props.children
  });
};
exports.UtmLinkRender = UtmLinkRender;
const ButtonMarkRender = props => {
  var _props$mark2, _props$mark3, _props$mark4, _props$mark5, _props$mark6, _props$mark7, _props$mark8, _props$mark9, _props$mark10, _props$mark11;
  let textColor = '';
  switch (props === null || props === void 0 || (_props$mark2 = props.mark) === null || _props$mark2 === void 0 ? void 0 : _props$mark2.variant) {
    case 'outlined':
      switch ((_props$mark3 = props.mark) === null || _props$mark3 === void 0 ? void 0 : _props$mark3.color) {
        case 'secondary':
          textColor = _Theme.default.palette.secondary.main;
          break;
        case 'primary':
          textColor = _Theme.default.palette.primary.main;
          break;
        case 'mint':
          textColor = "";
          break;
        default:
          textColor = "whitesmoke";
      }
      break;
    case 'contained':
      switch ((_props$mark4 = props.mark) === null || _props$mark4 === void 0 ? void 0 : _props$mark4.color) {
        case 'secondary':
          textColor = "whitesmoke";
          break;
        case 'primary':
          textColor = "whitesmoke";
          break;
        case 'mint':
          textColor = _Theme.default.palette.secondary.main;
          break;
        default:
          textColor = _Theme.default.palette.background.paper;
      }
      break;
    case 'text':
    default:
      switch ((_props$mark5 = props.mark) === null || _props$mark5 === void 0 ? void 0 : _props$mark5.color) {
        case 'secondary':
          textColor = _Theme.default.palette.secondary.main;
          break;
        case 'primary':
          textColor = _Theme.default.palette.primary.main;
          break;
        case 'mint':
          textColor = "";
          break;
        default:
          textColor = _Theme.default.palette.text.primary;
      }
      break;
  }
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    children: (0, _jsxRuntime.jsx)(_material.Button, {
      style: (props === null || props === void 0 || (_props$mark6 = props.mark) === null || _props$mark6 === void 0 ? void 0 : _props$mark6.color) === 'mint' ? (props === null || props === void 0 || (_props$mark7 = props.mark) === null || _props$mark7 === void 0 ? void 0 : _props$mark7.variant) === 'contained' ? {
        backgroundColor: "",
        borderRadius: "20px"
      } : {
        backgroundColor: 'transparent',
        borderColor: "",
        borderRadius: "20px"
      } : {
        borderRadius: "20px"
      },
      variant: props === null || props === void 0 || (_props$mark8 = props.mark) === null || _props$mark8 === void 0 ? void 0 : _props$mark8.variant,
      color: (props === null || props === void 0 || (_props$mark9 = props.mark) === null || _props$mark9 === void 0 ? void 0 : _props$mark9.color) !== 'mint' ? props === null || props === void 0 || (_props$mark10 = props.mark) === null || _props$mark10 === void 0 ? void 0 : _props$mark10.color : 'inherit',
      href: props === null || props === void 0 || (_props$mark11 = props.mark) === null || _props$mark11 === void 0 ? void 0 : _props$mark11.buttonLink,
      children: (0, _jsxRuntime.jsx)(_material.Typography, {
        variant: 'button',
        style: {
          color: textColor
        },
        children: props === null || props === void 0 ? void 0 : props.children
      })
    })
  });
};
exports.ButtonMarkRender = ButtonMarkRender;
const ListRender = props => {
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    direction: 'column',
    children: (0, _jsxRuntime.jsx)(_material.List, {
      children: props.children
    })
  });
};
exports.ListRender = ListRender;
const ListItemRender = props => {
  var _props$node, _props$node2, _props$index;
  const classes = (0, _CommonStyles.useCommonStyles)(props);
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    item: true,
    children: (0, _jsxRuntime.jsx)(_material.ListItem, {
      className: classes.listItemRoot,
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        wrap: 'nowrap',
        alignItems: 'flex-start',
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.ListItemIcon, {
            className: classes.bulletIconContainer,
            children: (_props$node = props.node) !== null && _props$node !== void 0 && _props$node.listItem && ((_props$node2 = props.node) === null || _props$node2 === void 0 ? void 0 : _props$node2.listItem) === 'bullet' ? (0, _jsxRuntime.jsx)(_iconsMaterial.FiberManualRecord, {
              className: classes.bulletIcon
            }) : (0, _jsxRuntime.jsxs)(_material.Typography, {
              variant: 'subtitle1',
              className: classes.orderedListIndex,
              children: [((_props$index = props === null || props === void 0 ? void 0 : props.index) !== null && _props$index !== void 0 ? _props$index : 0) + 1, "."]
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          wrap: 'nowrap',
          container: true,
          children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
            children: props.children
          })
        })]
      })
    })
  });
};
exports.ListItemRender = ListItemRender;