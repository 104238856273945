"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _blockContentToReact = _interopRequireDefault(require("@sanity/block-content-to-react"));
var _material = require("@mui/material");
var _BlockContentRenderer = require("../common/sanityIo/BlockContentRenderer");
var _ThwCommonStyles = _interopRequireDefault(require("../common/sanityIo/ThwCommonStyles"));
var _ThwPositivePsychology = _interopRequireDefault(require("./templates/transform-hw/ThwPositivePsychology"));
var _ThwMottoSection = _interopRequireDefault(require("./templates/transform-hw/ThwMottoSection"));
var _AboutTheProprietorSection = _interopRequireDefault(require("./templates/transform-hw/AboutTheProprietorSection"));
var _ThwServicesSection = _interopRequireDefault(require("./templates/transform-hw/ThwServicesSection"));
var _ThwWhyChooseUsSection = _interopRequireDefault(require("./templates/transform-hw/ThwWhyChooseUsSection"));
var _ThwContactUsSection = _interopRequireDefault(require("./templates/transform-hw/ThwContactUsSection"));
var _ThwServiceEducationPage = _interopRequireDefault(require("./templates/transform-hw/service-education-page/ThwServiceEducationPage"));
var _ResumeBioSection = _interopRequireDefault(require("./templates/my-digital-resume/resume-bio-section/ResumeBioSection"));
var _MMHeroContentSection = _interopRequireDefault(require("./templates/mackenzies-mind/MMHeroContentSection"));
var _ResumeSkillsSection = _interopRequireDefault(require("./templates/my-digital-resume/resume-skills-section/ResumeSkillsSection"));
var _ResumeExperienceSection = _interopRequireDefault(require("./templates/my-digital-resume/resume-experience-section/ResumeExperienceSection"));
var _ResumeEducationSection = _interopRequireDefault(require("./templates/my-digital-resume/resume-education-section/ResumeEducationSection"));
var _ResumeFeedbackSection = _interopRequireDefault(require("./templates/my-digital-resume/resume-feedback-section/ResumeFeedbackSection"));
var _ResumeContactUsSection = _interopRequireDefault(require("./templates/my-digital-resume/ResumeContactUsSection"));
var _ResumePortfolioSection = _interopRequireDefault(require("./templates/my-digital-resume/resume-portfolio-section/ResumePortfolioSection"));
var _WebDevHeroContentSection = _interopRequireDefault(require("./templates/web-dev-site/WebDevHeroContentSection"));
var _WebDevStatsCounterSection = _interopRequireDefault(require("./templates/web-dev-site/WebDevStatsCounterSection"));
var _WebDevAboutUsSection = _interopRequireDefault(require("./templates/web-dev-site/WebDevAboutUsSection"));
var _WebDevServicesSection = _interopRequireDefault(require("./templates/web-dev-site/WebDevServicesSection"));
var _WebDevPortfolioSection = _interopRequireDefault(require("./templates/web-dev-site/WebDevPortfolioSection"));
var _WebDevTestimonialsSection = _interopRequireDefault(require("./templates/web-dev-site/WebDevTestimonialsSection"));
var _WebDevHowItWorksSection = _interopRequireDefault(require("./templates/web-dev-site/WebDevHowItWorksSection"));
var _HeroAnimatedContentSection = _interopRequireDefault(require("./animated/HeroAnimatedContentSection"));
var _AnimatedAboutUsSection = _interopRequireDefault(require("./animated/AnimatedAboutUsSection"));
var _AnimatedServicesSection = _interopRequireDefault(require("./animated/AnimatedServicesSection"));
var _AnimatedPortfolioSection = _interopRequireDefault(require("./animated/AnimatedPortfolioSection"));
var _HeadlineCTASection = _interopRequireDefault(require("./animated/HeadlineCTASection"));
var _MapSection = _interopRequireDefault(require("./animated/MapSection"));
var _WebDevPricingSection = _interopRequireDefault(require("./templates/web-dev-site/WebDevPricingSection"));
var _FlashCardsContentSection = _interopRequireDefault(require("./templates/cocktail-flash-cards/FlashCardsContentSection"));
var _TheDrinkerySpecials = _interopRequireDefault(require("./templates/the-drinkery/TheDrinkerySpecials"));
var _TheDrinkeryStaffSection = _interopRequireDefault(require("./templates/the-drinkery/TheDrinkeryStaffSection"));
var _TheDrinkeryOtherSideSection = _interopRequireDefault(require("./templates/the-drinkery/TheDrinkeryOtherSideSection"));
var _TheDrinkeryAlbumSection = _interopRequireDefault(require("./templates/the-drinkery/TheDrinkeryAlbumSection"));
var _HolidayHeadlineSection = _interopRequireDefault(require("./holiday-headline-section/HolidayHeadlineSection"));
var _SanityContext = _interopRequireDefault(require("../common/sanityIo/sanity-context/SanityContext"));
var _AWBallSearchSection = _interopRequireDefault(require("./templates/anybody-walking/AWBallSearchSection"));
var _AWSingleBallPageSection = _interopRequireDefault(require("./templates/anybody-walking/AWSingleBallPageSection"));
var _AWBallSummarySection = _interopRequireDefault(require("./templates/anybody-walking/AWBallSummarySection"));
var _BallToolsSection = _interopRequireDefault(require("./templates/anybody-walking/BallToolsSection"));
var _AWNewHouseFormSection = _interopRequireDefault(require("./templates/anybody-walking/AWNewHouseFormSection"));
var _AWContactUs = _interopRequireDefault(require("./templates/anybody-walking/AWContactUs"));
var _AcresSpadesContentSection = _interopRequireDefault(require("./dj-40-spades-rules/41AcresSpadesContentSection"));
var _BartenderHeroSection = _interopRequireDefault(require("./templates/my-digital-resume/the-bartender/BartenderHeroSection"));
var _BartenderExperienceSection = _interopRequireDefault(require("./templates/my-digital-resume/the-bartender/BartenderExperienceSection"));
var _BartenderStatsCounterSection = _interopRequireDefault(require("./templates/my-digital-resume/the-bartender/BartenderStatsCounterSection"));
var _AWHouseInfoSection = _interopRequireDefault(require("./templates/anybody-walking/AWHouseInfoSection"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const BlockContentLayoutContainer = props => {
  var _props$content$map, _props$content;
  const classes = (0, _ThwCommonStyles.default)();
  const theme = (0, _material.useTheme)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    children: (_props$content$map = props === null || props === void 0 || (_props$content = props.content) === null || _props$content === void 0 ? void 0 : _props$content.map((columnLayoutContainer, index) => {
      switch (columnLayoutContainer._type) {
        case 'column1BlockContent':
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            justifyContent: 'center',
            alignItems: 'stretch',
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Card, {
                className: classes.root,
                style: {
                  paddingTop: '80px'
                },
                children: (0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  item: true,
                  xs: 12,
                  className: classes.layoutContainer,
                  children: (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    xs: 12,
                    children: (0, _jsxRuntime.jsx)(_blockContentToReact.default, {
                      blocks: columnLayoutContainer.content,
                      serializers: _BlockContentRenderer.blockSerializers,
                      projectId: sanityContext.theSanityClient.config().projectId,
                      dataset: sanityContext.theSanityClient.config().dataset
                    })
                  })
                })
              })
            })
          }, 'column1BlockContent');
        case 'column2BlockContent':
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            justifyContent: 'center',
            alignItems: 'stretch',
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Card, {
                className: classes.root,
                style: {
                  paddingTop: '80px'
                },
                children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                  container: true,
                  item: true,
                  xs: 12,
                  className: classes.layoutContainer,
                  children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    xs: 6,
                    children: (0, _jsxRuntime.jsx)(_blockContentToReact.default, {
                      blocks: columnLayoutContainer.column1.content,
                      serializers: _BlockContentRenderer.blockSerializers,
                      projectId: sanityContext.theSanityClient.config().projectId,
                      dataset: sanityContext.theSanityClient.config().dataset
                    })
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    xs: 6,
                    children: (0, _jsxRuntime.jsx)(_blockContentToReact.default, {
                      blocks: columnLayoutContainer.column2.content,
                      serializers: _BlockContentRenderer.blockSerializers,
                      projectId: sanityContext.theSanityClient.config().projectId,
                      dataset: sanityContext.theSanityClient.config().dataset
                    })
                  })]
                })
              })
            })
          }, 'column2BlockContent');
        case 'transformHeroContentSection':
          const thwHeroSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "TOP_OF_PAGE",
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_MMHeroContentSection.default, {
              sectionData: thwHeroSection
            })]
          }, 'transformHeroContentSection');
        case 'transformServiceItem':
          const thwServiceEducationPage = columnLayoutContainer;
          // const fetchedServiceItem =
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "TOP_OF_PAGE",
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_ThwServiceEducationPage.default, {
              serviceData: thwServiceEducationPage
            })]
          }, 'transformServiceItem');
        case 'transformPositivePsychologySection':
          const thwPositivePsychologySection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            style: {
              backgroundColor: theme.palette.background.paper
            },
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "ABOUT_US",
              style: {
                position: "relative",
                top: -80
              },
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_ThwPositivePsychology.default, {
              sectionData: thwPositivePsychologySection
            })]
          }, 'transformPositivePsychologySection');
        case 'transformMottoSection':
          const thwMottoSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            style: {
              backgroundColor: theme.palette.background.paper
            },
            children: (0, _jsxRuntime.jsx)(_ThwMottoSection.default, {
              sectionData: thwMottoSection
            })
          }, 'transformMottoSection');
        case 'transformAboutProprietorSection':
          const thwProprietorSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            style: {
              backgroundColor: theme.palette.background.paper
            },
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "ABOUT_PROPRIETOR",
              style: {
                position: "relative",
                top: -80
              },
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_AboutTheProprietorSection.default, {
              sectionData: thwProprietorSection
            })]
          }, 'transformAboutProprietorSection');
        case 'transformServicesSection':
          const thwServicesSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            style: {
              backgroundColor: theme.palette.background.paper
            },
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "SERVICES",
              style: {
                position: "relative",
                top: -80
              },
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_ThwServicesSection.default, {
              sectionData: thwServicesSection
            })]
          }, 'transformServicesSection');
        case 'transformWhyChooseUsSection':
          const thwWCUSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            style: {
              backgroundColor: theme.palette.background.paper
            },
            children: (0, _jsxRuntime.jsx)(_ThwWhyChooseUsSection.default, {
              sectionData: thwWCUSection
            })
          }, 'transformWhyChooseUsSection');
        case 'transformContactUsSection':
          const thwCUSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            style: {
              backgroundColor: theme.palette.background.paper
            },
            children: (0, _jsxRuntime.jsx)(_ThwContactUsSection.default, {
              sectionData: thwCUSection
            })
          }, 'transformContactUsSection');
        case 'ResumeBioSection':
          const resumeBioSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "TOP_OF_PAGE",
              style: {
                position: "relative",
                top: -80
              },
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_material.Link, {
              id: "BIO",
              style: {
                position: "relative",
                top: -80
              },
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_ResumeBioSection.default, {
              homePage: props.homePage,
              sectionData: resumeBioSection
            })]
          }, 'ResumeBioSection');
        case 'ResumeSkillSection':
          const resumeSkillSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "SKILLS",
              style: {
                position: "relative",
                top: -80
              },
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_ResumeSkillsSection.default, {
              sectionData: resumeSkillSection
            })]
          }, 'ResumeSkillSection');
        case 'ResumeExperienceSection':
          const resumeExperienceSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "EXPERIENCE",
              style: {
                position: "relative",
                top: -80
              },
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_ResumeExperienceSection.default, {
              sectionData: resumeExperienceSection
            })]
          }, 'ResumeExperienceSection');
        case 'ResumeEducationSection':
          const resumeEducationSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "EDUCATION",
              style: {
                position: "relative",
                top: -80
              },
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_ResumeEducationSection.default, {
              sectionData: resumeEducationSection
            })]
          }, 'ResumeEducationSection');
        case 'ResumeFeedbackSection':
          const resumeFeedbackSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "FEEDBACK",
              style: {
                position: "relative",
                top: -80
              },
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_ResumeFeedbackSection.default, {
              sectionData: resumeFeedbackSection
            })]
          }, 'ResumeFeedbackSection');
        case 'ResumePortfolioSection':
          const resumePortfolioSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "PORTFOLIO",
              style: {
                position: "relative",
                top: -80
              },
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_ResumePortfolioSection.default, {
              sectionData: resumePortfolioSection
            })]
          }, 'ResumePortfolioSection');
        case 'ResumeContactUsSection':
          const resumeContactUsSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "CONTACT",
              style: {
                position: "relative",
                top: -80
              },
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_ResumeContactUsSection.default, {
              sectionData: resumeContactUsSection
            })]
          }, 'ResumeContactUsSection');
        case 'WebDevHeroContentSection':
          const webDevHeroSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "TOP_OF_PAGE",
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_WebDevHeroContentSection.default, {
              sectionData: webDevHeroSection
            })]
          }, 'webDevHeroContentSection');
        case 'WebDevStatsCounterSection':
          const webDevStatsCounterSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: (0, _jsxRuntime.jsx)(_WebDevStatsCounterSection.default, {
              sectionData: webDevStatsCounterSection
            })
          }, 'webDevStatsCounterSection');
        case 'BartenderStatsCounterSection':
          const bartenderStatsCounterSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: (0, _jsxRuntime.jsx)(_BartenderStatsCounterSection.default, {
              sectionData: bartenderStatsCounterSection
            })
          }, 'bartenderStatsCounterSection');
        case 'WebDevAboutUsSection':
          const webDevAboutUsSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "ABOUT_US",
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_WebDevAboutUsSection.default, {
              sectionData: webDevAboutUsSection
            })]
          }, 'webDevAboutUsSection');
        case 'ServicesSection':
          const webDevServicesSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "SERVICES",
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_WebDevServicesSection.default, {
              sectionData: webDevServicesSection
            })]
          }, 'webDevServicesSection');
        case 'PortfolioSection':
          const webDevPortfolioSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "PORTFOLIO",
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_WebDevPortfolioSection.default, {
              sectionData: webDevPortfolioSection
            })]
          }, 'webDevPortfolioSection');
        case 'TestimonialsSection':
          const webDevTestimonialsSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "TESTIMONIALS",
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_WebDevTestimonialsSection.default, {
              sectionData: webDevTestimonialsSection
            })]
          }, 'webDevTestimonialsSection');
        case 'WebDevHowItWorksSection':
          const webDevHowItWorksSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "HOW_IT_WORKS",
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_WebDevHowItWorksSection.default, {
              sectionData: webDevHowItWorksSection
            })]
          }, 'webDevHowItWorksSection');
        case 'HeroAnimatedContentSection':
          const heroAnimatedContentSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "ANIMATED_HERO",
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_HeroAnimatedContentSection.default, {
              sectionData: heroAnimatedContentSection
            })]
          }, 'animated-hero');
        case 'AnimatedAboutUsSection':
          const animatedAboutusSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "ANIMATED_ABOUT_US",
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_AnimatedAboutUsSection.default, {
              sectionData: animatedAboutusSection
            })]
          }, 'animated-about-us');
        case 'AnimatedServicesSection':
          const animatedServicesSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "ANIMATED_SERVICES",
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_AnimatedServicesSection.default, {
              sectionData: animatedServicesSection
            })]
          }, 'animated-services');
        case 'AnimatedPortfolioSection':
          const animatedPortfolioSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "ANIMATED_PORTFOLIO",
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_AnimatedPortfolioSection.default, {
              sectionData: animatedPortfolioSection
            })]
          }, 'animated-portfolio');
        case 'HeadlineCTASection':
          const headlineSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            style: {
              zIndex: 1000
            },
            children: (0, _jsxRuntime.jsx)(_HeadlineCTASection.default, {
              sectionData: headlineSection
            })
          }, 'headline-section');
        case 'MapSection':
          const mapSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "MAP_SECTION",
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_MapSection.default, {
              sectionData: mapSection
            })]
          }, 'map-section');
        case 'WebDevPricingSection':
          const pricingSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "PRICING_SECTION",
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_WebDevPricingSection.default, {
              sectionData: pricingSection
            })]
          }, 'map-section');
        case 'FlashCardSection':
          const flashCardSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            style: {
              position: "relative"
            },
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "FLASHCARD_SECTION",
              sx: {
                position: "absolute",
                top: -90
              },
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_FlashCardsContentSection.default, {
              sectionData: flashCardSection
            })]
          }, index);
        case 'BartenderHeroSection':
          const bartenderHeroSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            style: {
              paddingTop: "68px",
              position: "relative"
            },
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              sx: {
                position: "absolute",
                top: -160
              },
              id: "HERO_SECTION",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_BartenderHeroSection.default, {
              sectionData: bartenderHeroSection
            })]
          }, index);
        case 'BartenderExperienceSection':
          const bartenderExperienceSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            style: {
              position: "relative"
            },
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "EXPERIENCE_SECTION",
              style: {
                position: "absolute",
                top: -90
              },
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_BartenderExperienceSection.default, {
              sectionData: bartenderExperienceSection
            })]
          }, index);
        case 'DrinkerySpecialsSection':
          const drinkerySpecialsSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "SPECIALS",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_TheDrinkerySpecials.default, {
              sectionData: drinkerySpecialsSection
            })]
          }, 'specials-section');
        case 'DrinkeryTeamSection':
          const drinkeryStaffSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "STAFF_SECTION",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_TheDrinkeryStaffSection.default, {
              sectionData: drinkeryStaffSection
            })]
          }, 'staff-section');
        case 'DrinkeryOtherSideSection':
          const drinkeryOtherSideSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "OTHER_SIDE_SECTION",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_TheDrinkeryOtherSideSection.default, {
              sectionData: drinkeryOtherSideSection
            })]
          }, 'other-side-section');
        case 'DrinkeryAlbumSection':
          const drinkeryAlbumSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "ALBUM_SECTION",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_TheDrinkeryAlbumSection.default, {
              sectionData: drinkeryAlbumSection
            })]
          }, 'album-section');
        case 'HolidayHeadlineSection':
          const holidayHeadlineSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_HolidayHeadlineSection.default, {
              sectionData: holidayHeadlineSection
            })
          }, holidayHeadlineSection.slug.current + '-holiday-headline-section');
        case 'AWBallSection':
          const awBallSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_AWBallSearchSection.default, {
              sectionData: awBallSection
            })
          }, 'aw-ball-section');
        case 'AWNewHouseFormSection':
          const awNewHouseFormSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_AWNewHouseFormSection.default, {
              sectionData: awNewHouseFormSection
            })
          }, 'aw-new-house-form-section');
        case 'AWHouseInfoSection':
          const awHouseInfoSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_AWHouseInfoSection.default, {
              sectionData: awHouseInfoSection
            })
          }, 'aw-house-info-section');
        case 'AWBallToolsSection':
          const awBallToolsSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_BallToolsSection.default, {
              sectionData: awBallToolsSection
            })
          }, 'aw-ball-section');
        case 'AWBallSummarySection':
          const awBallSummarySection = columnLayoutContainer;
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_AWBallSummarySection.default, {
              sectionData: awBallSummarySection
            })
          }, 'aw-ball-summary-section');
        case 'AWSingleBallPageSection':
          const awSingleBallPageSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_AWSingleBallPageSection.default, {
              sectionData: awSingleBallPageSection
            })
          }, 'aw-ball-section');
        case 'SimpleStringListSection':
          const simpleStringListSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_AcresSpadesContentSection.default, {
              sectionData: simpleStringListSection
            })
          }, 'spades-rules-list');
        case 'ContactUs':
          const awContactUsSection = columnLayoutContainer;
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_AWContactUs.default, {
              sectionData: awContactUsSection
            })
          }, 'aw-contact-us-section');
        default:
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true
          });
        // return <span key={index}>Undefined section {columnLayoutContainer._type}</span>
      }
    })) !== null && _props$content$map !== void 0 ? _props$content$map : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
  });
};
var _default = exports.default = BlockContentLayoutContainer;