"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _blockContentToReact = _interopRequireDefault(require("@sanity/block-content-to-react"));
var _material = require("@mui/material");
var _BlockContentRenderer = require("../common/sanityIo/BlockContentRenderer");
var _ThwCommonStyles = _interopRequireDefault(require("../common/sanityIo/ThwCommonStyles"));
var _Header = _interopRequireDefault(require("./templates/mackenzies-mind/header/Header"));
var _EnhancedHeader = _interopRequireDefault(require("./templates/mackenzies-mind/header/EnhancedHeader"));
var _CustomizedThemeContext = _interopRequireDefault(require("./customized-theme-provider/CustomizedThemeContext"));
var _PageContext = _interopRequireDefault(require("./page-context/PageContext"));
var _SanityContext = _interopRequireDefault(require("../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const HeaderBlockContentLayoutContainer = props => {
  var _props$content$map, _props$content;
  const classes = (0, _ThwCommonStyles.default)();
  // const mdDown = widthUtils.useIsWidthDown('md')
  const customizedTheme = (0, _react.useContext)(_CustomizedThemeContext.default);
  const page = (0, _react.useContext)(_PageContext.default);
  const [showBasicHeader, setShowBasicHeader] = (0, _react.useState)(false);
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  (0, _react.useEffect)(() => {
    const handResize = () => {
      if (window.innerWidth < customizedTheme.customizedTheme.breakpoints.values['md']) {
        setShowBasicHeader(true);
      } else {
        setShowBasicHeader(false);
      }
    };
    window.addEventListener('resize', handResize);
    handResize();
    return () => {
      window.removeEventListener('resize', handResize);
    };
    // eslint-disable-next-line
  }, []);
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    children: (_props$content$map = props === null || props === void 0 || (_props$content = props.content) === null || _props$content === void 0 ? void 0 : _props$content.map((columnLayoutContainer, index) => {
      var _page$page;
      switch (columnLayoutContainer._type) {
        case 'column1BlockContent':
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            justifyContent: 'center',
            alignItems: 'stretch',
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Card, {
                className: classes.root,
                style: {
                  paddingTop: '80px'
                },
                children: (0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  item: true,
                  xs: 12,
                  className: classes.layoutContainer,
                  children: (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    xs: 12,
                    children: (0, _jsxRuntime.jsx)(_blockContentToReact.default, {
                      blocks: columnLayoutContainer.content,
                      serializers: _BlockContentRenderer.blockSerializers,
                      projectId: sanityContext.theSanityClient.config().projectId,
                      dataset: sanityContext.theSanityClient.config().dataset
                    })
                  })
                })
              })
            })
          }, 'column1BlockContent_header');
        case 'column2BlockContent':
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            justifyContent: 'center',
            alignItems: 'stretch',
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Card, {
                className: classes.root,
                style: {
                  paddingTop: '80px'
                },
                children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                  container: true,
                  item: true,
                  xs: 12,
                  className: classes.layoutContainer,
                  children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    xs: 6,
                    children: (0, _jsxRuntime.jsx)(_blockContentToReact.default, {
                      blocks: columnLayoutContainer.column1.content,
                      serializers: _BlockContentRenderer.blockSerializers,
                      projectId: sanityContext.theSanityClient.config().projectId,
                      dataset: sanityContext.theSanityClient.config().dataset
                    })
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    xs: 6,
                    children: (0, _jsxRuntime.jsx)(_blockContentToReact.default, {
                      blocks: columnLayoutContainer.column2.content,
                      serializers: _BlockContentRenderer.blockSerializers,
                      projectId: sanityContext.theSanityClient.config().projectId,
                      dataset: sanityContext.theSanityClient.config().dataset
                    })
                  })]
                })
              })
            })
          }, 'column2BlockContent_header');
        case 'HeaderSection':
          const header = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "TOP_OF_PAGE",
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), showBasicHeader || !header.isEnhanced ? (0, _jsxRuntime.jsx)(_Header.default, {
              businessContact: (_page$page = page.page) === null || _page$page === void 0 ? void 0 : _page$page.businessContact,
              backgroundColor: header.backgroundColor,
              isSearch: header.isSearch,
              isAppBar: true,
              pageHeader: header.headerMenuRef
            }) : (0, _jsxRuntime.jsx)(_EnhancedHeader.default, {
              pageHeader: header
            })]
          }, 'TOP_OF_PAGE');
        default:
          return (0, _jsxRuntime.jsxs)("span", {
            children: ["Undefined section ", columnLayoutContainer._type]
          }, index);
      }
    })) !== null && _props$content$map !== void 0 ? _props$content$map : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
  });
};
var _default = exports.default = HeaderBlockContentLayoutContainer;