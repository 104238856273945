"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _BartenderExperienceItem = _interopRequireDefault(require("./BartenderExperienceItem"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  contactIcons: {
    paddingLeft: "16px",
    color: "gray"
  }
}));
const BartenderExperienceSection = props => {
  var _props$sectionData$ex;
  const theme = (0, _material.useTheme)();
  const xsOnly = (0, _material.useMediaQuery)(theme.breakpoints.only('xs'));
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    sx: {
      backgroundColor: "black",
      padding: "16px"
    },
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'center',
      sx: {
        border: "3px solid white"
      },
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        md: 12,
        justifyContent: 'center',
        alignContent: 'flex-start',
        color: 'white',
        sx: {
          borderBottom: "3px solid white",
          padding: theme.spacing(1, 2)
        },
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'h6',
          color: 'inherit',
          children: props.sectionData.title
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        sx: {
          padding: "16px"
        },
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          spacing: 2,
          justifyContent: xsOnly ? 'center' : 'flex-start',
          children: (_props$sectionData$ex = props.sectionData.experiences) === null || _props$sectionData$ex === void 0 ? void 0 : _props$sectionData$ex.map((experience, experienceIndex) => {
            return (0, _jsxRuntime.jsxs)(_material.Grid, {
              item: true,
              container: true,
              sm: 6,
              md: 4,
              children: [(0, _jsxRuntime.jsx)(_material.Link, {
                id: experience._id,
                underline: "hover",
                style: {
                  position: "relative",
                  top: -90
                },
                children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
              }), (0, _jsxRuntime.jsx)(_BartenderExperienceItem.default, {
                experience: experience
              }, experienceIndex)]
            }, experienceIndex);
          })
        })
      })]
    })
  });
};
var _default = exports.default = BartenderExperienceSection;