"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/es.promise.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _clsx = _interopRequireDefault(require("clsx"));
var _Styles = _interopRequireDefault(require("../mackenzies-mind/pages/Styles"));
var _BallSearchContext = _interopRequireDefault(require("./ball-search-context/BallSearchContext"));
var _AppSettingsContext = _interopRequireDefault(require("./app-settings/AppSettingsContext"));
var _reactRouterDom = require("react-router-dom");
var _Routes = require("./enums/Routes.enum");
var _BallDataTable = _interopRequireDefault(require("./ball-data-table/BallDataTable"));
var _BallFormSteps = _interopRequireDefault(require("./ball-form-steps/BallFormSteps"));
var _ViewChange = _interopRequireDefault(require("./view-change/ViewChange"));
var _BallSearchBox = _interopRequireDefault(require("./ball-search-box/BallSearchBox"));
var _SearchFilterDropDown = _interopRequireDefault(require("./search-filter-dropdown/SearchFilterDropDown"));
var _BallDataTiles = _interopRequireDefault(require("./ball-data-tiles/BallDataTiles"));
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
var _BallSearchProviderWrapper = _interopRequireDefault(require("./BallSearchProviderWrapper"));
var _FirebaseContext = _interopRequireDefault(require("../../../common/firebase/firebase-context/FirebaseContext"));
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  preroot: {
    minHeight: '521px',
    overflow: 'hidden'
    // color: "white",
    // position: "relative",
  }
}));
const AWBallSearchSection = props => {
  var _ref, _pageContext$page$the, _pageContext$page;
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const searchContext = (0, _react.useContext)(_BallSearchContext.default);
  const navigate = (0, _reactRouterDom.useNavigate)();
  const classes = (0, _Styles.default)({
    bgImage: undefined
  });
  const theClasses = useStyles();
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const buttonText = "Add a new Ball";
  const [displayedResults, setDisplayedResults] = (0, _react.useState)();
  (0, _react.useEffect)(() => {
    searchContext.setDisplayResults && searchContext.setDisplayResults(displayedResults);
  }, [displayedResults]);
  (0, _react.useEffect)(() => {
    if (props.balls) {
      setDisplayedResults(props.balls);
    }
  }, [props.balls]);
  const getNewData = async () => {
    const remainingBalls = props.balls ? props.balls : await sanityContext.fetchAllApprovedBalls('');
    setDisplayedResults(remainingBalls);
  };
  (0, _react.useEffect)(() => {
    getNewData().then();
  }, []);
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsx)(_BallSearchProviderWrapper.default, {
    results: props.balls,
    children: (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      className: theClasses.preroot,
      sx: {
        padding: theme.spacing((_ref = ((_pageContext$page$the = (_pageContext$page = pageContext.page) === null || _pageContext$page === void 0 || (_pageContext$page = _pageContext$page.theme) === null || _pageContext$page === void 0 ? void 0 : _pageContext$page.appBarHeight) !== null && _pageContext$page$the !== void 0 ? _pageContext$page$the : 8) / 8) !== null && _ref !== void 0 ? _ref : 8.5, 0)
      },
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        className: (0, _clsx.default)(classes.fullSection),
        justifyContent: 'center',
        alignItems: 'center',
        children: (0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          alignContent: 'center',
          container: true,
          direction: 'column',
          style: {
            overflow: 'hidden',
            position: "relative"
          },
          children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
            item: true,
            container: true,
            style: {
              zIndex: 1,
              position: "fixed",
              left: 0,
              backgroundColor: "whitesmoke",
              padding: "16px"
            },
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              justifyContent: 'center',
              paddingLeft: "32px",
              children: (0, _jsxRuntime.jsx)(_BallSearchBox.default, {})
            }), (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              justifyContent: 'space-between',
              marginTop: 1,
              alignItems: 'center',
              // paddingX={1.5}
              style: {
                backgroundColor: "white"
              },
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_SearchFilterDropDown.default, {})
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                justifyContent: 'flex-end',
                children: (0, _jsxRuntime.jsx)(_ViewChange.default, {})
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            spacing: 3,
            justifyContent: 'center',
            style: {
              paddingTop: "150px",
              paddingLeft: "8px",
              minHeight: "700px"
            },
            children: (0, _jsxRuntime.jsx)(_AppSettingsContext.default.Consumer, {
              children: appSettings => (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                item: true,
                children: [(0, _jsxRuntime.jsx)(_BallSearchContext.default.Consumer, {
                  children: searchValue => searchValue.viewType ? (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    container: true,
                    children: (0, _jsxRuntime.jsx)(_BallDataTiles.default, {
                      tileClickAnalytics: tileSlug => {
                        firebaseContext.ctaClick && firebaseContext.ctaClick('searched-ball-tile-click', tileSlug, pageContext.analyticsId);
                      },
                      tiles: searchValue.displayResults
                    })
                  }) : (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    container: true,
                    justifyContent: "center",
                    paddingTop: '2em',
                    children: (0, _jsxRuntime.jsx)(_material.Grid, {
                      item: true,
                      children: (0, _jsxRuntime.jsx)(_BallDataTable.default, {})
                    })
                  })
                }), appSettings.newAddBallStepsFlow ? (0, _jsxRuntime.jsx)(_BallFormSteps.default, {}) : (0, _jsxRuntime.jsx)(_material.Fab, {
                  style: {
                    position: 'fixed',
                    bottom: '32px',
                    right: '32px',
                    width: '200px',
                    height: '40px',
                    borderRadius: '3px'
                  },
                  onClick: () => {
                    firebaseContext.ctaClick && firebaseContext.ctaClick('search-page', buttonText, pageContext.analyticsId);
                    navigate(_Routes.RoutesEnum.ADD_BALL);
                  },
                  color: 'primary',
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    noWrap: true,
                    children: "Add a new Ball"
                  })
                })]
              })
            })
          })]
        })
      })
    })
  });
};
var _default = exports.default = AWBallSearchSection;