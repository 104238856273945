"use strict";

require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/es.error.cause.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _Styles = _interopRequireDefault(require("../templates/mackenzies-mind/pages/Styles"));
var _CssFadeToColorDirectionEnum = require("./CssFadeToColorDirectionEnum");
var _CustomizedThemeContext = _interopRequireDefault(require("../customized-theme-provider/CustomizedThemeContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  root: {
    height: '430px',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(5)
  },
  contentBullets: {
    marginBottom: theme.spacing(5)
  }
}));
const CssFadeToColor = props => {
  const globalClasses = (0, _Styles.default)({});
  const customizedThemeContext = (0, _react.useContext)(_CustomizedThemeContext.default);
  const smDown = (0, _material.useMediaQuery)(customizedThemeContext.customizedTheme.breakpoints.down('sm'));
  const getStyle = () => {
    const getRotationAngle = () => {
      switch (props.direction) {
        case _CssFadeToColorDirectionEnum.CssFadeToColorDirectionEnum.TOP:
          return -180;
        case _CssFadeToColorDirectionEnum.CssFadeToColorDirectionEnum.LEFT:
          return -90;
        case _CssFadeToColorDirectionEnum.CssFadeToColorDirectionEnum.RIGHT:
          return 90;
        case _CssFadeToColorDirectionEnum.CssFadeToColorDirectionEnum.BOTTOM:
        default:
          return 180;
      }
    };
    const getColor = () => {
      if (props.isResponsive && smDown) return 'transparent';
      let fromColor = props.fromColor ? props.fromColor : 'transparent';
      let toColor = props.toColor;
      if (props.direction === _CssFadeToColorDirectionEnum.CssFadeToColorDirectionEnum.TOP) {
        let temp = fromColor;
        fromColor = toColor;
        toColor = temp;
      }
      return "linear-gradient(".concat(getRotationAngle(), "deg, ").concat(fromColor, ", ").concat(toColor);
    };
    const style = {
      position: "absolute",
      backgroundImage: getColor()
    };
    switch (props.direction) {
      case _CssFadeToColorDirectionEnum.CssFadeToColorDirectionEnum.TOP:
        return _objectSpread(_objectSpread({}, style), {}, {
          top: 0,
          height: '120px'
        });
      case _CssFadeToColorDirectionEnum.CssFadeToColorDirectionEnum.LEFT:
        return _objectSpread(_objectSpread({}, style), {}, {
          left: 0,
          width: '120px',
          height: '100%'
        });
      case _CssFadeToColorDirectionEnum.CssFadeToColorDirectionEnum.RIGHT:
        return _objectSpread(_objectSpread({}, style), {}, {
          right: 0,
          width: '120px',
          height: '100%'
        });
      case _CssFadeToColorDirectionEnum.CssFadeToColorDirectionEnum.BOTTOM:
      default:
        return _objectSpread(_objectSpread({}, style), {}, {
          bottom: 0,
          height: '120px'
        });
    }
  };
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    className: globalClasses.fullContainer,
    style: {
      position: 'absolute',
      minHeight: "120px",
      minWidth: "120px"
    },
    children: (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      style: getStyle()
    })
  });
};
var _default = exports.default = CssFadeToColor;