"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _SnackbarContext = _interopRequireDefault(require("./SnackbarContext"));
var _material = require("@mui/material");
var _reactCountdown = _interopRequireDefault(require("react-countdown"));
var _SecondsCountdownButton = _interopRequireDefault(require("./SecondsCountdownButton"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const SnackbarProvider = props => {
  const [snackbarOpen, setSnackbarOpen] = (0, _react.useState)(false);
  const [snackPack, setSnackPack] = (0, _react.useState)([]);
  const [autoCloseTime, setAutoCloseTime] = (0, _react.useState)(15);
  const [messageInfo, setMessageInfo] = (0, _react.useState)(undefined);
  (0, _react.useEffect)(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo(_objectSpread({}, snackPack[0]));
      setSnackPack(prev => prev.slice(1));
      setSnackbarOpen(true);
    } else if (snackPack.length && messageInfo && snackbarOpen) {
      // Close an active snack when a new one is added
      setSnackbarOpen(false);
    }
  }, [snackPack, messageInfo, snackbarOpen]);
  const handleSnackbarClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleExited = () => {
    setMessageInfo(undefined);
    setSnackbarOpen(false);
  };
  const openSnackbar = (message, time) => {
    setAutoCloseTime(time);
    setSnackPack(prev => [...prev, {
      message,
      key: new Date().getTime()
    }]);
    // setSnackbarOpen(state=>!state)
  };
  const newValue = (0, _react.useMemo)(() => ({
    openSnackbar,
    handleSnackbarClose
  }), []);
  return (0, _jsxRuntime.jsxs)(_SnackbarContext.default.Provider, {
    value: newValue,
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsx)(_material.Snackbar, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        TransitionProps: {
          onExited: handleExited
        },
        open: snackbarOpen,
        autoHideDuration: autoCloseTime == null ? null : autoCloseTime,
        onClose: handleSnackbarClose,
        message: messageInfo ? messageInfo.message : undefined,
        action: (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          alignContent: 'center',
          alignItems: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: autoCloseTime ? (0, _jsxRuntime.jsx)(_reactCountdown.default, {
              date: autoCloseTime ? new Date(Date.now() + (autoCloseTime - 1000)) : undefined,
              renderer: date => (0, _jsxRuntime.jsx)(_SecondsCountdownButton.default, {
                date: date,
                totalTimeSeconds: autoCloseTime
              })
            }) : (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              justifyContent: 'center',
              children: (0, _jsxRuntime.jsx)(_SecondsCountdownButton.default, {
                totalTimeSeconds: autoCloseTime
              })
            })
          })
        })
      }, messageInfo ? messageInfo.key : undefined)
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: props.children
    })]
  });
};
var _default = exports.default = SnackbarProvider;