"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.promise.js");
var _clientUtils = _interopRequireDefault(require("../../transform-hw/pages/under-construction-page/clientUtils"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const search = async searchTerm => {
  var _ref;
  return fetch((_ref = "https://www.thecocktaildb.com/api/json/v1/1/search.php?s=" + searchTerm) !== null && _ref !== void 0 ? _ref : "").then(response => {
    return _clientUtils.default.processResponse(response, 'cocktaildbresponse');
  }).catch(e => {
    // console.error(LOG, 'ERROR', 'error', e);
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      attempt: Error(e)
    });
  });
};
const convertFromCocktailDbResultsToCocktails = _cocktailDbResults => {
  // const cocktailResults =
};
var _default = exports.default = {
  search,
  convertFromCocktailDbResultsToCocktails
};