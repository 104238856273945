"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  root: props => {
    var _props$height;
    return {
      backgroundImage: "url(".concat(props.logoImageSrc, ")"),
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      minWidth: "100px",
      height: "".concat((_props$height = props.height) !== null && _props$height !== void 0 ? _props$height : 68, "px")
      // marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(2)
    };
  }
}));
const TheOtherSideLogo = props => {
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const classes = useStyles({
    logoImageSrc: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.logoImageSrc, 732, 192),
    height: props.height
  });
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    item: true,
    container: true,
    className: classes.root,
    style: {
      backgroundPosition: props.isCenter ? "center" : "left"
    }
  });
};
var _default = exports.default = TheOtherSideLogo;