"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.blockSerializers = exports.HrRender = exports.HeaderRender = exports.CtaRender = exports.ButtonRender = exports.BlockContainerRender = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _CommonStyles = require("./CommonStyles");
var _BlockContentMarkRenderers = require("./BlockContentMarkRenderers");
var _blockContentToReact = _interopRequireDefault(require("@sanity/block-content-to-react"));
var _BlockContentAnnotations = require("./BlockContentAnnotations");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// import {dark} from 'react-syntax-highlighter/dist/esm/styles/prism'

const HeaderRender = (props, variant) => {
  const theme = (0, _material.useTheme)();
  const wrapWithHTag = children => {
    switch (variant) {
      case 'h1':
        return (0, _jsxRuntime.jsx)("h1", {
          style: {
            fontFamily: "Bitter Pro"
          },
          children: children
        });
      case 'h2':
        return (0, _jsxRuntime.jsx)("h2", {
          style: {
            fontFamily: "Bitter Pro"
          },
          children: children
        });
      case 'h3':
        return (0, _jsxRuntime.jsx)("h3", {
          style: {
            fontFamily: "Bitter Pro"
          },
          children: children
        });
      case 'h4':
        return (0, _jsxRuntime.jsx)("h4", {
          style: {
            fontFamily: "Bitter Pro"
          },
          children: children
        });
      case 'h5':
        return (0, _jsxRuntime.jsx)("h5", {
          style: {
            fontFamily: "Bitter Pro"
          },
          children: children
        });
      case 'h6':
        return (0, _jsxRuntime.jsx)("h6", {
          style: {
            fontFamily: "Bitter Pro"
          },
          children: children
        });
      default:
        return (0, _jsxRuntime.jsx)(_material.Typography, {
          display: 'inline',
          component: 'div',
          style: {
            color: theme.palette.secondary.main,
            fontWeight: variant === 'h3' ? 300 : 700,
            marginBottom: theme.spacing(3)
          },
          variant: variant,
          children: children
        });
    }
  };
  return (0, _jsxRuntime.jsxs)(_material.ThemeProvider, {
    theme: theme,
    children: [(0, _jsxRuntime.jsx)(_material.CssBaseline, {}), wrapWithHTag(props.children)]
  });
};
exports.HeaderRender = HeaderRender;
const CtaRender = props => {
  const theme = (0, _material.useTheme)();
  const classes = (0, _CommonStyles.useCommonStyles)(theme);
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    xs: 12,
    className: classes.callToAction,
    children: props.children
  });
};
exports.CtaRender = CtaRender;
const HrRender = () => {
  const theme = (0, _material.useTheme)();
  const classes = (0, _CommonStyles.useCommonStyles)(theme);
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    children: (0, _jsxRuntime.jsx)("hr", {
      className: classes.hr
    })
  });
};
// type SanityButtonBlockContentType = {
//     buttonText?: string, buttonLink?: string, color?: string
// }
exports.HrRender = HrRender;
const ButtonRender = props => {
  // const sanityButton:{buttonText?:string, buttonLink?:string, color?:string, variant?:string} = props.node as SanityButtonBlockContentType
  // console.log('Props from button', props)
  const theme = (0, _material.useTheme)();
  let textColor = '';
  // switch(props.color) {
  //   case 'secondary':
  //     textColor = "whitesmoke"
  //     break;
  //   case 'primary':
  //     textColor = "whitesmoke"
  //     break;
  //   case 'mint':
  //     textColor = TransformHWTheme.palette.secondary.main
  //     break;
  //   default:
  //     textColor = "whitesmoke"
  // }
  switch (props === null || props === void 0 ? void 0 : props.variant) {
    case 'outlined':
      switch (props.color) {
        case 'secondary':
          textColor = theme.palette.secondary.main;
          break;
        case 'primary':
          textColor = theme.palette.primary.main;
          break;
        case 'mint':
          textColor = "";
          break;
        default:
          textColor = 'whitesmoke';
      }
      break;
    case 'contained':
      switch (props.color) {
        case 'secondary':
          textColor = 'whitesmoke';
          break;
        case 'primary':
          textColor = 'whitesmoke';
          break;
        case 'mint':
          textColor = theme.palette.secondary.main;
          break;
        default:
          textColor = theme.palette.background.paper;
      }
      break;
    case 'text':
    default:
      switch (props.color) {
        case 'secondary':
          textColor = theme.palette.secondary.main;
          break;
        case 'primary':
          textColor = theme.palette.primary.main;
          break;
        case 'mint':
          textColor = "";
          break;
        default:
          textColor = theme.palette.text.primary;
      }
      break;
  }
  return (0, _jsxRuntime.jsxs)(_material.ThemeProvider, {
    theme: theme,
    children: [(0, _jsxRuntime.jsx)(_material.CssBaseline, {}), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: (0, _jsxRuntime.jsx)(_material.Button, {
        style: (props === null || props === void 0 ? void 0 : props.color) === 'mint' ? (props === null || props === void 0 ? void 0 : props.variant) === 'contained' ? {
          backgroundColor: "",
          borderRadius: '20px'
        } : {
          backgroundColor: 'transparent',
          borderColor: "",
          borderRadius: '20px'
        } : {
          borderRadius: '20px'
        },
        variant: props.variant,
        color: (props === null || props === void 0 ? void 0 : props.color) != 'mint' ? props === null || props === void 0 ? void 0 : props.color : 'inherit',
        href: props.buttonLink,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'button',
          style: {
            color: textColor
          },
          children: props.children
        })
      })
    })]
  });
};
exports.ButtonRender = ButtonRender;
const BlockContainerRender = props => {
  return (0, _jsxRuntime.jsxs)(_material.Typography, {
    component: 'span',
    style: {
      width: '100%',
      overflow: 'scroll',
      height: '100%'
    },
    variant: 'body1',
    children: [(0, _jsxRuntime.jsx)(_material.CssBaseline, {}), props.children]
  });
};
exports.BlockContainerRender = BlockContainerRender;
const blockSerializers = exports.blockSerializers = {
  list: _BlockContentAnnotations.ListRender,
  listItem: _BlockContentAnnotations.ListItemRender,
  types: {
    // code: CodeBlockRender,
    utmLink: _BlockContentAnnotations.UtmLinkRender,
    button: ButtonRender,
    block: props => {
      switch (props.node.style) {
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
          return HeaderRender(props, props.node.style);
        case 'normal':
          return (0, _BlockContentMarkRenderers.NormalRender)(props);
        case 'cta':
          return CtaRender(props);
        default:
          //@ts-ignore
          return _blockContentToReact.default.defaultSerializers.types.block(props);
      }
    },
    lineBreak: props => {
      const {
        style
      } = props.node;
      if (style === 'horizontalRule') {
        return (0, _jsxRuntime.jsx)(HrRender, {});
      }
      return null;
    }
  },
  marks: {
    light: _BlockContentMarkRenderers.LightRender,
    dropCap: _BlockContentMarkRenderers.DropCapRender,
    primaryTextColor: props => (0, _BlockContentMarkRenderers.TextColorRender)(props, ""),
    secondaryTextColor: props => (0, _BlockContentMarkRenderers.TextColorRender)(props, ""),
    underlinePrimaryColor: props => (0, _BlockContentMarkRenderers.UnderlineRender)(props, ""),
    utmLink: _BlockContentAnnotations.UtmLinkRender,
    bold: _BlockContentMarkRenderers.BoldRender,
    button: _BlockContentAnnotations.ButtonMarkRender,
    keyStroke: _BlockContentMarkRenderers.KeystrokeRender,
    subtitle: props => (0, _BlockContentMarkRenderers.TypographyRender)(props, 'subtitle1'),
    overline: props => (0, _BlockContentMarkRenderers.TypographyRender)(props, 'overline'),
    largeBodyText: props => (0, _BlockContentMarkRenderers.LargeBodyTextRender)(props)
  },
  container: BlockContainerRender
};
var _default = exports.default = {
  HeaderRender,
  CtaRender,
  BlockContainerRender
};