"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.symbol.description.js");
var _jsxRuntime = require("react/jsx-runtime");
var _renderer = require("@react-pdf/renderer");
var _uuid = require("uuid");
var _dateUtils = _interopRequireDefault(require("../utils/dateUtils"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// const PDF_COLORS = {
//     primary: "#d20027"
// }
const ResumeDocumentPDF = props => {
  var _props$homePage;
  // Create styles
  const styles = _renderer.StyleSheet.create({
    page: {
      // flexDirection: 'row',
      // backgroundColor: '#E4E4E4'
      // fontFamily: "Raleway",
      padding: "16px"
    },
    section: {
      // margin: 10,
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 10
      // flexGrow: 1
    },
    subtitle: {
      fontSize: "10px"
    },
    body: {
      fontSize: "12px"
      // color:"red",
    },
    body2: {
      fontSize: "14px"
      // color:"red",
    },
    h5: {
      fontSize: "32px",
      fontWeight: "bold"
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      alignItems: "flex-start"
    },
    resumeSkillset: {
      // marginBottom: "8px",
      flexBasis: "50%",
      paddingTop: "8px",
      paddingBottom: "4px",
      paddingLeft: "8px",
      paddingRight: "8px"
      // flex: "1 "
    },
    resumeExperience: {
      // marginBottom: "8px",
      // flexBasis:"50%",
      paddingLeft: "8px",
      paddingTop: "8px"
      // flex: "1 "
    },
    chip: {
      backgroundColor: "#1D1D1D",
      borderRadius: "32px",
      color: "white",
      marginRight: "4px",
      marginBottom: "2px",
      paddingHorizontal: "12px",
      paddingVertical: "2px",
      fontWeight: 500
    }
  });
  return (0, _jsxRuntime.jsx)(_renderer.Document, {
    children: (0, _jsxRuntime.jsx)(_renderer.Page, {
      size: "A4",
      style: styles.page,
      children: props === null || props === void 0 || (_props$homePage = props.homePage) === null || _props$homePage === void 0 || (_props$homePage = _props$homePage.pageContent.content) === null || _props$homePage === void 0 ? void 0 : _props$homePage.map((columnLayoutContainer, pageIdx) => {
        var _props$homePage2, _props$homePage3, _props$homePage4, _props$homePage5, _props$homePage6, _resumeSkillSection$s, _resumeExperienceSect, _resumeEducationSecti, _resumePortfolioSecti;
        switch (columnLayoutContainer._type) {
          case 'ResumeBioSection':
            const resumeBioSection = columnLayoutContainer;
            return (0, _jsxRuntime.jsxs)(_renderer.View, {
              children: [(0, _jsxRuntime.jsxs)(_renderer.View, {
                style: {
                  textAlign: 'center',
                  marginBottom: "8px"
                },
                children: [(0, _jsxRuntime.jsx)(_renderer.View, {
                  style: styles.body2,
                  children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                    children: resumeBioSection.title
                  })
                }), (0, _jsxRuntime.jsx)(_renderer.View, {
                  style: styles.body,
                  children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                    children: (_props$homePage2 = props.homePage) === null || _props$homePage2 === void 0 || (_props$homePage2 = _props$homePage2.businessContact) === null || _props$homePage2 === void 0 ? void 0 : _props$homePage2.email
                  })
                }), (0, _jsxRuntime.jsx)(_renderer.View, {
                  style: styles.body,
                  children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                    children: (_props$homePage3 = props.homePage) === null || _props$homePage3 === void 0 || (_props$homePage3 = _props$homePage3.businessContact) === null || _props$homePage3 === void 0 ? void 0 : _props$homePage3.phone
                  })
                }), (0, _jsxRuntime.jsx)(_renderer.View, {
                  style: styles.body,
                  children: (0, _jsxRuntime.jsxs)(_renderer.Text, {
                    children: ["LinkedIn:", (_props$homePage4 = props.homePage) === null || _props$homePage4 === void 0 || (_props$homePage4 = _props$homePage4.businessContact) === null || _props$homePage4 === void 0 ? void 0 : _props$homePage4.linkedIn]
                  })
                }), (0, _jsxRuntime.jsx)(_renderer.View, {
                  style: styles.body,
                  children: (0, _jsxRuntime.jsxs)(_renderer.Text, {
                    children: ["Github: ", (_props$homePage5 = props.homePage) === null || _props$homePage5 === void 0 || (_props$homePage5 = _props$homePage5.businessContact) === null || _props$homePage5 === void 0 ? void 0 : _props$homePage5.github]
                  })
                }), (0, _jsxRuntime.jsx)(_renderer.View, {
                  style: styles.body,
                  children: (0, _jsxRuntime.jsxs)(_renderer.Text, {
                    children: ["Portfolio: ", (_props$homePage6 = props.homePage) === null || _props$homePage6 === void 0 ? void 0 : _props$homePage6.website]
                  })
                })]
              }), (0, _jsxRuntime.jsx)(_renderer.View, {
                children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                  style: styles.body,
                  children: resumeBioSection.introduction
                })
              })]
            }, 'resume-bio-section-' + pageIdx);
          case 'ResumeSkillSection':
            const resumeSkillSection = columnLayoutContainer;
            // console.log(resumeSkillSection)
            return (0, _jsxRuntime.jsxs)(_renderer.View, {
              style: styles.section,
              children: [(0, _jsxRuntime.jsx)(_renderer.View, {
                children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                  children: resumeSkillSection.title
                })
              }), (0, _jsxRuntime.jsx)(_renderer.View, {
                children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                  style: styles.body,
                  children: resumeSkillSection.introduction
                })
              }), (0, _jsxRuntime.jsx)(_renderer.View, {
                style: styles.container,
                children: (_resumeSkillSection$s = resumeSkillSection.skillsets) === null || _resumeSkillSection$s === void 0 ? void 0 : _resumeSkillSection$s.map((skillset, skillsetIndx) => {
                  var _skillset$skills;
                  return (0, _jsxRuntime.jsxs)(_renderer.View, {
                    style: styles.resumeSkillset,
                    children: [(0, _jsxRuntime.jsx)(_renderer.Text, {
                      style: styles.body2,
                      children: skillset.title
                    }), (0, _jsxRuntime.jsx)(_renderer.View, {
                      style: styles.container,
                      children: (_skillset$skills = skillset.skills) === null || _skillset$skills === void 0 ? void 0 : _skillset$skills.map((theSkill, index) => {
                        var _skillset$skills$leng, _skillset$skills2;
                        return (0, _jsxRuntime.jsx)(_renderer.View, {
                          children: (0, _jsxRuntime.jsxs)(_renderer.Text, {
                            style: styles.body,
                            children: [theSkill.title, index !== ((_skillset$skills$leng = (_skillset$skills2 = skillset.skills) === null || _skillset$skills2 === void 0 ? void 0 : _skillset$skills2.length) !== null && _skillset$skills$leng !== void 0 ? _skillset$skills$leng : 0) - 1 ? "," : "", " "]
                          })
                        }, index);
                      })
                    })]
                  }, skillsetIndx);
                })
              })]
            });
          case 'ResumeExperienceSection':
            const resumeExperienceSection = columnLayoutContainer;
            return (0, _jsxRuntime.jsxs)(_renderer.View, {
              style: styles.section,
              children: [(0, _jsxRuntime.jsx)(_renderer.View, {
                children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                  children: resumeExperienceSection.title
                })
              }), (0, _jsxRuntime.jsx)(_renderer.View, {
                children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                  style: styles.body,
                  children: resumeExperienceSection.introduction
                })
              }), (0, _jsxRuntime.jsx)(_renderer.View, {
                style: styles.container,
                children: (_resumeExperienceSect = resumeExperienceSection.experiences) === null || _resumeExperienceSect === void 0 ? void 0 : _resumeExperienceSect.map((experience, expIdx) => {
                  var _experience$skillsUse;
                  return (0, _jsxRuntime.jsxs)(_renderer.View, {
                    style: styles.resumeExperience,
                    children: [(0, _jsxRuntime.jsx)(_renderer.Text, {
                      style: styles.body2,
                      children: experience.companyName
                    }), (0, _jsxRuntime.jsx)(_renderer.Text, {
                      style: styles.subtitle,
                      children: experience.companySubtitle
                    }), (0, _jsxRuntime.jsx)(_renderer.Text, {
                      style: styles.body,
                      children: experience.title
                    }), (0, _jsxRuntime.jsxs)(_renderer.Text, {
                      style: styles.body,
                      children: [_dateUtils.default.YearMonth(new Date(experience.dateStart)), "-", !experience.isPresentPosition ? _dateUtils.default.YearMonth(new Date(experience.dateEnd)) : "present"]
                    }), (0, _jsxRuntime.jsx)(_renderer.Text, {
                      style: styles.body,
                      children: _dateUtils.default.getLengthOfTime(new Date(experience.dateStart), !experience.isPresentPosition ? new Date(experience.dateEnd) : new Date()).result
                    }), (0, _jsxRuntime.jsx)(_renderer.View, {
                      style: {
                        borderLeft: "1px solid black",
                        paddingLeft: "8px",
                        marginLeft: "4px",
                        marginTop: "2px",
                        marginBottom: "2px"
                      },
                      children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                        style: styles.body,
                        children: experience.description
                      })
                    }), (0, _jsxRuntime.jsx)(_renderer.View, {
                      style: styles.container,
                      children: (_experience$skillsUse = experience.skillsUsed) === null || _experience$skillsUse === void 0 ? void 0 : _experience$skillsUse.map((theSkill, index) => {
                        return (0, _jsxRuntime.jsx)(_renderer.View, {
                          style: styles.chip,
                          children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                            style: styles.subtitle,
                            children: theSkill.title
                          })
                        }, index);
                      })
                    })]
                  }, expIdx);
                })
              })]
            });
          case 'ResumeEducationSection':
            const resumeEducationSection = columnLayoutContainer;
            return (0, _jsxRuntime.jsxs)(_renderer.View, {
              style: styles.section,
              children: [(0, _jsxRuntime.jsx)(_renderer.View, {
                children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                  children: resumeEducationSection.title
                })
              }), (0, _jsxRuntime.jsx)(_renderer.View, {
                children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                  style: styles.body,
                  children: resumeEducationSection.introduction
                })
              }), (_resumeEducationSecti = resumeEducationSection.educationExperiences) === null || _resumeEducationSecti === void 0 ? void 0 : _resumeEducationSecti.map((theEducation, educationIdx) => {
                return (0, _jsxRuntime.jsxs)(_renderer.View, {
                  style: {
                    padding: "8px"
                  },
                  children: [(0, _jsxRuntime.jsx)(_renderer.View, {
                    children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                      style: styles.body2,
                      children: theEducation.institutionName
                    })
                  }), (0, _jsxRuntime.jsx)(_renderer.View, {
                    children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                      style: styles.body,
                      children: theEducation.qualification
                    })
                  })]
                }, educationIdx);
              })]
            });
          // case 'ResumeFeedbackSection':
          //     const resumeFeedbackSection: ResumeFeedbackSectionType = columnLayoutContainer
          //
          //     return (
          //         <Grid key={'ResumeFeedbackSection'} container item xs={12} justifyContent='center'>
          //             <Link
          //                 id={"FEEDBACK"}
          //                 style={{position: "relative", top: -80}}
          //                 underline="hover"><></>
          //             </Link>
          //
          //             <ResumeFeedbackSection
          //                 sectionData={resumeFeedbackSection}
          //             />
          //         </Grid>
          //     );
          case 'ResumePortfolioSection':
            const resumePortfolioSection = columnLayoutContainer;
            return (0, _jsxRuntime.jsx)(_renderer.View, {
              style: styles.section,
              children: (0, _jsxRuntime.jsxs)(_renderer.View, {
                children: [(0, _jsxRuntime.jsx)(_renderer.View, {
                  children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                    children: resumePortfolioSection.title
                  })
                }), (0, _jsxRuntime.jsx)(_renderer.View, {
                  children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                    style: styles.body,
                    children: resumePortfolioSection.introduction
                  })
                }), (_resumePortfolioSecti = resumePortfolioSection.portfolioEntries) === null || _resumePortfolioSecti === void 0 ? void 0 : _resumePortfolioSecti.map((entry, entryIdx) => {
                  var _entry$linkToProd;
                  return (0, _jsxRuntime.jsxs)(_renderer.View, {
                    style: {
                      padding: "8px"
                    },
                    children: [(0, _jsxRuntime.jsx)(_renderer.View, {
                      children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                        style: styles.body2,
                        children: entry.title
                      })
                    }), (0, _jsxRuntime.jsx)(_renderer.View, {
                      children: (0, _jsxRuntime.jsx)(_renderer.Link, {
                        src: (_entry$linkToProd = entry.linkToProd) !== null && _entry$linkToProd !== void 0 ? _entry$linkToProd : "",
                        style: styles.body,
                        children: (0, _jsxRuntime.jsx)(_renderer.Text, {
                          children: entry.linkToProd
                        })
                      })
                    })]
                  }, entryIdx);
                })]
              })
            });
          // case 'ResumeContactUsSection':
          //     const resumeContactUsSection: ResumeContactUsSectionType = columnLayoutContainer
          //
          //     return (
          //         <Grid key={'ResumeContactUsSection'} container item xs={12} justifyContent='center'>
          //             <Link id={"CONTACT"} style={{position: "relative", top: -80}} underline="hover"><></>
          //             </Link>
          //
          //             <ResumeContactUsSection
          //                 sectionData={resumeContactUsSection}
          //             />
          //         </Grid>
          //     );
          // case 'column1BlockContent':
          //     return <Grid key={'column1BlockContent'} container justifyContent='center' alignItems='stretch'>
          //         <Grid item>
          //             <Card className={classes.root} style={{paddingTop: '80px'}}>
          //                 <Grid container item xs={12} className={classes.layoutContainer}>
          //                     <Grid item xs={12}>
          //                         <BlockContent
          //                             blocks={columnLayoutContainer.content}
          //                             serializers={blockSerializers}
          //                             projectId={sanityClient.config().projectId}
          //                             dataset={sanityClient.config().dataset}
          //                         />
          //                     </Grid>
          //                 </Grid>
          //             </Card>
          //         </Grid>
          //     </Grid>
          // case 'column2BlockContent':
          //     return <Grid key={'column2BlockContent'} container justifyContent='center' alignItems='stretch'>
          //         <Grid item>
          //             <Card className={classes.root} style={{paddingTop: '80px'}}>
          //                 <Grid container item xs={12} className={classes.layoutContainer}>
          //                     <Grid item xs={6}>
          //                         <BlockContent
          //                             blocks={columnLayoutContainer.column1.content}
          //                             serializers={blockSerializers}
          //                             projectId={sanityClient.config().projectId}
          //                             dataset={sanityClient.config().dataset}
          //                         />
          //                     </Grid>
          //                     <Grid item xs={6}>
          //                         <BlockContent
          //                             blocks={columnLayoutContainer.column2.content}
          //                             serializers={blockSerializers}
          //                             projectId={sanityClient.config().projectId}
          //                             dataset={sanityClient.config().dataset}
          //                         />
          //                     </Grid>
          //                 </Grid>
          //             </Card></Grid>
          //     </Grid>
          // case 'transformHeroContentSection':
          //     const thwHeroSection: ThwHeroContentSectionType = columnLayoutContainer
          //
          //     return (
          //         <Grid key={'transformHeroContentSection'} container item xs={12}>
          //             <Link id={"TOP_OF_PAGE"} underline="hover"><></>
          //             </Link>
          //             <MMHeroContentSection
          //                 sectionData={thwHeroSection}
          //             />
          //         </Grid>
          //     );
          // case 'transformServiceItem':
          //     const thwServiceEducationPage: ThwServiceItemType = columnLayoutContainer
          //
          //     // const fetchedServiceItem =
          //
          //     return (
          //         <Grid key={'transformServiceItem'} container item xs={12}>
          //             <Link id={"TOP_OF_PAGE"} underline="hover"><></>
          //             </Link>
          //             <ThwServicesEducationPage
          //                 serviceData={thwServiceEducationPage}
          //             />
          //         </Grid>
          //     );
          // case 'transformPositivePsychologySection':
          //     const thwPositivePsychologySection: ThwPositivePsychologySectionType = columnLayoutContainer
          //
          //     return (
          //         <Grid key={'transformPositivePsychologySection'} container item xs={12}
          //               justifyContent='center'
          //               style={{backgroundColor: theme.palette.background.paper}}>
          //             <Link
          //                 id={"ABOUT_US"}
          //                 style={{position: "relative", top: -80}}
          //                 underline="hover"><></>
          //             </Link>
          //             <ThwPositivePsychology
          //                 sectionData={thwPositivePsychologySection}
          //             />
          //         </Grid>
          //     );
          // case 'transformMottoSection':
          //     const thwMottoSection: ThwMottoSectionType = columnLayoutContainer
          //
          //     return <Grid key={'transformMottoSection'} container item xs={12} justifyContent='center'
          //                  style={{backgroundColor: theme.palette.background.paper}}>
          //         <ThwMottoSection
          //             sectionData={thwMottoSection}
          //         />
          //     </Grid>
          // case 'transformAboutProprietorSection':
          //     const thwProprietorSection: ThwAboutProprietorSectionType = columnLayoutContainer
          //
          //     return (
          //         <Grid key={'transformAboutProprietorSection'} container item xs={12} justifyContent='center'
          //               style={{backgroundColor: theme.palette.background.paper}}>
          //             <Link
          //                 id={"ABOUT_PROPRIETOR"}
          //                 style={{position: "relative", top: -80}}
          //                 underline="hover"><></>
          //             </Link>
          //             <AboutTheProprietorSection
          //                 sectionData={thwProprietorSection}
          //             />
          //         </Grid>
          //     );
          // case 'transformServicesSection':
          //     const thwServicesSection: ThwServicesSectionType = columnLayoutContainer
          //
          //     return (
          //         <Grid key={'transformServicesSection'} container item xs={12} justifyContent='center'
          //               style={{backgroundColor: theme.palette.background.paper}}>
          //             <Link
          //                 id={"SERVICES"}
          //                 style={{position: "relative", top: -80}}
          //                 underline="hover"><></>
          //             </Link>
          //             <ThwServicesSection
          //                 sectionData={thwServicesSection}
          //             />
          //         </Grid>
          //     );
          // case 'transformWhyChooseUsSection':
          //     const thwWCUSection: ThwWhyChooseUsSectionType = columnLayoutContainer
          //
          //     return <Grid key={'transformWhyChooseUsSection'} container item xs={12} justifyContent='center'
          //                  style={{backgroundColor: theme.palette.background.paper}}>
          //         <ThwWhyChooseUsSection
          //             sectionData={thwWCUSection}
          //         />
          //     </Grid>
          // case 'transformContactUsSection':
          //     const thwCUSection: ThwContactUsSectionType = columnLayoutContainer
          //
          //     return <Grid key={'transformContactUsSection'} container item xs={12} justifyContent='center'
          //                  style={{backgroundColor: theme.palette.background.paper}}>
          //         <ThwContactUsSection
          //             sectionData={thwCUSection}
          //         />
          //     </Grid>
          // case 'WebDevHeroContentSection':
          //     const webDevHeroSection: WebDevHeroContentSectionType = columnLayoutContainer
          //
          //     return (
          //         <Grid key={'webDevHeroContentSection'} container item xs={12}>
          //             <Link id={"TOP_OF_PAGE"} underline="hover"><></>
          //             </Link>
          //             <WebDevHeroContentSection
          //                 sectionData={webDevHeroSection}
          //             />
          //         </Grid>
          //     );
          // case 'WebDevStatsCounterSection':
          //     const webDevStatsCounterSection: WebDevStatsCounterSectionType = columnLayoutContainer
          //
          //     return <Grid key={'webDevStatsCounterSection'} container item xs={12}>
          //         <WebDevStatsCounterSection
          //             sectionData={webDevStatsCounterSection}
          //         />
          //     </Grid>
          // case 'WebDevAboutUsSection':
          //     const webDevAboutUsSection: WebDevAboutUsSectionType = columnLayoutContainer
          //
          //     return (
          //         <Grid key={'webDevAboutUsSection'} container item xs={12}>
          //             <Link id={"ABOUT_US"} underline="hover"><></>
          //             </Link>
          //             <WebDevAboutUsSection
          //                 sectionData={webDevAboutUsSection}
          //             />
          //         </Grid>
          //     );
          // case 'ServicesSection':
          //     const webDevServicesSection: PortfolioSectionType = columnLayoutContainer
          //
          //     return (
          //         <Grid key={'webDevServicesSection'} container item xs={12}>
          //             <Link id={"SERVICES"} underline="hover"><></>
          //             </Link>
          //
          //             <WebDevServicesSection
          //                 sectionData={webDevServicesSection}
          //             />
          //         </Grid>
          //     );
          // case 'PortfolioSection':
          //     const webDevPortfolioSection: PortfolioSectionType = columnLayoutContainer
          //
          //     return (
          //         <Grid key={'webDevPortfolioSection'} container item xs={12}>
          //             <Link id={"PORTFOLIO"} underline="hover"><></>
          //             </Link>
          //
          //             <WebDevPortfolioSection
          //                 sectionData={webDevPortfolioSection}
          //             />
          //         </Grid>
          //     );
          // case 'TestimonialsSection':
          //     const webDevTestimonialsSection: WebDevTestimonialsSectionType = columnLayoutContainer
          //
          //     return (
          //         <Grid key={'webDevTestimonialsSection'} container item xs={12}>
          //             <Link id={"TESTIMONIALS"} underline="hover"><></>
          //             </Link>
          //
          //             <WebDevTestimonialsSection
          //                 sectionData={webDevTestimonialsSection}
          //             />
          //         </Grid>
          //     );
          // case 'WebDevHowItWorksSection':
          //     const webDevHowItWorksSection: HowItWorksSectionType = columnLayoutContainer
          //
          //     return (
          //         <Grid key={'webDevHowItWorksSection'} container item xs={12}>
          //             <Link id={"HOW_IT_WORKS"} underline="hover"><></>
          //             </Link>
          //             <WebDevHowItWorksSection
          //                 sectionData={webDevHowItWorksSection}
          //             />
          //         </Grid>
          //     );
          // case 'HeroAnimatedContentSection':
          //     const heroAnimatedContentSection: HeroAnimatedContentSectionType = columnLayoutContainer
          //     return (
          //         <Grid key={'animated-hero'} container item xs={12}>
          //             <Link id={"ANIMATED_HERO"} underline="hover"><></>
          //             </Link>
          //             <HeroAnimatedContentSection
          //                 sectionData={heroAnimatedContentSection}
          //             />
          //         </Grid>
          //     );
          // case 'AnimatedAboutUsSection':
          //     const animatedAboutusSection: AnimatedAboutUsSectionType = columnLayoutContainer
          //     return (
          //         <Grid key={'animated-about-us'} container item xs={12}>
          //             <Link id={"ANIMATED_ABOUT_US"} underline="hover"><></>
          //             </Link>
          //             <AnimatedAboutUsSection
          //                 sectionData={animatedAboutusSection}
          //             />
          //         </Grid>
          //     );
          // case 'AnimatedServicesSection':
          //     const animatedServicesSection: AnimatedServicesSectionType = columnLayoutContainer
          //     return (
          //         <Grid key={'animated-services'} container item xs={12}>
          //             <Link id={"ANIMATED_SERVICES"} underline="hover"><></>
          //             </Link>
          //             <AnimatedServicesSection
          //                 sectionData={animatedServicesSection}
          //             />
          //         </Grid>
          //     );
          // case 'AnimatedPortfolioSection':
          //     const animatedPortfolioSection: AnimatedPortfolioSectionType = columnLayoutContainer
          //     return (
          //         <Grid key={'animated-portfolio'} container item xs={12}>
          //             <Link id={"ANIMATED_PORTFOLIO"} underline="hover"><></>
          //             </Link>
          //             <AnimatedPortfolioSection
          //                 sectionData={animatedPortfolioSection}
          //             />
          //         </Grid>
          //     );
          // case 'HeadlineCTASection':
          //     const headlineSection: HeadlineCTASectionType = columnLayoutContainer
          //
          //     return <Grid key={'headline-section'} container item style={{zIndex: 1000}}>
          //         <HeadlineCTASection
          //             sectionData={headlineSection}
          //         />
          //     </Grid>
          // case 'MapSection':
          //     const mapSection: MapSectionType = columnLayoutContainer
          //
          //     return <Grid key={'map-section'} container item>
          //         <Link id={"MAP_SECTION"} underline="hover"><></>
          //         </Link>
          //         <MapSection
          //             sectionData={mapSection}
          //         />
          //     </Grid>
          // case 'WebDevPricingSection':
          //     const pricingSection: PricingSectionType = columnLayoutContainer
          //
          //     return <Grid key={'map-section'} container item>
          //         <Link id={"PRICING_SECTION"} underline="hover"><></>
          //         </Link>
          //         <WebDevPricingSection
          //             sectionData={pricingSection}
          //         />
          //     </Grid>
          default:
            return (0, _jsxRuntime.jsx)(_renderer.View, {
              children: (0, _jsxRuntime.jsx)(_renderer.Text, {})
            });
          // return <span key={index}>Undefined section {columnLayoutContainer._type}</span>
        }
      })
    }, (0, _uuid.v4)())
  }, (0, _uuid.v4)());
};
var _default = exports.default = ResumeDocumentPDF;