"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.promise.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _pdfClient = _interopRequireDefault(require("../../../../utils/pdfClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const BusinessCardSubmitEmail = () => {
  // const theme = useTheme()
  // const classes = useCustomStyles({})
  // const myClasses = useStyles()
  // const [email, setEmail] = useState("")
  // const [isLoading, setIsLoading] = useState<boolean>(false)
  // const [isError, setIsError] = useState<boolean>(false)
  // const [data, setData] = useState<any>()
  const [aLinkUrl, setALinkUrl] = (0, _react.useState)(undefined);
  (0, _react.useEffect)(() => {
    _pdfClient.default.getPDFLink().then(async theLink => {
      setALinkUrl(theLink);
    });
  }, []);
  // const createLead = async (): Promise<any> => {
  //     setIsLoading(true)
  //     setIsError(false)
  //
  //     const response = await leadClient.sendBusinessCardEmail({email, source: props.source});
  //     // console.log(response)
  //
  //     if (response.status === "400") {
  //         console.log("there was error")
  //         setIsError(true)
  //         setData(null)
  //         setIsLoading(false)
  //     } else {
  //
  //         setData(response)
  //         setIsLoading(false)
  //     }
  // }
  // useEffect(() => {
  //     if (!isEmail(email) && email.length > 0) {
  //         setIsError(true)
  //     } else if (isEmail(email)) {
  //         setIsError(false)
  //     }
  // }, [email])
  // const getHelperText = () => {
  //     if ((data || isError) && !isEmail(email)) {
  //         return <Typography style={{color: theme.palette.error.main, fontFamily: "Raleway"}} variant='subtitle1'>This
  //             is not a
  //             valid email address.</Typography>
  //     }
  //
  //     if (data) {
  //         return <Typography style={{color: theme.palette.success.main, fontFamily: "Raleway"}} variant='subtitle1'>Thank
  //             you for
  //             your submission!</Typography>
  //     }
  //     if (isError) {
  //         return <Typography style={{color: theme.palette.error.main, fontFamily: "Raleway"}} variant='subtitle1'>Please
  //             Try your
  //             submission again later or contact hello@thehandsomestnerd.com.</Typography>
  //     }
  //
  //     return <Typography variant='subtitle1'>&nbsp;</Typography>
  // }
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    justifyContent: 'center',
    "data-testid": 'submit-email-block',
    children: (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'center',
      children: aLinkUrl ? (0, _jsxRuntime.jsx)(_material.Link, {
        href: aLinkUrl,
        children: "Download PDF"
      }) : (0, _jsxRuntime.jsxs)(_material.Grid, {
        spacing: .5,
        container: true,
        item: true,
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.CircularProgress, {
            size: 12,
            color: 'primary'
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            color: 'primary',
            children: "Creating PDF version of Resume..."
          })
        })]
      })
    })
  });
};
var _default = exports.default = BusinessCardSubmitEmail;