"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.json.stringify.js");
require("core-js/modules/es.promise.js");
var _clientUtils = _interopRequireDefault(require("./clientUtils"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const createLead = lead => {
  var _collectEmailAddre;
  return fetch((_collectEmailAddre = "/collect-email-address") !== null && _collectEmailAddre !== void 0 ? _collectEmailAddre : "", {
    method: 'POST',
    body: JSON.stringify(lead)
  }).then(response => {
    return _clientUtils.default.processResponse(response, 'ColdLead');
  }).catch(e => {
    // console.error(LOG, 'ERROR', 'error', e);
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      attempt: Error(e)
    });
  });
};
const sendBusinessCardEmail = lead => {
  var _sendEmailResume;
  return fetch((_sendEmailResume = "/send-email-resume") !== null && _sendEmailResume !== void 0 ? _sendEmailResume : "", {
    method: 'POST',
    body: JSON.stringify(lead)
  }).then(response => {
    return _clientUtils.default.processResponse(response, 'EmailedResume');
  }).catch(e => {
    // console.error(LOG, 'ERROR', 'error', e);
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      attempt: Error(e)
    });
  });
};
var _default = exports.default = {
  createLead,
  sendBusinessCardEmail
};