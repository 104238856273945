"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.promise.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _ResumeExperienceItem = _interopRequireDefault(require("../resume-experience-section/ResumeExperienceItem"));
var _ResumePortfolioEntry = _interopRequireDefault(require("../resume-portfolio-section/ResumePortfolioEntry"));
var _ResumeSkillSetItem = _interopRequireDefault(require("./ResumeSkillSetItem"));
var _PageContext = _interopRequireDefault(require("../../../page-context/PageContext"));
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const ResumeSkillReferences = props => {
  const [referenceResults, setReferenceResults] = (0, _react.useState)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const searchCMS = async () => {
    // console.log("about to search full text")
    if (props.skill) {
      var _pageContext$page$_id, _pageContext$page;
      const cmsResponse = await sanityContext.skillReferenceSearch(props.skill, (_pageContext$page$_id = (_pageContext$page = pageContext.page) === null || _pageContext$page === void 0 ? void 0 : _pageContext$page._id) !== null && _pageContext$page$_id !== void 0 ? _pageContext$page$_id : "");
      setReferenceResults(cmsResponse);
    }
  };
  (0, _react.useEffect)(() => {
    // find the rerences of this skill
    searchCMS().then();
  }, []);
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    spacing: 2,
    children: referenceResults === null || referenceResults === void 0 ? void 0 : referenceResults.map((searchResult, index) => {
      switch (searchResult === null || searchResult === void 0 ? void 0 : searchResult._type) {
        case "ResumeExperience":
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: 'textPrimary',
                variant: 'subtitle2',
                textTransform: 'uppercase',
                sx: {
                  borderLeft: "1px solid red",
                  paddingLeft: "4px"
                },
                children: "My Job Experience"
              })
            }), (0, _jsxRuntime.jsx)(_ResumeExperienceItem.default, {
              experience: searchResult
            })]
          }, "searched-experience-".concat(index));
        case "ResumePortfolioItem":
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            xs: 12,
            sm: 6,
            lg: 4,
            xl: 4,
            item: true,
            children: [(0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'textPrimary',
              variant: 'subtitle2',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              sx: {
                borderLeft: "1px solid red",
                paddingLeft: "4px",
                marginBottom: "4px"
              },
              children: "My Portfolio item"
            }), (0, _jsxRuntime.jsx)(_ResumePortfolioEntry.default, {
              portfolioItem: searchResult
            })]
          }, "searched-portfolio-item-".concat(index));
        case "ResumeSkillSection":
          return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
        case "ResumeSkillset":
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: 'textPrimary',
                variant: 'subtitle2',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                sx: {
                  borderLeft: "1px solid red",
                  paddingLeft: "4px"
                },
                children: "My Related Skills"
              })
            }), (0, _jsxRuntime.jsx)(_ResumeSkillSetItem.default, {
              skillset: searchResult
            })]
          }, "searched-skillset-".concat(index));
        default:
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              children: searchResult._type
            })
          });
      }
    })
  });
};
var _default = exports.default = ResumeSkillReferences;