"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.promise.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _uuid = require("uuid");
var _ImageWithButtonOverlay = _interopRequireDefault(require("../image-with-button-overlay/ImageWithButtonOverlay"));
var _framerMotion = require("framer-motion");
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const AnimatedAboutUsItem = props => {
  var _props$service$slug, _sanityContext$urlFor, _props$service$backgr;
  // const customizedTheme = useContext(CustomizedThemeContext)
  const controls = (0, _framerMotion.useAnimationControls)();
  const textColorControls = (0, _framerMotion.useAnimationControls)();
  const overlayControl = (0, _framerMotion.useAnimationControls)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const theme = (0, _material.useTheme)();
  const animateServiceHover = async () => {
    overlayControl.start({
      opacity: 1
    }, {
      duration: .5
    });
    controls.start({
      scale: 1
    }, {
      duration: .5
    });
    textColorControls.start({
      color: theme.palette.primary.main
    });
  };
  const animateServiceNoHover = async () => {
    controls.start({
      scale: 1.1
    }, {
      duration: .25
    });
    overlayControl.start({
      opacity: 0
    }, {
      duration: .5
    });
    textColorControls.start({
      color: theme.palette.text.primary
    });
  };
  const smDown = (0, _material.useMediaQuery)(theme.breakpoints.down('sm'));
  return (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
    onHoverStart: async () => {
      animateServiceHover();
    },
    onHoverEnd: async () => {
      animateServiceNoHover();
    },
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      sx: {
        border: "1px solid #e6e6e6",
        position: "relative"
      },
      maxWidth: smDown ? 550 : 450,
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          style: {
            position: "relative",
            overflow: "hidden"
          },
          children: [(0, _jsxRuntime.jsx)(_ImageWithButtonOverlay.default, {
            source: (_props$service$slug = props.service.slug) === null || _props$service$slug === void 0 ? void 0 : _props$service$slug.current,
            imageAltText: props.service.imageSrcAltText,
            imageSrc: props.service.imageSrc,
            height: 200
          }), (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
            initial: {
              opacity: 0
            },
            animate: overlayControl,
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              style: {
                zIndex: 1,
                top: -4,
                backgroundColor: "rgba(0,0,0,.4)",
                position: "absolute",
                height: "100%",
                width: "100%"
              }
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          alignItems: 'center',
          style: {
            position: "absolute",
            top: "154px"
          },
          children: [(0, _jsxRuntime.jsx)(_material.Card, {
            elevation: 0,
            style: {
              borderRadius: "50%",
              border: "1px solid #e6e6e6",
              height: "90px",
              width: "90px",
              zIndex: 3
            },
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              style: {
                height: "100%"
              },
              children: (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
                animate: controls,
                initial: {
                  scale: 1.1
                },
                children: (0, _jsxRuntime.jsx)("img", {
                  style: {
                    zIndex: 2
                  },
                  width: 64,
                  height: 64,
                  src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.service.iconImageSrc, 64, 64)
                })
              })
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            style: {
              backgroundColor: theme.palette.primary.main,
              height: "8px",
              zIndex: 2,
              position: "absolute"
            }
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        style: {
          padding: theme.spacing(4, 4, 4, 4),
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          backgroundImage: "url(".concat((_sanityContext$urlFor = sanityContext.urlFor((_props$service$backgr = props.service.backgroundImageSrc) !== null && _props$service$backgr !== void 0 ? _props$service$backgr : "").url()) !== null && _sanityContext$urlFor !== void 0 ? _sanityContext$urlFor : "", ")")
        },
        justifyContent: 'center',
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
            animate: textColorControls,
            initial: {
              color: 'initial'
            },
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              style: {
                marginTop: "16px",
                marginBottom: "16px"
              },
              variant: 'h6',
              align: 'center',
              noWrap: true,
              children: props.service.contentTitle
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          justifyContent: 'center',
          minWidth: 150,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            align: 'center',
            children: props.service.contentText
          })
        })]
      })]
    })
  }, (0, _uuid.v4)());
};
var _default = exports.default = AnimatedAboutUsItem;