"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _LoadingButton = _interopRequireDefault(require("../../../loading-button/LoadingButton"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  root: {
    marginRight: theme.spacing(11)
  },
  footerLink: {
    marginBottom: '8px',
    textDecoration: 'none',
    // color: '#FDF3EB',
    '&:hover': {
      textDecoration: 'none'
    },
    textTransform: 'capitalize'
  },
  menuItem: {
    paddingLeft: '32px',
    paddingRight: '32px'
  },
  menuTitle: {
    // color: '#FDF3EB',
    marginBottom: theme.spacing(1)
  },
  popover: {
    boxShadow: 'none',
    borderLeft: "4px solid ".concat(theme.palette.background.default),
    borderRadius: 0
  },
  list: {
    padding: 0
  }
}));
const FooterMenuGroup = _ref => {
  let {
    menuGroup
  } = _ref;
  const classes = useStyles();
  const theme = (0, _material.useTheme)();
  const [menuGroupContents, setMenuGroupContents] = (0, _react.useState)();
  const [menuItemContents, setMenuItemContents] = (0, _react.useState)();
  (0, _react.useEffect)(() => {
    if (menuGroup._type === "menuGroup") {
      setMenuGroupContents(menuGroup);
    } else if (menuGroup._type === "menuItem") {
      setMenuItemContents(menuGroup);
    }
  }, [menuGroup]);
  const mdOnly = (0, _material.useMediaQuery)(theme.breakpoints.only('sm'));
  const mdUp = (0, _material.useMediaQuery)(theme.breakpoints.up('md'));
  const mdDown = (0, _material.useMediaQuery)(theme.breakpoints.down('md'));
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    direction: "column",
    spacing: 2,
    className: classes.root,
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: mdUp ? 'flex-start' : 'center',
      children: (0, _jsxRuntime.jsx)(_material.Typography, {
        color: 'primary',
        variant: "body2",
        className: classes.menuTitle,
        children: menuGroupContents && menuGroupContents.menuGroupTitle
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        xs: 12,
        direction: mdOnly ? 'row' : 'column',
        spacing: 2,
        alignItems: mdUp ? 'flex-start' : 'center',
        alignContent: mdUp ? 'flex-start' : 'center',
        justifyContent: mdUp ? 'flex-start' : 'center',
        children: [(menuGroup === null || menuGroup === void 0 ? void 0 : menuGroup.links) && menuGroup.links.map((menuLink, index) => {
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: !menuLink.isContainedButton && !menuLink.isOutlinedButton ? (0, _jsxRuntime.jsx)(_material.Link, {
              underline: 'hover',
              href: menuLink.url,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: "body1",
                color: 'textSecondary',
                noWrap: true,
                children: menuLink.displayText
              })
            }) : (0, _jsxRuntime.jsx)(_LoadingButton.default, {
              variant: menuLink.isContainedButton ? 'contained' : 'outlined',
              href: menuLink.url,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: "body1",
                color: 'textSecondary',
                noWrap: true,
                children: menuLink.displayText
              })
            })
          }, index);
        }), menuItemContents && (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          justifyContent: mdDown && (menuItemContents.isContainedButton || menuItemContents.isOutlinedButton) ? 'center' : 'flex-start',
          children: !menuItemContents.isContainedButton && !menuItemContents.isOutlinedButton ? (0, _jsxRuntime.jsx)(_material.Link, {
            underline: 'hover',
            href: menuItemContents.url,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: "body1",
              color: 'textSecondary',
              noWrap: true,
              children: menuItemContents.displayText
            })
          }) : (0, _jsxRuntime.jsx)(_LoadingButton.default, {
            variant: menuItemContents.isContainedButton ? 'contained' : 'outlined',
            href: menuItemContents.url,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: "body1",
              color: 'textSecondary',
              noWrap: true,
              children: menuItemContents.displayText
            })
          })
        }), !menuGroupContents && !menuItemContents && (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})]
      })
    })]
  });
};
var _default = exports.default = FooterMenuGroup;