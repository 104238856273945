"use strict";

require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _ballroomTypes = require("../../ballroomTypes");
var _HTMLUtils = require("../../HTMLUtils");
var _StyledTextField = _interopRequireDefault(require("../../styled-text-field/StyledTextField"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const CategoryStepComponent = props => {
  const theme = (0, _material.useTheme)();
  const initialState = {
    category: _objectSpread({}, props.category)
  };
  const [categoryState, setCategoryState] = (0, _react.useState)(initialState);
  const [categoryStep, setCategoryStep] = (0, _react.useState)(0);
  const [canFinalize, setCanFinalize] = (0, _react.useState)(false);
  const [isCategoryClosed, setIsCategoryClosed] = (0, _react.useState)(false);
  const [isEditMode, setIsEditMode] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    setCategoryState({
      category: props.category
    });
  }, [props.category]);
  (0, _react.useEffect)(() => {
    setIsEditMode(!props.isEditMode);
  }, [props.isEditMode]);
  (0, _react.useEffect)(() => {
    setIsCategoryClosed(!props.isCategoryClosed);
  }, [props.isCategoryClosed]);
  (0, _react.useEffect)(() => {
    if (categoryState.category.catGender && categoryStep < 1) {
      setCategoryStep(1);
    }
  }, [categoryState.category.catGender]);
  (0, _react.useEffect)(() => {
    if (categoryState.category.catType && categoryStep < 2) {
      setCategoryStep(2);
    }
  }, [categoryState.category.catType]);
  (0, _react.useEffect)(() => {
    if (categoryState.category.catName && categoryStep < 3) {
      setCategoryStep(3);
    }
  }, [categoryState.category.catName]);
  (0, _react.useEffect)(() => {
    if (categoryState.category.catDescription && categoryState.category.catDescription.length > 1 && categoryStep < 4) {
      setCategoryStep(4);
    }
  }, [categoryState.category.catDescription]);
  (0, _react.useEffect)(() => {
    if (categoryState.category.catPrize && categoryStep < 5 &&
    //@ts-ignore
    _ballroomTypes.CategoryPrizeType[categoryState.category.catPrize] !== _ballroomTypes.CategoryPrizeType.MONETARY) {
      setCategoryStep(5);
      setCanFinalize(true);
      //@ts-ignore
    } else if (categoryState.category.catPrize && _ballroomTypes.CategoryPrizeType[categoryState.category.catPrize] === _ballroomTypes.CategoryPrizeType.MONETARY) {
      setCategoryStep(5);
      setCanFinalize(false);
    }
  }, [categoryState.category.catPrize]);
  (0, _react.useEffect)(() => {
    if (categoryState.category.monetaryPrize && categoryStep < 6 && categoryState.category.monetaryPrize > 0) {
      setCategoryStep(6);
      setCanFinalize(true);
      console.log("Finalizing add category", categoryState);
    }
  }, [categoryState.category.monetaryPrize]);
  const closeCategory = () => {
    setIsCategoryClosed(!isCategoryClosed);
    props.onCategoryChange(categoryState.category);
    // props.finalizeCategory(categoryState.category)
  };
  const updateCategoryFormParams = event => {
    if (event.target.name.includes('.')) {
      const [objectName, propertyName] = event.target.name.split('.');
      const newValue = {
        [propertyName]: event.target.value
      };
      setCategoryState(state => _objectSpread(_objectSpread({}, state), {}, {
        [objectName]: _objectSpread(_objectSpread({}, state[objectName]), newValue)
      }));
      /** Calling Parent category update function */
      // props.onCategoryChange(categoryState.category)
    } else {
      setCategoryState(state => _objectSpread(_objectSpread({}, state), {}, {
        [event.target.name]: event.target.value
      }));
      /** Calling Parent category update function */
      // props.onCategoryChange(categoryState.category)
    }
  };
  const updateCategoryBooleanFormParams = event => {
    event.persist();
    if (event.target.name.includes('.')) {
      const [objectName, propertyName] = event.target.name.split('.');
      setCategoryState(state => _objectSpread(_objectSpread({}, state), {}, {
        [objectName]: _objectSpread(_objectSpread({}, state[objectName]), {}, {
          [propertyName]: event.target.checked
        })
      }));
      /** Calling Parent category update function */
      // props.onCategoryChange(categoryState.category)
    }
  };
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    style: {
      padding: theme.spacing(3),
      backgroundColor: '#FAFAFA',
      marginBottom: theme.spacing(2)
    },
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsx)(_material.Typography, {
        display: 'inline',
        variant: 'h5',
        color: 'textSecondary',
        gutterBottom: true,
        align: 'center',
        children: "The Category is..."
      })
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      children: [categoryStep >= 0 && (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        xs: 12,
        spacing: 2,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          xs: 12,
          md: 5,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.FormGroup, {
            children: (0, _jsxRuntime.jsx)(_material.FormControlLabel
            // style={{alignItems: 'start'}}
            , {
              // style={{alignItems: 'start'}}
              control: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
                margin: 'dense',
                required: true,
                fullWidth: true,
                variant: 'outlined',
                select: true,
                SelectProps: {
                  native: true
                },
                onChange: updateCategoryFormParams,
                name: 'category.catGender',
                value: categoryState.category.catGender || '',
                "data-testid": 'category-gender-select-input-group',
                inputProps: {
                  'data-testid': 'category-gender-select-input'
                },
                children: (0, _HTMLUtils.enumCreateSelectOptionsFromType)(_ballroomTypes.CategoryGenderType, true).map(option => (0, _jsxRuntime.jsx)("option", {
                  value: option.value || '',
                  "data-testid": "categoryGender-".concat(option.value),
                  children: option.label
                }, option.value))
              }),
              label: (0, _jsxRuntime.jsx)(_material.Typography, {
                display: 'inline',
                color: 'textSecondary',
                variant: 'h6',
                noWrap: true,
                children: "Category Gender"
              }),
              labelPlacement: 'top'
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          xs: 12,
          md: 2,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.FormGroup, {
            children: (0, _jsxRuntime.jsx)(_material.FormControlLabel, {
              style: {
                alignItems: 'start'
              },
              control: (0, _jsxRuntime.jsx)(_material.Switch, {
                checked: categoryState.category.versus || false,
                onChange: updateCategoryBooleanFormParams,
                name: 'category.versus',
                color: 'primary',
                inputProps: {
                  'aria-label': 'categoryVs'
                },
                "data-testid": 'category-vs-switch-input'
              }),
              label: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: 'textSecondary',
                variant: 'h6',
                display: 'inline',
                noWrap: true,
                children: "Versus"
              }),
              labelPlacement: 'top'
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          xs: 12,
          md: 5,
          justifyContent: 'center',
          children: categoryState.category.versus && (0, _jsxRuntime.jsx)(_material.FormGroup, {
            children: (0, _jsxRuntime.jsx)(_material.FormControlLabel, {
              style: {
                alignItems: 'start'
              },
              control: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
                margin: 'dense',
                required: true,
                fullWidth: true,
                variant: 'outlined',
                select: true,
                SelectProps: {
                  native: true
                },
                onChange: updateCategoryFormParams,
                name: 'category.catVsGender',
                value: categoryState.category.catVsGender || '',
                "data-testid": 'category-vs-gender-select-input-group',
                inputProps: {
                  'data-testid': 'category-vs-gender-select-input'
                },
                children: (0, _HTMLUtils.enumCreateSelectOptionsFromType)(_ballroomTypes.CategoryGenderType, true).map(option => (0, _jsxRuntime.jsx)("option", {
                  value: option.value || '',
                  "data-testid": "categoryVsGender-".concat(option.value),
                  children: option.label
                }, option.value))
              }),
              label: (0, _jsxRuntime.jsx)(_material.Typography, {
                display: 'inline',
                color: 'textSecondary',
                variant: 'h6',
                noWrap: true,
                children: "Category Gender"
              }),
              labelPlacement: 'top'
            })
          })
        })]
      }), categoryStep >= 1 && (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          style: {
            overflow: "scroll"
          },
          item: true,
          xs: 12,
          children: (0, _jsxRuntime.jsx)(_material.FormGroup, {
            children: (0, _jsxRuntime.jsx)(_material.FormControlLabel, {
              style: {
                alignItems: 'start'
              },
              control: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
                margin: 'dense',
                required: true,
                fullWidth: true,
                variant: 'outlined',
                select: true,
                SelectProps: {
                  native: true
                },
                onChange: updateCategoryFormParams,
                name: 'category.catType',
                value: categoryState.category.catType || '',
                "data-testid": 'category-type-select-input-group',
                inputProps: {
                  'data-testid': 'category-type-select-input'
                },
                children: (0, _HTMLUtils.enumCreateSelectOptionsFromType)(_ballroomTypes.CategoryTypeType, true).map(option => (0, _jsxRuntime.jsx)("option", {
                  value: option.value || '',
                  "data-testid": "categoryType-".concat(option.value),
                  children: option.label
                }, option.value))
              }),
              label: (0, _jsxRuntime.jsx)(_material.Typography, {
                display: 'inline',
                color: 'textSecondary',
                variant: 'h6',
                noWrap: true,
                children: "Category Type"
              }),
              labelPlacement: 'top'
            })
          })
        })
      }), categoryStep >= 2 && (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        xs: 12,
        children: (0, _jsxRuntime.jsx)(_material.FormGroup, {
          children: (0, _jsxRuntime.jsx)(_material.FormControlLabel, {
            style: {
              alignItems: 'start'
            },
            control: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
              margin: 'dense',
              required: true,
              fullWidth: true,
              variant: 'outlined',
              select: true,
              SelectProps: {
                native: true
              },
              onChange: updateCategoryFormParams,
              name: 'category.catName',
              value: categoryState.category.catName || '',
              "data-testid": 'category-name-select-input-group',
              inputProps: {
                'data-testid': 'category-name-select-input'
              },
              children: (0, _HTMLUtils.enumCreateSelectOptionsFromType)(_ballroomTypes.CategoryNameType, true).map(option => (0, _jsxRuntime.jsx)("option", {
                value: option.value || '',
                "data-testid": "categoryName-".concat(option.value),
                children: option.label
              }, option.value))
            }),
            label: (0, _jsxRuntime.jsx)(_material.Typography, {
              display: 'inline',
              color: 'textSecondary',
              variant: 'h6',
              noWrap: true,
              children: "Category Name"
            }),
            labelPlacement: 'top'
          })
        })
      }), categoryStep >= 3 && (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        xs: 12,
        children: (0, _jsxRuntime.jsx)(_material.FormGroup, {
          children: (0, _jsxRuntime.jsx)(_material.FormControlLabel, {
            style: {
              alignItems: 'start'
            },
            control: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
              margin: 'dense',
              fullWidth: true,
              variant: 'outlined',
              multiline: true,
              rows: '4',
              value: categoryState.category.catDescription || '',
              onChange: updateCategoryFormParams,
              name: 'category.catDescription',
              inputProps: {
                'data-testid': 'category-description-input'
              }
            }),
            label: (0, _jsxRuntime.jsx)(_material.Typography, {
              display: 'inline',
              variant: 'h6',
              color: 'textSecondary',
              noWrap: true,
              children: "Category Description"
            }),
            labelPlacement: 'top'
          })
        })
      }), categoryStep >= 4 && (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        xs: 12,
        children: (0, _jsxRuntime.jsx)(_material.FormGroup, {
          children: (0, _jsxRuntime.jsx)(_material.FormControlLabel, {
            style: {
              alignItems: 'start'
            },
            control: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
              margin: 'dense',
              required: true,
              fullWidth: true,
              variant: 'outlined',
              select: true,
              SelectProps: {
                native: true
              },
              onChange: updateCategoryFormParams,
              name: 'category.catPrize',
              value: categoryState.category.catPrize || '',
              "data-testid": 'category-prize-select-input-group',
              inputProps: {
                'data-testid': 'category-prize-select-input'
              },
              children: (0, _HTMLUtils.enumCreateSelectOptionsFromType)(_ballroomTypes.CategoryPrizeType, true).map(option => (0, _jsxRuntime.jsx)("option", {
                value: option.value,
                "data-testid": "categoryPrize-".concat(option.value),
                children: option.label
              }, option.value))
            }),
            label: (0, _jsxRuntime.jsx)(_material.Typography, {
              display: 'inline',
              color: 'textSecondary',
              variant: 'h6',
              noWrap: true,
              children: "Category Prize"
            }),
            labelPlacement: 'top'
          })
        })
      }),
      //@ts-ignore
      categoryState.category.catPrize && _ballroomTypes.CategoryPrizeType[categoryState.category.catPrize] === _ballroomTypes.CategoryPrizeType.MONETARY && (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        xs: 12,
        style: {
          paddingLeft: theme.spacing(5)
        },
        children: (0, _jsxRuntime.jsx)(_material.FormGroup, {
          children: (0, _jsxRuntime.jsx)(_material.FormControlLabel, {
            style: {
              alignItems: 'start'
            },
            control: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
              margin: 'dense',
              variant: 'outlined',
              type: 'number',
              fullWidth: true,
              onChange: updateCategoryFormParams,
              name: 'category.monetaryPrize',
              value: categoryState.category.monetaryPrize || '',
              inputProps: {
                'data-testid': 'monetary-prize-input'
              },
              InputProps: {
                startAdornment: (0, _jsxRuntime.jsx)(_material.InputAdornment, {
                  position: 'start',
                  children: "$"
                })
              }
            }),
            label: '',
            labelPlacement: 'top'
          })
        })
      })]
    }), canFinalize && (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsx)(_material.Button, {
        onClick: closeCategory,
        variant: !isEditMode ? 'outlined' : 'contained',
        "data-testid": 'close-category-button',
        children: !isEditMode ? 'Add Next Category...' : 'Save'
      })
    })]
  });
};
var _default = exports.default = CategoryStepComponent;