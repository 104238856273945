"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _FooterMenuGroup = _interopRequireDefault(require("./FooterMenuGroup"));
var _PageContext = _interopRequireDefault(require("../../../page-context/PageContext"));
var _MailTo = _interopRequireDefault(require("../../../mail-to/MailTo"));
var _Logo = _interopRequireDefault(require("../../../logo/Logo"));
var _AlternatingText = _interopRequireDefault(require("../../../logo/AlternatingText"));
var _SocialMediaBlock = _interopRequireDefault(require("../../my-digital-resume/social-media-block/SocialMediaBlock"));
var _ColorPalette = require("../../../../theme/common/ColorPalette");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const FooterMenuContainer = props => {
  var _props$pageFooterMenu, _props$pageFooterMenu3, _props$pageFooterMenu4, _props$pageFooterMenu5, _props$pageFooterMenu6, _pageContext$page, _pageContext$page2, _pageContext$page3, _pageContext$page4, _pageContext$page5, _pageContext$page$bus, _pageContext$page6, _pageContext$page7, _pageContext$page8, _pageContext$page9, _pageContext$page10, _pageContext$page11, _pageContext$page12, _pageContext$page13, _pageContext$page14;
  const theme = (0, _material.useTheme)();
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const mdDown = (0, _material.useMediaQuery)(theme.breakpoints.down('md'));
  const mdUp = (0, _material.useMediaQuery)(theme.breakpoints.up('md'));
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    style: {
      color: theme.palette.getContrastText((0, _ColorPalette.convertToHexCode)(props.backgroundColor)),
      backgroundColor: props.backgroundColor,
      padding: theme.spacing(4, 4, 1, 4)
    },
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      xs: 12,
      md: 4,
      sx: mdDown ? {
        paddingLeft: "16px",
        paddingTop: "16px",
        paddingBottom: "16px",
        borderLeft: "4px solid ".concat(theme.palette.primary.main),
        backgroundColor: (0, _ColorPalette.convertToHexCode)(_ColorPalette.COLORS.GRAY),
        borderRight: "4px solid ".concat(theme.palette.primary.main)
      } : {},
      justifyContent: mdDown ? 'center' : 'flex-start',
      children: (_props$pageFooterMenu = props.pageFooterMenu) === null || _props$pageFooterMenu === void 0 || (_props$pageFooterMenu = _props$pageFooterMenu.subMenus) === null || _props$pageFooterMenu === void 0 ? void 0 : _props$pageFooterMenu.map((menuGroup, index) => {
        var _props$pageFooterMenu2;
        return (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 6,
          sm: 12,
          md: ((_props$pageFooterMenu2 = props.pageFooterMenu) === null || _props$pageFooterMenu2 === void 0 || (_props$pageFooterMenu2 = _props$pageFooterMenu2.subMenus) === null || _props$pageFooterMenu2 === void 0 ? void 0 : _props$pageFooterMenu2.length) === 1 ? 12 : 6,
          container: true,
          justifyContent: mdUp ? 'flex-start' : 'center',
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_FooterMenuGroup.default, {
              menuGroup: menuGroup
            })
          })
        }, index);
      })
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      container: true,
      xs: 12,
      md: 4,
      justifyContent: 'center',
      sx: {
        paddingTop: mdDown ? 2 : 0
      },
      children: [(_props$pageFooterMenu3 = props.pageFooterMenu) !== null && _props$pageFooterMenu3 !== void 0 && _props$pageFooterMenu3.logoImageSrc ? (0, _jsxRuntime.jsx)(_Logo.default, {
        isCenter: true,
        logoImageSrc: props.pageFooterMenu.logoImageSrc,
        height: 108
      }) : (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        justifyContent: 'center',
        alignContent: 'center',
        children: (0, _jsxRuntime.jsx)(_AlternatingText.default, {
          isLarge: true,
          logoText: (_props$pageFooterMenu4 = props.pageFooterMenu) === null || _props$pageFooterMenu4 === void 0 ? void 0 : _props$pageFooterMenu4.logoText,
          logoAccentText: (_props$pageFooterMenu5 = props.pageFooterMenu) === null || _props$pageFooterMenu5 === void 0 ? void 0 : _props$pageFooterMenu5.logoAccentText
        })
      }), (_props$pageFooterMenu6 = props.pageFooterMenu) !== null && _props$pageFooterMenu6 !== void 0 && _props$pageFooterMenu6.isShowSocialMedia ? (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        children: (0, _jsxRuntime.jsx)(_SocialMediaBlock.default, {
          isCentered: true,
          facebook: (_pageContext$page = pageContext.page) === null || _pageContext$page === void 0 || (_pageContext$page = _pageContext$page.businessContact) === null || _pageContext$page === void 0 ? void 0 : _pageContext$page.facebook,
          instagram: (_pageContext$page2 = pageContext.page) === null || _pageContext$page2 === void 0 || (_pageContext$page2 = _pageContext$page2.businessContact) === null || _pageContext$page2 === void 0 ? void 0 : _pageContext$page2.instagram,
          twitter: (_pageContext$page3 = pageContext.page) === null || _pageContext$page3 === void 0 || (_pageContext$page3 = _pageContext$page3.businessContact) === null || _pageContext$page3 === void 0 ? void 0 : _pageContext$page3.twitter
        })
      }) : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {}), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        justifyContent: 'center',
        style: {
          paddingBottom: "16px",
          marginTop: "12px"
        },
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Divider, {
            style: {
              width: "70px",
              backgroundColor: "white"
            }
          })
        })
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          spacing: 1,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'inherit',
              style: {
                width: "180px"
              },
              align: 'center',
              variant: 'subtitle1',
              gutterBottom: true,
              children: (_pageContext$page4 = pageContext.page) === null || _pageContext$page4 === void 0 || (_pageContext$page4 = _pageContext$page4.businessContact) === null || _pageContext$page4 === void 0 ? void 0 : _pageContext$page4.address
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          spacing: 1,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'inherit',
              align: 'center',
              variant: 'subtitle1',
              children: (_pageContext$page5 = pageContext.page) === null || _pageContext$page5 === void 0 || (_pageContext$page5 = _pageContext$page5.businessContact) === null || _pageContext$page5 === void 0 ? void 0 : _pageContext$page5.phone
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          spacing: 1,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_MailTo.default, {
              color: "inherit",
              email: (_pageContext$page$bus = (_pageContext$page6 = pageContext.page) === null || _pageContext$page6 === void 0 || (_pageContext$page6 = _pageContext$page6.businessContact) === null || _pageContext$page6 === void 0 ? void 0 : _pageContext$page6.email) !== null && _pageContext$page$bus !== void 0 ? _pageContext$page$bus : "",
              subject: "Information Request",
              body: "",
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: 'inherit',
                children: (_pageContext$page7 = pageContext.page) === null || _pageContext$page7 === void 0 || (_pageContext$page7 = _pageContext$page7.businessContact) === null || _pageContext$page7 === void 0 ? void 0 : _pageContext$page7.email
              })
            })
          })
        })]
      })]
    }), (_pageContext$page8 = pageContext.page) !== null && _pageContext$page8 !== void 0 && (_pageContext$page8 = _pageContext$page8.businessContact) !== null && _pageContext$page8 !== void 0 && _pageContext$page8.hoursOfOperation ? (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      container: true,
      xs: 12,
      md: 4,
      alignContent: 'flex-start',
      spacing: 2,
      paddingLeft: 3,
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            color: 'textSecondary',
            children: "Hours"
          })
        })
      }), (_pageContext$page9 = pageContext.page) === null || _pageContext$page9 === void 0 || (_pageContext$page9 = _pageContext$page9.businessContact) === null || _pageContext$page9 === void 0 || (_pageContext$page9 = _pageContext$page9.hoursOfOperation) === null || _pageContext$page9 === void 0 ? void 0 : _pageContext$page9.map((location, index) => {
        return (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'primary',
              children: (0, _jsxRuntime.jsx)("b", {
                children: location.name
              })
            })
          }), location.hoursOfOperation.map((hours, index2) => {
            return (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              children: (0, _jsxRuntime.jsxs)(_material.Typography, {
                color: 'textSecondary',
                children: [(0, _jsxRuntime.jsxs)("b", {
                  children: [hours.dayName, ": \u00A0"]
                }), hours.isClosed ? "closed" : "".concat(hours.startTime, "-").concat(hours.endTime)]
              })
            }, index2);
          })]
        }, index);
      })]
    }) : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {}), (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      container: true,
      xs: 12,
      md: 4,
      spacing: 2,
      alignItems: 'center',
      alignContent: 'center',
      children: [!mdDown && props.isSocialMediaBlock && (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        justifyContent: 'flex-end',
        xs: 6,
        md: 12,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'body2',
          children: "Social Media"
        })
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        xs: 12,
        md: 12,
        sx: {
          paddingTop: "4px !important",
          marginTop: mdDown ? 4 : 0,
          borderTop: props.isSocialMediaBlock ? "1px solid " + theme.palette.primary.main : "0px solid transparent"
        },
        children: [mdDown && props.isSocialMediaBlock && (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'flex-end',
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body2',
            children: "Social Media"
          })
        }), props.isSocialMediaBlock && (0, _jsxRuntime.jsx)(_SocialMediaBlock.default, {
          bgColor: true,
          theBackgroundColor: 'white',
          iconColor: 'rgba(0,0,0,0.85)',
          // isBackgroundColor={true}
          color: 'primary',
          spacing: 1,
          facebook: (_pageContext$page10 = pageContext.page) === null || _pageContext$page10 === void 0 || (_pageContext$page10 = _pageContext$page10.businessContact) === null || _pageContext$page10 === void 0 ? void 0 : _pageContext$page10.facebook,
          twitter: (_pageContext$page11 = pageContext.page) === null || _pageContext$page11 === void 0 || (_pageContext$page11 = _pageContext$page11.businessContact) === null || _pageContext$page11 === void 0 ? void 0 : _pageContext$page11.twitter,
          instagram: (_pageContext$page12 = pageContext.page) === null || _pageContext$page12 === void 0 || (_pageContext$page12 = _pageContext$page12.businessContact) === null || _pageContext$page12 === void 0 ? void 0 : _pageContext$page12.instagram,
          linkedIn: (_pageContext$page13 = pageContext.page) === null || _pageContext$page13 === void 0 || (_pageContext$page13 = _pageContext$page13.businessContact) === null || _pageContext$page13 === void 0 ? void 0 : _pageContext$page13.linkedIn,
          github: (_pageContext$page14 = pageContext.page) === null || _pageContext$page14 === void 0 || (_pageContext$page14 = _pageContext$page14.businessContact) === null || _pageContext$page14 === void 0 ? void 0 : _pageContext$page14.linkedIn
        })]
      })]
    })]
  });
};
var _default = exports.default = FooterMenuContainer;