"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _reactParallax = require("react-parallax");
var _clsx = _interopRequireDefault(require("clsx"));
var _Styles = _interopRequireDefault(require("../mackenzies-mind/pages/Styles"));
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _CustomizedThemeContext = _interopRequireDefault(require("../../customized-theme-provider/CustomizedThemeContext"));
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  root: {
    height: '430px',
    // backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(5)
  },
  contentBullets: {
    // border: "1px solid black"
    marginBottom: theme.spacing(5)
  }
}));
const ThwMottoSection = props => {
  var _sanityContext$urlFor;
  const globalClasses = (0, _Styles.default)({});
  const classes = useStyles();
  const customizedThemeContext = (0, _react.useContext)(_CustomizedThemeContext.default);
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const smDown = (0, _material.useMediaQuery)(customizedThemeContext.customizedTheme.breakpoints.down('sm'));
  return (0, _jsxRuntime.jsx)(_reactParallax.Parallax, {
    blur: 1,
    bgImage: (_sanityContext$urlFor = sanityContext.urlFor(props.sectionData.parallaxImage).url()) !== null && _sanityContext$urlFor !== void 0 ? _sanityContext$urlFor : undefined,
    bgImageAlt: "the cat",
    strength: 600,
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      className: (0, _clsx.default)([globalClasses.fullSection, classes.root]),
      style: {
        position: "relative",
        overflow: "hidden"
      },
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        className: (0, _clsx.default)(globalClasses.fullSectionOverlay)
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        item: true,
        style: {
          zIndex: 2,
          padding: "40px"
        },
        children: [(0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'subtitle1',
          style: {
            color: '#FAFAFA'
          },
          align: 'center',
          children: props.sectionData.contentSuperTitle
        }), (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: smDown ? 'h3' : 'h2',
          style: {
            color: '#FAFAFA'
          },
          align: 'center',
          children: props.sectionData.contentText
        })]
      })]
    })
  });
};
var _default = exports.default = ThwMottoSection;