"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
const MailTo = props => {
  return (0, _jsxRuntime.jsx)(_material.Button, {
    fullWidth: true,
    style: {
      color: props.color
    },
    href: "mailto:".concat(props.email, "?subject=").concat(props.subject || "", "&body=").concat(props.body || ""),
    children: (0, _jsxRuntime.jsx)(_material.Typography, {
      color: 'inherit',
      align: 'center',
      variant: 'subtitle1',
      children: props.children
    })
  });
};
var _default = exports.default = MailTo;