"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _ImageWithButtonOverlay = _interopRequireDefault(require("../../image-with-button-overlay/ImageWithButtonOverlay"));
var _ImageWithButtonOverlayAligmentEnum = require("../../image-with-button-overlay/ImageWithButtonOverlayAligmentEnum");
var _LoadingButton = _interopRequireDefault(require("../../loading-button/LoadingButton"));
var _uuid = require("uuid");
var _AmenitiesSection = _interopRequireDefault(require("./AmenitiesSection"));
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
var _TransformHWTheme = _interopRequireDefault(require("../../../theme/TransformHWTheme"));
var _CustomizedThemeContext = _interopRequireDefault(require("../../customized-theme-provider/CustomizedThemeContext"));
var _FirebaseContext = _interopRequireDefault(require("../../../common/firebase/firebase-context/FirebaseContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const ThwServiceItem = props => {
  var _props$service$slug2;
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const customizedThemeContext = (0, _react.useContext)(_CustomizedThemeContext.default);
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const mdDown = (0, _material.useMediaQuery)(customizedThemeContext.customizedTheme.breakpoints.down('md'));
  const LearnMoreButton = () => {
    var _props$service;
    return (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      justifyContent: 'center',
      children: props.service.learnMoreText && ((_props$service = props.service) === null || _props$service === void 0 ? void 0 : _props$service.learnMoreText.length) > 0 && (0, _jsxRuntime.jsx)(_LoadingButton.default, {
        clickHandler: () => {
          var _props$service$slug$c, _props$service$slug;
          return firebaseContext.ctaClick && firebaseContext.ctaClick((_props$service$slug$c = (_props$service$slug = props.service.slug) === null || _props$service$slug === void 0 ? void 0 : _props$service$slug.current) !== null && _props$service$slug$c !== void 0 ? _props$service$slug$c : "", props.service.learnMoreText, pageContext.analyticsId);
        },
        color: 'secondary',
        href: props.service.learnMoreLink,
        variant: 'outlined',
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'button',
          noWrap: true,
          children: props.service.learnMoreText
        })
      })
    });
  };
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    xs: 12,
    sm: 12,
    md: 6,
    style: {
      marginBottom: _TransformHWTheme.default.spacing(4)
    },
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      direction: 'column',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center',
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        direction: "column",
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          children: (0, _jsxRuntime.jsx)(_ImageWithButtonOverlay.default, {
            source: (_props$service$slug2 = props.service.slug) === null || _props$service$slug2 === void 0 ? void 0 : _props$service$slug2.current,
            // hideCtaButton={prop.hideCtaButton}
            tooltip: 'Click to Learn More',
            learnMoreLink: props.service.learnMoreLink,
            buttonAlignment: mdDown ? _ImageWithButtonOverlayAligmentEnum.ImageWithButtonOverlayAligmentEnum.CENTER : _ImageWithButtonOverlayAligmentEnum.ImageWithButtonOverlayAligmentEnum.RIGHT,
            imageAltText: props.service.imageSrcAltText,
            variant: 'contained',
            imageSrc: props.service.imageSrc,
            height: 352,
            ctaButtonText: props.service.ctaButtonText,
            ctaButtonLink: !props.hideCtaButton ? props.service.ctaButtonLink : undefined
          })
        }), (0, _jsxRuntime.jsx)(_material.Tooltip, {
          title: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'subtitle1',
            style: {
              fontWeight: "normal"
            },
            children: "Click to Learn More"
          }),
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            justifyContent: 'center',
            style: {
              marginTop: "16px",
              marginBottom: "16px"
            },
            children: (0, _jsxRuntime.jsx)(_material.Button, {
              variant: 'text',
              color: 'secondary',
              href: props.service.learnMoreLink,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'body2',
                align: 'center',
                children: props.service.contentTitle
              })
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            align: 'center',
            style: {
              marginBottom: "48px"
            },
            children: props.service.contentText
          })
        })]
      }), props.showAmenities && (0, _jsxRuntime.jsx)(_AmenitiesSection.default, {
        service: props.service
      }), !props.hideLearnMoreButton && (0, _jsxRuntime.jsx)(LearnMoreButton, {})]
    })
  }, (0, _uuid.v4)());
};
var _default = exports.default = ThwServiceItem;