"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _AlternatingText = _interopRequireDefault(require("./AlternatingText"));
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const logoImg = "/public/50bdcd4b6a1e9432fb73bb84192fb91f.png";
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  imageRoot: props => {
    var _props$logoImageSrc, _props$height;
    return {
      backgroundImage: "url('".concat((_props$logoImageSrc = props.logoImageSrc) !== null && _props$logoImageSrc !== void 0 ? _props$logoImageSrc : logoImg, "')"),
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      height: "".concat((_props$height = props.height) !== null && _props$height !== void 0 ? _props$height : 68, "px")
      // marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(2)
    };
  },
  root: props => {
    var _props$height2;
    return {
      minWidth: "100px",
      height: "".concat((_props$height2 = props.height) !== null && _props$height2 !== void 0 ? _props$height2 : 68, "px")
      // marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(2)
    };
  }
}));
const Logo = props => {
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  // const theme = useTheme()
  // const smDown = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles({
    logoImageSrc: props.logoImageSrc ? sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.logoImageSrc) : logoImg,
    height: props.height
  });
  return !props.logoText ? (0, _jsxRuntime.jsx)(_material.Grid, {
    item: true,
    container: true,
    className: classes.imageRoot,
    sx: {
      // minWidth: smDown ? "190px" : "100px",
    },
    style: {
      backgroundPosition: props.isCenter ? "center" : "left"
    }
  }) : (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    className: classes.root,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: props.isCenter ? 'center' : 'flex-start',
    children: (0, _jsxRuntime.jsx)(_AlternatingText.default, {
      noWrap: true,
      logoText: props.logoText,
      logoAccentText: props.logoAccentText
    })
  });
};
var _default = exports.default = Logo;