"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.promise.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
var _dateUtils = _interopRequireDefault(require("../../../../utils/dateUtils"));
var _CommonStyles = require("../../../../common/sanityIo/CommonStyles");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const LinkedExperiences = props => {
  const classes = (0, _CommonStyles.useCommonStyles)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const [skillExperiences, setSkillExperiences] = (0, _react.useState)();
  const [skillNumYears, setSkillNumYears] = (0, _react.useState)();
  const getSkillExperience = async skill => {
    if (sanityContext.fetchSkillExperiences) return sanityContext.fetchSkillExperiences(skill);else return undefined;
  };
  (0, _react.useEffect)(() => {
    getSkillExperience(props.resumeSkill).then(response => {
      if (response) {
        setSkillExperiences(response);
      }
    });
  }, []);
  (0, _react.useEffect)(() => {
    if (skillExperiences && skillExperiences[0] && skillExperiences[skillExperiences.length - 1] && skillExperiences[skillExperiences.length - 1].dateStart && skillExperiences[0].dateEnd) setSkillNumYears(_dateUtils.default.getLengthOfTime(skillExperiences[skillExperiences.length - 1].dateStart, skillExperiences[0].dateEnd).result);
  }, [skillExperiences]);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    paddingBottom: 1,
    children: [skillNumYears ? (0, _jsxRuntime.jsxs)(_material.Typography, {
      variant: 'caption',
      color: 'whitesmoke',
      children: ["".concat(skillNumYears), " "]
    }) : (0, _jsxRuntime.jsx)(_material.Typography, {
      variant: 'caption',
      color: 'whitesmoke',
      children: (skillExperiences === null || skillExperiences === void 0 ? void 0 : skillExperiences.length) !== 0 ? "Loading..." : ""
    }), skillExperiences ? skillExperiences.map((experience, index) => {
      return (0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 3,
          children: (0, _jsxRuntime.jsx)(_material.Link, {
            href: "#" + experience._id,
            className: classes.toolTiplink,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'caption',
              color: 'whitesmoke',
              children: _dateUtils.default.YearNumeric(experience.dateStart)
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 9,
          children: (0, _jsxRuntime.jsx)(_material.Link, {
            href: "#" + experience._id,
            className: classes.toolTiplink,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              lineHeight: .5,
              variant: 'caption',
              color: 'whitesmoke',
              children: experience.title
            })
          })
        })]
      }, index);
    }) : (0, _jsxRuntime.jsx)(_material.CircularProgress, {})]
  });
};
var _default = exports.default = LinkedExperiences;