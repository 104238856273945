"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.promise.js");
const processResponse = async (apiResponse, apiResponseTypeStr) => {
  // const LOG = apiResponseTypeStr
  return apiResponse.json().then(jsonResponse => {
    if (jsonResponse) {
      return jsonResponse;
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      attempt: Error("No response for ".concat(apiResponseTypeStr))
    });
  });
};
var _default = exports.default = {
  processResponse
};