"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _SanityContext = _interopRequireDefault(require("../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const ImageWithPlaceholder = props => {
  const [imageUrl, setImageUrl] = (0, _react.useState)();
  const [placeHolderUrl, setPlaceHolderUrl] = (0, _react.useState)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  (0, _react.useEffect)(() => {
    if (props.image) {
      var _theUrl$url;
      const theUrl = sanityContext.urlFor(props.image);
      if (props.width) {
        theUrl.width(props.width);
      }
      if (props.height) {
        theUrl.width(props.height);
      }
      setImageUrl((_theUrl$url = theUrl.url()) !== null && _theUrl$url !== void 0 ? _theUrl$url : "");
    } else {
      let theUrl = "https://placehold.co/";
      if (props.width) {
        if (props.height) {
          theUrl += "".concat(props.width, "x").concat(props.height);
        } else {
          theUrl += "".concat(props.width, "x").concat(props.width);
        }
      } else {
        theUrl += "".concat(props.height, "x").concat(props.height);
      }
      if (props.text) {
        theUrl += "?text=".concat(props.text);
      }
      setPlaceHolderUrl(theUrl);
    }
  }, []);
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    children: props.image ? (0, _jsxRuntime.jsx)("img", {
      alt: props.imageAltText,
      src: imageUrl
    }) : (0, _jsxRuntime.jsx)("img", {
      src: placeHolderUrl,
      alt: 'placeholder'
    })
  });
};
var _default = exports.default = ImageWithPlaceholder;