"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _uuid = require("uuid");
var _WebDevSiteTheme = _interopRequireDefault(require("../../../theme/WebDevSiteTheme"));
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
var _FirebaseContext = _interopRequireDefault(require("../../../common/firebase/firebase-context/FirebaseContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));
const COLOR_ROTATION = ["#cd3647", "#343656", "#333784"];
const WebDevHowItWorksStep = props => {
  var _props$index, _props$index2;
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const classes = useStyles(_WebDevSiteTheme.default);
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const LearnMoreButton = () => {
    var _props$step;
    return (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      sm: 8,
      children: props.step.learnMoreText && ((_props$step = props.step) === null || _props$step === void 0 ? void 0 : _props$step.learnMoreText.length) > 0 && (0, _jsxRuntime.jsx)(_material.Button, {
        fullWidth: true,
        onClick: () => {
          var _props$step$slug$curr, _props$step$slug, _props$step$learnMore;
          return firebaseContext.ctaClick && firebaseContext.ctaClick((_props$step$slug$curr = (_props$step$slug = props.step.slug) === null || _props$step$slug === void 0 ? void 0 : _props$step$slug.current) !== null && _props$step$slug$curr !== void 0 ? _props$step$slug$curr : "", (_props$step$learnMore = props.step.learnMoreText) !== null && _props$step$learnMore !== void 0 ? _props$step$learnMore : "", pageContext.analyticsId);
        },
        color: 'primary',
        href: props.step.learnMoreLink,
        variant: 'outlined',
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'button',
          noWrap: true,
          children: props.step.learnMoreText
        })
      })
    });
  };
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    className: classes.root,
    container: true,
    item: true,
    xs: 12,
    sm: 12,
    md: 6,
    style: {
      backgroundColor: COLOR_ROTATION[((_props$index = props.index) !== null && _props$index !== void 0 ? _props$index : 0) % 3]
    },
    children: (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      direction: 'column',
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        spacing: 2,
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          alignContent: 'center',
          justifyContent: 'space-between',
          alignItems: 'center',
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            xs: 6,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'h4',
              style: {
                fontFamily: "Elaine Sans"
              },
              color: 'primary',
              children: props.step.title
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              xs: 6,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'h1',
                style: {
                  fontFamily: "Elaine Sans",
                  color: "rgba(255,255,255,.3)"
                },
                color: 'primary',
                children: ((_props$index2 = props.index) !== null && _props$index2 !== void 0 ? _props$index2 : 0) + 1
              })
            })
          })]
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            color: 'primary',
            style: {
              fontFamily: "Raleway"
            },
            children: props.step.contentText
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          children: !props.hideLearnMoreButton && (0, _jsxRuntime.jsx)(LearnMoreButton, {})
        })]
      })
    })
  }, (0, _uuid.v4)());
};
var _default = exports.default = WebDevHowItWorksStep;