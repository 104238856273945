"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _ColorPalette = require("../../../theme/common/ColorPalette");
var _iconsMaterial = require("@mui/icons-material");
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
var _CustomizedThemeContext = _interopRequireDefault(require("../../customized-theme-provider/CustomizedThemeContext"));
var _FirebaseContext = _interopRequireDefault(require("../../../common/firebase/firebase-context/FirebaseContext"));
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  root: {
    backgroundColor: '#131313',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  }
}));
const COLOR_ROTATION = ["#d9dde9", "#333784"];
const COLOR_ROTATION_FONT = ["textSecondary", "textPrimary"];
const WebDevPortfolioSection = props => {
  var _props$sectionData, _props$sectionData$po, _currentItem$skillsHi, _currentItem$inceptio, _currentItem$imageGal;
  const theme = (0, _material.useTheme)();
  const classes = useStyles();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const customizedThemeContext = (0, _react.useContext)(_CustomizedThemeContext.default);
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const xsOnly = (0, _material.useMediaQuery)(customizedThemeContext.customizedTheme.breakpoints.only('xs'));
  const [isOpen, setIsOpen] = (0, _react.useState)(false);
  const [currentItem, setCurrentItem] = (0, _react.useState)();
  const sendToModal = portfolioItem => {
    setCurrentItem(portfolioItem);
    setIsOpen(true);
  };
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const LearnMoreButton = props => {
    return (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      md: 8,
      children: props.learnMoreText && props.learnMoreText.length > 0 && (0, _jsxRuntime.jsx)(_material.Button, {
        fullWidth: true,
        onClick: () => {
          var _props$service$slug$c, _props$service$slug;
          sendToModal(props.portfolioItem);
          return firebaseContext.ctaClick && firebaseContext.ctaClick((_props$service$slug$c = (_props$service$slug = props.service.slug) === null || _props$service$slug === void 0 ? void 0 : _props$service$slug.current) !== null && _props$service$slug$c !== void 0 ? _props$service$slug$c : "", props.service.learnMoreText, pageContext.analyticsId);
        },
        color: props.index % 2 === 0 ? 'primary' : 'secondary',
        href: props.portfolioItem.prodLink,
        variant: 'outlined',
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'button',
          noWrap: true,
          children: props.learnMoreText
        })
      })
    });
  };
  const mdDown = (0, _material.useMediaQuery)(customizedThemeContext.customizedTheme.breakpoints.down('md'));
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    className: classes.root,
    children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      spacing: 3,
      justifyContent: 'center',
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        alignContent: 'flex-start',
        spacing: 1,
        xs: 11,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'subtitle2',
            color: 'secondary',
            style: {
              color: _ColorPalette.COLORS.AQUA,
              fontFamily: "Elaine Sans",
              lineHeight: 1
            },
            children: (_props$sectionData = props.sectionData) === null || _props$sectionData === void 0 ? void 0 : _props$sectionData.preTitle
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h2',
            color: 'primary',
            style: {
              fontFamily: "Elaine Sans"
            },
            children: props.sectionData.title
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          xs: 8,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            color: 'primary',
            variant: 'body1',
            style: {
              fontFamily: "Raleway"
            },
            children: props.sectionData.introduction
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        justifyContent: xsOnly ? 'center' : 'flex-start',
        xs: 11,
        children: (_props$sectionData$po = props.sectionData.portfolioEntries) === null || _props$sectionData$po === void 0 ? void 0 : _props$sectionData$po.map((portfolioItem, index2) => {
          var _portfolioItem$coverI, _ref, _portfolioItem$skills, _ref2, _ref3;
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            children: (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              item: true,
              direction: index2 % 2 === 0 ? "row" : "row-reverse",
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                md: 6,
                style: {
                  backgroundImage: "url('".concat(sanityContext.urlFor((_portfolioItem$coverI = portfolioItem.coverImage) !== null && _portfolioItem$coverI !== void 0 ? _portfolioItem$coverI : "").url(), "')"),
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  minHeight: "400px"
                }
              }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                item: true,
                md: 6,
                style: {
                  padding: theme.spacing(mdDown ? 4 : 6),
                  backgroundColor: COLOR_ROTATION[((_ref = index2 + 1) !== null && _ref !== void 0 ? _ref : 1) % 2]
                },
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  container: true,
                  children: (_portfolioItem$skills = portfolioItem.skillsHighlighted) === null || _portfolioItem$skills === void 0 ? void 0 : _portfolioItem$skills.map((skill, skillIndex) => {
                    var _portfolioItem$skills2, _portfolioItem$skills3;
                    return (0, _jsxRuntime.jsxs)(_material.Grid, {
                      container: true,
                      item: true,
                      wrap: 'nowrap',
                      style: {
                        width: "max-content",
                        color: _ColorPalette.COLORS.AQUA
                      },
                      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                        item: true,
                        container: true,
                        children: (0, _jsxRuntime.jsx)(_material.Typography, {
                          variant: 'subtitle2',
                          color: 'inherit',
                          noWrap: true,
                          style: {
                            textTransform: "uppercase",
                            fontWeight: 900,
                            fontFamily: "Elaine Sans",
                            letterSpacing: ".15em"
                          },
                          children: skill.title
                        })
                      }), (0, _jsxRuntime.jsx)(_material.Grid, {
                        item: true,
                        container: true,
                        children: skillIndex < ((_portfolioItem$skills2 = (_portfolioItem$skills3 = portfolioItem.skillsHighlighted) === null || _portfolioItem$skills3 === void 0 ? void 0 : _portfolioItem$skills3.length) !== null && _portfolioItem$skills2 !== void 0 ? _portfolioItem$skills2 : 0) - 1 && (0, _jsxRuntime.jsx)(_material.Typography, {
                          variant: 'subtitle1',
                          color: 'inherit',
                          children: "\u2022"
                        })
                      })]
                    }, skillIndex);
                  })
                }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                  container: true,
                  item: true,
                  children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    xs: 6,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      gutterBottom: true,
                      variant: 'h3',
                      style: {
                        fontFamily: "Elaine Sans"
                      },
                      color: COLOR_ROTATION_FONT[((_ref2 = index2 + 1) !== null && _ref2 !== void 0 ? _ref2 : 1) % 2],
                      children: portfolioItem === null || portfolioItem === void 0 ? void 0 : portfolioItem.title
                    })
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    container: true,
                    item: true,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      variant: 'body1',
                      gutterBottom: true,
                      style: {
                        fontFamily: "Raleway",
                        marginBottom: "16px"
                      },
                      color: COLOR_ROTATION_FONT[((_ref3 = index2 + 1) !== null && _ref3 !== void 0 ? _ref3 : 1) % 2],
                      children: portfolioItem.detailDescription
                    })
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    container: true,
                    item: true,
                    children: (0, _jsxRuntime.jsx)(LearnMoreButton, {
                      learnMoreText: "Learn More",
                      portfolioItem: portfolioItem,
                      index: index2
                    })
                  })]
                })]
              })]
            })
          }, index2);
        })
      })]
    }), (0, _jsxRuntime.jsx)(_material.Modal, {
      open: isOpen,
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        style: {
          width: "100vw",
          height: "100vh",
          position: "relative"
        },
        children: [(0, _jsxRuntime.jsx)(_material.IconButton, {
          color: 'secondary',
          style: {
            position: "absolute",
            top: 0,
            right: 0
          },
          onClick: () => setIsOpen(false),
          size: "large",
          children: (0, _jsxRuntime.jsx)(_iconsMaterial.Close, {
            fontSize: 'large'
          })
        }), (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          sm: 8,
          item: true,
          style: {
            backgroundColor: "white",
            padding: theme.spacing(4),
            maxHeight: "800px",
            overflowY: "scroll",
            maxWidth: "100%"
          },
          spacing: 2,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'h3',
              children: currentItem === null || currentItem === void 0 ? void 0 : currentItem.detailTitle
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'body1',
              children: currentItem === null || currentItem === void 0 ? void 0 : currentItem.detailDescription
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            spacing: 1,
            children: currentItem === null || currentItem === void 0 || (_currentItem$skillsHi = currentItem.skillsHighlighted) === null || _currentItem$skillsHi === void 0 ? void 0 : _currentItem$skillsHi.map(skill => (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Chip, {
                color: 'primary',
                label: skill.title
              })
            }))
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: currentItem === null || currentItem === void 0 || (_currentItem$inceptio = currentItem.inceptionDate) === null || _currentItem$inceptio === void 0 ? void 0 : _currentItem$inceptio.toString()
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              justifyContent: 'center',
              children: currentItem === null || currentItem === void 0 || (_currentItem$imageGal = currentItem.imageGallery) === null || _currentItem$imageGal === void 0 ? void 0 : _currentItem$imageGal.map(image => {
                var _sanityContext$urlFor;
                return (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  container: true,
                  xs: 11,
                  justifyContent: 'center',
                  children: (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    children: (0, _jsxRuntime.jsx)("img", {
                      src: (_sanityContext$urlFor = sanityContext.urlFor(image !== null && image !== void 0 ? image : "").url()) !== null && _sanityContext$urlFor !== void 0 ? _sanityContext$urlFor : "",
                      width: "100%"
                    })
                  })
                });
              })
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsxs)(_material.ButtonGroup, {
              fullWidth: true,
              style: {
                marginTop: theme.spacing(4)
              },
              children: [(0, _jsxRuntime.jsx)(_material.Button, {
                variant: 'contained',
                color: "primary",
                href: currentItem === null || currentItem === void 0 ? void 0 : currentItem.linkToProd,
                children: "Go to this Project"
              }), (0, _jsxRuntime.jsx)(_material.Button, {
                variant: 'contained',
                color: 'secondary',
                onClick: () => setIsOpen(false),
                children: "Back to Resume"
              })]
            })
          })]
        })]
      })
    })]
  });
};
var _default = exports.default = WebDevPortfolioSection;