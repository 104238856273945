"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _SearchContext = _interopRequireDefault(require("./search-context/SearchContext"));
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const FlashCardNav = props => {
  var _searchContext$search, _searchContext$search2, _searchContext$cardCo, _searchContext$search3, _searchContext$search4;
  const searchContext = (0, _react.useContext)(_SearchContext.default);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    sx: {
      position: "relative",
      color: props.isDarkMode ? 'white' : 'secondary'
    },
    children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'center',
      alignContent: 'center',
      minHeight: 450,
      minWidth: 350,
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        justifyContent: 'center',
        children: (0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          children: [" ", props.children]
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: ((_searchContext$search = (_searchContext$search2 = searchContext.searchResults) === null || _searchContext$search2 === void 0 ? void 0 : _searchContext$search2.length) !== null && _searchContext$search !== void 0 ? _searchContext$search : 0) > 0 ? (0, _jsxRuntime.jsx)(_material.Typography, {
          color: props.isDarkMode ? 'inherit' : 'secondary',
          fontFamily: 'Oswald',
          variant: 'h6',
          children: "".concat(((_searchContext$cardCo = searchContext.cardCounter) !== null && _searchContext$cardCo !== void 0 ? _searchContext$cardCo : 0) + 1, " / ").concat((_searchContext$search3 = (_searchContext$search4 = searchContext.searchResults) === null || _searchContext$search4 === void 0 ? void 0 : _searchContext$search4.length) !== null && _searchContext$search3 !== void 0 ? _searchContext$search3 : 1)
        }) : (0, _jsxRuntime.jsx)(_material.Typography, {
          color: 'textPrimary',
          fontFamily: 'Oswald',
          variant: 'h6',
          children: "0 / 0"
        })
      })]
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      alignContent: 'center',
      sx: {
        position: "absolute",
        height: "100%"
      },
      children: (0, _jsxRuntime.jsx)(_material.IconButton, {
        sx: {
          zIndex: 1000
        },
        color: props.isDarkMode ? 'inherit' : 'secondary',
        onClick: searchContext.prevCard,
        children: (0, _jsxRuntime.jsx)(_iconsMaterial.ArrowLeft, {
          style: {
            fontSize: 32
          },
          color: 'inherit'
        })
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      alignContent: 'center',
      justifyContent: 'flex-end',
      style: {
        position: "absolute",
        right: 0,
        height: "100%"
      },
      children: (0, _jsxRuntime.jsx)(_material.IconButton, {
        sx: {
          zIndex: 1000
        },
        color: props.isDarkMode ? 'inherit' : 'secondary',
        onClick: searchContext.nextCard,
        children: (0, _jsxRuntime.jsx)(_iconsMaterial.ArrowRight, {
          style: {
            fontSize: 32
          },
          color: 'inherit'
        })
      })
    })]
  });
};
var _default = exports.default = FlashCardNav;