"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _clsx = _interopRequireDefault(require("clsx"));
var _Styles = _interopRequireDefault(require("../mackenzies-mind/pages/Styles"));
var _TheOtherSideLogo = _interopRequireDefault(require("./TheOtherSideLogo"));
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  preroot: {
    minHeight: '521px',
    color: "white",
    position: "relative",
    padding: theme.spacing(9, 0)
  }
}));
const TheDrinkerySpecials = props => {
  var _pageContext$page, _props$sectionData, _props$sectionData2, _props$sectionData3, _props$sectionData4, _props$sectionData5, _props$sectionData6;
  const classes = (0, _Styles.default)({
    bgImage: undefined
  });
  const theme = (0, _material.useTheme)();
  const theClasses = useStyles();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const pageContext = (0, _react.useContext)(_PageContext.default);
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    className: theClasses.preroot,
    children: (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      className: (0, _clsx.default)(classes.fullSection),
      justifyContent: 'center',
      alignItems: 'center',
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        spacing: 1,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_TheOtherSideLogo.default, {
            isCenter: true,
            logoImageSrc: (_pageContext$page = pageContext.page) === null || _pageContext$page === void 0 ? void 0 : _pageContext$page.businessCardImageSrc
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)("img", {
            src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.sectionData.imageSrc, 485, 356),
            alt: (_props$sectionData = props.sectionData) === null || _props$sectionData === void 0 ? void 0 : _props$sectionData.imageSrcAltText
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            style: {
              maxWidth: 800
            },
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              align: 'center',
              variant: 'body2',
              style: {
                fontWeight: 400,
                paddingTop: 16,
                paddingRight: 32,
                paddingLeft: 32
              },
              children: (_props$sectionData2 = props.sectionData) === null || _props$sectionData2 === void 0 ? void 0 : _props$sectionData2.contentText
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            style: {
              backgroundColor: "whitesmoke"
            },
            children: (0, _jsxRuntime.jsxs)(_material.Typography, {
              align: 'center',
              variant: 'body1',
              style: {
                fontWeight: 400,
                paddingRight: 32,
                paddingLeft: 32,
                color: "black"
              },
              children: ["*", (_props$sectionData3 = props.sectionData) === null || _props$sectionData3 === void 0 ? void 0 : _props$sectionData3.disclaimer]
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h3',
            children: (_props$sectionData4 = props.sectionData) === null || _props$sectionData4 === void 0 ? void 0 : _props$sectionData4.contentTitle
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            children: (_props$sectionData5 = props.sectionData) === null || _props$sectionData5 === void 0 ? void 0 : _props$sectionData5.subTitle
          })
        }), (_props$sectionData6 = props.sectionData) === null || _props$sectionData6 === void 0 ? void 0 : _props$sectionData6.theSpecials.map((specialData, index) => (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          style: {
            paddingTop: theme.spacing(4)
          },
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'h4',
              children: specialData.title
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'body2',
              children: specialData.content
            })
          })]
        }, index))]
      })
    })
  });
};
var _default = exports.default = TheDrinkerySpecials;