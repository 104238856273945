"use strict";

require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _styles = require("@mui/styles");
var _material = require("@mui/material");
var _BallSearchContext = _interopRequireDefault(require("../ball-search-context/BallSearchContext"));
var _iconsMaterial = require("@mui/icons-material");
var _StyledTextField = _interopRequireDefault(require("../styled-text-field/StyledTextField"));
var _Region = require("../enums/Region.enum");
var _dateUtils = _interopRequireDefault(require("../../../../utils/dateUtils"));
var _BallType = require("../enums/BallType.enum");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const StyledMenu = (0, _styles.withStyles)({
  paper: {
    border: '1px solid #d3d4d5',
    maxWidth: '850px',
    width: '422px',
    backgroundColor: "white !important"
  }
})(props => (0, _jsxRuntime.jsx)(_material.Menu, _objectSpread({
  elevation: 0,
  // getContentAnchorEl={undefined}
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }
}, props)));
const SearchFilterDropDown = () => {
  var _searchContext$search, _searchContext$search2, _searchContext$search3, _searchContext$search4, _searchContext$search5, _searchContext$search6, _searchContext$search7, _searchContext$search8, _searchContext$search9, _searchContext$search10, _searchContext$search11, _searchContext$search12, _searchContext$search13, _searchContext$search14;
  const [anchorEl, setAnchorEl] = (0, _react.useState)(null);
  const searchContext = (0, _react.useContext)(_BallSearchContext.default);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = (0, _material.useTheme)();
  const style = {
    fieldset: {
      borderColor: "black"
    },
    "&:hover fieldset": {
      //borderColor: "green!important" // works
      borderColor: "black!important" // doesnt work
    }
  };
  const getRegionFilterLabel = regionToConvert => {
    switch (regionToConvert) {
      case _Region.RegionEnum.ABROAD:
        return "Abroad";
      case _Region.RegionEnum.CANADA:
        return "Canada";
      case _Region.RegionEnum.EAST_COAST:
        return "East Coast";
      case _Region.RegionEnum.THE_MIDWEST:
        return "The Midwest";
      case _Region.RegionEnum.THE_SOUTH:
        return "The South";
      case _Region.RegionEnum.WEST_COAST:
        return "West Coast";
      case _Region.RegionEnum.NONE:
      default:
        return "";
    }
  };
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      children: (0, _jsxRuntime.jsxs)(_material.Button, {
        "aria-controls": "customized-menu",
        "aria-haspopup": "true",
        onClick: handleClick,
        color: 'secondary',
        sx: {
          padding: theme.spacing(1, 2)
        },
        children: [(0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'h6',
          fontWeight: 800,
          children: "Filters:"
        }), " ", (0, _jsxRuntime.jsx)(_iconsMaterial.FilterList, {
          fontSize: 'medium'
        })]
      })
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'flex-start',
      paddingLeft: "16px",
      paddingBottom: "8px",
      children: [((_searchContext$search = searchContext.searchParams) === null || _searchContext$search === void 0 ? void 0 : _searchContext$search.ballType) && (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Chip, {
          onDelete: () => {
            if (searchContext.updateSearchParams) {
              searchContext.updateSearchParams({
                target: {
                  name: "ballType",
                  value: undefined
                }
              });
            }
          },
          color: "primary",
          label: "ball type: ".concat((0, _BallType.renderBallType)(searchContext.searchParams.ballType))
        })
      }), ((_searchContext$search2 = searchContext.searchParams) === null || _searchContext$search2 === void 0 ? void 0 : _searchContext$search2.region) && (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Chip, {
          onDelete: () => {
            if (searchContext.updateSearchParams) {
              searchContext.updateSearchParams({
                target: {
                  name: "region",
                  value: undefined
                }
              });
            }
          },
          color: "primary",
          label: "region: ".concat(getRegionFilterLabel(searchContext.searchParams.region))
        })
      }), ((_searchContext$search3 = searchContext.searchParams) === null || _searchContext$search3 === void 0 ? void 0 : _searchContext$search3.startDate) && (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Chip, {
          onDelete: () => {
            if (searchContext.updateSearchParams) {
              searchContext.updateSearchParams({
                target: {
                  name: "endDate",
                  value: undefined
                }
              });
              searchContext.updateSearchParams({
                target: {
                  name: "startDate",
                  value: undefined
                }
              });
            }
          },
          color: "primary",
          label: "dates: ".concat(_dateUtils.default.MonthDateYear((_searchContext$search4 = searchContext.searchParams) === null || _searchContext$search4 === void 0 ? void 0 : _searchContext$search4.startDate)).concat((_searchContext$search5 = searchContext.searchParams) !== null && _searchContext$search5 !== void 0 && _searchContext$search5.endDate ? " to " + _dateUtils.default.MonthDateYear((_searchContext$search6 = searchContext.searchParams) === null || _searchContext$search6 === void 0 ? void 0 : _searchContext$search6.endDate) : "")
        })
      })]
    }), (0, _jsxRuntime.jsx)(StyledMenu, {
      id: "customized-menu",
      anchorEl: anchorEl,
      keepMounted: true,
      open: Boolean(anchorEl),
      onClose: handleClose,
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        direction: "column",
        children: [(0, _jsxRuntime.jsx)(_material.MenuItem, {
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            alignItems: "center",
            style: {
              borderBottom: "1px solid #ece7e7",
              paddingBottom: theme.spacing(2)
            },
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              xs: 1,
              children: (0, _jsxRuntime.jsx)(_material.ListItemIcon, {
                children: (0, _jsxRuntime.jsx)(_iconsMaterial.Web, {
                  fontSize: "small"
                })
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              xs: 4,
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  children: "Ball Type"
                })
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              xs: 7,
              justifyContent: 'flex-end',
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                xs: 12,
                children: (0, _jsxRuntime.jsxs)(_material.Select, {
                  sx: style,
                  fullWidth: true,
                  value: (_searchContext$search7 = (_searchContext$search8 = searchContext.searchParams) === null || _searchContext$search8 === void 0 ? void 0 : _searchContext$search8.ballType) !== null && _searchContext$search7 !== void 0 ? _searchContext$search7 : _BallType.BallTypeEnum.NONE,
                  onChange: e => {
                    if (searchContext.updateSearchParams) {
                      setAnchorEl(null);
                      searchContext.updateSearchParams(e);
                    }
                  },
                  name: "ballType",
                  displayEmpty: true,
                  inputProps: {
                    'aria-label': 'Without label'
                  },
                  children: [(0, _jsxRuntime.jsx)(_material.MenuItem, {
                    value: _BallType.BallTypeEnum.NONE,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: (0, _jsxRuntime.jsx)("em", {
                        children: "None"
                      })
                    })
                  }), (0, _jsxRuntime.jsx)(_material.MenuItem, {
                    value: _BallType.BallTypeEnum.BALL,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: (0, _BallType.renderBallType)(_BallType.BallTypeEnum.BALL)
                    })
                  }), (0, _jsxRuntime.jsx)(_material.MenuItem, {
                    value: _BallType.BallTypeEnum.MINI_BALL,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: (0, _BallType.renderBallType)(_BallType.BallTypeEnum.MINI_BALL)
                    })
                  }), (0, _jsxRuntime.jsx)(_material.MenuItem, {
                    value: _BallType.BallTypeEnum.MINI_BALL_DELUXE,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: (0, _BallType.renderBallType)(_BallType.BallTypeEnum.MINI_BALL_DELUXE)
                    })
                  }), (0, _jsxRuntime.jsx)(_material.MenuItem, {
                    value: _BallType.BallTypeEnum.KIKI_BALL,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: (0, _BallType.renderBallType)(_BallType.BallTypeEnum.KIKI_BALL)
                    })
                  })]
                })
              })
            })]
          })
        }), (0, _jsxRuntime.jsx)(_material.MenuItem, {
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            alignItems: "center",
            style: {
              borderBottom: "1px solid #ece7e7",
              paddingBottom: theme.spacing(2)
            },
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              xs: 1,
              children: (0, _jsxRuntime.jsx)(_material.ListItemIcon, {
                children: (0, _jsxRuntime.jsx)(_iconsMaterial.LocationOn, {
                  fontSize: "small"
                })
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              xs: 4,
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  children: "Region"
                })
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              xs: 7,
              justifyContent: 'flex-end',
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                xs: 12,
                children: (0, _jsxRuntime.jsxs)(_material.Select, {
                  sx: style,
                  fullWidth: true,
                  value: (_searchContext$search9 = (_searchContext$search10 = searchContext.searchParams) === null || _searchContext$search10 === void 0 ? void 0 : _searchContext$search10.region) !== null && _searchContext$search9 !== void 0 ? _searchContext$search9 : _Region.RegionEnum.NONE,
                  onChange: e => {
                    if (searchContext.updateSearchParams) {
                      setAnchorEl(null);
                      searchContext.updateSearchParams(e);
                    }
                  },
                  name: "region",
                  displayEmpty: true,
                  inputProps: {
                    'aria-label': 'Without label'
                  },
                  children: [(0, _jsxRuntime.jsx)(_material.MenuItem, {
                    value: "",
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: (0, _jsxRuntime.jsx)("em", {
                        children: "None"
                      })
                    })
                  }), (0, _jsxRuntime.jsx)(_material.MenuItem, {
                    value: _Region.RegionEnum.EAST_COAST,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: _Region.RegionTitleEnum.EAST_COAST
                    })
                  }), (0, _jsxRuntime.jsx)(_material.MenuItem, {
                    value: _Region.RegionEnum.WEST_COAST,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: _Region.RegionTitleEnum.WEST_COAST
                    })
                  }), (0, _jsxRuntime.jsx)(_material.MenuItem, {
                    value: _Region.RegionEnum.CANADA,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: _Region.RegionTitleEnum.CANADA
                    })
                  }), (0, _jsxRuntime.jsx)(_material.MenuItem, {
                    value: _Region.RegionEnum.THE_MIDWEST,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: _Region.RegionTitleEnum.THE_MIDWEST
                    })
                  }), (0, _jsxRuntime.jsx)(_material.MenuItem, {
                    value: _Region.RegionEnum.THE_SOUTH,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: _Region.RegionTitleEnum.THE_SOUTH
                    })
                  }), (0, _jsxRuntime.jsx)(_material.MenuItem, {
                    value: _Region.RegionEnum.ABROAD,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: _Region.RegionTitleEnum.ABROAD
                    })
                  })]
                })
              })
            })]
          })
        }), (0, _jsxRuntime.jsx)(_material.MenuItem, {
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            wrap: "nowrap",
            alignItems: "flex-start",
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              xs: 1,
              children: (0, _jsxRuntime.jsx)(_material.ListItemIcon, {
                children: (0, _jsxRuntime.jsx)(_iconsMaterial.CalendarToday, {
                  fontSize: "small"
                })
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              xs: 4,
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  children: "Date"
                })
              })
            }), (0, _jsxRuntime.jsxs)(_material.Grid, {
              item: true,
              container: true,
              xs: 7,
              direction: "column",
              spacing: 2,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
                  fullWidth: true,
                  sx: {
                    input: {
                      color: 'black',
                      paddingRight: "0px"
                    }
                  },
                  label: "Start Date",
                  type: "date",
                  InputLabelProps: {
                    shrink: true
                  },
                  onChange: e => {
                    if (searchContext.updateSearchParams) {
                      searchContext.updateSearchParams(e);
                    }
                  },
                  name: "startDate",
                  value: (_searchContext$search11 = (_searchContext$search12 = searchContext.searchParams) === null || _searchContext$search12 === void 0 ? void 0 : _searchContext$search12.startDate) !== null && _searchContext$search11 !== void 0 ? _searchContext$search11 : ''
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
                  fullWidth: true,
                  sx: {
                    input: {
                      color: 'black',
                      paddingRight: "0px"
                    }
                  },
                  label: "End Date",
                  type: "date",
                  InputLabelProps: {
                    shrink: true
                  },
                  onChange: e => {
                    if (searchContext.updateSearchParams) {
                      setAnchorEl(null);
                      searchContext.updateSearchParams(e);
                    }
                  },
                  name: "endDate",
                  value: (_searchContext$search13 = (_searchContext$search14 = searchContext.searchParams) === null || _searchContext$search14 === void 0 ? void 0 : _searchContext$search14.endDate) !== null && _searchContext$search13 !== void 0 ? _searchContext$search13 : ''
                })
              })]
            })]
          })
        })]
      })
    })]
  });
};
var _default = exports.default = SearchFilterDropDown;