"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.weak-map.js");
require("core-js/modules/web.dom-collections.iterator.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _materialUiPopupState = _interopRequireWildcard(require("material-ui-popup-state"));
var _iconsMaterial = require("@mui/icons-material");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _SubMenu = _interopRequireDefault(require("../templates/mackenzies-mind/header/SubMenu"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const useStyles = (0, _makeStyles.default)(() => ({
  hover: {
    "&:hover": {
      backgroundColor: 'rgba(16,43,136, 0.04)',
      "& .MuiTypography-root": {
        color: "#2828d3"
      }
    }
  }
}));
const PopupStateWrapper = _ref => {
  var _menuGroup$menuGroupT;
  let {
    menuGroup
  } = _ref;
  const classes = useStyles();
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsx)(_materialUiPopupState.default, {
    variant: "popover",
    popupId: (_menuGroup$menuGroupT = menuGroup.menuGroupTitle) === null || _menuGroup$menuGroupT === void 0 ? void 0 : _menuGroup$menuGroupT.toLowerCase().replace(" ", "-"),
    children: popupState => {
      const handleClose = () => {
        // e.stopPropagation()
        popupState.close();
      };
      return (0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        style: {
          height: "100%"
        },
        children: [(0, _jsxRuntime.jsx)(_material.Button, _objectSpread(_objectSpread({
          className: classes.hover
        }, (0, _materialUiPopupState.bindTrigger)(popupState)), {}, {
          color: "secondary",
          style: {
            borderRadius: 0,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(3),
            height: "100%",
            color: theme.palette.secondary.main
          },
          endIcon: (0, _jsxRuntime.jsx)(_iconsMaterial.ArrowDropDown, {}),
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body2',
            style: {
              fontSize: "18px"
            },
            children: menuGroup.menuGroupTitle
          })
        })), (0, _jsxRuntime.jsx)(_material.Popover, _objectSpread(_objectSpread({}, (0, _materialUiPopupState.bindPopover)(popupState)), {}, {
          elevation: 1,
          PaperProps: {
            style: {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              backgroundColor: theme.palette.primary.main
            }
          },
          anchorOrigin: {
            vertical: 100,
            horizontal: "right"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right"
          },
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            children: (0, _jsxRuntime.jsx)(_SubMenu.default, {
              subMenu: menuGroup,
              handleClose: handleClose
            })
          })
        }))]
      });
    }
  });
};
var _default = exports.default = PopupStateWrapper;