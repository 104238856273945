"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _CategoryStepComponent = _interopRequireDefault(require("./CategoryStepComponent"));
var _ClosedCategory = _interopRequireDefault(require("./ClosedCategory"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const AddBallCategories = props => {
  var _props$newBallToAdd2, _addBallCategoriesSta3, _addBallCategoriesSta4;
  const [addBallCategoriesState, setAddBallCategoriesState] = (0, _react.useState)({});
  const [isAddCategoryOpen, setIsAddCategoryOpen] = (0, _react.useState)(false);
  const [currentCategoryIndex, setCurrentCategoryIndex] = (0, _react.useState)(-1);
  (0, _react.useEffect)(() => {
    if (addBallCategoriesState.categories && addBallCategoriesState.categories.length > 0) {
      props.stepComplete(addBallCategoriesState);
    }
  }, [addBallCategoriesState.categories]);
  (0, _react.useEffect)(() => {
    var _props$newBallToAdd;
    if ((_props$newBallToAdd = props.newBallToAdd) !== null && _props$newBallToAdd !== void 0 && _props$newBallToAdd.categories) {
      setAddBallCategoriesState(state => _objectSpread(_objectSpread({}, state), {}, {
        categories: props.newBallToAdd.categories
      }));
    }
  }, [(_props$newBallToAdd2 = props.newBallToAdd) === null || _props$newBallToAdd2 === void 0 ? void 0 : _props$newBallToAdd2.categories]);
  const updateCategory = (update, index) => {
    var _addBallCategoriesSta;
    let newCategories = [...((_addBallCategoriesSta = addBallCategoriesState.categories) !== null && _addBallCategoriesSta !== void 0 ? _addBallCategoriesSta : [])];
    if (index === -1) {
      newCategories = [...newCategories, update];
    } else {
      newCategories[index] = update;
    }
    setAddBallCategoriesState(state => _objectSpread(_objectSpread({}, state), {}, {
      categories: newCategories
    }));
    setIsAddCategoryOpen(false);
  };
  const addCategory = () => {
    setCurrentCategoryIndex(-1);
    setIsAddCategoryOpen(true);
  };
  const editCategory = (_categoryToEdit, index) => {
    setCurrentCategoryIndex(index);
    setIsAddCategoryOpen(true);
  };
  const deleteCategory = (_categoryToEdit, index) => {
    var _addBallCategoriesSta2;
    const allCategories = [...((_addBallCategoriesSta2 = addBallCategoriesState.categories) !== null && _addBallCategoriesSta2 !== void 0 ? _addBallCategoriesSta2 : [])];
    allCategories.splice(index, 1);
    setAddBallCategoriesState(state => _objectSpread(_objectSpread({}, state), {}, {
      categories: allCategories
    }));
    setCurrentCategoryIndex(-1);
  };
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    "data-testid": 'add-ball-categories-step',
    direction: 'column',
    spacing: 1,
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: (0, _jsxRuntime.jsx)(_material.Typography, {
        variant: 'h5',
        color: 'textSecondary',
        gutterBottom: true,
        children: !addBallCategoriesState.categories || addBallCategoriesState.categories && addBallCategoriesState.categories.length < 1 ? 'Add a category below...' : 'The Categories are...'
      })
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      direction: 'column',
      spacing: 3,
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: addBallCategoriesState.categories && ((_addBallCategoriesSta3 = addBallCategoriesState.categories) === null || _addBallCategoriesSta3 === void 0 ? void 0 : _addBallCategoriesSta3.length) > 0 && addBallCategoriesState.categories.map((category, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
          "data-testid": "category-".concat(index, "-closed-container"),
          container: true,
          children: (0, _jsxRuntime.jsx)(_ClosedCategory.default, {
            showDescription: true,
            showMenu: true,
            deleteCategory: deleteCategory,
            editCategory: editCategory,
            category: category,
            keyValue: index
          })
        }, index))
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        justifyContent: 'center',
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 6,
          children: (0, _jsxRuntime.jsxs)(_material.Button, {
            fullWidth: true,
            onClick: addCategory,
            variant: 'outlined',
            color: 'primary',
            "data-testid": 'add-category-button',
            children: [(0, _jsxRuntime.jsx)(_iconsMaterial.Add, {}), "Add a Category"]
          })
        })
      })]
    }), (0, _jsxRuntime.jsx)(_material.Modal
    // open={!!props.open}
    , {
      // open={!!props.open}
      open: isAddCategoryOpen,
      "data-testid": 'add-ball-modal',
      "aria-labelledby": 'add-ball-modal-title',
      "aria-describedby": 'add-ball-modal-desc',
      style: {
        maxWidth: '800px',
        minWidth: '300px',
        overflow: "scroll"
      },
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        style: {
          display: 'relative'
        },
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          style: {
            display: 'absolute',
            right: 32,
            top: 32
          },
          children: (0, _jsxRuntime.jsx)(_material.Button, {
            children: (0, _jsxRuntime.jsx)(_iconsMaterial.Close, {
              onClick: () => setIsAddCategoryOpen(false),
              fontSize: 'large',
              style: {
                color: "#FAFAFA"
              }
            })
          })
        }), (0, _jsxRuntime.jsx)(_CategoryStepComponent.default, {
          isEditMode: currentCategoryIndex !== -1,
          category: currentCategoryIndex === -1 ? {
            catName: undefined,
            catDescription: '',
            catType: undefined,
            catPrize: undefined,
            catGender: undefined,
            versus: false,
            catVsGender: undefined,
            monetaryPrize: undefined
          } : ((_addBallCategoriesSta4 = addBallCategoriesState.categories) !== null && _addBallCategoriesSta4 !== void 0 ? _addBallCategoriesSta4 : [])[currentCategoryIndex],
          onCategoryChange: update => updateCategory(update, currentCategoryIndex)
        }, 'edit')]
      }, 'categoryName-edit')
    })]
  });
};
var _default = exports.default = AddBallCategories;