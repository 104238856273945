"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _BallSearchProvider = _interopRequireDefault(require("./ball-search-context/BallSearchProvider"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const BallSearchProviderWrapper = props => {
  return props.results ? (0, _jsxRuntime.jsx)(_BallSearchProvider.default, {
    balls: props.results,
    children: props.children
  }) : (0, _jsxRuntime.jsx)(_BallSearchProvider.default, {
    children: props.children
  });
};
var _default = exports.default = BallSearchProviderWrapper;