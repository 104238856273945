"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _TransformHWTheme = _interopRequireDefault(require("../../../theme/TransformHWTheme"));
var _CustomizedThemeContext = _interopRequireDefault(require("../../customized-theme-provider/CustomizedThemeContext"));
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  root: {
    height: 'max-content',
    backgroundColor: theme.palette.primary.main
  },
  contentBottom: {
    border: "1px solid ".concat(theme.palette.secondary.main),
    padding: '20px'
  }
}));
const ThwServicesSection = props => {
  var _props$sectionData$pr;
  const classes = useStyles();
  const customizedThemeContext = (0, _react.useContext)(_CustomizedThemeContext.default);
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const xsOnly = (0, _material.useMediaQuery)(customizedThemeContext.customizedTheme.breakpoints.only('xs'));
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    className: classes.root,
    xs: 12,
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        xs: 12,
        md: 5,
        style: {
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "600px",
          backgroundImage: "url(".concat(sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.sectionData.imageSrc, 600, 600), ")")
        }
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        xs: 12,
        md: 7,
        style: {
          padding: xsOnly ? _TransformHWTheme.default.spacing(1.75, 1.5, 3) : _TransformHWTheme.default.spacing(5, 4, 7)
        },
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          container: true,
          style: {
            marginBottom: "24px"
          },
          children: [(0, _jsxRuntime.jsx)(_material.Typography, {
            display: 'inline',
            gutterBottom: true,
            color: 'secondary',
            variant: 'h4',
            align: 'center',
            children: props.sectionData.sectionTitle
          }), (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h4',
            color: 'secondary',
            display: 'inline',
            style: {
              letterSpacing: "-.25em"
            },
            children: "____"
          })]
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          spacing: 2,
          xs: 11,
          children: (_props$sectionData$pr = props.sectionData.prosList) === null || _props$sectionData$pr === void 0 ? void 0 : _props$sectionData$pr.map((pro, index) => {
            var _props$sectionData$pr2, _props$sectionData$pr3;
            return (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              item: true,
              spacing: 2,
              children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                item: true,
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  container: true,
                  xs: 3,
                  style: {
                    backgroundSize: "contain",
                    minHeight: "145px",
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: "url(".concat(sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(pro.imageSrc, 145, 145), ")")
                  }
                }), (0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  item: true,
                  xs: 9,
                  justifyContent: 'center',
                  alignContent: 'center',
                  children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                    container: true,
                    item: true,
                    xs: 11,
                    direction: 'column',
                    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                      item: true,
                      container: true,
                      children: (0, _jsxRuntime.jsx)(_material.Typography, {
                        gutterBottom: true,
                        variant: 'h6',
                        color: 'textPrimary',
                        children: pro.contentTitle
                      })
                    }), (0, _jsxRuntime.jsx)(_material.Grid, {
                      item: true,
                      container: true,
                      children: (0, _jsxRuntime.jsx)(_material.Typography, {
                        variant: 'body1',
                        color: 'secondary',
                        children: pro.contentText
                      })
                    })]
                  })
                })]
              }), index < ((_props$sectionData$pr2 = (_props$sectionData$pr3 = props.sectionData.prosList) === null || _props$sectionData$pr3 === void 0 ? void 0 : _props$sectionData$pr3.length) !== null && _props$sectionData$pr2 !== void 0 ? _props$sectionData$pr2 : 0) - 1 && (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Divider, {
                  style: {
                    width: "100%"
                  }
                })
              })]
            }, index);
          })
        })]
      })]
    })
  });
};
var _default = exports.default = ThwServicesSection;