"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _clsx = _interopRequireDefault(require("clsx"));
var _Styles = _interopRequireDefault(require("../mackenzies-mind/pages/Styles"));
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _FirebaseContext = _interopRequireDefault(require("../../../common/firebase/firebase-context/FirebaseContext"));
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  marketingBackground: props => ({
    backgroundRepeat: 'no-repeat',
    backgroundImage: "url('".concat(props.heroBaseImageUrl, "')"),
    backgroundSize: 'cover',
    backgroundPosition: "center",
    minHeight: '521px',
    position: "relative"
  }),
  contentSection: {
    marginTop: '16px',
    backgroundColor: 'transparent'
  },
  contentBullets: {
    // borderLeft: `4px solid ${theme.palette.primary.main}`,
    // paddingLeft: '26px',
  }
}));
const WebDevHeroContentSection = props => {
  var _sanityContext$urlFor, _props$sectionData$he, _theme$typography$fon, _props$sectionData$ct;
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  let classParameters = {
    heroBaseImageUrl: (_sanityContext$urlFor = sanityContext.urlFor((_props$sectionData$he = props.sectionData.heroImageBackground) !== null && _props$sectionData$he !== void 0 ? _props$sectionData$he : "").url()) !== null && _sanityContext$urlFor !== void 0 ? _sanityContext$urlFor : ''
  };
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const theme = (0, _material.useTheme)();
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const classes = useStyles(classParameters);
  const globalClasses = (0, _Styles.default)({});
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    className: classes.marketingBackground,
    justifyContent: 'center',
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      className: (0, _clsx.default)(globalClasses.fullSection, globalClasses.fullSectionOverlay)
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      direction: 'column',
      style: {
        zIndex: 2
      },
      xs: 11,
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          className: classes.contentSection,
          item: true,
          xs: 12,
          justifyContent: 'flex-start',
          alignContent: 'center',
          alignItems: 'center',
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            spacing: 2,
            xs: 8,
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              xs: 8,
              container: true,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'h2',
                color: 'textPrimary',
                children: props.sectionData.contentTitle
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              xs: 8,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'body1',
                sx: {
                  fontFamily: (_theme$typography$fon = theme.typography.fontFamily) === null || _theme$typography$fon === void 0 ? void 0 : _theme$typography$fon.split(',')[1]
                },
                color: 'textPrimary',
                children: props.sectionData.contentText
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              children: (0, _jsxRuntime.jsx)(_material.Button, {
                color: 'primary',
                variant: 'outlined',
                onClick: () => {
                  firebaseContext.ctaClick && firebaseContext.ctaClick("hero-section", props.sectionData.ctaButtonTitle, pageContext.analyticsId);
                },
                href: (_props$sectionData$ct = props.sectionData.ctaButtonLink) !== null && _props$sectionData$ct !== void 0 ? _props$sectionData$ct : "",
                children: (0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  alignItems: 'center',
                  spacing: 1,
                  children: (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      variant: 'button',
                      children: props.sectionData.ctaButtonTitle
                    })
                  })
                })
              })
            })]
          })
        })
      })
    })]
  });
};
var _default = exports.default = WebDevHeroContentSection;