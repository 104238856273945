"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _uuid = require("uuid");
var _ResponsiveBullet = _interopRequireDefault(require("../../ResponsiveBullet"));
var _TransformHWTheme = _interopRequireDefault(require("../../../theme/TransformHWTheme"));
var _CustomizedThemeContext = _interopRequireDefault(require("../../customized-theme-provider/CustomizedThemeContext"));
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  root: {
    minHeight: '521px',
    backgroundColor: _TransformHWTheme.default.palette.background.paper,
    paddingTop: _TransformHWTheme.default.spacing(5),
    paddingBottom: _TransformHWTheme.default.spacing(5)
  },
  contentBullets: {
    // border: "1px solid black"
    marginBottom: _TransformHWTheme.default.spacing(5)
  }
}));
const PositivePsychologySection = props => {
  var _props$sectionData$co, _props$sectionData$ct, _sanityContext$urlFor, _props$sectionData$im;
  const classes = useStyles(_TransformHWTheme.default);
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const customizedThemeContext = (0, _react.useContext)(_CustomizedThemeContext.default);
  const mdUp = (0, _material.useMediaQuery)(customizedThemeContext.customizedTheme.breakpoints.up('md'));
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    className: classes.root,
    xs: 11,
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'space-between',
      spacing: 4,
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        xs: 12,
        md: 7,
        lg: 8,
        container: true,
        direction: 'column',
        spacing: 2,
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'body1',
              style: {
                fontStyle: "italic"
              },
              children: props.sectionData.superTitle
            })
          }), (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            wrap: 'nowrap',
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'h4',
                color: 'secondary',
                display: 'inline',
                children: props.sectionData.contentTitle
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'h3',
                color: 'secondary',
                display: 'inline',
                style: {
                  letterSpacing: "-.25em"
                },
                children: "____"
              })
            })]
          })]
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            color: 'textPrimary',
            children: props.sectionData.contentText
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            className: classes.contentBullets,
            spacing: 3,
            children: (_props$sectionData$co = props.sectionData.contentBullets) === null || _props$sectionData$co === void 0 ? void 0 : _props$sectionData$co.map(reason => {
              return (0, _jsxRuntime.jsx)(_ResponsiveBullet.default, {
                text: reason,
                bulletColor: 'secondary'
              }, (0, _uuid.v4)());
            })
          })
        }), props.sectionData.ctaButtonLink && props.sectionData.ctaButtonText && (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Button, {
            variant: 'contained',
            color: 'secondary',
            style: {
              backgroundColor: _TransformHWTheme.default.palette.secondary.main
            },
            href: (_props$sectionData$ct = props.sectionData.ctaButtonLink) !== null && _props$sectionData$ct !== void 0 ? _props$sectionData$ct : '',
            children: props.sectionData.ctaButtonText
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        xs: 12,
        md: 5,
        lg: 4,
        container: true,
        justifyContent: 'flex-end',
        alignContent: 'center',
        alignItems: 'center',
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          style: {
            overflow: "hidden"
          },
          children: !props.sectionData.imageSrc ? (0, _jsxRuntime.jsx)("img", {
            src: "https://placehold.co/465x".concat(mdUp ? 370 : 900),
            alt: 'placeholder'
          }) : (0, _jsxRuntime.jsx)("img", {
            alt: props.sectionData.imageSrcAltText,
            src: (_sanityContext$urlFor = sanityContext.urlFor((_props$sectionData$im = props.sectionData.imageSrc) !== null && _props$sectionData$im !== void 0 ? _props$sectionData$im : "").width(mdUp ? 370 : 900).height(465).url()) !== null && _sanityContext$urlFor !== void 0 ? _sanityContext$urlFor : ''
          })
        })
      })]
    })
  });
};
var _default = exports.default = PositivePsychologySection;