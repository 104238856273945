"use strict";

require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _ModalContext = _interopRequireDefault(require("../../snackbar-context/ModalContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const useStyles = (0, _makeStyles.default)(theme => ({
  hover: {
    "&:hover": {
      backgroundColor: 'rgba(16,43,136, 0.04)',
      "& .MuiTypography-root": {
        color: theme.palette.primary.main
      }
    }
  }
}));
const HeaderMenuItemButton = _ref => {
  var _menuItem$url;
  let {
    menuItem,
    textStyle
  } = _ref;
  const theme = (0, _material.useTheme)();
  const classes = useStyles(theme);
  const modalContext = (0, _react.useContext)(_ModalContext.default);
  return (0, _jsxRuntime.jsx)(_material.Button, {
    href: (_menuItem$url = menuItem.url) !== null && _menuItem$url !== void 0 ? _menuItem$url : "",
    color: menuItem.isOutlinedButton || menuItem.isContainedButton ? 'secondary' : "primary",
    style: {
      borderRadius: menuItem.isOutlinedButton || menuItem.isContainedButton ? theme.shape.borderRadius : 0,
      paddingLeft: menuItem.isOutlinedButton || menuItem.isContainedButton ? theme.spacing(3.25) : theme.spacing(1),
      paddingRight: menuItem.isOutlinedButton || menuItem.isContainedButton ? theme.spacing(3.25) : theme.spacing(1),
      marginTop: menuItem.isOutlinedButton || menuItem.isContainedButton ? theme.spacing(3) : 0,
      marginBottom: menuItem.isOutlinedButton || menuItem.isContainedButton ? theme.spacing(2) : 0,
      height: menuItem.isOutlinedButton || menuItem.isContainedButton ? "48px" : "100%"
    },
    className: classes.hover,
    onClick: () => {
      if (menuItem.isModalButton) {
        modalContext.openModal && modalContext.openModal(menuItem.modalRef);
      }
    },
    variant: menuItem.isContainedButton ? 'contained' : menuItem.isOutlinedButton ? 'outlined' : 'text',
    children: (0, _jsxRuntime.jsx)(_material.Typography, {
      noWrap: true,
      color: menuItem.isOutlinedButton || menuItem.isContainedButton ? 'textPrimary' : theme.palette.secondary.main,
      variant: menuItem.isOutlinedButton || menuItem.isContainedButton ? "button" : 'h6',
      style: _objectSpread({}, textStyle),
      children: menuItem.displayText
    })
  });
};
var _default = exports.default = HeaderMenuItemButton;