"use strict";

require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _BallSearchContext = _interopRequireDefault(require("../ball-search-context/BallSearchContext"));
var _HTMLUtils = require("../HTMLUtils");
var _DataTable = _interopRequireDefault(require("../data-table/DataTable"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const BallDataTable = props => {
  const searchContext = (0, _react.useContext)(_BallSearchContext.default);
  const theme = (0, _material.useTheme)();
  const smDown = (0, _material.useMediaQuery)(theme.breakpoints.down('sm'));
  const xsOnly = (0, _material.useMediaQuery)(theme.breakpoints.only('xs'));
  const ballTableColumns = [{
    id: 'date',
    label: 'DATE',
    minWidth: smDown ? 20 : 50,
    // eslint-disable-next-line no-unused-vars
    renderer: row => {
      let dateString = '';
      if (row.functionStartDate) {
        if (smDown) {
          dateString = (0, _HTMLUtils.getPrettyDateStr)(row.functionStartDate, false, true);
          const tokenizedDate = dateString.split('/');
          dateString = [tokenizedDate[0], tokenizedDate[1]].join('/');
        } else {
          dateString = (0, _HTMLUtils.getPrettyDateStr)(row.functionStartDate);
        }
      }
      return dateString;
    }
  }, {
    id: 'ballTitle',
    label: 'BALL TITLE',
    minWidth: smDown ? 450 : 300,
    // eslint-disable-next-line no-unused-vars
    renderer: row => {
      let ballTitle;
      if (smDown) {
        ballTitle = (0, _HTMLUtils.trailOffAfter)(row.ballTitle, 20).shortString;
      } else {
        // eslint-disable-next-line prefer-destructuring
        ballTitle = (0, _HTMLUtils.trailOffAfter)(row.ballTitle, 35).shortString;
      }
      return ballTitle;
    }
  }, {
    id: 'location',
    label: () => {
      if (xsOnly) {
        return (0, _jsxRuntime.jsx)(_iconsMaterial.LocationOn, {});
      }
      return 'LOCATION';
    },
    minWidth: 20,
    renderer: row => {
      if (xsOnly) {
        const locationTableValue = (0, _HTMLUtils.trailOffAfter)("".concat(row.location ? row.location.state : ''), 7);
        return (0, _jsxRuntime.jsx)(_material.Tooltip, {
          title: locationTableValue.tooltip,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            component: 'span',
            children: locationTableValue.shortString
          })
        });
      }
      return "".concat(row.location ? row.location.city : '', ",\n      ").concat(row.location ? row.location.state : '');
    }
  }];
  const sortBalls = (columnName, sortDir) => {
    const ballTitleSort = (a, b) => {
      switch (sortDir) {
        case 'asc':
          if (a.ballTitle < b.ballTitle) return 1;
          if (a.ballTitle > b.ballTitle) return -1;
          break;
        case 'desc':
          if (a.ballTitle > b.ballTitle) return 1;
          if (a.ballTitle < b.ballTitle) return -1;
          break;
        default:
          return 0;
      }
      return 0;
    };
    const dateSort = (a, b) => {
      const aStartDate = new Date(a.startDate);
      const bStartDate = new Date(b.startDate);
      switch (sortDir) {
        case 'asc':
          if (aStartDate < bStartDate) return 1;
          if (aStartDate > bStartDate) return -1;
          break;
        case 'desc':
          if (aStartDate > bStartDate) return 1;
          if (aStartDate < bStartDate) return -1;
          break;
        default:
          return 0;
      }
      return 0;
    };
    const locationSort = (a, b) => {
      let aLocation = a.location ? "".concat(a.location.city, " ").concat(a.location.state) : '';
      let bLocation = b.location ? "".concat(b.location.city, " ").concat(b.location.state) : '';
      if (xsOnly) {
        aLocation = a.location ? a.location.state : '';
        bLocation = b.location ? b.location.state : '';
      }
      switch (sortDir) {
        case 'asc':
          if (aLocation < bLocation) return 1;
          if (aLocation > bLocation) return -1;
          break;
        case 'desc':
          if (aLocation > bLocation) return 1;
          if (aLocation < bLocation) return -1;
          break;
        default:
          return 0;
      }
      return 0;
    };
    switch (columnName) {
      case 'date':
        return dateSort;
      case 'ballTitle':
        return ballTitleSort;
      case 'location':
        return locationSort;
      default:
        return ballTitleSort;
    }
  };
  return (0, _jsxRuntime.jsx)(_DataTable.default, _objectSpread({
    rows: searchContext.displayResults,
    rowClick: row => {
      var _row$slug;
      console.log('aw - rowCLick', row);
      return searchContext.getBall((_row$slug = row.slug) === null || _row$slug === void 0 ? void 0 : _row$slug.current);
    },
    sortFunction: sortBalls,
    columns: ballTableColumns
  }, props));
};
var _default = exports.default = BallDataTable;