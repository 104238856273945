"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _uuid = require("uuid");
var _ModalContext = _interopRequireDefault(require("../../../snackbar-context/ModalContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const SubMenu = props => {
  var _props$subMenu;
  const modalContext = (0, _react.useContext)(_ModalContext.default);
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    item: true,
    container: true,
    children: (0, _jsxRuntime.jsx)(_material.List, {
      style: {
        padding: 0
      },
      children: (_props$subMenu = props.subMenu) === null || _props$subMenu === void 0 || (_props$subMenu = _props$subMenu.links) === null || _props$subMenu === void 0 ? void 0 : _props$subMenu.map((theLink, index) => {
        // console.log("submenu link", theLink)
        return (0, _jsxRuntime.jsx)(_material.ListItem, {
          onClick: theLink.url && theLink.url.length > 0 ? undefined : props.handleClose,
          button: true,
          style: {
            height: "48px",
            padding: 0
          },
          children: (0, _jsxRuntime.jsx)(_material.Button, {
            variant: 'text',
            href: theLink.url && theLink.url.length > 0 && theLink.url ? theLink.url : undefined,
            onClick: theLink.isModalButton ? () => {
              // console.log()
              if (theLink.isModalButton) {
                modalContext.openModal && modalContext.openModal(theLink.modalRef);
              }
            } : undefined,
            style: {
              margin: 0,
              height: "100%",
              width: "100%",
              borderRadius: 0,
              padding: theme.spacing(0, 3, 0)
            },
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'body1',
                style: {
                  color: "#1a1a1a",
                  fontSize: "18px"
                },
                children: theLink.displayText
              })
            })
          })
        }, (0, _uuid.v4)() + index);
      })
    })
  }, (0, _uuid.v4)());
};
var _default = exports.default = SubMenu;