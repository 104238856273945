"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UnderlineRender = exports.TypographyRender = exports.TextColorRender = exports.NormalRender = exports.LightRender = exports.LargeBodyTextRender = exports.KeystrokeRender = exports.DropCapRender = exports.BoldRender = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _CommonStyles = require("./CommonStyles");
var _material = require("@mui/material");
const LightRender = props => {
  const classes = (0, _CommonStyles.useCommonStyles)();
  return (0, _jsxRuntime.jsx)("span", {
    className: classes.lightWeightFont,
    children: props.children
  });
};
exports.LightRender = LightRender;
const BoldRender = props => {
  const classes = (0, _CommonStyles.useCommonStyles)();
  return (0, _jsxRuntime.jsx)("span", {
    className: classes.boldWeightFont,
    children: props.children
  });
};
exports.BoldRender = BoldRender;
const DropCapRender = props => {
  const classes = (0, _CommonStyles.useCommonStyles)();
  return (0, _jsxRuntime.jsx)("span", {
    className: classes.dropCapLetter,
    children: props.children
  });
};
exports.DropCapRender = DropCapRender;
const KeystrokeRender = props => {
  const classes = (0, _CommonStyles.useCommonStyles)();
  return (0, _jsxRuntime.jsx)("span", {
    className: classes.keystroke,
    children: props.children
  });
};
exports.KeystrokeRender = KeystrokeRender;
const TextColorRender = (props, color) => {
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsxs)(_material.ThemeProvider, {
    theme: theme,
    children: [(0, _jsxRuntime.jsx)(_material.CssBaseline, {}), (0, _jsxRuntime.jsx)("span", {
      style: {
        color: color
      },
      children: props.children
    })]
  });
};
exports.TextColorRender = TextColorRender;
const UnderlineRender = (props, color) => {
  return (0, _jsxRuntime.jsx)("span", {
    style: {
      textDecoration: "".concat(color, " underline")
    },
    children: props.children
  });
};
exports.UnderlineRender = UnderlineRender;
const NormalRender = props => {
  const classes = (0, _CommonStyles.useCommonStyles)();
  return (0, _jsxRuntime.jsx)("p", {
    className: classes.bodyText,
    children: props.children
  });
};
exports.NormalRender = NormalRender;
const TypographyRender = (props, variant) => {
  const wrapWithHTag = children => {
    switch (variant) {
      case 'h1':
        return (0, _jsxRuntime.jsx)("h1", {
          children: children
        });
      case 'h6':
        return (0, _jsxRuntime.jsx)("h6", {
          children: children
        });
      case 'h2':
        return (0, _jsxRuntime.jsx)("h2", {
          children: children
        });
      case 'h3':
        return (0, _jsxRuntime.jsx)("h3", {
          children: children
        });
      case 'h4':
        return (0, _jsxRuntime.jsx)("h4", {
          children: children
        });
      case 'h5':
        return (0, _jsxRuntime.jsx)("h5", {
          children: children
        });
      default:
        return (0, _jsxRuntime.jsx)(_material.Typography, {
          display: 'inline',
          component: 'p',
          style: {
            textDecoration: 'none'
          },
          variant: variant,
          children: children
        });
    }
  };
  return wrapWithHTag(props.children);
};
exports.TypographyRender = TypographyRender;
const LargeBodyTextRender = props => {
  return (0, _jsxRuntime.jsx)(_material.Typography, {
    component: 'p',
    style: {
      fontSize: '21px'
    },
    children: props.children
  });
};
exports.LargeBodyTextRender = LargeBodyTextRender;