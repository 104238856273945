"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _PageContext = _interopRequireDefault(require("../../../page-context/PageContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const BallMapComponent = props => {
  const processStringForGoogleQuery = rawString => {
    if (!rawString) {
      return '';
    }
    const processedString = rawString.replace(' ', '+');
    return processedString;
  };
  const getQueryfromLocation = () => {
    let query = '';
    if (props.location) {
      // TODO: deprecate this after locations are sanitized
      if (props.location.locationName !== '') {
        query = processStringForGoogleQuery(props.location.locationName);
      } else {
        query = processStringForGoogleQuery("".concat(props.location.street1, " ").concat(props.location.city, " ").concat(props.location.state));
      }
    } else if (props.locationString) {
      query = props.locationString;
    }
    return query;
  };
  const pageContext = (0, _react.useContext)(_PageContext.default);
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    children: (0, _jsxRuntime.jsx)("iframe", {
      title: "googleMapsMap",
      height: "".concat(props.height ? props.height : 450),
      style: {
        border: 0,
        width: '100%'
      },
      allowFullScreen: true,
      src: "https://www.google.com/maps/embed/v1/place?key=".concat(pageContext.googleMapsApiKey, "&q=").concat(getQueryfromLocation())
    })
  });
};
var _default = exports.default = BallMapComponent;