"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.promise.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _Styles = _interopRequireDefault(require("../../../mackenzies-mind/pages/Styles"));
var _CountdownToLaunch = _interopRequireDefault(require("./CountdownToLaunch"));
var _clsx = _interopRequireDefault(require("clsx"));
var _CssFadeToColor = _interopRequireDefault(require("../../../../css-fade-to-color/CssFadeToColor"));
var _Logo = _interopRequireDefault(require("../../../../logo/Logo"));
var _MailTo = _interopRequireDefault(require("../../../../mail-to/MailTo"));
var _ColorPalette = require("../../../../../theme/common/ColorPalette");
var _CustomizedThemeContext = _interopRequireDefault(require("../../../../customized-theme-provider/CustomizedThemeContext"));
var _BusinessCardSubmitEmail = _interopRequireDefault(require("../BusinessCardSubmitEmail"));
var _SanityContext = _interopRequireDefault(require("../../../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const UnderConstruction = props => {
  var _cmsPageData$bgImage, _cmsPageData$emailFie, _cmsPageData$emailBut, _cmsPageData$subscrib, _props$email, _cmsPageData$footerTe;
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const [cmsPageData, setCmsPageData] = (0, _react.useState)();
  const classes = (0, _Styles.default)({
    bgImage: sanityContext.urlFor((_cmsPageData$bgImage = cmsPageData === null || cmsPageData === void 0 ? void 0 : cmsPageData.bgImage) !== null && _cmsPageData$bgImage !== void 0 ? _cmsPageData$bgImage : "").url()
  });
  const customizedThemeContext = (0, _react.useContext)(_CustomizedThemeContext.default);
  const smDown = (0, _material.useMediaQuery)(customizedThemeContext.customizedTheme.breakpoints.down('lg'));
  const xsDown = (0, _material.useMediaQuery)(customizedThemeContext.customizedTheme.breakpoints.down('md'));
  const [releaseDate, setReleaseDate] = (0, _react.useState)();
  (0, _react.useEffect)(() => {
    const getPage = async () => {
      return sanityContext.fetchRef(props.underConstructionPageRef).then(pageResponse => {
        return pageResponse;
      });
    };
    getPage().then(page => {
      setCmsPageData(page);
    });
  }, [props.underConstructionPageRef]);
  (0, _react.useEffect)(() => {
    let releaseDateHolder = new Date(Date.now() + 2000000000);
    if (cmsPageData && cmsPageData.releaseDate) {
      releaseDateHolder = cmsPageData.releaseDate;
    }
    setReleaseDate(releaseDateHolder);
  }, [cmsPageData]);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    className: (0, _clsx.default)(xsDown ? classes.fullscreenPlus : classes.fullscreen, classes.fullScreenImage),
    style: {
      position: "relative"
    },
    children: [(0, _jsxRuntime.jsx)(_CssFadeToColor.default, {
      toColor: _ColorPalette.COLORS.LIGHTGRAY,
      isResponsive: true
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      className: (0, _clsx.default)(xsDown ? classes.fullscreenPlus : classes.fullscreen, classes.fullscreenWhiteOverlay)
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      container: true,
      className: (0, _clsx.default)(classes.fullscreen),
      style: {
        position: 'absolute',
        paddingBottom: smDown ? 0 : customizedThemeContext.customizedTheme.spacing(10)
      },
      justifyContent: 'center',
      alignItems: 'center',
      children: [(cmsPageData === null || cmsPageData === void 0 ? void 0 : cmsPageData.contentTitle) && (cmsPageData === null || cmsPageData === void 0 ? void 0 : cmsPageData.contentTitle.length) > 0 && (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        xs: 11,
        className: classes.spacer,
        justifyContent: 'center',
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: smDown ? 'h2' : 'h1',
          align: 'center',
          color: 'textSecondary',
          children: cmsPageData === null || cmsPageData === void 0 ? void 0 : cmsPageData.contentTitle
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        xs: 11,
        className: classes.spacer,
        justifyContent: 'center',
        style: {
          marginBottom: smDown ? customizedThemeContext.customizedTheme.spacing(15) : 0
        },
        children: (0, _jsxRuntime.jsx)(_Logo.default, {
          isCenter: smDown,
          height: 250
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        xs: 10,
        container: true,
        item: true,
        justifyContent: 'center',
        className: classes.spacer,
        children: (0, _jsxRuntime.jsx)(_CountdownToLaunch.default, {
          launchDate: releaseDate !== null && releaseDate !== void 0 ? releaseDate : new Date(Date.now() + 2000000000)
        })
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        sm: 10,
        style: {
          paddingBottom: customizedThemeContext.customizedTheme.spacing(5),
          marginBottom: xsDown ? 0 : customizedThemeContext.customizedTheme.spacing(1)
        },
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          style: {
            marginTop: customizedThemeContext.customizedTheme.spacing(2.5)
          },
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            xs: 11,
            sm: 10,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'body1',
              align: 'center',
              style: {
                fontFamily: "Raleway"
              },
              children: cmsPageData === null || cmsPageData === void 0 ? void 0 : cmsPageData.contentText
            })
          })
        }), (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            justifyContent: 'center',
            style: {
              marginTop: customizedThemeContext.customizedTheme.spacing(5.75)
            },
            children: (0, _jsxRuntime.jsx)(_BusinessCardSubmitEmail.default, {
              source: "Under Construction Page",
              emailFieldText: (_cmsPageData$emailFie = cmsPageData === null || cmsPageData === void 0 ? void 0 : cmsPageData.emailFieldText) !== null && _cmsPageData$emailFie !== void 0 ? _cmsPageData$emailFie : "",
              emailButtonText: (_cmsPageData$emailBut = cmsPageData === null || cmsPageData === void 0 ? void 0 : cmsPageData.emailButtonText) !== null && _cmsPageData$emailBut !== void 0 ? _cmsPageData$emailBut : "",
              subscribeText: (_cmsPageData$subscrib = cmsPageData === null || cmsPageData === void 0 ? void 0 : cmsPageData.subscribeText) !== null && _cmsPageData$subscrib !== void 0 ? _cmsPageData$subscrib : ""
            })
          }), (0, _jsxRuntime.jsxs)(_material.Grid, {
            item: true,
            container: true,
            style: {
              // backgroundColor: xsDown ? customizedThemeContext.customizedTheme.palette.background.default : "transparent",
              // position: 'static',
              bottom: 0
              // height: "84px"
            },
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              justifyContent: 'center',
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_MailTo.default, {
                  color: customizedThemeContext.customizedTheme.palette.primary.main,
                  email: (_props$email = props.email) !== null && _props$email !== void 0 ? _props$email : "",
                  subject: "Information Request",
                  body: "",
                  children: props.email
                })
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              sm: 12,
              container: true,
              justifyContent: 'center',
              direction: 'column',
              alignItems: 'center',
              children: cmsPageData === null || cmsPageData === void 0 || (_cmsPageData$footerTe = cmsPageData.footerTextLines) === null || _cmsPageData$footerTe === void 0 ? void 0 : _cmsPageData$footerTe.map((footerLine, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  align: 'center',
                  variant: 'subtitle1',
                  style: {
                    fontFamily: "Raleway"
                  },
                  children: footerLine
                })
              }, index))
            })]
          })]
        })]
      })]
    })]
  });
};
var _default = exports.default = UnderConstruction;