"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _dateUtils = _interopRequireDefault(require("../../../../utils/dateUtils"));
var _PortfolioItemModal = _interopRequireDefault(require("./PortfolioItemModal"));
var _ColorPalette = require("../../../../theme/common/ColorPalette");
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const ResumePortfolioEntry = props => {
  var _props$portfolioItem, _props$portfolioItem2, _props$portfolioItem3;
  const theme = (0, _material.useTheme)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const [isOpen, _setIsOpen] = (0, _react.useState)(false);
  const [currentItem, setCurrentItem] = (0, _react.useState)();
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    role: 'portfoliobutton',
    // style={{backgroundColor: index2 % 2 === 1 ? "whitesmoke" : "white"}}
    container: true,
    item: true,
    alignContent: 'flex-start',
    justifyContent: 'center',
    children: (0, _jsxRuntime.jsxs)(_material.Card, {
      elevation: 0,
      sx: {
        width: "100%",
        border: "1px solid ".concat(_ColorPalette.COLORS.GRAY)
      },
      children: [(0, _jsxRuntime.jsx)(_material.Button, {
        fullWidth: true,
        sx: {
          padding: 0
        },
        onClick: () => {
          setCurrentItem(props.portfolioItem);
          _setIsOpen(true);
        },
        children: (0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          container: true,
          justifyContent: 'center',
          alignContent: 'space-between',
          direction: 'column',
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            justifyContent: 'center',
            xs: 2,
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              xs: 12,
              style: {
                backgroundImage: "url(".concat(sanityContext.placeholderOrImage && sanityContext.placeholderOrImage((_props$portfolioItem = props.portfolioItem) === null || _props$portfolioItem === void 0 ? void 0 : _props$portfolioItem.coverImage, 200, 200), ")"),
                backgroundSize: "cover",
                backgroundPosition: "top center",
                backgroundRepeat: "no-repeat",
                width: "100%",
                minHeight: "200px"
              },
              children: (0, _jsxRuntime.jsx)(_material.Box, {
                sx: {
                  width: "100%",
                  height: "100%",
                  opacity: .2,
                  backgroundColor: "rgba(0,0,0, .5)",
                  zIndex: 9999
                }
              })
            })
          }), (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            alignContent: 'center',
            alignItems: 'center',
            xs: 10,
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: 'center',
              style: {
                marginTop: theme.spacing(2)
              },
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                display: 'inline',
                variant: 'body1',
                color: 'textPrimary',
                children: _dateUtils.default.MonthYear((_props$portfolioItem2 = props.portfolioItem) === null || _props$portfolioItem2 === void 0 ? void 0 : _props$portfolioItem2.inceptionDate)
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: 'center',
              alignContent: 'center',
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                style: {
                  minHeight: "60px"
                },
                variant: 'body2',
                children: (_props$portfolioItem3 = props.portfolioItem) === null || _props$portfolioItem3 === void 0 ? void 0 : _props$portfolioItem3.title
              })
            })]
          })]
        })
      }), (0, _jsxRuntime.jsx)(_PortfolioItemModal.default, {
        currentItem: currentItem,
        isOpen: isOpen,
        setIsOpen: val => {
          _setIsOpen(val);
        }
      })]
    })
  });
};
var _default = exports.default = ResumePortfolioEntry;