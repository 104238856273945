"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _TransformHWTheme = _interopRequireDefault(require("../../../theme/TransformHWTheme"));
var _ImageWithButtonOverlay = _interopRequireDefault(require("../../image-with-button-overlay/ImageWithButtonOverlay"));
var _LoadingButton = _interopRequireDefault(require("../../loading-button/LoadingButton"));
var _ResponsiveBullet = _interopRequireDefault(require("../../ResponsiveBullet"));
var _iconsMaterial = require("@mui/icons-material");
var _ColoredPng = _interopRequireDefault(require("../../colored-png/ColoredPng"));
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
var _ImageWithPlaceholder = _interopRequireDefault(require("../../ImageWithPlaceholder"));
var _CustomizedThemeContext = _interopRequireDefault(require("../../customized-theme-provider/CustomizedThemeContext"));
var _FirebaseContext = _interopRequireDefault(require("../../../common/firebase/firebase-context/FirebaseContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  root: {
    minHeight: '521px',
    backgroundColor: _TransformHWTheme.default.palette.background.paper
    // paddingLeft: -TransformHWTheme.spacing(-5),
  },
  contentBullets: {
    // border: "1px solid black"
    marginBottom: _TransformHWTheme.default.spacing(5)
  }
}));
const ProprietorAtAGlance = props => {
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const customizedThemeContext = (0, _react.useContext)(_CustomizedThemeContext.default);
  const smDown = (0, _material.useMediaQuery)(customizedThemeContext.customizedTheme.breakpoints.down('sm'));
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    item: true,
    container: true,
    justifyContent: 'center',
    style: {
      backgroundColor: _TransformHWTheme.default.palette.secondary.dark,
      border: smDown ? "0px solid transparent" : "1px solid white",
      margin: smDown ? _TransformHWTheme.default.spacing(0, 0, 0, 0) : _TransformHWTheme.default.spacing(2, 0, 0, 0),
      padding: _TransformHWTheme.default.spacing(2, 0, smDown ? 6 : 2, 0)
    },
    spacing: 6,
    xs: 12,
    children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      xs: 11,
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        justifyContent: 'center',
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'body1',
          color: 'primary',
          gutterBottom: true,
          children: props.sectionData.serviceName
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'h6',
          color: 'primary',
          gutterBottom: true,
          children: props.sectionData.serviceTitle
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        children: props.sectionData.sessionList.map((term, index) => (0, _jsxRuntime.jsx)(_ResponsiveBullet.default, {
          notResponsive: true,
          bullet: (0, _jsxRuntime.jsx)(_iconsMaterial.FiberManualRecord, {
            color: 'primary',
            style: {
              fontSize: "8px"
            }
          }),
          condensed: true,
          fontVariant: 'subtitle1',
          text: term,
          textColor: 'textSecondary'
        }, index))
      })]
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      container: true,
      xs: 11,
      justifyContent: 'center',
      style: {
        // marginBottom: TransformHWTheme.spacing(5)
      },
      children: [(0, _jsxRuntime.jsx)(_ColoredPng.default, {
        maskAsset: props.sectionData.dividerImage,
        color: "white"
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        justifyContent: 'center',
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'h6',
          color: 'primary',
          gutterBottom: true,
          align: 'center',
          children: props.sectionData.amenitiesSectionTitle
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        spacing: 1,
        justifyContent: 'center',
        children: props.sectionData.amenities.map((modality, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Chip, {
            color: 'primary',
            label: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'inherit',
              color: 'secondary',
              children: modality
            })
          })
        }, index))
      })]
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsx)(_LoadingButton.default, {
        clickHandler: () => {
          firebaseContext.ctaClick && firebaseContext.ctaClick(props.source, props.sectionData.ctaButtonText, pageContext.analyticsId);
        },
        href: props.sectionData.ctaButtonLink,
        color: "primary",
        variant: 'outlined',
        children: props.sectionData.ctaButtonText
      })
    })]
  });
};
const AboutTheProprietorSection = props => {
  const classes = useStyles(_TransformHWTheme.default);
  const customizedThemeContext = (0, _react.useContext)(_CustomizedThemeContext.default);
  const smDown = (0, _material.useMediaQuery)(customizedThemeContext.customizedTheme.breakpoints.down('sm'));
  const xsOnly = (0, _material.useMediaQuery)(customizedThemeContext.customizedTheme.breakpoints.only('xs'));
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    className: classes.root,
    xs: xsOnly ? 12 : 11,
    style: xsOnly ? {
      paddingBottom: 0,
      paddingTop: 0
    } : {
      paddingBottom: _TransformHWTheme.default.spacing(10),
      paddingTop: _TransformHWTheme.default.spacing(10)
    },
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'space-around',
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        xs: 12,
        md: 5,
        lg: 4,
        container: true,
        justifyContent: 'center',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        style: {
          minWidth: "min-content"
        },
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          style: {
            overflow: "hidden",
            position: "relative",
            backgroundColor: "white",
            marginBottom: _TransformHWTheme.default.spacing(3)
          },
          container: true,
          sm: 8,
          md: 12,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_ImageWithButtonOverlay.default, {
            variant: 'contained',
            ctaButtonText: props.sectionData.ctaButtonText,
            ctaButtonLink: props.sectionData.ctaButtonLink,
            // toColor={"rgb(19,35,35)"}
            imageSrc: props.sectionData.proprietorImage,
            height: 545,
            // direction={CssFadeToColorDirectionEnum.RIGHT}
            isResponsive: true,
            placeholderWidth: 376
          })
        }), !smDown && (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          children: (0, _jsxRuntime.jsx)(ProprietorAtAGlance, {
            source: 'about-the-proprietor',
            sectionData: props.sectionData.proprietorServices
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        xs: 12,
        md: 6,
        lg: 7,
        container: true,
        direction: 'column',
        alignContent: 'space-between',
        spacing: 2,
        children: (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          style: {
            minHeight: "549px"
          },
          direction: 'column',
          spacing: 4,
          children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            direction: 'column',
            children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
              item: true,
              container: true,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'h4',
                  color: 'secondary',
                  noWrap: true,
                  style: {
                    fontWeight: 550
                  },
                  children: props.sectionData.proprietorName
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'h3',
                  color: 'secondary',
                  display: 'inline',
                  style: {
                    letterSpacing: "-.25em"
                  },
                  children: "____"
                })
              })]
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'body1',
                color: 'textPrimary',
                style: {
                  fontStyle: "italic"
                },
                children: props.sectionData.proprietorTitle
              })
            })]
          }), (0, _jsxRuntime.jsxs)(_material.Grid, {
            item: true,
            container: true,
            spacing: 2,
            justifyContent: 'flex-end',
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'body2',
                color: 'secondary',
                children: props.sectionData.contentTitle
              })
            }), props.sectionData.contentText.map((text, index) => {
              return (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                container: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'body1',
                  color: 'secondary',
                  gutterBottom: true,
                  children: text
                })
              }, index);
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              direction: 'column',
              alignItems: 'flex-end',
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_ImageWithPlaceholder.default, {
                  height: 70,
                  width: 185,
                  image: props.sectionData.proprietorSignatureImage,
                  text: "Your Signature"
                })
              })
            })]
          })]
        })
      }), smDown && (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        xs: 12,
        sm: 12,
        md: 5,
        lg: 4,
        container: true,
        justifyContent: 'center',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        style: {
          paddingTop: _TransformHWTheme.default.spacing(3),
          minWidth: "min-content"
        },
        children: (0, _jsxRuntime.jsx)(ProprietorAtAGlance, {
          source: 'about-the-proprietor',
          sectionData: props.sectionData.proprietorServices
        })
      })]
    })
  });
};
var _default = exports.default = AboutTheProprietorSection;