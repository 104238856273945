"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _styles = require("@mui/styles");
var _react = require("react");
var _material = require("@mui/material");
var _BallSearchContext = _interopRequireDefault(require("../ball-search-context/BallSearchContext"));
var _reactInfiniteScrollComponent = _interopRequireDefault(require("react-infinite-scroll-component"));
var _SingleBallSmallView = _interopRequireDefault(require("../single-ball-small-view/SingleBallSmallView"));
var _reactTimeago = _interopRequireDefault(require("react-timeago"));
var _ColorPalette = require("../../../../theme/common/ColorPalette");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const useStyles = exports.useStyles = (0, _styles.makeStyles)(() => ({
  root: {
    // position: 'relative',
    // marginTop: '64px',
  }
}));
const BallDataTiles = props => {
  const classes = useStyles();
  const searchContext = (0, _react.useContext)(_BallSearchContext.default);
  const [state, setState] = (0, _react.useState)({
    displayTiles: [],
    page: 0,
    rowsPerPage: 9
  });
  (0, _react.useEffect)(() => {
    if (props.tiles) {
      setState(newState => _objectSpread(_objectSpread({}, newState), {}, {
        displayTiles: props.tiles
      }));
    }
  }, [props.tiles]);
  const getTiles = (rows, page, thisRowsPerPage) => rows === null || rows === void 0 ? void 0 : rows.slice(0, page * thisRowsPerPage + thisRowsPerPage);
  const fetchMoreData = () => {
    setState(pageAndTilesState => _objectSpread(_objectSpread({}, pageAndTilesState), {}, {
      page: pageAndTilesState.page + 1,
      displayTiles: getTiles(state.displayTiles, pageAndTilesState.page + 1, pageAndTilesState.rowsPerPage)
    }));
  };
  // useEffect(() => {
  //     console.log('dataTiles mout', props, searchContext)
  //     if (searchContext.setDisplayResults && props.tiles)
  //         setDisplayedResults(props.tiles)
  // },[])
  const tileClick = tile => {
    var _tile$slug, _tile$slug2, _tile$slug3;
    console.log('tileCLick', tile);
    if (props.tileClickAnalytics && (_tile$slug = tile.slug) !== null && _tile$slug !== void 0 && _tile$slug.current) {
      props.tileClickAnalytics(tile.slug.current);
    }
    if (searchContext.getBall && (_tile$slug2 = tile.slug) !== null && _tile$slug2 !== void 0 && _tile$slug2.current) return searchContext.getBall((_tile$slug3 = tile.slug) === null || _tile$slug3 === void 0 ? void 0 : _tile$slug3.current);
    return undefined;
  };
  // useEffect(() => {
  //     console.log('page is ', state)
  // }, [state.page])
  return (0, _jsxRuntime.jsx)(_BallSearchContext.default.Consumer, {
    children: searchContextUI => searchContextUI.loading ? (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      style: {
        width: 'calc(100vw - 20px)',
        height: 53 * 7
      },
      alignItems: 'center',
      justifyContent: 'center',
      children: "Searching for Balls..."
    }) : !state.displayTiles || state.displayTiles.length === 0 ? (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      style: {
        width: 'calc(100vw - 20px)',
        height: 53 * 7,
        border: '1px solid #9D9D9D'
      },
      alignItems: 'center',
      justifyContent: 'center',
      direction: 'column',
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          color: 'textSecondary',
          children: "No Balls Match your Search"
        })
      })
    }) : state.displayTiles && state.displayTiles.length > 0 && (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      xs: 12,
      className: classes.root,
      id: 'scrollDiv',
      justifyContent: 'center',
      children: state.displayTiles.length > 3 ? (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_reactInfiniteScrollComponent.default, {
          style: {
            width: '100%',
            paddingTop: "56px",
            paddingLeft: "8px"
          },
          dataLength: state.displayTiles.length,
          next: fetchMoreData,
          hasMore: true,
          loader: (0, _jsxRuntime.jsx)("h4", {}),
          scrollableTarget: 'scrollDiv',
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            style: {
              overflowX: 'hidden'
            },
            spacing: 2,
            children: state.displayTiles.map((tile, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              onClick: () => {
                console.log('click this tile', tile);
                tileClick(tile);
              },
              xs: props.numColumns ? props.numColumns : 12,
              sm: props.numColumns ? props.numColumns : 6,
              md: props.numColumns ? props.numColumns : 4,
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                style: {
                  padding: '8px'
                },
                container: true,
                children: (0, _jsxRuntime.jsx)(_SingleBallSmallView.default, {
                  ball: tile
                }, index)
              })
            }, index))
          })
        })
      }) : (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        style: {
          overflowX: 'hidden'
        },
        spacing: 2,
        children: state.displayTiles.map((tile, index) => (0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          style: {
            position: "relative"
          },
          onClick: () => {
            console.log('click this tile', tile);
            tileClick(tile);
          },
          xs: props.numColumns ? props.numColumns : 12,
          sm: props.numColumns ? props.numColumns : 6,
          md: props.numColumns ? props.numColumns : 4,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            style: {
              padding: '8px'
            },
            children: (0, _jsxRuntime.jsx)(_SingleBallSmallView.default, {
              ball: tile
            }, index)
          }), props.isAgoOn && tile.functionStartDate && (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            justifyContent: 'flex-end',
            style: {
              margin: "8px",
              backgroundColor: _ColorPalette.COLORS.TRANSPARENTDARKGRAY,
              padding: '8px',
              position: "absolute",
              right: 0,
              top: 0
            },
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'white',
              children: (0, _jsxRuntime.jsx)(_reactTimeago.default, {
                date: tile.functionStartDate
              })
            })
          })]
        }, index))
      })
    })
  });
};
var _default = exports.default = BallDataTiles;