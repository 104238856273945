"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _AnimatedAboutUsItem = _interopRequireDefault(require("./AnimatedAboutUsItem"));
var _iconsMaterial = require("@mui/icons-material");
var _framerMotion = require("framer-motion");
var _BulletedHeader = _interopRequireDefault(require("./BulletedHeader"));
var _HorizontalAmenity = _interopRequireDefault(require("./HorizontalAmenity"));
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  root: {
    paddingTop: '64px',
    backgroundColor: '#f6f6f6',
    position: "relative"
  }
}));
const AnimatedAboutUsSection = props => {
  var _props$sectionData, _props$sectionData$se, _props$sectionData2, _props$sectionData3, _props$sectionData4, _props$sectionData5, _props$sectionData$hi, _props$sectionData6, _props$sectionData7, _sanityContext$urlFor, _props$sectionData$se2, _props$sectionData8, _props$sectionData9, _props$sectionData10, _props$sectionData11, _props$sectionData12, _props$sectionData13, _props$sectionData14, _sanityContext$urlFor2, _props$sectionData$se3, _props$sectionData15, _props$sectionData16, _sanityContext$urlFor3, _props$sectionData$se4, _props$sectionData17, _props$sectionData18;
  const classes = useStyles();
  const theme = (0, _material.useTheme)();
  const xsOnly = (0, _material.useMediaQuery)(theme.breakpoints.only('xs'));
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    className: classes.root,
    xs: 12,
    alignItems: 'center',
    children: [!xsOnly && (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
      style: {
        opacity: .2,
        width: 256,
        height: 256,
        bottom: 32,
        left: 32,
        // marginLeft: "-10px",
        // marginTop: "-5px",
        position: "absolute"
      },
      animate: {
        rotate: 360
      },
      transition: {
        ease: "linear",
        duration: 10,
        repeat: Infinity
      },
      children: (0, _jsxRuntime.jsx)("img", {
        width: 256,
        height: 256,
        src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage((_props$sectionData = props.sectionData) === null || _props$sectionData === void 0 ? void 0 : _props$sectionData.servicesMasonryAccentImageSrc, 256, 256)
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      justifyContent: 'center',
      spacing: 2,
      px: 4,
      children: (_props$sectionData$se = props.sectionData.servicesList) === null || _props$sectionData$se === void 0 ? void 0 : _props$sectionData$se.map((service, index) => {
        return (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 12,
          sm: 5,
          md: 4,
          container: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_AnimatedAboutUsItem.default, {
            service: service
          })
        }, index);
      })
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      container: true,
      style: {
        padding: theme.spacing(8, 6)
      },
      spacing: 2,
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        xs: 12,
        sm: 12,
        md: 6,
        children: (0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          container: true,
          xs: 12,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (0, _jsxRuntime.jsx)(_BulletedHeader.default, {
              textContent: (_props$sectionData2 = props.sectionData) === null || _props$sectionData2 === void 0 ? void 0 : _props$sectionData2.contentPreTitle,
              heroBullet: (_props$sectionData3 = props.sectionData) === null || _props$sectionData3 === void 0 ? void 0 : _props$sectionData3.heroBullet
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              wrap: 'nowrap',
              maxWidth: '600px',
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  style: {
                    fontSize: "48px"
                  },
                  gutterBottom: true,
                  color: 'primary',
                  variant: 'h2',
                  align: 'center',
                  display: 'inline',
                  children: props.sectionData.contentTitle
                })
              })
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (_props$sectionData4 = props.sectionData) === null || _props$sectionData4 === void 0 || (_props$sectionData4 = _props$sectionData4.contentTexts) === null || _props$sectionData4 === void 0 ? void 0 : _props$sectionData4.map((segment, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              maxWidth: '700px',
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'body1',
                gutterBottom: true,
                children: segment
              })
            }, index))
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              style: {
                padding: theme.spacing(4, 0)
              },
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'h6',
                gutterBottom: true,
                children: (_props$sectionData5 = props.sectionData) === null || _props$sectionData5 === void 0 ? void 0 : _props$sectionData5.highlightedAmenitiesTitle
              })
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            style: {
              paddingBottom: "32px"
            },
            spacing: 2,
            children: (_props$sectionData$hi = props.sectionData.highlightedAmenities) === null || _props$sectionData$hi === void 0 ? void 0 : _props$sectionData$hi.map((amenity, index) => {
              return (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                xs: 12,
                sm: 6,
                children: (0, _jsxRuntime.jsx)(_HorizontalAmenity.default, {
                  amenity: amenity
                })
              }, index);
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (_props$sectionData6 = props.sectionData) === null || _props$sectionData6 === void 0 || (_props$sectionData6 = _props$sectionData6.highlightedAmenitiesTexts) === null || _props$sectionData6 === void 0 ? void 0 : _props$sectionData6.map((segment, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              maxWidth: '700px',
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'body1',
                gutterBottom: true,
                children: segment
              })
            }, index))
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            style: {
              paddingLeft: theme.spacing(3)
            },
            children: (_props$sectionData7 = props.sectionData) === null || _props$sectionData7 === void 0 || (_props$sectionData7 = _props$sectionData7.highlightedAmenitiesBullets) === null || _props$sectionData7 === void 0 ? void 0 : _props$sectionData7.map((segment, index) => (0, _jsxRuntime.jsxs)(_material.Grid, {
              item: true,
              container: true,
              alignContent: 'center',
              spacing: 1,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_iconsMaterial.Circle, {
                  style: {
                    fontSize: "8px"
                  }
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'body1',
                  gutterBottom: true,
                  children: segment
                })
              })]
            }, index))
          })]
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        xs: 12,
        md: 6,
        spacing: 2,
        container: true,
        justifyContent: 'center',
        children: (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          spacing: 1,
          style: {
            position: "relative"
          },
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            style: {
              left: "calc(50% - 45px)",
              top: "calc(50% - 8px)",
              // top: "50%",
              paddingTop: "8px",
              borderRadius: "50%",
              height: "90px",
              width: "90px",
              position: "absolute",
              backgroundColor: theme.palette.background.paper
            },
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
                style: {
                  width: 64,
                  height: 64,
                  marginLeft: "-10px",
                  marginTop: "-5px"
                },
                animate: {
                  rotate: 360
                },
                transition: {
                  ease: "linear",
                  duration: 10,
                  repeat: Infinity
                },
                children: (0, _jsxRuntime.jsx)("img", {
                  width: 64,
                  height: 64,
                  src: (_sanityContext$urlFor = sanityContext.urlFor((_props$sectionData$se2 = (_props$sectionData8 = props.sectionData) === null || _props$sectionData8 === void 0 ? void 0 : _props$sectionData8.servicesMasonryAccentImageSrc) !== null && _props$sectionData$se2 !== void 0 ? _props$sectionData$se2 : "").width(64).height(64).url()) !== null && _sanityContext$urlFor !== void 0 ? _sanityContext$urlFor : sanityContext.placeholderOrImage && sanityContext.placeholderOrImage((_props$sectionData9 = props.sectionData) === null || _props$sectionData9 === void 0 ? void 0 : _props$sectionData9.servicesMasonryAccentImageSrc, 64, 64)
                })
              })
            })
          }), ((_props$sectionData10 = props.sectionData) === null || _props$sectionData10 === void 0 ? void 0 : _props$sectionData10.servicesImageSrcArr) && (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            justifyContent: 'center',
            alignContent: 'flex-end',
            alignItems: 'flex-end',
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)("img", {
                src: (_props$sectionData11 = props.sectionData) !== null && _props$sectionData11 !== void 0 && _props$sectionData11.servicesImageSrcArr[0] ? sanityContext.urlFor((_props$sectionData12 = props.sectionData) === null || _props$sectionData12 === void 0 ? void 0 : _props$sectionData12.servicesImageSrcArr[0]).url() : sanityContext.placeholderOrImage && sanityContext.placeholderOrImage((_props$sectionData13 = props.sectionData) === null || _props$sectionData13 === void 0 ? void 0 : _props$sectionData13.servicesImageSrcArr[0], 485, 356)
              })
            })
          }), ((_props$sectionData14 = props.sectionData) === null || _props$sectionData14 === void 0 ? void 0 : _props$sectionData14.servicesImageSrcArr) && (0, _jsxRuntime.jsxs)(_material.Grid, {
            item: true,
            container: true,
            justifyContent: 'center',
            spacing: 2,
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              xs: 6,
              container: true,
              justifyContent: 'flex-end',
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)("img", {
                  src: (_sanityContext$urlFor2 = sanityContext.urlFor((_props$sectionData$se3 = (_props$sectionData15 = props.sectionData) === null || _props$sectionData15 === void 0 ? void 0 : _props$sectionData15.servicesImageSrcArr[1]) !== null && _props$sectionData$se3 !== void 0 ? _props$sectionData$se3 : "").url()) !== null && _sanityContext$urlFor2 !== void 0 ? _sanityContext$urlFor2 : sanityContext.placeholderOrImage && sanityContext.placeholderOrImage((_props$sectionData16 = props.sectionData) === null || _props$sectionData16 === void 0 ? void 0 : _props$sectionData16.servicesImageSrcArr[1], 230, 265)
                })
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              xs: 6,
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)("img", {
                  style: {
                    maxWidth: "100%"
                  },
                  src: (_sanityContext$urlFor3 = sanityContext.urlFor((_props$sectionData$se4 = (_props$sectionData17 = props.sectionData) === null || _props$sectionData17 === void 0 ? void 0 : _props$sectionData17.servicesImageSrcArr[2]) !== null && _props$sectionData$se4 !== void 0 ? _props$sectionData$se4 : "").url()) !== null && _sanityContext$urlFor3 !== void 0 ? _sanityContext$urlFor3 : sanityContext.placeholderOrImage && sanityContext.placeholderOrImage((_props$sectionData18 = props.sectionData) === null || _props$sectionData18 === void 0 ? void 0 : _props$sectionData18.servicesImageSrcArr[2], 260, 305)
                })
              })
            })]
          })]
        })
      })]
    })]
  });
};
var _default = exports.default = AnimatedAboutUsSection;