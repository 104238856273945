"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _SnackbarContext = _interopRequireDefault(require("./SnackbarContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const SecondsCountdownButton = _ref => {
  var _date$seconds;
  let {
    date,
    totalTimeSeconds
  } = _ref;
  const snackbarContext = (0, _react.useContext)(_SnackbarContext.default);
  return (0, _jsxRuntime.jsx)(_material.IconButton, {
    "aria-label": "close",
    color: 'secondary',
    onClick: snackbarContext.handleSnackbarClose,
    size: "large",
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      container: true,
      justifyContent: 'center',
      alignContent: 'center',
      style: {
        position: "relative",
        minWidth: "32px",
        height: "32px"
      },
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        style: {
          position: "absolute",
          top: 3
        },
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          children: (0, _jsxRuntime.jsx)(_iconsMaterial.Close, {})
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        style: {
          position: "absolute",
          top: 0
        },
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          children: (0, _jsxRuntime.jsx)(_material.CircularProgress, {
            color: 'secondary',
            variant: 'determinate',
            size: 30,
            value: ((_date$seconds = date === null || date === void 0 ? void 0 : date.seconds) !== null && _date$seconds !== void 0 ? _date$seconds : 0) / (totalTimeSeconds ? totalTimeSeconds : 15) * 100
          })
        })
      })]
    })
  });
};
var _default = exports.default = SecondsCountdownButton;