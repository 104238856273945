"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _uuid = require("uuid");
const ResponsiveBullet = props => {
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    sm: props.notResponsive ? 12 : 6,
    alignItems: 'center',
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      spacing: props.condensed ? 0 : 2,
      alignItems: 'flex-start',
      alignContent: 'flex-start',
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        xs: 2,
        container: true,
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        style: {
          height: props.fontVariant === 'subtitle1' ? "1.2em" : "2em"
        },
        children: props.bullet ? props.bullet : (0, _jsxRuntime.jsx)(_iconsMaterial.Check, {
          color: props.bulletColor
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        xs: 10,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: props.fontVariant ? props.fontVariant : "body1",
          color: props.textColor ? props.textColor : "textPrimary",
          children: props.text
        })
      })]
    })
  }, (0, _uuid.v4)());
};
var _default = exports.default = ResponsiveBullet;