"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _withStyles = _interopRequireDefault(require("@mui/styles/withStyles"));
var _material = require("@mui/material");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const StyledTextField = (0, _withStyles.default)({
  root: {
    transition: "all 0.3s ease-in-out",
    "& label": {
      position: "relative",
      fontSize: "16px",
      top: "10px",
      "&:focus": {
        top: "16px",
        position: "relative"
      }
    },
    "& legend": {
      maxWidth: "0px"
    },
    "& input": {
      zIndex: 2
    },
    "& textarea": {
      zIndex: 2
    },
    "& fieldset": {
      "&:focus": {
        position: "relative"
      }
    },
    "& .MuiOutlinedInput-root": {
      borderColor: "#212121 !important",
      color: "black",
      "&.Mui-focused": {
        borderColor: "#212121 !important",
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#212121 !important"
          }
        }
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#212121 !important"
        }
      }
    }
  }
})(_material.TextField);
var _default = exports.default = StyledTextField;