"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _SocialMediaBlockButton = _interopRequireDefault(require("./SocialMediaBlockButton"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const SocialMediaBlock = props => {
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    "data-testid": 'social-media-block',
    item: true,
    xs: 12,
    container: true,
    alignItems: 'center',
    justifyContent: props.isCentered ? 'center' : 'flex-end',
    spacing: props.spacing ? props.spacing : 0,
    wrap: 'nowrap',
    children: [props.facebook && (0, _jsxRuntime.jsx)(_SocialMediaBlockButton.default, {
      isHoverColor: props.isHoverColor,
      iconColor: props.iconColor,
      bgColor: props.bgColor,
      theBackgroundColor: theme.palette.primary.main,
      socialMediaName: props.facebook,
      socialMediaLink: "https://facebook.com/".concat(props.facebook),
      iconButtonIcon: (0, _jsxRuntime.jsx)(_iconsMaterial.Facebook, {
        color: 'inherit'
      })
    }), props.linkedIn && (0, _jsxRuntime.jsx)(_SocialMediaBlockButton.default, {
      isHoverColor: props.isHoverColor,
      iconColor: props.iconColor,
      bgColor: props.bgColor,
      theBackgroundColor: theme.palette.primary.main,
      socialMediaName: props.linkedIn,
      socialMediaLink: "https://linkedIn.com/in/".concat(props.linkedIn),
      iconButtonIcon: (0, _jsxRuntime.jsx)(_iconsMaterial.LinkedIn, {
        color: 'inherit'
      })
    }), props.github && (0, _jsxRuntime.jsx)(_SocialMediaBlockButton.default, {
      isHoverColor: props.isHoverColor,
      iconColor: props.iconColor,
      bgColor: props.bgColor,
      theBackgroundColor: theme.palette.primary.main,
      socialMediaName: props.github,
      iconButtonIcon: (0, _jsxRuntime.jsx)(_iconsMaterial.GitHub, {
        color: 'inherit'
      }),
      socialMediaLink: "https://github.com/".concat(props.github)
    }), props.twitter && (0, _jsxRuntime.jsx)(_SocialMediaBlockButton.default, {
      isHoverColor: props.isHoverColor,
      iconColor: props.iconColor,
      bgColor: props.bgColor,
      theBackgroundColor: theme.palette.primary.main,
      socialMediaName: props.twitter,
      iconButtonIcon: (0, _jsxRuntime.jsx)(_iconsMaterial.Twitter, {
        color: 'inherit'
      }),
      socialMediaLink: "https://twitter.com/".concat(props.twitter)
    }), props.instagram && (0, _jsxRuntime.jsx)(_SocialMediaBlockButton.default, {
      isHoverColor: props.isHoverColor,
      iconColor: props.iconColor,
      bgColor: props.bgColor,
      theBackgroundColor: theme.palette.primary.main,
      socialMediaName: props.instagram,
      iconButtonIcon: (0, _jsxRuntime.jsx)(_iconsMaterial.Instagram, {
        color: 'inherit'
      }),
      socialMediaLink: "https://instagram.com/".concat(props.instagram)
    })]
  });
};
var _default = exports.default = SocialMediaBlock;