"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _reactRouterDom = require("react-router-dom");
var _material = require("@mui/material");
var _Routes = require("../enums/Routes.enum");
var _HTMLUtils = require("../HTMLUtils");
var _Image = _interopRequireDefault(require("@mui/icons-material/Image"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const SingleBallSmallView = props => {
  var _props$ball, _props$ball2, _props$ball3, _props$ball4, _props$ball5, _props$ball6, _props$ball7, _props$ball8, _props$ball9;
  const navigate = (0, _reactRouterDom.useNavigate)();
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    direction: "column",
    item: true,
    style: {
      backgroundColor: 'whitesmoke',
      border: "1px solid #111111"
    },
    spacing: 2,
    children: (0, _jsxRuntime.jsxs)(_material.Link, {
      role: 'button',
      underline: "none",
      onClick: () => {
        var _props$ball$slug$curr, _props$ball$slug;
        return navigate("".concat(_Routes.RoutesEnum.BALL, "/").concat((_props$ball$slug$curr = (_props$ball$slug = props.ball.slug) === null || _props$ball$slug === void 0 ? void 0 : _props$ball$slug.current) !== null && _props$ball$slug$curr !== void 0 ? _props$ball$slug$curr : "no-slug"));
      },
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        alignItems: "stretch",
        item: true,
        style: {
          backgroundImage: "url(".concat((_props$ball = props.ball) === null || _props$ball === void 0 || (_props$ball = _props$ball.flyer) === null || _props$ball === void 0 ? void 0 : _props$ball.asset.url, ")"),
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          height: '250px'
        },
        children: !((_props$ball2 = props.ball) !== null && _props$ball2 !== void 0 && _props$ball2.flyer) && (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: "center",
          alignItems: "center",
          style: {
            backgroundColor: 'white'
          },
          children: (0, _jsxRuntime.jsx)(_Image.default, {})
        })
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        style: {
          minHeight: '120px'
        },
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: "center",
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            align: 'center',
            color: 'textSecondary',
            fontStyle: 'oblique',
            children: (0, _HTMLUtils.getPrettyDateStr)((_props$ball3 = props.ball) === null || _props$ball3 === void 0 ? void 0 : _props$ball3.functionStartDate)
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: "center",
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            align: 'center',
            color: 'primary',
            fontSize: 'medium',
            children: (_props$ball4 = props.ball) === null || _props$ball4 === void 0 ? void 0 : _props$ball4.ballTitle
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: "center",
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            align: 'center',
            color: 'textSecondary',
            children: (_props$ball5 = props.ball) === null || _props$ball5 === void 0 || (_props$ball5 = _props$ball5.location) === null || _props$ball5 === void 0 ? void 0 : _props$ball5.locationName
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          justifyContent: "center",
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            align: 'center',
            color: 'textSecondary',
            children: "".concat((_props$ball6 = props.ball) === null || _props$ball6 === void 0 || (_props$ball6 = _props$ball6.location) === null || _props$ball6 === void 0 ? void 0 : _props$ball6.city, ", ").concat((_props$ball7 = props.ball) === null || _props$ball7 === void 0 || (_props$ball7 = _props$ball7.location) === null || _props$ball7 === void 0 ? void 0 : _props$ball7.state).concat((_props$ball8 = props.ball) !== null && _props$ball8 !== void 0 && (_props$ball8 = _props$ball8.location) !== null && _props$ball8 !== void 0 && _props$ball8.zip ? " ".concat((_props$ball9 = props.ball) === null || _props$ball9 === void 0 || (_props$ball9 = _props$ball9.location) === null || _props$ball9 === void 0 ? void 0 : _props$ball9.zip) : '')
          })
        })]
      })]
    })
  });
};
var _default = exports.default = SingleBallSmallView;