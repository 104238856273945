"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImageWithButtonOverlayAligmentEnum = void 0;
var ImageWithButtonOverlayAligmentEnum;
(function (ImageWithButtonOverlayAligmentEnum) {
  ImageWithButtonOverlayAligmentEnum[ImageWithButtonOverlayAligmentEnum["RIGHT"] = 0] = "RIGHT";
  ImageWithButtonOverlayAligmentEnum[ImageWithButtonOverlayAligmentEnum["CENTER"] = 1] = "CENTER";
  ImageWithButtonOverlayAligmentEnum[ImageWithButtonOverlayAligmentEnum["LEFT"] = 2] = "LEFT";
})(ImageWithButtonOverlayAligmentEnum || (exports.ImageWithButtonOverlayAligmentEnum = ImageWithButtonOverlayAligmentEnum = {}));