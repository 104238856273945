"use strict";

require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.importToSanityBall = exports.fromStepFormToSanity = exports.fromFormToSanity = exports.convertToCurrent = void 0;
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.promise.js");
var _BallType = require("./enums/BallType.enum");
var _HTMLUtils = require("./HTMLUtils");
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _uuid = require("uuid");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const convertToCurrent = rhs => {
  let convertedBall = _objectSpread({}, rhs);
  if (rhs && rhs.Draft === 0) {
    const oldBall = _objectSpread({}, rhs);
    const {
      Timestamp,
      ballTitle,
      ballType,
      approval,
      description,
      endTime,
      host,
      miniGrandPrize,
      region,
      source,
      startDate,
      startTime,
      website,
      grandprize,
      uid
    } = oldBall;
    convertedBall = {
      Timestamp,
      approval,
      ballTitle,
      ballType,
      categories: [],
      createdBy: '',
      description,
      endTime,
      flyer: oldBall.Flyer,
      grandprize,
      host,
      miniGrandPrize,
      region,
      source,
      startDate,
      startTime,
      venue: {
        city: oldBall.city,
        commonName: oldBall.venue,
        street: oldBall.address,
        street2: '',
        state: oldBall.State
      },
      website,
      uid
    };
  }
  return convertedBall;
};
// export const convertSanityToAW = (sanityBall:SanityBall) => {
//   const ball = {
//     _id: sanityBall._id,
//     _createdAt: sanityBall._createdAt,
//     flyer: sanityBall.flyer,
//     ballTitle: sanityBall.ballTitle,
//     host: sanityBall.host,
//     categories: sanityBall.categories,
//     miniGrandPrize: sanityBall.miniGrandPrize,
//     ballType: sanityBall.ballType,
//     createdBy: "",
//     description: sanityBall.description,
//     startTime: sanityBall.functionStartDate,
//     endTime: sanityBall.functionEndDate,
//     region: sanityBall.region,
//     source: sanityBall.source,
//     website: sanityBall.website,
//     uid: sanityBall.uid,
//     venue: {
//       commonName: sanityBall.location.locationName,
//       street: sanityBall.location.street1,
//       street2: sanityBall.location.street2,
//       city: sanityBall.location.city,
//       state: sanityBall.location.state
//     },
//     grandprize: sanityBall.grandPrize,
//     startDate: sanityBall.functionStart,
//     approval: sanityBall.approval ? "Approved":""
//   }
//
//   return ball
// }
// export const convertToSanityBall = (currentBall: FirebaseBallType) => {
//   const ball: SanityBall = {
//     // flyer: currentBall.flyer,
//     ballTitle: currentBall.ballTitle,
//     host: currentBall.host,
//     categories: currentBall.categories,
//     miniGrandPrize: currentBall.miniGrandPrize,
//     ballType: currentBall.ballType,
//     createdBy: "",
//     description: currentBall.description,
//     // startTime: "",
//     // endTime: "",
//     region: currentBall.region,
//     source: currentBall.source,
//     uid: currentBall.uid,
//     location: {
//       locationName: currentBall.venue?.commonName,
//       street1: currentBall.venue?.street,
//       street2: currentBall.venue?.street2,
//       city: currentBall.venue?.city,
//       state: currentBall.venue?.state,
//
//     },
//     approval: currentBall.approval === 'Approved',
//     // Timestamp: sanityBall._createdAt,
//     grandPrize: currentBall.grandprize,
//     functionStartDate: moment.tz(`${currentBall.startDate} ${currentBall.startTime}`, "America/New York").utc().format(),
//     functionEndDate: currentBall.endTime !== ''? moment.tz(`1971-01-01 ${currentBall.endTime}`, "America/New York").utc().format():"",
//     // functionEndDate: currentBall.endTime !== ''?'0000-00-00T'+currentBall.endTime+'Z':'',
//     website: currentBall.website
//   }
//
//   console.log("ball conversion", ball, currentBall)
//
//   return ball
// }
exports.convertToCurrent = convertToCurrent;
const importToSanityBall = async currentBall => {
  var _currentBall$venue, _currentBall$venue2, _currentBall$venue3, _currentBall$venue4, _currentBall$venue5;
  const ball = {
    ballTitle: currentBall.ballTitle,
    host: currentBall.host,
    categories: currentBall.categories,
    miniGrandPrize: currentBall.miniGrandPrize,
    ballType: _BallType.BallTypeEnum.BALL,
    description: currentBall.description,
    region: currentBall.region,
    source: currentBall.source,
    uid: currentBall.uid,
    location: {
      locationName: (_currentBall$venue = currentBall.venue) === null || _currentBall$venue === void 0 ? void 0 : _currentBall$venue.commonName,
      street1: (_currentBall$venue2 = currentBall.venue) === null || _currentBall$venue2 === void 0 ? void 0 : _currentBall$venue2.street,
      street2: (_currentBall$venue3 = currentBall.venue) === null || _currentBall$venue3 === void 0 ? void 0 : _currentBall$venue3.street2,
      city: (_currentBall$venue4 = currentBall.venue) === null || _currentBall$venue4 === void 0 ? void 0 : _currentBall$venue4.city,
      state: (_currentBall$venue5 = currentBall.venue) === null || _currentBall$venue5 === void 0 ? void 0 : _currentBall$venue5.state
    },
    approval: currentBall.approval === 'Approved',
    grandPrize: currentBall.grandprize,
    functionStartDate: _momentTimezone.default.tz("".concat(currentBall.startDate, " ").concat(currentBall.startTime), 'America/New_York').utc().format(),
    // functionStartDate: currentBall.startDate+'T'+currentBall.startTime+"Z",
    functionEndDate: currentBall.endTime !== '' ? _momentTimezone.default.tz("1971-01-01 ".concat(currentBall.endTime), 'America/New_York').utc().format() : '',
    // functionEndDate: currentBall.endTime !== "" ? currentBall.endTime + "Z":"",
    website: currentBall.website
  };
  console.log('ball conversion', ball, currentBall);
  return ball;
};
exports.importToSanityBall = importToSanityBall;
const fromFormToSanity = currentBall => {
  const nextDay = new Date(currentBall.startDate);
  nextDay.setDate(nextDay.getDate() + 1);
  console.log('ballform ball for submission', currentBall);
  const ball = {
    slug: currentBall.slug,
    createdBy: currentBall.createdBy,
    ballTitle: currentBall.ballTitle,
    host: currentBall.host,
    categories: currentBall.categories.map(category => _objectSpread(_objectSpread({}, category), {}, {
      _key: (0, _uuid.v4)()
    })),
    miniGrandPrize: currentBall.miniGrandPrize,
    ballType: _BallType.BallTypeEnum.BALL,
    description: currentBall.description,
    region: currentBall.region,
    source: currentBall.source,
    uid: currentBall.uid,
    location: currentBall.location,
    approval: currentBall.approval,
    grandPrize: currentBall.grandPrize,
    functionStartDate: (0, _HTMLUtils.combineDateTime)(currentBall.startDate, currentBall.startTime),
    functionEndDate: currentBall.endTime !== '' ? (0, _HTMLUtils.combineDateTime)((0, _HTMLUtils.getDateInputValue)(nextDay.toLocaleDateString()), currentBall.endTime) : '',
    website: currentBall.website
  };
  console.log('ball transformed', ball, currentBall);
  return ball;
};
exports.fromFormToSanity = fromFormToSanity;
const fromStepFormToSanity = currentBall => {
  var _currentBall$function, _currentBall$function2, _currentBall$function3, _currentBall$function4, _currentBall$function5;
  const nextDay = new Date((_currentBall$function = currentBall.functionStartDate) !== null && _currentBall$function !== void 0 ? _currentBall$function : "");
  nextDay.setDate(nextDay.getDate() + 1);
  console.log('ballform ball for submission', currentBall);
  // convert date and time to localedatetime
  const ball = {
    slug: currentBall.slug,
    createdBy: currentBall.createdBy,
    ballTitle: currentBall.ballTitle,
    host: currentBall.host,
    categories: currentBall.categories ? currentBall.categories.map(category => _objectSpread(_objectSpread({}, category), {}, {
      _key: (0, _uuid.v4)()
    })) : [],
    ballType: currentBall.ballType,
    description: currentBall.description,
    source: currentBall.source,
    location: currentBall.location,
    approval: false,
    functionStartDate: (0, _HTMLUtils.combineDateAndTime)((_currentBall$function2 = currentBall.functionStartDate) !== null && _currentBall$function2 !== void 0 ? _currentBall$function2 : "", (_currentBall$function3 = currentBall.functionStartTime) !== null && _currentBall$function3 !== void 0 ? _currentBall$function3 : ""),
    functionEndDate: (0, _HTMLUtils.combineDateAndTime)((_currentBall$function4 = currentBall.functionEndDate) !== null && _currentBall$function4 !== void 0 ? _currentBall$function4 : "", (_currentBall$function5 = currentBall.functionEndTime) !== null && _currentBall$function5 !== void 0 ? _currentBall$function5 : ""),
    website: currentBall.website,
    notifyName: currentBall.notifyName,
    notifyEmail: currentBall.notifyEmail,
    notifyOnApproval: currentBall.notifyOnApproval
  };
  console.log('ball transformed', ball, currentBall);
  return ball;
};
exports.fromStepFormToSanity = fromStepFormToSanity;