"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _react = require("react");
var _BallSearchContext = _interopRequireDefault(require("../ball-search-context/BallSearchContext"));
var _iconsMaterial = require("@mui/icons-material");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const ViewChange = () => {
  const searchContext = (0, _react.useContext)(_BallSearchContext.default);
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsxs)(_material.Button, {
    color: 'secondary',
    sx: {
      padding: theme.spacing(1, 2)
    },
    onClick: () => {
      if (searchContext.setViewType) searchContext.setViewType(!searchContext.viewType);
    },
    children: [(0, _jsxRuntime.jsx)(_material.Typography, {
      variant: 'h6',
      fontWeight: 800,
      children: "View:"
    }), searchContext.viewType ? (0, _jsxRuntime.jsx)(_iconsMaterial.TableChart, {}) : (0, _jsxRuntime.jsx)(_iconsMaterial.ViewModule, {})]
  });
};
var _default = exports.default = ViewChange;