"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/es.symbol.description.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _clsx = _interopRequireDefault(require("clsx"));
var _ColorPalette = require("../../../../theme/common/ColorPalette");
var _Header = _interopRequireDefault(require("./Header"));
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  root: {
    backgroundColor: _ColorPalette.COLORS.TRANSPARENTWHITE,
    transition: 'background-color .5s ease 0s',
    paddingLeft: "32px",
    paddingRight: "56px",
    paddingTop: "32px",
    height: "148px",
    position: "relative"
  },
  opaque: {
    backgroundColor: "".concat(_ColorPalette.COLORS.LIGHTGRAY, " !important")
  }
}));
const EnhancedHeader = props => {
  var _props$pageHeader, _props$pageHeader2, _props$pageHeader3, _props$pageHeader$hig, _props$pageHeader4, _props$pageHeader5;
  const classes = useStyles();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  return (0, _jsxRuntime.jsx)(_material.AppBar, {
    className: (0, _clsx.default)({
      [classes.opaque]: true
    }, classes.root),
    children: (_props$pageHeader = props.pageHeader) !== null && _props$pageHeader !== void 0 && (_props$pageHeader = _props$pageHeader.headerMenuRef) !== null && _props$pageHeader !== void 0 && _props$pageHeader.title ? (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        spacing: 4,
        justifyContent: 'space-between',
        wrap: 'nowrap',
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 3,
          children: (0, _jsxRuntime.jsx)(_material.Button, {
            fullWidth: true,
            variant: 'contained',
            href: (_props$pageHeader2 = props.pageHeader) === null || _props$pageHeader2 === void 0 ? void 0 : _props$pageHeader2.ctaButtonLink,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'button',
              noWrap: true,
              children: (_props$pageHeader3 = props.pageHeader) === null || _props$pageHeader3 === void 0 ? void 0 : _props$pageHeader3.ctaButtonText
            })
          })
        }), (_props$pageHeader$hig = props.pageHeader.highlightedDetails) === null || _props$pageHeader$hig === void 0 ? void 0 : _props$pageHeader$hig.map((detail, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
          xs: 3,
          item: true,
          container: true,
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          alignContent: 'flex-end',
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            item: true,
            container: true,
            xs: 12,
            sm: 3,
            maxWidth: 350,
            minWidth: 270,
            spacing: 1,
            wrap: 'nowrap',
            justifyContent: 'flex-end',
            alignItems: 'center',
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              maxWidth: 64,
              style: {
                position: "relative"
              },
              children: (0, _jsxRuntime.jsx)(_material.Card, {
                elevation: 0,
                style: {
                  width: "48px",
                  height: "48px",
                  backgroundColor: "#e3e3e3",
                  borderRadius: "50%"
                },
                children: (0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                  style: {
                    height: "100%",
                    width: "100%"
                  },
                  children: (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    children: (0, _jsxRuntime.jsx)("img", {
                      width: 24,
                      src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(detail.imageSrc, 24, 24),
                      style: {
                        paddingTop: "6px"
                      }
                    })
                  })
                })
              })
            }), (0, _jsxRuntime.jsxs)(_material.Grid, {
              item: true,
              maxWidth: 260,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'body1',
                  color: 'textPrimary',
                  noWrap: true,
                  children: detail.name
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  fontWeight: 'bold',
                  fontSize: '16px',
                  variant: 'body2',
                  color: 'textPrimary',
                  children: detail.description
                })
              })]
            })]
          })
        }, index))]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          xs: 12,
          container: true,
          item: true,
          style: {
            // padding: "16px",
            padding: "8px",
            // maxWidth: "calc(100%-100px)",
            // marginRight: "8px",
            // width: "100%",
            position: "relative",
            top: "16px",
            borderRadius: 4,
            backgroundColor: "black"
          },
          children: (0, _jsxRuntime.jsx)(_Header.default, {
            isSearch: (_props$pageHeader4 = props.pageHeader) === null || _props$pageHeader4 === void 0 ? void 0 : _props$pageHeader4.isSearch,
            pageHeader: (_props$pageHeader5 = props.pageHeader) === null || _props$pageHeader5 === void 0 ? void 0 : _props$pageHeader5.headerMenuRef,
            isEnhanced: true
          })
        })
      })]
    }) : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
  });
};
var _default = exports.default = EnhancedHeader;