"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _createStyles = _interopRequireDefault(require("@mui/styles/createStyles"));
var _iconsMaterial = require("@mui/icons-material");
var _MainMenuSubMenu = _interopRequireDefault(require("./MainMenuSubMenu"));
var _ModalContext = _interopRequireDefault(require("../../../snackbar-context/ModalContext"));
var _Logo = _interopRequireDefault(require("../../../logo/Logo"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = (0, _makeStyles.default)(() => (0, _createStyles.default)({
  listItem: {
    "&.MuiListItem-gutters": {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0
    }
  }
}));
const MainMenu = _ref => {
  let {
    menu,
    anchor
  } = _ref;
  const [isDrawerOpen, setIsDrawerOpen] = (0, _react.useState)();
  const toggleDrawer = (_anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };
  const modalContext = (0, _react.useContext)(_ModalContext.default);
  const classes = useStyles();
  const list = anchor => {
    var _menu$subMenus;
    return (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      role: "presentation",
      onClick: toggleDrawer(anchor, false),
      onKeyDown: toggleDrawer(anchor, false),
      children: [(0, _jsxRuntime.jsx)(_material.Divider, {}), menu === null || menu === void 0 || (_menu$subMenus = menu.subMenus) === null || _menu$subMenus === void 0 ? void 0 : _menu$subMenus.map((subMenu, index) => {
        switch (subMenu._type) {
          case 'menuGroup':
            const menuGroup = subMenu;
            return (0, _jsxRuntime.jsx)(_MainMenuSubMenu.default, {
              menuGroup: menuGroup
            }, index);
          case 'menuItem':
          default:
            const menuItem = subMenu;
            return (0, _jsxRuntime.jsxs)(_material.List, {
              style: {
                padding: 0
              },
              children: [(0, _jsxRuntime.jsx)(_material.ListItem, {
                className: classes.listItem,
                children: (0, _jsxRuntime.jsx)(_material.Button, {
                  variant: 'text',
                  href: menuItem.isModalButton ? undefined : menuItem.url,
                  onClick: menuItem.isModalButton ? () => {
                    // console.log()
                    if (menuItem.isModalButton) {
                      modalContext.openModal && modalContext.openModal(menuItem.modalRef);
                    }
                  } : undefined,
                  style: {
                    padding: theme.spacing(2.25, 2),
                    height: "100%"
                  },
                  fullWidth: true,
                  children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                    secondary: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'primary',
                      children: menuItem.displayText
                    })
                  })
                })
              }), (0, _jsxRuntime.jsx)(_material.Divider, {})]
            }, menuItem.displayText);
        }
      })]
    });
  };
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    item: true,
    children: [(0, _jsxRuntime.jsx)(_material.Button, {
      onClick: toggleDrawer(anchor, true),
      children: (0, _jsxRuntime.jsx)(_iconsMaterial.Menu, {
        color: 'inherit',
        fontSize: 'large'
      })
    }), (0, _jsxRuntime.jsxs)(_material.Drawer, {
      anchor: anchor,
      open: isDrawerOpen,
      onClose: toggleDrawer(anchor, false),
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        alignItems: 'center',
        style: {
          paddingLeft: theme.spacing(4)
          // paddingRight: theme.spacing(6),
        },
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 10,
          children: (0, _jsxRuntime.jsx)(_Logo.default, {
            logoImageSrc: menu.logoImageSrc,
            logoText: menu.logoText,
            logoAccentText: "."
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 2,
          container: true,
          justifyContent: 'flex-end',
          children: (0, _jsxRuntime.jsx)(_material.Button, {
            onClick: () => {
              setIsDrawerOpen(false);
            },
            children: (0, _jsxRuntime.jsx)(_iconsMaterial.Close, {
              color: 'primary',
              fontSize: 'large'
            })
          })
        })]
      }), list(anchor)]
    })]
  });
};
var _default = exports.default = MainMenu;