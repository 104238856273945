"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _BallType = require("../enums/BallType.enum");
var _GooglePlacesAutoComplete = _interopRequireDefault(require("./GooglePlacesAutoComplete"));
var _PageContext = _interopRequireDefault(require("../../../page-context/PageContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const BallLocation = props => {
  var _props$newBallToAdd, _props$newBallToAdd2;
  const [location, setLocation] = (0, _react.useState)({});
  (0, _react.useEffect)(() => {
    if (location.locationName) {
      props.stepComplete({
        location
      });
    }
  }, [location]);
  const updateAddress = address => {
    setLocation(state => _objectSpread(_objectSpread({}, state), {}, {
      locationName: address.locationName,
      street1: address.street1,
      street2: address.street2,
      city: address.city,
      state: address.state,
      zip: address.zip,
      country: address.country,
      url: address.url
    }));
  };
  const pageContext = (0, _react.useContext)(_PageContext.default);
  (0, _react.useEffect)(() => {
    console.log("Page context from ball location", pageContext, pageContext.googleMapsApiKey);
  }, [pageContext]);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    "data-testid": 'add-ball-location-step',
    direction: 'column',
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: (0, _jsxRuntime.jsxs)(_material.Typography, {
        variant: 'h5',
        color: 'textSecondary',
        children: ["Where is the ", (0, _BallType.renderBallType)((_props$newBallToAdd = props.newBallToAdd) === null || _props$newBallToAdd === void 0 ? void 0 : _props$newBallToAdd.ballType), "?"]
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      direction: 'column',
      children: (0, _jsxRuntime.jsx)(_GooglePlacesAutoComplete.default, {
        apiKey: pageContext.googleMapsApiKey,
        locationValue: (_props$newBallToAdd2 = props.newBallToAdd) === null || _props$newBallToAdd2 === void 0 ? void 0 : _props$newBallToAdd2.location,
        mapHeight: 450,
        setAddress: updateAddress
      })
    })]
  });
};
var _default = exports.default = BallLocation;