"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _googleMapsReact = require("google-maps-react");
var _reactPlacesAutocomplete = _interopRequireDefault(require("react-places-autocomplete"));
var _BallMapComponent = _interopRequireDefault(require("./BallMapComponent"));
var _StyledTextField = _interopRequireDefault(require("../styled-text-field/StyledTextField"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const locationObjectToString = sourceLocation => {
  let result = '';
  if (!!sourceLocation && !!sourceLocation.locationName) {
    result += sourceLocation.locationName;
  }
  return result;
};
const GooglePlacesAutoComplete = props => {
  const [address, setAddress] = (0, _react.useState)('');
  const [placeId, setPlaceId] = (0, _react.useState)('');
  const [googlePlace, setGooglePlace] = (0, _react.useState)(null);
  const handleChange = newAddress => {
    setAddress(newAddress);
  };
  const handleSelect = (newAddress, selectedPlaceId) => {
    // const autocompleteSessionId = suggestion
    // console.log('autocomplete suggestion selected', suggestion)
    setPlaceId(selectedPlaceId);
    setAddress(newAddress);
  };
  const fetchPlaceDetails = () => {
    const us = new props.google.maps.LatLng(39.8283, 98.5795);
    const map = new props.google.maps.Map(document.getElementById('map'), {
      center: us,
      zoom: 15
    });
    const service = new props.google.maps.places.PlacesService(map);
    service.getDetails({
      placeId,
      sessionToken: new props.google.maps.places.AutocompleteSessionToken(),
      fields: ['address_components', 'name', 'url']
    }, function (place) {
      setGooglePlace(place);
    });
  };
  const getAddressComponentFromGooglePlace = (componentName, isShort) => {
    var _googlePlace$address_;
    const requestedComponent = googlePlace === null || googlePlace === void 0 || (_googlePlace$address_ = googlePlace.address_components) === null || _googlePlace$address_ === void 0 ? void 0 : _googlePlace$address_.find(addressComponent => !!addressComponent.types.find(addressComponentType => addressComponentType === componentName));
    if (!requestedComponent) {
      console.log('you requested something not in places address _coponent', componentName, googlePlace === null || googlePlace === void 0 ? void 0 : googlePlace.address_components);
      return '';
    }
    return isShort ? requestedComponent.short_name : requestedComponent.long_name;
  };
  (0, _react.useEffect)(() => {
    console.log('constructing location from place', googlePlace);
    if (googlePlace) {
      const sanityLocation = {
        locationName: googlePlace.name,
        url: googlePlace.url,
        street1: "".concat(getAddressComponentFromGooglePlace('street_number'), " ").concat(getAddressComponentFromGooglePlace('route')),
        city: getAddressComponentFromGooglePlace('locality'),
        state: getAddressComponentFromGooglePlace('administrative_area_level_1', true),
        zip: "".concat(getAddressComponentFromGooglePlace('postal_code')).concat(getAddressComponentFromGooglePlace('postal_code_suffix') !== '' ? "-".concat(getAddressComponentFromGooglePlace('postal_code_suffix')) : ''),
        country: getAddressComponentFromGooglePlace('country')
      };
      props.setAddress(sanityLocation);
    }
  }, [googlePlace]);
  (0, _react.useEffect)(() => {
    if (placeId) {
      fetchPlaceDetails();
    }
  }, [placeId]);
  // const locationObjectToString = (sourceLocation: SanityLocation) => sourceLocation && sourceLocation.locationName ? `${sourceLocation.locationName  } ${  sourceLocation.street1  } ${  sourceLocation.street2  } ${  sourceLocation.city  } ${  sourceLocation.state  } ${  sourceLocation.zip}`:''
  (0, _react.useEffect)(() => {
    // console.log('GoogleAPI', props.google)
    if (!address.locationName) {
      if (props.locationValue) {
        setAddress(locationObjectToString(props.locationValue));
      }
    }
  }, []);
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    direction: 'column',
    item: true,
    id: 'googleMap',
    xs: 12,
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      xs: 12,
      children: (0, _jsxRuntime.jsx)(_reactPlacesAutocomplete.default
      // style={{ width: '100%' }}
      , {
        // style={{ width: '100%' }}
        value: address !== null && address !== void 0 ? address : '',
        onChange: handleChange,
        onSelect: handleSelect,
        children: _ref => {
          let {
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          } = _ref;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            style: {
              position: 'relative'
            },
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.FormGroup, {
                style: {
                  width: '100%',
                  marginBottom: theme.spacing(1.5)
                },
                children: (0, _jsxRuntime.jsx)(_material.FormControlLabel, {
                  style: {
                    width: '100%',
                    alignItems: 'start',
                    marginLeft: 0
                  },
                  control: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
                    fullWidth: true,
                    variant: 'outlined',
                    type: 'text',
                    "data-testid": 'location-input',
                    //@ts-ignore
                    inputProps: _objectSpread({}, getInputProps({
                      className: 'location-search-input'
                    }))
                  }),
                  label: (0, _jsxRuntime.jsx)(_material.Typography, {
                    variant: 'h6',
                    color: 'textSecondary',
                    children: "Location"
                  }),
                  labelPlacement: 'top'
                })
              })
            }), suggestions.length > 0 && (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              direction: 'column',
              item: true,
              className: 'autocomplete-dropdown-container',
              style: {
                position: 'absolute',
                top: 80,
                border: '1px solid #9D9D9D',
                backgroundColor: '#ffffff',
                cursor: 'pointer'
              },
              children: [loading && (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                justifyContent: 'center',
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  children: "Loading..."
                })
              }), !loading && suggestions.map((suggestion, suggestionIndex) => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active ? {
                  backgroundColor: '#fafafa',
                  cursor: 'pointer'
                } : {
                  backgroundColor: '#ffffff',
                  cursor: 'pointer'
                };
                return (0, _jsxRuntime.jsx)(_material.Grid, _objectSpread(_objectSpread({
                  container: true,
                  item: true
                }, getSuggestionItemProps(suggestion, {
                  key: suggestionIndex,
                  className,
                  style
                })), {}, {
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    color: 'textSecondary',
                    children: suggestion.description
                  })
                }));
              })]
            })]
          });
        }
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsx)("div", {
        id: 'map'
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      style: {
        height: props.mapHeight ? "".concat(props.mapHeight, "px") : '450px',
        width: '100%',
        border: "2px solid ".concat(theme.palette.text.secondary)
      },
      alignItems: 'center',
      children: address !== '' ? (0, _jsxRuntime.jsx)(_BallMapComponent.default, {
        height: props.mapHeight,
        locationString: address
      }) : (0, _jsxRuntime.jsxs)(_material.Grid, {
        "data-testid": 'emptyLocation',
        container: true,
        item: true,
        alignItems: 'center',
        direction: 'column',
        xs: 12,
        style: {
          width: '100%'
        },
        children: [(0, _jsxRuntime.jsx)(_material.Typography, {
          color: 'textSecondary',
          children: (0, _jsxRuntime.jsx)(_iconsMaterial.LocationOn, {
            style: {
              fontSize: '56px'
            }
          })
        }), (0, _jsxRuntime.jsx)(_material.Typography, {
          color: 'textSecondary',
          children: "Type Location to Show Map"
        })]
      })
    })]
  });
};
var _default = exports.default = (0, _googleMapsReact.GoogleApiWrapper)(props => {
  return {
    apiKey: props.apiKey,
    libraries: ['places']
  };
})(GooglePlacesAutoComplete);