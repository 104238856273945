"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
const FlashCardBack = _ref => {
  var _currentCard$glass, _currentCard$glassPre, _currentCard$mixingGl, _currentCard$mixingGl2, _currentCard$garnish, _currentCard$instruct;
  let {
    currentCard
  } = _ref;
  const getGlassPrep = prepString => {
    switch (prepString) {
      case 'ICE':
        return "ice";
      case 'CHILLED':
        return "chilled";
      case 'STRAWBERRY_ICE':
        return "strawberry ice cubes";
      case 'SALT_RIM':
        return "salt on rim";
      case 'CHILI_SALT_RIM':
        return "chili salt on rim";
      case 'SUGAR_RIM':
        return "sugar on rim";
      case 'RED_SUGAR_RIM':
        return "red sugar on rim";
      case 'CARAMEL_STRIPE':
        return "caramel stripes";
      case 'CHOC_STRIPE':
        return "chocolate stripes";
      case 'GREEN_SUGAR_RIM':
        return "green sugar on rim";
      default:
        return prepString;
    }
  };
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    spacing: 2,
    justifyContent: 'center',
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsx)(_material.Typography, {
        variant: 'h2',
        align: 'center',
        fontFamily: 'Covered By Your Grace',
        children: currentCard === null || currentCard === void 0 ? void 0 : currentCard.title
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsxs)(_material.Typography, {
        variant: 'subtitle1',
        align: 'center',
        children: [currentCard === null || currentCard === void 0 ? void 0 : currentCard.glass.sizeOz, "oz ", currentCard === null || currentCard === void 0 || (_currentCard$glass = currentCard.glass) === null || _currentCard$glass === void 0 ? void 0 : _currentCard$glass.title]
      })
    }), (currentCard === null || currentCard === void 0 ? void 0 : currentCard.glassPrep) && (currentCard === null || currentCard === void 0 ? void 0 : currentCard.glassPrep.length) > 0 && (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      justifyContent: 'center',
      spacing: 1,
      children: currentCard === null || currentCard === void 0 || (_currentCard$glassPre = currentCard.glassPrep) === null || _currentCard$glassPre === void 0 ? void 0 : _currentCard$glassPre.map((prep, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Chip, {
          color: 'secondary',
          label: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'subtitle2',
            align: 'center',
            children: getGlassPrep(prep)
          })
        })
      }, "prep" + index))
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        xs: 8,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            fontFamily: 'Oswald',
            children: "Ingredients:"
          })
        }), (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            style: {
              paddingLeft: "48px"
            },
            children: // @ts-ignore
            currentCard === null || currentCard === void 0 || (_currentCard$mixingGl = currentCard.mixingGlass) === null || _currentCard$mixingGl === void 0 ? void 0 : _currentCard$mixingGl.map((mixin, index) => {
              if ((mixin === null || mixin === void 0 ? void 0 : mixin._type) === "MixingGlass") {
                var _mixin$ingredient;
                return (0, _jsxRuntime.jsxs)(_material.Grid, {
                  container: true,
                  item: true,
                  spacing: 1,
                  children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
                    item: true,
                    container: true,
                    xs: 2,
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    wrap: 'nowrap',
                    children: [(0, _jsxRuntime.jsx)(_material.Typography, {
                      variant: 'body2',
                      children: mixin === null || mixin === void 0 ? void 0 : mixin.amount
                    }), (0, _jsxRuntime.jsx)(_material.Typography, {
                      textAlign: 'left',
                      variant: 'subtitle1',
                      children: "oz"
                    })]
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    container: true,
                    xs: 10,
                    wrap: 'nowrap',
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      variant: 'body2',
                      children: mixin === null || mixin === void 0 || (_mixin$ingredient = mixin.ingredient) === null || _mixin$ingredient === void 0 ? void 0 : _mixin$ingredient.title
                    })
                  })]
                }, "mixin" + index);
              }
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            children: // @ts-ignore
            currentCard === null || currentCard === void 0 || (_currentCard$mixingGl2 = currentCard.mixingGlassGarnishes) === null || _currentCard$mixingGl2 === void 0 ? void 0 : _currentCard$mixingGl2.map((mixin, index) => {
              if (mixin) {
                return (0, _jsxRuntime.jsxs)(_material.Grid, {
                  container: true,
                  item: true,
                  spacing: 1,
                  children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
                    item: true,
                    container: true,
                    xs: 2,
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    alignContent: 'center',
                    children: [(0, _jsxRuntime.jsx)(_material.Typography, {
                      variant: 'body2',
                      children: "1"
                    }), " ", (0, _jsxRuntime.jsx)(_iconsMaterial.Close, {
                      fontSize: 'small'
                    })]
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      variant: 'body2',
                      children: mixin === null || mixin === void 0 ? void 0 : mixin.title
                    })
                  })]
                }, index);
              }
            })
          })]
        })]
      })
    }), (currentCard === null || currentCard === void 0 ? void 0 : currentCard.garnish) && (currentCard === null || currentCard === void 0 ? void 0 : currentCard.garnish.length) > 0 && (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        justifyContent: 'flex-end',
        xs: 8,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            fontFamily: 'Oswald',
            children: "Garnished By:"
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          xs: 11,
          style: {
            paddingLeft: "48px"
          },
          children: currentCard === null || currentCard === void 0 || (_currentCard$garnish = currentCard.garnish) === null || _currentCard$garnish === void 0 ? void 0 : _currentCard$garnish.map((garnishedBy, index) => {
            return (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                container: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'body2',
                  children: garnishedBy.title
                })
              })
            }, index);
          })
        })]
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        justifyContent: 'flex-end',
        xs: 8,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            fontFamily: 'Oswald',
            children: "Recipe:"
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          xs: 11,
          children: currentCard === null || currentCard === void 0 || (_currentCard$instruct = currentCard.instructions) === null || _currentCard$instruct === void 0 ? void 0 : _currentCard$instruct.map((mixin, index) => {
            var _mixin$mixingGlass, _mixin$mixingGlassGar;
            return (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              item: true,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                container: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'body2',
                  children: "".concat(mixin === null || mixin === void 0 ? void 0 : mixin.action, " ").concat(mixin !== null && mixin !== void 0 && mixin.instruction ? mixin === null || mixin === void 0 ? void 0 : mixin.instruction : "")
                })
              }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                item: true,
                container: true,
                style: {
                  paddingLeft: "8px"
                },
                children: [// @ts-ignore
                mixin === null || mixin === void 0 || (_mixin$mixingGlass = mixin.mixingGlass) === null || _mixin$mixingGlass === void 0 ? void 0 : _mixin$mixingGlass.map((mixin, index) => {
                  if ((mixin === null || mixin === void 0 ? void 0 : mixin._type) === "MixingGlass") {
                    var _mixin$ingredient2;
                    return (0, _jsxRuntime.jsxs)(_material.Grid, {
                      container: true,
                      item: true,
                      spacing: 1,
                      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                        item: true,
                        container: true,
                        xs: 2,
                        justifyContent: 'flex-end',
                        children: (0, _jsxRuntime.jsxs)(_material.Typography, {
                          variant: 'body2',
                          children: [mixin === null || mixin === void 0 ? void 0 : mixin.amount, "oz"]
                        })
                      }), " ", (0, _jsxRuntime.jsx)(_material.Grid, {
                        item: true,
                        children: (0, _jsxRuntime.jsx)(_material.Typography, {
                          variant: 'body2',
                          children: mixin === null || mixin === void 0 || (_mixin$ingredient2 = mixin.ingredient) === null || _mixin$ingredient2 === void 0 ? void 0 : _mixin$ingredient2.title
                        })
                      })]
                    }, "mixingglass" + index);
                  }
                }), // @ts-ignore
                mixin === null || mixin === void 0 || (_mixin$mixingGlassGar = mixin.mixingGlassGarnishes) === null || _mixin$mixingGlassGar === void 0 ? void 0 : _mixin$mixingGlassGar.map((mixin, index) => {
                  if (mixin) {
                    return (0, _jsxRuntime.jsx)(_material.Grid, {
                      container: true,
                      item: true,
                      children: (0, _jsxRuntime.jsx)(_material.Grid, {
                        item: true,
                        children: (0, _jsxRuntime.jsx)(_material.Typography, {
                          variant: 'body2',
                          children: mixin.title
                        })
                      })
                    }, "garnish" + index);
                  }
                })]
              })]
            }, 'mixin' + index);
          })
        })]
      })
    })]
  });
};
var _default = exports.default = FlashCardBack;