"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.array.sort.js");
require("core-js/modules/es.parse-int.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _uuid = require("uuid");
var _iconsMaterial = require("@mui/icons-material");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  tableRow: {
    // fontSize: '14px',
    // lineHeight: 2,
    color: '#5a5a5a',
    // fontFamily: 'Cantarell',
    // fontWeight: 400,
    '&:nth-of-type(odd)': {
      backgroundColor: '#f8f8f8'
    }
  },
  headerTableRow: {
    // fontSize: '14px',
    lineHeight: 2,
    // color: '#5a5a5a',
    // fontFamily: 'Cantarell',
    // fontWeight: 700,
    borderBottom: '2px solid rgba(90,90,90,.5)'
  },
  tableContainer: {
    /* min-width: 700px, */
  },
  filterIcon: {
    fontSize: 'small'
  },
  table: {
    '& .MuiTableCell-stickyHeader': {
      zIndex: 'unset'
    }
  }
}));
// eslint-disable-next-line func-style
function DataTable(props) {
  var _props$columns2;
  const classes = useStyles();
  const [page, setPage] = (0, _react.useState)(0);
  const [columnStates, setColumnStates] = (0, _react.useState)({});
  const [rowsPerPage, setRowsPerPage] = (0, _react.useState)(10);
  (0, _react.useEffect)(() => {
    var _props$columns;
    const reducer = (accumulator, currentValue) => _objectSpread(_objectSpread({}, accumulator), {}, {
      [currentValue.id]: 'asc'
    });
    const initialColumnStates = (_props$columns = props.columns) === null || _props$columns === void 0 ? void 0 : _props$columns.reduce(reducer, {});
    setColumnStates(initialColumnStates);
  }, []);
  const getRows = (rows, thisPage, thisRowsPerPage) => {
    return rows === null || rows === void 0 ? void 0 : rows.slice(thisPage * thisRowsPerPage, thisPage * thisRowsPerPage + thisRowsPerPage);
  };
  const getEmptyRows = () => rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    event.persist();
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const sortRowsOn = (columnName, sortDir) => {
    const sortedRows = props.rows.sort(props.sortFunction(columnName, sortDir));
    console.log('sorting rows ', sortedRows);
  };
  const sortData = columnLabel => {
    // @ts-ignore
    console.log('sort State', columnLabel, columnStates[columnLabel]);
    // @ts-ignore
    sortRowsOn(columnLabel, columnStates[columnLabel]);
    setColumnStates(state => _objectSpread(_objectSpread({}, state), {}, {
      // @ts-ignore
      [columnLabel]: columnStates[columnLabel] === 'asc' ? 'desc' : 'asc'
    }));
  };
  return (
    // eslint-disable-next-line no-nested-ternary
    props.loading ? (0, _jsxRuntime.jsxs)(_material.TableBody, {
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        style: {
          width: 'calc(100vw - 20px)',
          height: 53 * 7
        },
        alignItems: "center",
        justifyContent: "center",
        children: "Searching for Balls..."
      }), " "]
    }) : !props.rows || props.rows.length === 0 ? (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      style: {
        width: 'calc(100vw - 20px)',
        height: 53 * 7,
        border: '1px solid #9D9D9D'
      },
      alignItems: "center",
      justifyContent: "center",
      direction: "column",
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_iconsMaterial.TableChart, {})
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: "No Balls Match your Search"
      })]
    }) : props.rows && props.rows.length > 0 ? (0, _jsxRuntime.jsx)(_material.TableContainer, {
      style: {
        width: '100%'
      },
      children: (0, _jsxRuntime.jsxs)(_material.Table, {
        style: {
          width: '100%'
        },
        size: "small",
        stickyHeader: true,
        "aria-label": "a custom pagination sticky dense table",
        className: classes.table,
        children: [(0, _jsxRuntime.jsx)(_material.TableHead, {
          children: (0, _jsxRuntime.jsx)(_material.TableRow, {
            className: classes.headerTableRow,
            children: (_props$columns2 = props.columns) === null || _props$columns2 === void 0 ? void 0 : _props$columns2.map((column, index) => (0, _jsxRuntime.jsx)(_material.Hidden, {
              only: column.hidden,
              children: (0, _jsxRuntime.jsx)(_material.TableCell, {
                // align={column.align}
                style: {
                  width: 'max-content',
                  height: 'max-content'
                },
                children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                  style: {
                    width: 'max-content',
                    height: 'max-content'
                  },
                  container: true,
                  wrap: "nowrap",
                  alignItems: "stretch",
                  onClick: () => sortData(column.id),
                  children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    style: {
                      whiteSpace: 'nowrap'
                    },
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      variant: 'body1',
                      children: column.label && (typeof column.label === 'string' ? column.label : column.label())
                    })
                  }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                    container: true,
                    direction: "column",
                    item: true,
                    xs: 2,
                    alignItems: "center",
                    justifyContent: "center",
                    children: [(0, _jsxRuntime.jsx)(_iconsMaterial.KeyboardArrowUp, {
                      className: classes.filterIcon,
                      //@ts-ignore
                      style: {
                        display: columnStates[column.id] === 'asc' ? 'inline-block' : 'none'
                      }
                    }), (0, _jsxRuntime.jsx)(_iconsMaterial.KeyboardArrowDown, {
                      className: classes.filterIcon,
                      style: {
                        //@ts-ignore
                        display: columnStates[column.id] === 'desc' ? 'inline-block' : 'none'
                      }
                    })]
                  })]
                })
              }, (0, _uuid.v4)())
            }, index))
          }, (0, _uuid.v4)())
        }), (0, _jsxRuntime.jsxs)(_material.TableBody, {
          children: [(rowsPerPage > 0 ? getRows(props.rows, page, rowsPerPage) : props.rows || []).map(row => {
            var _props$columns3;
            return (0, _jsxRuntime.jsx)(_material.TableRow, {
              className: classes.tableRow,
              onClick: () => {
                props.rowClick(row);
              },
              children: (_props$columns3 = props.columns) === null || _props$columns3 === void 0 ? void 0 : _props$columns3.map((column, index) => (0, _jsxRuntime.jsx)(_material.Hidden, {
                only: column.hidden,
                children: (0, _jsxRuntime.jsx)(_material.TableCell, {
                  style: {
                    width: 'max-content',
                    height: 'max-content',
                    whiteSpace: 'nowrap'
                  },
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    color: 'textSecondary',
                    variant: 'body1',
                    children: column.renderer(row)
                  })
                }, (0, _uuid.v4)())
              }, index))
            }, (0, _uuid.v4)());
          }), getEmptyRows() > 0 && (0, _jsxRuntime.jsx)(_material.TableRow, {
            style: {
              height: 53 * getEmptyRows()
            },
            children: (0, _jsxRuntime.jsx)(_material.TableCell, {
              colSpan: 6,
              style: {
                width: 'max-content',
                height: 'max-content',
                whiteSpace: 'nowrap'
              }
            })
          }, (0, _uuid.v4)())]
        }), (0, _jsxRuntime.jsx)(_material.TableFooter, {
          children: (0, _jsxRuntime.jsx)(_material.TableRow, {
            className: classes.tableRow,
            children: (0, _jsxRuntime.jsx)(_material.TablePagination, {
              color: '#131313',
              style: {
                color: "#131313"
              },
              labelRowsPerPage: "per page",
              rowsPerPageOptions: [5, 10, 25, {
                label: 'All',
                value: -1
              }],
              colSpan: 3,
              count: props.rows.length,
              rowsPerPage: rowsPerPage,
              page: page,
              slotProps: {
                select: {
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }
              },
              onPageChange: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage
            })
          }, (0, _uuid.v4)())
        })]
      })
    }) : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
  );
}
var _default = exports.default = DataTable;