"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _AnimatedAbstractShape = _interopRequireDefault(require("./AnimatedAbstractShape"));
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const AbstractShapesBackground = props => {
  const theme = (0, _material.useTheme)();
  const shape0Variants = {
    onScreen: {
      top: 0,
      transition: {
        duration: .65
      }
    },
    offScreen: {
      top: -440,
      transition: {
        duration: .65
      }
    }
  };
  const shape1Variants = {
    onScreen: {
      bottom: -5,
      transition: {
        duration: .5
      }
    },
    offScreen: {
      bottom: -500,
      transition: {
        duration: .5
      }
    }
  };
  const shape2Variants = {
    onScreen: {
      bottom: -5,
      transition: {
        duration: .75
      }
    },
    offScreen: {
      bottom: -300,
      transition: {
        duration: .75
      }
    }
  };
  const shape3Variants = {
    onScreen: {
      top: 0,
      transition: {
        duration: .65
      }
    },
    offScreen: {
      top: -900,
      transition: {
        duration: .65
      }
    }
  };
  const shape4Variants = {
    onScreen: {
      right: 0,
      top: 0,
      transition: {
        duration: .75
      }
    },
    offScreen: {
      right: 0,
      top: -720,
      transition: {
        duration: .75
      }
    }
  };
  const shape5Variants = {
    onScreen: {
      right: 0,
      top: 0,
      transition: {
        duration: 1
      }
    },
    offScreen: {
      right: 0,
      top: -250,
      transition: {
        duration: 1
      }
    }
  };
  const shape6Variants = {
    onScreen: {
      right: 0,
      top: 0,
      transition: {
        duration: .65
      }
    },
    offScreen: {
      right: 0,
      top: -250,
      transition: {
        duration: .65
      }
    }
  };
  const shape7Variants = {
    onScreen: {
      bottom: 0,
      transition: {
        duration: .85
      }
    },
    offScreen: {
      bottom: -900,
      transition: {
        duration: .85
      }
    }
  };
  const shape8Variants = {
    onScreen: {
      bottom: 0,
      transition: {
        duration: .55
      }
    },
    offScreen: {
      bottom: -800,
      transition: {
        duration: .55
      }
    }
  };
  const shape9Variants = {
    onScreen: {
      top: 0,
      right: 0,
      bottom: 0,
      transition: {
        duration: .65
      }
    },
    offScreen: {
      top: -980,
      right: 0,
      bottom: 0,
      transition: {
        duration: .65
      }
    }
  };
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    style: {
      backgroundColor: theme.palette.primary.dark,
      zIndex: 0,
      width: "100%",
      height: "100%",
      position: "absolute"
    },
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsx)(_AnimatedAbstractShape.default, {
        shapeVariants: shape1Variants,
        image: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.imagesArray[1])
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsx)(_AnimatedAbstractShape.default, {
        shapeVariants: shape7Variants,
        image: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.imagesArray[7]),
        opacity: .35
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsx)(_AnimatedAbstractShape.default, {
        shapeVariants: shape8Variants,
        image: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.imagesArray[8]),
        opacity: .15
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsx)(_AnimatedAbstractShape.default, {
        shapeVariants: shape2Variants,
        opacity: .5,
        image: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.imagesArray[2])
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsx)(_AnimatedAbstractShape.default, {
        shapeVariants: shape5Variants,
        image: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.imagesArray[5])
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsx)(_AnimatedAbstractShape.default, {
        shapeVariants: shape6Variants,
        image: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.imagesArray[6]),
        opacity: .35
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsx)(_AnimatedAbstractShape.default, {
        shapeVariants: shape3Variants,
        image: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.imagesArray[3])
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsx)(_AnimatedAbstractShape.default, {
        shapeVariants: shape0Variants,
        image: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.imagesArray[0]),
        opacity: .6
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsx)(_AnimatedAbstractShape.default, {
        shapeVariants: shape4Variants,
        image: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.imagesArray[4])
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsx)(_AnimatedAbstractShape.default, {
        shapeVariants: shape9Variants,
        image: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.imagesArray[9]),
        opacity: .6
      })
    })]
  });
};
var _default = exports.default = AbstractShapesBackground;