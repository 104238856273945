"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _ModalContext = _interopRequireDefault(require("./ModalContext"));
var _iconsMaterial = require("@mui/icons-material");
var _LoadingButton = _interopRequireDefault(require("../loading-button/LoadingButton"));
var _ColoredPng = _interopRequireDefault(require("../colored-png/ColoredPng"));
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const ModalProvider = props => {
  var _modalContent$notes;
  const [modalOpen, setModalOpen] = (0, _react.useState)(false);
  const theme = (0, _material.useTheme)();
  const ref = (0, _react.useRef)(null);
  const xsDown = (0, _material.useMediaQuery)(theme.breakpoints.down('xs'));
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const [modalContent, setModalContent] = (0, _react.useState)(undefined);
  const handleModalClose = () => {
    setModalOpen(false);
  };
  // useEffect(() => {
  //     console.log("height", ref?.current?.scrollHeight, ref?.current?.clientHeight)
  // }, [ref.current])
  const openModal = contents => {
    // console.log("Opening modal", contents)
    if (!contents) {
      return;
    }
    setModalOpen(true);
    setModalContent(contents);
  };
  const newValue = (0, _react.useMemo)(() => ({
    openModal,
    handleModalClose
  }), [openModal, handleModalClose, modalContent]);
  return (0, _jsxRuntime.jsxs)(_ModalContext.default.Provider, {
    value: newValue,
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: (0, _jsxRuntime.jsx)(_material.Modal, {
        open: modalOpen,
        onClose: handleModalClose,
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          justifyContent: 'center',
          alignItems: 'stretch',
          alignContent: 'center',
          style: {
            width: "100%",
            height: "100%",
            position: "relative"
          },
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            item: true,
            container: true,
            xs: 12,
            sm: 9,
            md: 7,
            style: {
              border: "2px solid black",
              // borderRight:`4px solid ${theme.palette.primary.dark}`,
              backgroundColor: 'rgb(250,250,250,.96)',
              color: theme.palette.getContrastText(theme.palette.background.paper),
              padding: theme.spacing(0, 0, 4, 0),
              margin: theme.spacing(!xsDown ? 4 : 0, 0),
              maxHeight: "100%",
              // minHeight: '550px',
              overflowY: "scroll",
              height: xsDown ? "100%" : "unset",
              position: "relative"
            },
            justifyContent: 'center',
            alignContent: 'flex-start',
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: 'flex-end',
              style: {
                position: "absolute"
              },
              children: (0, _jsxRuntime.jsx)(_material.IconButton, {
                color: 'primary',
                onClick: () => setModalOpen(false),
                style: {
                  zIndex: 3,
                  margin: theme.spacing(2.5, 2.5, 0, 0)
                },
                size: "large",
                children: (0, _jsxRuntime.jsx)(_iconsMaterial.Close, {
                  color: 'secondary',
                  fontSize: 'large'
                })
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              style: {
                position: "absolute",
                height: "100%",
                zIndex: 1
              },
              children: (0, _jsxRuntime.jsx)(_ColoredPng.default, {
                color: 'rgba(16, 43, 136, .3)',
                maskUrl: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(modalContent === null || modalContent === void 0 ? void 0 : modalContent.iconOverlayImageSrc, 400, 400),
                size: 400
              })
            }), (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              xs: 12,
              // spacing={smDown ? 2 : 4}
              style: {
                padding: theme.spacing(10, 1, 0, 1),
                zIndex: 2
              },
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                xs: 10,
                sm: 12,
                item: true,
                justifyContent: 'center',
                alignItems: "center",
                alignContent: 'center',
                style: {
                  flexGrow: "1",
                  marginBottom: theme.spacing(2)
                },
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'h4',
                  align: 'center',
                  color: 'secondary',
                  children: modalContent === null || modalContent === void 0 ? void 0 : modalContent.title
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                style: {
                  position: "relative",
                  flexGrow: "2"
                },
                justifyContent: 'center',
                children: (0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  item: true,
                  xs: 12,
                  sm: 11,
                  ref: ref,
                  style: {
                    maxHeight: xsDown ? "" : "600px",
                    overflowY: "scroll",
                    overflowX: "hidden"
                  },
                  children: (0, _jsxRuntime.jsx)(_material.List, {
                    style: {
                      marginBottom: "36px"
                    },
                    children: modalContent === null || modalContent === void 0 ? void 0 : modalContent.contentText.map(faq => {
                      return (0, _jsxRuntime.jsx)(_material.ListItem, {
                        children: (0, _jsxRuntime.jsxs)(_material.ListItemText, {
                          children: [(0, _jsxRuntime.jsx)(_material.Typography, {
                            variant: 'body2',
                            gutterBottom: true,
                            color: 'secondary',
                            children: faq === null || faq === void 0 ? void 0 : faq.question
                          }), (0, _jsxRuntime.jsx)(_material.Typography, {
                            variant: 'body1',
                            style: {
                              // borderLeft: "1px solid white",
                              paddingLeft: theme.spacing(2)
                            },
                            children: faq === null || faq === void 0 ? void 0 : faq.answer
                          })]
                        })
                      });
                    })
                  })
                })
              }), modalContent === null || modalContent === void 0 || (_modalContent$notes = modalContent.notes) === null || _modalContent$notes === void 0 ? void 0 : _modalContent$notes.map((note, index) => {
                return (0, _jsxRuntime.jsx)(_material.ListItem, {
                  children: (0, _jsxRuntime.jsxs)(_material.ListItemText, {
                    children: [index === 0 && (0, _jsxRuntime.jsx)(_material.Typography, {
                      variant: 'body2',
                      gutterBottom: true,
                      color: 'secondary',
                      children: "Note:"
                    }), (0, _jsxRuntime.jsx)(_material.Typography, {
                      variant: 'body1',
                      style: {
                        // borderLeft: "1px solid white",
                        paddingLeft: theme.spacing(2)
                      },
                      children: note
                    })]
                  })
                });
              }), (modalContent === null || modalContent === void 0 ? void 0 : modalContent.ctaButtonTitle) && (modalContent === null || modalContent === void 0 ? void 0 : modalContent.ctaButtonTitle.length) > 0 && (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                style: {
                  marginTop: theme.spacing(4)
                },
                children: (0, _jsxRuntime.jsx)(_LoadingButton.default, {
                  href: modalContent === null || modalContent === void 0 ? void 0 : modalContent.ctaButtonLink,
                  color: "secondary",
                  variant: 'contained',
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    variant: 'button',
                    color: 'textSecondary',
                    children: modalContent === null || modalContent === void 0 ? void 0 : modalContent.ctaButtonTitle
                  })
                })
              })]
            })]
          })
        })
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: props.children
    })]
  });
};
var _default = exports.default = ModalProvider;