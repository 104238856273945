"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _FirebaseProvider = _interopRequireDefault(require("./common/firebase/firebase-context/FirebaseProvider"));
var _SanityProvider = _interopRequireDefault(require("./common/sanityIo/sanity-context/SanityProvider"));
var _App = _interopRequireDefault(require("./App"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const AppWrapper = props => {
  var _process$env$REACT_AP, _process$env$REACT_AP2, _process$env$REACT_AP3, _process$env$REACT_AP4, _process$env$REACT_AP5, _process$env$REACT_AP6, _process$env$REACT_AP7, _process$env$REACT_AP8, _process$env$REACT_AP9, _process$env$REACT_AP10, _process$env$REACT_AP11, _process$env$REACT_AP12, _process$env$REACT_AP13, _process$env$REACT_AP14, _process$env$REACT_AP15, _process$env$REACT_AP16, _process$env$REACT_AP17;
  console.log("config The raw environment variables in app wrapper...", process.env, props.logo);
  return (0, _jsxRuntime.jsx)(_FirebaseProvider.default, {
    children: (0, _jsxRuntime.jsx)(_SanityProvider.default, {
      children: (0, _jsxRuntime.jsx)(_App.default, {
        logo: props.logo,
        react_app_api_url: "",
        react_app_sanity_projectid: (_process$env$REACT_AP = process.env.REACT_APP_SANITY_PROJECTID) !== null && _process$env$REACT_AP !== void 0 ? _process$env$REACT_AP : props.react_app_sanity_projectid,
        react_app_sanity_db: (_process$env$REACT_AP2 = process.env.REACT_APP_SANITY_DB) !== null && _process$env$REACT_AP2 !== void 0 ? _process$env$REACT_AP2 : props.react_app_sanity_db,
        react_app_sanity_apiversion: (_process$env$REACT_AP3 = process.env.REACT_APP_SANITY_APIVERSION) !== null && _process$env$REACT_AP3 !== void 0 ? _process$env$REACT_AP3 : props.react_app_sanity_apiversion,
        react_app_sanity_projectid_cocktails: (_process$env$REACT_AP4 = process.env.REACT_APP_SANITY_PROJECTID_COCKTAILS) !== null && _process$env$REACT_AP4 !== void 0 ? _process$env$REACT_AP4 : props.react_app_sanity_projectid_cocktails,
        react_app_sanity_db_cocktails: (_process$env$REACT_AP5 = process.env.REACT_APP_SANITY_DB_COCKTAILS) !== null && _process$env$REACT_AP5 !== void 0 ? _process$env$REACT_AP5 : props.react_app_sanity_db_cocktails,
        react_app_sanity_apiversion_cocktails: (_process$env$REACT_AP6 = process.env.REACT_APP_SANITY_APIVERSION_COCKTAILS) !== null && _process$env$REACT_AP6 !== void 0 ? _process$env$REACT_AP6 : props.react_app_sanity_apiversion_cocktails,
        react_app_api_key: (_process$env$REACT_AP7 = process.env.REACT_APP_API_KEY) !== null && _process$env$REACT_AP7 !== void 0 ? _process$env$REACT_AP7 : props.react_app_api_key,
        react_app_auth_domain: (_process$env$REACT_AP8 = process.env.REACT_APP_AUTH_DOMAIN) !== null && _process$env$REACT_AP8 !== void 0 ? _process$env$REACT_AP8 : props.react_app_auth_domain,
        react_app_database_url: (_process$env$REACT_AP9 = process.env.REACT_APP_DATABASE_URL) !== null && _process$env$REACT_AP9 !== void 0 ? _process$env$REACT_AP9 : props.react_app_database_url,
        react_app_project_id: (_process$env$REACT_AP10 = process.env.REACT_APP_PROJECT_ID) !== null && _process$env$REACT_AP10 !== void 0 ? _process$env$REACT_AP10 : props.react_app_project_id,
        react_app_storage_bucket: (_process$env$REACT_AP11 = process.env.REACT_APP_STORAGE_BUCKET) !== null && _process$env$REACT_AP11 !== void 0 ? _process$env$REACT_AP11 : props.react_app_storage_bucket,
        react_app_messaging_sender_id: (_process$env$REACT_AP12 = process.env.REACT_APP_MESSAGING_SENDER_ID) !== null && _process$env$REACT_AP12 !== void 0 ? _process$env$REACT_AP12 : props.react_app_messaging_sender_id,
        react_app_app_id: (_process$env$REACT_AP13 = process.env.REACT_APP_APP_ID) !== null && _process$env$REACT_AP13 !== void 0 ? _process$env$REACT_AP13 : props.react_app_app_id,
        react_app_firebase_analytics_tracking_id: (_process$env$REACT_AP14 = process.env.REACT_APP_FIREBASE_ANALYTICS_TRACKING_ID) !== null && _process$env$REACT_AP14 !== void 0 ? _process$env$REACT_AP14 : props.react_app_firebase_analytics_tracking_id,
        react_app_base_route: (_process$env$REACT_AP15 = process.env.REACT_APP_BASE_ROUTE) !== null && _process$env$REACT_AP15 !== void 0 ? _process$env$REACT_AP15 : props.react_app_base_route,
        react_app_bar_inventory_slug: (_process$env$REACT_AP16 = process.env.REACT_APP_BAR_INVENTORY_SLUG) !== null && _process$env$REACT_AP16 !== void 0 ? _process$env$REACT_AP16 : props.react_app_bar_inventory_slug,
        react_app_googlemaps_embed_api_key: (_process$env$REACT_AP17 = process.env.REACT_APP_GOOGLEMAPS_EMBED_API_KEY) !== null && _process$env$REACT_AP17 !== void 0 ? _process$env$REACT_AP17 : props.react_app_googlemaps_embed_api_key
      })
    })
  });
};
var _default = exports.default = AppWrapper;