"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _styles = require("@mui/styles");
var _styles2 = require("@mui/material/styles");
var _StyledTextField = _interopRequireDefault(require("../styled-text-field/StyledTextField"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _styles.makeStyles)(theme => ({
  formControlLabel: {
    color: "black",
    "& .MuiFormControlLabel-asterisk": {
      color: "black"
    }
  },
  errorTextField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
      borderWidth: '2px'
    },
    '& .MuiInputBase-root': {
      color: "".concat(theme.palette.error.main)
    },
    '::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)'
    }
  }
}));
const AwTextField = props => {
  const classes = useStyles();
  const [isError, setIsError] = (0, _react.useState)(false);
  const [errorMessageText, setErrorMessageText] = (0, _react.useState)();
  (0, _react.useEffect)(() => {
    validateField(props.value);
  }, [props.dependendentFieldValue]);
  const validateField = valueToValidate => {
    if (props.validate) {
      const {
        status
      } = props.validate(valueToValidate);
      if (status !== null && status !== void 0 && status.messageText) {
        setIsError(true);
        setErrorMessageText(status.messageText);
      } else {
        setIsError(false);
        setErrorMessageText('');
      }
    }
  };
  (0, _react.useEffect)(() => {
    validateField(props.value);
  }, [props.value]);
  (0, _react.useEffect)(() => {
    validateField(props.value);
  });
  const theme = (0, _styles2.useTheme)();
  // const isFieldError = () => errorMessageText && errorMessageText !== ''
  // const styles = {
  //     labelAsterisk: {
  //         color: "red"
  //     }
  // };
  const style = {
    fieldset: {
      borderColor: "black"
    },
    "&:hover fieldset": {
      //borderColor: "green!important" // works
      borderColor: "black!important" // doesnt work
    },
    "& .MuiOutlinedInput-root": {
      paddingRight: "12px"
    },
    "& input": {
      color: 'black',
      paddingRight: "0px"
    }
  };
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: (0, _jsxRuntime.jsxs)(_material.FormGroup, {
      children: [(0, _jsxRuntime.jsx)(_material.FormControl, {
        error: isError,
        children: (0, _jsxRuntime.jsx)(_material.FormControlLabel, {
          className: classes.formControlLabel,
          style: {
            alignItems: 'start'
          },
          control: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
            sx: style,
            required: true,
            fullWidth: true,
            multiline: props.multiline ? props.multiline : false,
            minRows: props.multiline ? '7' : '1',
            variant: 'outlined',
            onChange: props.onChange,
            name: props.fieldName,
            type: props.type ? props.type : 'text',
            value: props.value || '',
            "data-testid": props.testId,
            className: isError ? classes.errorTextField : "",
            style: {
              minWidth: '270px',
              fontSize: '24px',
              color: isError ? theme.palette.error.main : "black"
            },
            inputProps: {
              'data-testid': "".concat(props.testId, "-input")
            }
          }),
          label: (0, _jsxRuntime.jsx)(_material.Typography, {
            gutterBottom: true,
            component: 'div',
            variant: 'h6',
            noWrap: true,
            style: {
              textTransform: 'capitalize',
              display: "inline",
              color: isError ? theme.palette.error.main : 'black'
            },
            children: props.fieldLabel
          }),
          labelPlacement: 'top'
        })
      }), (0, _jsxRuntime.jsx)(_material.FormHelperText, {
        "data-testid": "".concat(props.testId, "-").concat(isError ? 'error' : 'valid'),
        // error={!!errorMessageText && errorMessageText !== ''}
        style: {
          position: 'relative',
          paddingLeft: theme.spacing(2),
          marginTop: '0px',
          width: '100%',
          color: isError ? theme.palette.error.main : 'inherit',
          height: isError ? 'unset' : '20px'
        },
        id: 'component-helper-text',
        children: errorMessageText
      })]
    })
  });
};
var _default = exports.default = AwTextField;