"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.sort.js");
const capitalizeArray = theString => {
  const place = theString.map(aString => {
    const lower = aString.toLowerCase();
    const upper = aString.toUpperCase();
    return "\"".concat(upper[0] + lower.slice(1), "\"");
  });
  // console.log("the capitalized array",place)
  return place;
};
const sortByTitle = array => {
  if (!array) return undefined;
  return array.sort((skillA, skillB) => {
    var _skillA$title, _skillB$title;
    if (skillA.title === skillB.title) return 0;
    return ((_skillA$title = skillA.title) !== null && _skillA$title !== void 0 ? _skillA$title : "") < ((_skillB$title = skillB.title) !== null && _skillB$title !== void 0 ? _skillB$title : "") ? -1 : 1;
  });
};
var _default = exports.default = {
  capitalizeArray,
  sortByTitle
};