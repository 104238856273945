"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _CustomizedThemeContext = _interopRequireDefault(require("../../../customized-theme-provider/CustomizedThemeContext"));
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _ColorPalette = require("../../../../theme/common/ColorPalette");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  root: props => ({
    backgroundColor: _ColorPalette.COLORS.TRANSPARENTWHITE,
    transition: 'background-color .5s ease 0s',
    paddingLeft: props.paddingLeft,
    height: props.appBarHeight,
    width: "100%"
  }),
  opaque: {
    backgroundColor: "black !important"
  }
}));
const AppBarWrapper = props => {
  const customizedTheme = (0, _react.useContext)(_CustomizedThemeContext.default);
  const classes = useStyles({
    paddingLeft: customizedTheme.customizedTheme.spacing(1.5),
    appBarHeight: customizedTheme.customizedTheme.mixins.toolbar.height
  });
  return props.isAppBar ? (0, _jsxRuntime.jsx)(_material.AppBar, {
    style: {
      backgroundColor: "".concat(props.isAppBar && props.isEnhanced ? "black" : (0, _ColorPalette.convertToHexCode)(props.backgroundColor))
    },
    className: classes.root,
    children: props.children
  }) : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: props.children
  });
};
var _default = exports.default = AppBarWrapper;