"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.search.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/web.url-search-params.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _reactRouterDom = require("react-router-dom");
var _react = require("react");
var _reactQuery = require("@tanstack/react-query");
var _FourOhFour = _interopRequireDefault(require("./components/templates/transform-hw/pages/error-page/FourOhFour"));
var _PageProvider = _interopRequireDefault(require("./components/page-context/PageProvider"));
var _AmenityProvider = _interopRequireDefault(require("./components/amenity-context/AmenityProvider"));
var _ModalProvider = _interopRequireDefault(require("./components/snackbar-context/ModalProvider"));
var _SnackbarProvider = _interopRequireDefault(require("./components/modal-context/SnackbarProvider"));
var _PageMux = _interopRequireDefault(require("./components/templates/mackenzies-mind/pages/PageMux"));
var _CustomizedThemeProvider = _interopRequireDefault(require("./components/customized-theme-provider/CustomizedThemeProvider"));
var _queryClient = require("./queryClient");
var _FirebaseContext = _interopRequireDefault(require("./common/firebase/firebase-context/FirebaseContext"));
var _SanityContext = _interopRequireDefault(require("./common/sanityIo/sanity-context/SanityContext"));
var _AppSettingsProvider = _interopRequireDefault(require("./components/templates/anybody-walking/app-settings/AppSettingsProvider"));
require("core-js/stable");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const App = props => {
  const theme = (0, _material.useTheme)();
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  (0, _react.useEffect)(() => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    if (params.has('utm_source') || params.has('utm_medium') || params.has('utm_campaign') || params.has('utm_id')) {
      firebaseContext.utmCodes && firebaseContext.utmCodes(params.get('utm_source'), params.get('utm_medium'), params.get('utm_campaign'), params.get('utm_id'));
    }
    // console.log("Props passed to APp", props)
  }, []);
  (0, _react.useEffect)(() => {
    if (firebaseContext.initFirebase) {
      // console.log("Initializing firebase")
      firebaseContext.initFirebase(props.react_app_api_key, props.react_app_auth_domain, props.react_app_database_url, props.react_app_project_id, props.react_app_storage_bucket, props.react_app_messaging_sender_id, props.react_app_app_id, props.react_app_firebase_analytics_tracking_id);
    }
    if (sanityContext.initSanity) {
      sanityContext.initSanity(props.react_app_sanity_projectid, props.react_app_sanity_db, props.react_app_sanity_apiversion, true, props.react_app_sanity_projectid_cocktails, props.react_app_sanity_db_cocktails);
    }
  }, []);
  (0, _react.useEffect)(() => {
    console.log("The logo before storage", props.logo);
  }, [props.logo]);
  return (0, _jsxRuntime.jsx)(_reactRouterDom.BrowserRouter, {
    children: (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
      client: _queryClient.queryClient,
      children: (0, _jsxRuntime.jsx)(_PageProvider.default, {
        googleApiKey: props.react_app_googlemaps_embed_api_key,
        barInventorySlug: props.react_app_bar_inventory_slug,
        children: (0, _jsxRuntime.jsx)(_CustomizedThemeProvider.default, {
          logoSrc: props.logo,
          children: (0, _jsxRuntime.jsx)(_SnackbarProvider.default, {
            children: (0, _jsxRuntime.jsx)(_ModalProvider.default, {
              children: (0, _jsxRuntime.jsx)(_AmenityProvider.default, {
                children: (0, _jsxRuntime.jsx)(_AppSettingsProvider.default, {
                  children: (0, _jsxRuntime.jsx)(_material.Grid, {
                    container: true,
                    item: true,
                    alignItems: "center",
                    style: {
                      backgroundColor: theme.palette.background.default,
                      overflow: "hidden",
                      width: "100vw"
                    },
                    justifyContent: 'center',
                    children: (0, _jsxRuntime.jsx)(_material.Grid, {
                      item: true,
                      style: {
                        overflow: "hidden"
                      },
                      children: (0, _jsxRuntime.jsxs)(_reactRouterDom.Routes, {
                        children: [(0, _jsxRuntime.jsx)(_reactRouterDom.Route, {
                          path: "/" + props.react_app_base_route + "/:pageSlug/:documentType/:documentSlug",
                          element: (0, _jsxRuntime.jsx)(_PageMux.default, {
                            baseRoute: props.react_app_base_route
                          })
                        }), (0, _jsxRuntime.jsx)(_reactRouterDom.Route, {
                          path: "/" + props.react_app_base_route + "/:pageSlug",
                          element: (0, _jsxRuntime.jsx)(_PageMux.default, {
                            baseRoute: props.react_app_base_route
                          })
                        }), (0, _jsxRuntime.jsx)(_reactRouterDom.Route, {
                          path: '/error',
                          element: (0, _jsxRuntime.jsx)(_FourOhFour.default, {})
                        }), (0, _jsxRuntime.jsx)(_reactRouterDom.Route, {
                          path: "/*",
                          element: (0, _jsxRuntime.jsx)(_reactRouterDom.Navigate, {
                            to: "/" + props.react_app_base_route + "/home"
                          })
                        })]
                      })
                    })
                  })
                })
              })
            })
          })
        })
      })
    })
  });
};
var _default = exports.default = App;