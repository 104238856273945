"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.promise.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _framerMotion = require("framer-motion");
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const HorizontalAmenity = props => {
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const controls = (0, _framerMotion.useAnimationControls)();
  const animateFlipIcon = async () => {
    await controls.start({
      rotateY: 180
    }, {
      duration: .5
    });
  };
  const animateUnFlipIcon = async () => {
    await controls.start({
      rotateY: 0
    }, {
      duration: .2
    });
  };
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
    onHoverStart: animateFlipIcon,
    onHoverEnd: animateUnFlipIcon,
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      container: true,
      spacing: 1,
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        maxWidth: 64,
        style: {
          position: "relative"
        },
        children: [(0, _jsxRuntime.jsx)(_material.Card, {
          style: {
            position: "absolute",
            bottom: 12,
            opacity: .5,
            width: "36px",
            height: "36px",
            backgroundColor: theme.palette.primary.main,
            borderRadius: "50%"
          }
        }), (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
          animate: controls,
          children: (0, _jsxRuntime.jsx)("img", {
            width: 56,
            src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.amenity.imageSrc, 56, 56)
          })
        })]
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        maxWidth: 250,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body2',
            gutterBottom: true,
            noWrap: true,
            children: props.amenity.name
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            children: props.amenity.description
          })
        })]
      })]
    })
  });
};
var _default = exports.default = HorizontalAmenity;