"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _BulletedHeader = _interopRequireDefault(require("./BulletedHeader"));
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _AnimatedPortfolioItem = _interopRequireDefault(require("./AnimatedPortfolioItem"));
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  iconButton: {
    "&:hover": {
      backgroundColor: theme.palette.primary.dark
    }
  }
}));
const AnimatedPortfolioSection = props => {
  var _props$sectionData$po, _currentItem$inceptio, _currentItem$skillsHi, _currentItem$imageGal;
  const theme = (0, _material.useTheme)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const [isOpen, setIsOpen] = (0, _react.useState)(false);
  const [currentItem, setCurrentItem] = (0, _react.useState)();
  const sendToModal = portfolioItem => {
    setCurrentItem(portfolioItem);
    setIsOpen(true);
  };
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    style: {
      padding: theme.spacing(2, 4, 12, 4),
      borderBottom: "1px solid lightgray"
    },
    justifyContent: 'center',
    children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      spacing: 3,
      justifyContent: 'center',
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        alignContent: 'flex-start',
        spacing: 1,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          children: (0, _jsxRuntime.jsx)(_BulletedHeader.default, {
            isCenter: true,
            heroBullet: props.sectionData.heroBullet,
            textContent: props.sectionData.preTitle
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            align: 'center',
            children: props.sectionData.title
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        justifyContent: 'center',
        xs: 12,
        justifySelf: 'center',
        children: (_props$sectionData$po = props.sectionData.portfolioEntries) === null || _props$sectionData$po === void 0 ? void 0 : _props$sectionData$po.map(portfolioItem => {
          return (0, _jsxRuntime.jsx)(_AnimatedPortfolioItem.default, {
            action: () => sendToModal(portfolioItem),
            portfolioItem: portfolioItem
          });
        })
      })]
    }), (0, _jsxRuntime.jsx)(_material.Modal, {
      open: isOpen,
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        style: {
          width: "100vw",
          height: "100vh",
          position: "relative"
        },
        onClick: () => {
          setIsOpen(false);
        },
        children: [(0, _jsxRuntime.jsx)(_material.IconButton, {
          color: 'secondary',
          style: {
            position: "absolute",
            top: 0,
            right: 0
          },
          onClick: () => setIsOpen(false),
          size: "large",
          children: (0, _jsxRuntime.jsx)(_iconsMaterial.Close, {
            fontSize: 'large'
          })
        }), (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          sm: 8,
          item: true,
          style: {
            backgroundColor: "white",
            padding: theme.spacing(4),
            maxHeight: "800px",
            overflowY: "scroll"
          },
          spacing: 1,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            sx: {
              backgroundImage: "url(".concat(sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(currentItem === null || currentItem === void 0 ? void 0 : currentItem.coverImage), ")"),
              backgroundSize: "cover",
              backgroundPosition: "top center",
              backgroundRepeat: "no-repeat",
              // width: "100%",
              // margin: "8px",
              height: "300px"
            }
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            justifyContent: 'flex-end',
            children: currentItem === null || currentItem === void 0 || (_currentItem$inceptio = currentItem.inceptionDate) === null || _currentItem$inceptio === void 0 ? void 0 : _currentItem$inceptio.toString()
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'primary',
              variant: 'body1',
              textTransform: 'uppercase',
              children: currentItem === null || currentItem === void 0 ? void 0 : currentItem.preTitle
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'h3',
              children: currentItem === null || currentItem === void 0 ? void 0 : currentItem.detailTitle
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            spacing: 1,
            children: currentItem === null || currentItem === void 0 || (_currentItem$skillsHi = currentItem.skillsHighlighted) === null || _currentItem$skillsHi === void 0 ? void 0 : _currentItem$skillsHi.map((skill, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Chip, {
                color: 'primary',
                label: skill.title
              })
            }, index))
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'body1',
              children: currentItem === null || currentItem === void 0 ? void 0 : currentItem.detailDescription
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              justifyContent: 'center',
              children: currentItem === null || currentItem === void 0 || (_currentItem$imageGal = currentItem.imageGallery) === null || _currentItem$imageGal === void 0 ? void 0 : _currentItem$imageGal.map((image, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                container: true,
                xs: 11,
                justifyContent: 'center',
                children: (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)("img", {
                    alt: 'imageGalleryEntry',
                    src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(image, 80, 80),
                    width: "100%"
                  })
                }, index)
              }, index))
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsxs)(_material.ButtonGroup, {
              fullWidth: true,
              style: {
                marginTop: theme.spacing(4)
              },
              children: [(0, _jsxRuntime.jsx)(_material.Button, {
                variant: 'contained',
                color: 'secondary',
                onClick: () => setIsOpen(false),
                children: "Back"
              }), (0, _jsxRuntime.jsx)(_material.Button, {
                variant: 'contained',
                color: "primary",
                href: currentItem === null || currentItem === void 0 ? void 0 : currentItem.linkToProd,
                children: "Goto Project"
              })]
            })
          })]
        })]
      })
    })]
  });
};
var _default = exports.default = AnimatedPortfolioSection;