"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/es.promise.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _styles = require("@mui/styles");
var _Styles = _interopRequireDefault(require("../mackenzies-mind/pages/Styles"));
var _isEmail = _interopRequireDefault(require("validator/lib/isEmail"));
var _LoadingButton = _interopRequireDefault(require("../../loading-button/LoadingButton"));
var _reactQuery = require("@tanstack/react-query");
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
var _leadClient = _interopRequireDefault(require("../transform-hw/pages/under-construction-page/leadClient"));
var _SnackbarContext = _interopRequireDefault(require("../../modal-context/SnackbarContext"));
var _withStyles = _interopRequireDefault(require("@mui/styles/withStyles"));
var _material = require("@mui/material");
var _FirebaseContext = _interopRequireDefault(require("../../../common/firebase/firebase-context/FirebaseContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _styles.makeStyles)(() => ({
  root: {
    width: '100vw',
    // minHeight: '100vh',
    // backgroundColor: '#1f1f1f',
    color: "#FAFAFA"
  },
  header: {
    fontWeight: 800,
    letterSpacing: '10px',
    lineHeight: 1.4,
    fontSize: '30px',
    textTransform: 'uppercase'
  },
  headerAccent: {
    display: 'inline-block',
    marginLeft: "8px"
  },
  formContainer: {
    // margin: 'auto',
    // height: '500px',
    // backgroundColor: '#313131',
    // boxShadow: '11px 10px 38px rgb(0 0 0 / 38%)',
    zIndex: 2
  },
  inputAdornmentContainer: {
    marginTop: "8px",
    zIndex: 3
  },
  inputAdornmentTextBlockContainer: {
    position: "relative",
    top: -34,
    zIndex: 3
  },
  formTitle: {
    marginBottom: "8px"
  },
  socialMediaContainer: {
    marginTop: "8px"
  },
  lhsContainer: {
    // width: "500px",
    // height: "650px"
  },
  formInput: {
    // color: "white",
  },
  sectionTitle: {
    fontWeight: 800
    // color: "white !important"
  }
}));
const StyledTextField = (0, _withStyles.default)(theme => ({
  root: {
    // marginTop: "16px",
    minHeight: 90,
    transition: "all 0.3s ease-in-out",
    "& label": {
      // display: "inline-block",
      // fontSize: "16px",
      // fontWeight: 700,
      position: "relative",
      top: "8px"
      // left: "-14px",
    },
    "& legend": {
      maxWidth: "0px"
    },
    "& input": {
      zIndex: 2
    },
    "& textarea": {
      zIndex: 2
    },
    "& .MuiOutlinedInput-root": {
      border: "1px solid black",
      borderRadius: 0,
      "&.Mui-focused": {
        borderColor: "".concat(theme.palette.primary.main, " !important"),
        "&.Mui-hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "".concat(theme.palette.primary.main, " !important")
          }
        }
      },
      "&.Mui-hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#212121 !important"
        }
      }
    }
  }
}))(_material.TextField);
const ResumeContactUsSection = props => {
  const classes = useStyles();
  const snackbarContext = (0, _react.useContext)(_SnackbarContext.default);
  const globalClasses = (0, _Styles.default)({});
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const [leadName, setleadName] = (0, _react.useState)();
  const [email, setEmail] = (0, _react.useState)();
  const [leadMessage, setLeadMessage] = (0, _react.useState)();
  const {
    isLoading,
    isError,
    data,
    refetch,
    isRefetching
  } = (0, _reactQuery.useQuery)({
    queryKey: ['submitContactUsForm'],
    queryFn: () => {
      if (email && email.length > 0 && !data && !isError) {
        return _leadClient.default.createLead({
          email,
          leadName,
          leadMessage,
          source: "Contact Us"
        }).then(response => {
          snackbarContext.openSnackbar && snackbarContext.openSnackbar(response.message);
          return response.response;
        });
      }
      return null;
    }
  });
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const createLead = async () => {
    firebaseContext.ctaClick && firebaseContext.ctaClick('contact-us', 'send-message', pageContext.analyticsId);
    return refetch();
  };
  const theme = (0, _material.useTheme)();
  const smDown = (0, _material.useMediaQuery)(theme.breakpoints.down('sm'));
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    style: {
      padding: theme.spacing(4, smDown ? 1 : 4)
    },
    className: globalClasses.resumeSection,
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      spacing: 3,
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        sm: 4,
        alignContent: 'flex-start',
        spacing: 1,
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          container: true,
          children: [(0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            children: props.sectionData.title
          }), (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            color: 'primary',
            display: 'inline',
            children: "."
          })]
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            children: props.sectionData.introduction
          })
        })]
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        sm: 8,
        spacing: 2,
        justifyContent: 'space-between',
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          md: 6,
          alignContent: 'flex-start',
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            children: (0, _jsxRuntime.jsx)(StyledTextField, {
              fullWidth: true,
              id: "contact-name-input",
              value: leadName,
              onChange: e => {
                setleadName(e.target.value);
              },
              label: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: 'textPrimary',
                children: "Name"
              }),
              variant: "outlined",
              InputProps: {
                // startAdornment: (
                //     <InputAdornment position="start">
                //         <Typography className={classes.inputAdornmentContainer}>
                //             <AccountCircle/>
                //         </Typography>
                //     </InputAdornment>
                // ),
                className: classes.formInput
              }
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            children: (0, _jsxRuntime.jsx)(StyledTextField, {
              fullWidth: true,
              value: email,
              onChange: e => {
                setEmail(e.target.value);
              },
              id: "contact-email-input",
              label: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: 'textPrimary',
                children: "Email"
              }),
              variant: "outlined",
              InputProps: {
                // startAdornment: (
                //     <InputAdornment position="start">
                //         <Typography className={classes.inputAdornmentContainer}>
                //             <Email/>
                //         </Typography>
                //     </InputAdornment>
                // ),
                className: classes.formInput
              }
            })
          })]
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          md: 6,
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            children: (0, _jsxRuntime.jsx)(StyledTextField, {
              fullWidth: true,
              id: "contact-message-input",
              value: leadMessage,
              onChange: e => {
                setLeadMessage(e.target.value);
              },
              label: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: 'textPrimary',
                children: "Message"
              }),
              variant: "outlined",
              multiline: true,
              minRows: "6",
              InputProps: {
                // startAdornment: (
                //     <InputAdornment position="start">
                //         <Typography
                //
                //             className={classes.inputAdornmentTextBlockContainer}>
                //             <Message/>
                //         </Typography>
                //     </InputAdornment>
                // ),
                className: classes.formInput
              }
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'flex-end',
          style: {
            paddingRight: "16px"
          },
          children: (0, _jsxRuntime.jsx)(_LoadingButton.default, {
            width: 200,
            isLoading: isLoading || isRefetching,
            disabled: !!(data || isError || email && email.length > 0 && !(0, _isEmail.default)(email)),
            clickHandler: createLead,
            color: "primary",
            variant: "contained",
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              children: "Send Message"
            })
          })
        })]
      })]
    })
  });
};
var _default = exports.default = ResumeContactUsSection;