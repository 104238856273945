"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/es.parse-int.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _TheOtherSideLogo = _interopRequireDefault(require("./TheOtherSideLogo"));
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
var _iconsMaterial = require("@mui/icons-material");
var _FirebaseContext = _interopRequireDefault(require("../../../common/firebase/firebase-context/FirebaseContext"));
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  preroot: {
    minHeight: '1400px',
    // backgroundColor: "black",
    color: "white",
    paddingTop: 64,
    paddingBottom: 64
    // paddingLeft: -theme.spacing(-5),
  },
  imageList: {
    maxWidth: "800px",
    minWidth: "300px"
    // height: 2000,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  }
}));
const TheDrinkeryAlbumSection = props => {
  var _pageContext$page, _pageContext$page2, _pageContext$page3, _pageContext$page4, _pageContext$page5, _props$sectionData$ha;
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const theClasses = useStyles();
  const theme = (0, _material.useTheme)();
  const xsDown = (0, _material.useMediaQuery)(theme.breakpoints.only('xs'));
  const [open, setOpen] = (0, _react.useState)(false);
  const [selectedItem, setSelectedItem] = (0, _react.useState)(null);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    className: theClasses.preroot,
    style: {
      color: "white"
    },
    spacing: 1,
    children: [props.sectionData.isLogo && (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsx)(_TheOtherSideLogo.default, {
        isCenter: true,
        logoImageSrc: (_pageContext$page = pageContext.page) === null || _pageContext$page === void 0 ? void 0 : _pageContext$page.businessCardImageSrc
      })
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      container: true,
      justifyContent: 'center',
      alignItems: 'center',
      spacing: 2,
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        justifyContent: 'center',
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'h5',
          children: props.sectionData.contentTitle
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        justifyContent: 'center',
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          align: 'center',
          variant: 'body2',
          style: {
            maxWidth: 800,
            fontWeight: 400
          },
          children: props.sectionData.contentText
        })
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        justifyContent: 'center',
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            gutterBottom: true,
            children: "Tags & Hashtags"
          })
        }), (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_material.Link, {
              href: "https://instagram.com/".concat((_pageContext$page2 = pageContext.page) === null || _pageContext$page2 === void 0 || (_pageContext$page2 = _pageContext$page2.businessContact) === null || _pageContext$page2 === void 0 ? void 0 : _pageContext$page2.instagram),
              children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                alignItems: 'center',
                children: [" ", (0, _jsxRuntime.jsx)(_iconsMaterial.Instagram, {
                  style: {
                    marginRight: theme.spacing(1)
                  }
                }), " ", (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: "body2",
                  children: (_pageContext$page3 = pageContext.page) === null || _pageContext$page3 === void 0 || (_pageContext$page3 = _pageContext$page3.businessContact) === null || _pageContext$page3 === void 0 ? void 0 : _pageContext$page3.instagram
                })]
              })
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_material.Link, {
              href: "https://facebook.com/".concat((_pageContext$page4 = pageContext.page) === null || _pageContext$page4 === void 0 || (_pageContext$page4 = _pageContext$page4.businessContact) === null || _pageContext$page4 === void 0 ? void 0 : _pageContext$page4.facebook),
              children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                alignItems: 'center',
                children: [" ", (0, _jsxRuntime.jsx)(_iconsMaterial.Facebook, {
                  style: {
                    marginRight: theme.spacing(1)
                  }
                }), " ", (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: "body2",
                  children: (_pageContext$page5 = pageContext.page) === null || _pageContext$page5 === void 0 || (_pageContext$page5 = _pageContext$page5.businessContact) === null || _pageContext$page5 === void 0 ? void 0 : _pageContext$page5.facebook
                })]
              })
            })
          })]
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            justifyContent: 'center',
            style: {
              maxWidth: 550
            },
            children: (_props$sectionData$ha = props.sectionData.hashtags) === null || _props$sectionData$ha === void 0 ? void 0 : _props$sectionData$ha.map((tag, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
              xs: 6,
              container: true,
              item: true,
              justifyContent: 'center',
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                align: 'center',
                variant: 'body2',
                children: tag
              })
            }, index))
          })
        })]
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        justifyContent: 'center',
        children: [(0, _jsxRuntime.jsx)(_material.ImageList, {
          rowHeight: 500,
          className: theClasses.imageList,
          cols: xsDown ? 2 : 3,
          children: props.sectionData.imageList.map((item, index) => {
            var _item$cols;
            return (0, _jsxRuntime.jsxs)(_material.ImageListItem, {
              cols: parseInt((_item$cols = item.cols) !== null && _item$cols !== void 0 ? _item$cols : "1"),
              onClick: () => {
                firebaseContext.albumImageClick && firebaseContext.albumImageClick(item.title, item.subtitle, pageContext.analyticsId || "no-id");
                setSelectedItem(item);
                handleClickOpen();
              },
              style: {
                cursor: "pointer"
              },
              children: [(0, _jsxRuntime.jsx)("img", {
                src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(item.imageSrc, 800, 500),
                alt: item.title
              }), (0, _jsxRuntime.jsx)(_material.ImageListItemBar, {
                title: item.title,
                subtitle: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'subtitle1',
                  style: {
                    color: "white",
                    fontWeight: 400
                  },
                  children: item.subtitle
                })
              })]
            }, index);
          })
        }), (0, _jsxRuntime.jsxs)(_material.Dialog, {
          onClick: () => handleClose(),
          onClose: handleClose,
          "aria-labelledby": "simple-dialog-title",
          open: open,
          children: [(0, _jsxRuntime.jsx)("img", {
            src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.imageSrc, 480, 480),
            alt: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.title
          }), (0, _jsxRuntime.jsxs)(_material.DialogTitle, {
            id: "simple-dialog-title",
            style: {
              backgroundColor: 'black'
            },
            children: [(0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'body2',
              style: {
                color: "white"
              },
              gutterBottom: true,
              children: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.title
            }), (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'subtitle1',
              style: {
                color: "white",
                fontWeight: 400
              },
              children: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.subtitle
            })]
          })]
        })]
      })]
    })]
  });
};
var _default = exports.default = TheDrinkeryAlbumSection;