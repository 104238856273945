"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _react = require("react");
var _AddBallModal = _interopRequireDefault(require("../modal-add-ball/AddBallModal"));
var _FirebaseContext = _interopRequireDefault(require("../../../../common/firebase/firebase-context/FirebaseContext"));
var _PageContext = _interopRequireDefault(require("../../../page-context/PageContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const BallFormSteps = props => {
  const [isModalOpen, setIsModalOpen] = (0, _react.useState)(false);
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const buttonText = "Add a new Ball";
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_material.Fab, {
      "data-testid": 'add-ball-steps-button',
      style: {
        position: 'fixed',
        bottom: '32px',
        right: '32px',
        width: '200px',
        height: '40px',
        borderRadius: '3px'
      },
      onClick: () => {
        setIsModalOpen(state => !state);
        firebaseContext.ctaClick && firebaseContext.ctaClick('ball-form-steps', buttonText, pageContext.analyticsId);
      },
      color: 'primary',
      children: (0, _jsxRuntime.jsx)(_material.Typography, {
        noWrap: true,
        children: buttonText
      })
    }), (0, _jsxRuntime.jsx)(_AddBallModal.default, {
      open: isModalOpen,
      ballToAdd: props.ballToAdd ? _objectSpread(_objectSpread({}, props.ballToAdd), {}, {
        fileUploaded: props.ballFlyerFile
      }) : {
        categories: []
      }
    })]
  });
};
var _default = exports.default = BallFormSteps;