"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.STEP_BALL_FORM_VALIDATION_STATUS_CODES = void 0;
var STEP_BALL_FORM_VALIDATION_STATUS_CODES;
(function (STEP_BALL_FORM_VALIDATION_STATUS_CODES) {
  STEP_BALL_FORM_VALIDATION_STATUS_CODES[STEP_BALL_FORM_VALIDATION_STATUS_CODES["BALL_TITLE_TOO_SHORT"] = 410] = "BALL_TITLE_TOO_SHORT";
  STEP_BALL_FORM_VALIDATION_STATUS_CODES[STEP_BALL_FORM_VALIDATION_STATUS_CODES["BALL_HOST_TOO_SHORT"] = 420] = "BALL_HOST_TOO_SHORT";
  STEP_BALL_FORM_VALIDATION_STATUS_CODES[STEP_BALL_FORM_VALIDATION_STATUS_CODES["VALID"] = 200] = "VALID";
  STEP_BALL_FORM_VALIDATION_STATUS_CODES[STEP_BALL_FORM_VALIDATION_STATUS_CODES["URL_INVALID"] = 430] = "URL_INVALID";
  STEP_BALL_FORM_VALIDATION_STATUS_CODES[STEP_BALL_FORM_VALIDATION_STATUS_CODES["END_DATE_BEFORE_START_DATE"] = 440] = "END_DATE_BEFORE_START_DATE";
  STEP_BALL_FORM_VALIDATION_STATUS_CODES[STEP_BALL_FORM_VALIDATION_STATUS_CODES["BALL_DESCRIPTION_TOO_SHORT"] = 450] = "BALL_DESCRIPTION_TOO_SHORT";
})(STEP_BALL_FORM_VALIDATION_STATUS_CODES || (exports.STEP_BALL_FORM_VALIDATION_STATUS_CODES = STEP_BALL_FORM_VALIDATION_STATUS_CODES = {}));
var _default = exports.default = STEP_BALL_FORM_VALIDATION_STATUS_CODES;