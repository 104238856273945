"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/es.symbol.description.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _ColorPalette = require("../../theme/common/ColorPalette");
var _api = require("@react-google-maps/api");
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  markerClass: {
    paddingTop: "70px"
  },
  grayscale: {
    // -webkit-filter: grayscale(100%);
    // -moz-filter: grayscale(100%);
    // -ms-filter: grayscale(100%);
    // -o-filter: grayscale(100%);
    filter: 'grayscale(100%)'
    // filter: url(grayscale.svg); /* Firefox 4+ */
    // filter: gray; /* IE 6-9 */
  }
}));
const containerStyle = {
  width: '100vw',
  height: '750px'
};
const center = {
  lat: 39.332029,
  lng: -76.760486
};
const MapSection = props => {
  var _process$env$REACT_AP, _props$sectionData, _props$sectionData$ma, _props$sectionData2, _props$sectionData3;
  const classes = useStyles();
  const theme = (0, _material.useTheme)();
  const mdDown = (0, _material.useMediaQuery)(theme.breakpoints.down('md'));
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const {
    isLoaded
  } = (0, _api.useJsApiLoader)({
    id: 'google-map-script',
    googleMapsApiKey: (_process$env$REACT_AP = process.env.REACT_APP_GOOGLE_MAPS_APIKEY) !== null && _process$env$REACT_AP !== void 0 ? _process$env$REACT_AP : ""
  });
  const getIcon = iconName => {
    const iconSize = 'medium';
    const color = 'secondary';
    switch (iconName) {
      case "email":
        return (0, _jsxRuntime.jsx)(_iconsMaterial.Email, {
          color: color,
          fontSize: iconSize
        });
      case "phone":
        return (0, _jsxRuntime.jsx)(_iconsMaterial.Phone, {
          color: color,
          fontSize: iconSize
        });
      case "address":
      default:
        return (0, _jsxRuntime.jsx)(_iconsMaterial.LocationOn, {
          color: color,
          fontSize: iconSize
        });
    }
  };
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      className: classes.grayscale,
      children: isLoaded ? (0, _jsxRuntime.jsx)(_api.GoogleMap, {
        mapContainerStyle: containerStyle,
        center: center,
        zoom: 11,
        children: (0, _jsxRuntime.jsx)(_api.Marker, {
          title: (_props$sectionData = props.sectionData) === null || _props$sectionData === void 0 ? void 0 : _props$sectionData.mapMarkerTitle,
          position: {
            lat: center.lat,
            lng: center.lng
          },
          label: {
            text: (_props$sectionData$ma = (_props$sectionData2 = props.sectionData) === null || _props$sectionData2 === void 0 ? void 0 : _props$sectionData2.mapMarkerTitle) !== null && _props$sectionData$ma !== void 0 ? _props$sectionData$ma : " ",
            className: classes.markerClass,
            fontSize: "18px"
          }
        })
      }) : (0, _jsxRuntime.jsx)(_material.CircularProgress, {})
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      style: {
        position: "relative"
      },
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        sx: {
          position: mdDown ? "relative" : "absolute",
          top: mdDown ? 0 : -66,
          paddingLeft: mdDown ? 0 : "32px",
          paddingRight: mdDown ? 0 : "32px"
        },
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          sx: {
            zIndex: 2,
            backgroundColor: _ColorPalette.COLORS.TRANSPARENT_DARKBLUE,
            border: "1px solid white",
            padding: theme.spacing(4, 4, 2, 4)
          },
          justifyContent: 'space-between',
          children: (_props$sectionData3 = props.sectionData) === null || _props$sectionData3 === void 0 ? void 0 : _props$sectionData3.contactInfo.map((detail, index) => {
            var _sanityContext$urlFor, _detail$imageSrc;
            return (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              xs: 12,
              md: 3,
              style: {
                marginBottom: "16px"
              },
              justifyContent: 'center',
              children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                item: true,
                container: true,
                alignItems: 'center',
                maxWidth: 380,
                minWidth: 290,
                justifyContent: 'center',
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  xs: 12,
                  md: 3,
                  style: {
                    marginRight: mdDown ? 0 : "8px",
                    marginBottom: mdDown ? 0 : "4px"
                  },
                  container: true,
                  justifyContent: 'center',
                  children: (0, _jsxRuntime.jsx)(_material.Card, {
                    elevation: 0,
                    style: {
                      width: "60px",
                      height: "60px",
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: "50%",
                      border: "3px solid ".concat(theme.palette.secondary.main)
                    },
                    children: (0, _jsxRuntime.jsx)(_material.Grid, {
                      container: true,
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      style: {
                        height: "100%"
                      },
                      children: (0, _jsxRuntime.jsx)(_material.Grid, {
                        item: true,
                        style: {
                          paddingTop: "6px"
                        },
                        children: detail.imageSrc ? (0, _jsxRuntime.jsx)("img", {
                          width: 24,
                          src: (_sanityContext$urlFor = sanityContext.urlFor((_detail$imageSrc = detail.imageSrc) !== null && _detail$imageSrc !== void 0 ? _detail$imageSrc : "").url()) !== null && _sanityContext$urlFor !== void 0 ? _sanityContext$urlFor : ""
                        }) : getIcon(detail.muiIcon)
                      })
                    })
                  })
                }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                  item: true,
                  maxWidth: 260,
                  children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      variant: 'body1',
                      color: 'textSecondary',
                      noWrap: true,
                      align: mdDown ? "center" : "left",
                      children: detail.name
                    })
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      fontWeight: 'bold',
                      fontSize: '16px',
                      variant: 'body2',
                      color: 'textSecondary',
                      align: mdDown ? "center" : "left",
                      children: detail.description
                    })
                  })]
                })]
              })
            }, index);
          })
        })
      })
    })]
  });
};
var _default = exports.default = MapSection;