"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _FooterMenuContainer = _interopRequireDefault(require("./FooterMenuContainer"));
var _ColorPalette = require("../../../../theme/common/ColorPalette");
var _Styles = _interopRequireDefault(require("../pages/Styles"));
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  root: {
    // paddingTop: "32px",
    // backgroundColor: COLORS.DARK_GRAY,
    // color: '#FDF3EB',
    // marginLeft: -1 * theme.spacing(1),
    zIndex: 1000,
    '& .MuiFormLabel-root': {
      color: 'white'
    }
  },
  emailContainer: {
    height: '72px'
  },
  columnHeader: {
    fontWeight: 500,
    color: '#FDF3EB',
    marginBottom: '16px'
  },
  footerLink: {
    marginBottom: '8px'
  },
  newsletterForm: {
    maxWidth: '370px'
  },
  emailInputProps: {
    borderColor: '#FDF3EB',
    color: '#FDF3EB !important'
  }
}));
const Footer = props => {
  var _sanityContext$urlFor, _props$backgroundImgS, _props$topPadding, _props$pageFooter;
  const classes = useStyles();
  const globalClasses = (0, _Styles.default)({});
  const theme = (0, _material.useTheme)();
  const mdDown = (0, _material.useMediaQuery)(theme.breakpoints.down('md'));
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    sx: {
      zIndex: 1,
      backgroundColor: props.backgroundColor ? (0, _ColorPalette.convertToHexCode)(props.backgroundColor) : _ColorPalette.COLORS.LIGHTGRAY,
      position: "relative"
    },
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      className: classes.root,
      sx: {
        backgroundImage: "url(".concat((_sanityContext$urlFor = sanityContext.urlFor((_props$backgroundImgS = props.backgroundImgSrc) !== null && _props$backgroundImgS !== void 0 ? _props$backgroundImgS : "")) === null || _sanityContext$urlFor === void 0 ? void 0 : _sanityContext$urlFor.url(), ")"),
        backgroundSize: "cover"
      },
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        justifyContent: "flex-start",
        sx: {
          paddingTop: mdDown ? 0 : (_props$topPadding = props.topPadding) !== null && _props$topPadding !== void 0 ? _props$topPadding : "0px"
        },
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 12,
          children: props.pageFooter && (0, _jsxRuntime.jsx)(_FooterMenuContainer.default, {
            backgroundColor: props.backgroundColor,
            isSocialMediaBlock: props.isSocialMediaBlock,
            pageFooterMenu: props.pageFooter,
            updateIsLoading: props.updateIsLoading
          })
        })
      })
    }), !((_props$pageFooter = props.pageFooter) !== null && _props$pageFooter !== void 0 && _props$pageFooter.isHideOverlay) ? (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      className: globalClasses.fullSectionOverlay
    }) : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})]
  });
};
var _default = exports.default = Footer;