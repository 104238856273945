"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _styles = require("@mui/material/styles");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _ThwServiceItem = _interopRequireDefault(require("./ThwServiceItem"));
var _TransformHWTheme = _interopRequireDefault(require("../../../theme/TransformHWTheme"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  root: {
    padding: _TransformHWTheme.default.spacing(6),
    [_TransformHWTheme.default.breakpoints.down('md')]: {
      padding: _TransformHWTheme.default.spacing(5, 2, 5)
    },
    minHeight: 'max-content',
    backgroundColor: '#f6f6f6'
  },
  contentBottom: {
    border: "1px solid ".concat(_TransformHWTheme.default.palette.secondary.main),
    padding: '20px'
  }
}));
const ThwServicesSection = props => {
  var _props$sectionData, _props$sectionData$se;
  const classes = useStyles();
  return (0, _jsxRuntime.jsx)(_styles.ThemeProvider, {
    theme: _TransformHWTheme.default,
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      className: classes.root,
      xs: 12,
      direction: 'column',
      spacing: 2,
      alignItems: 'center',
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            style: {
              fontStyle: "italic"
            },
            children: props.sectionData.contentPreTitle
          })
        }), (0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          container: true,
          wrap: 'nowrap',
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'secondary',
              variant: 'h4',
              align: 'center',
              display: 'inline',
              children: props.sectionData.contentTitle
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'h4',
              color: 'secondary',
              display: 'inline',
              style: {
                letterSpacing: "-.25em"
              },
              children: "____"
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        children: (_props$sectionData = props.sectionData) === null || _props$sectionData === void 0 || (_props$sectionData = _props$sectionData.contentTexts) === null || _props$sectionData === void 0 ? void 0 : _props$sectionData.map((segment, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            gutterBottom: true,
            children: segment
          })
        }, index))
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        spacing: 4,
        justifyContent: 'center',
        alignItems: "stretch",
        children: (_props$sectionData$se = props.sectionData.servicesList) === null || _props$sectionData$se === void 0 ? void 0 : _props$sectionData$se.map((service, index) => {
          return (0, _jsxRuntime.jsx)(_ThwServiceItem.default, {
            showAmenities: true,
            service: service
          }, index);
        })
      })]
    })
  });
};
var _default = exports.default = ThwServicesSection;