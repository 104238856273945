"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _FlashCard = _interopRequireDefault(require("./flash-card/FlashCard"));
var _SearchBox = _interopRequireDefault(require("./search-box/SearchBox"));
var _FlashCardNav = _interopRequireDefault(require("./FlashCardNav"));
var _CocktailDbResults = _interopRequireDefault(require("./CocktailDbResults"));
var _FiltersMenu = _interopRequireDefault(require("./FiltersMenu"));
var _material = require("@mui/material");
var _SearchProvider = _interopRequireDefault(require("./search-context/SearchProvider"));
var _DrinkCard = _interopRequireDefault(require("./flash-card/DrinkCard"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const FlashCardsContentSection = props => {
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsx)(_SearchProvider.default, {
    children: (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      minWidth: 380,
      item: true,
      sx: {
        backgroundColor: props.sectionData.isDarkMode ? "black" : "white",
        padding: theme.spacing(2)
      },
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        alignItems: 'center',
        alignContent: 'center',
        sx: {
          border: "3px solid white",
          overflow: 'hidden'
        },
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          justifyContent: 'center',
          alignContent: 'flex-start',
          color: 'white',
          sx: {
            borderBottom: "3px solid white"
            // padding: theme.spacing(1, 2)
          },
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            align: 'center',
            sx: {
              color: props.sectionData.isDarkMode ? "#dddddd" : theme.palette.text.primary
            },
            children: props.sectionData.title
          })
        }), (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            xs: 2,
            justifyContent: 'flex-end',
            children: (0, _jsxRuntime.jsx)(_FiltersMenu.default, {
              anchor: 'bottom'
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            xs: 10,
            children: (0, _jsxRuntime.jsx)(_SearchBox.default, {
              isDarkMode: props.sectionData.isDarkMode
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          spacing: 1,
          justifyContent: 'center',
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            sx: {
              maxWidth: 508
            },
            children: (0, _jsxRuntime.jsx)(_FlashCardNav.default, {
              isDarkMode: props.sectionData.isDarkMode,
              children: props.sectionData.isFlashCard ? (0, _jsxRuntime.jsx)(_FlashCard.default, {}) : (0, _jsxRuntime.jsx)(_DrinkCard.default, {
                isDarkMode: props.sectionData.isDarkMode
              })
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            xs: 12,
            style: {
              padding: theme.spacing(2.5)
            },
            children: (0, _jsxRuntime.jsx)(_CocktailDbResults.default, {})
          })]
        })]
      })
    })
  });
};
var _default = exports.default = FlashCardsContentSection;