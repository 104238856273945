"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _reactCountdown = _interopRequireDefault(require("react-countdown"));
var _ColorPalette = require("../../../../../theme/common/ColorPalette");
var _CustomizedThemeContext = _interopRequireDefault(require("../../../../customized-theme-provider/CustomizedThemeContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  counterSection: {
    height: "155px",
    width: "155px",
    borderRadius: "85px",
    backgroundColor: _ColorPalette.COLORS.TRANSPARENTWHITE,
    border: "4px solid ".concat(theme.palette.primary.main)
  }
}));
const CountdownToLaunch = props => {
  const customizedThemeContext = (0, _react.useContext)(_CustomizedThemeContext.default);
  const classes = useStyles(customizedThemeContext.customizedTheme);
  const Completionist = () => (0, _jsxRuntime.jsx)("span", {
    children: "Congratulations! If your site is not already here please contact hello@thehandsomestnerd.com launched!"
  });
  const smDown = (0, _material.useMediaQuery)(customizedThemeContext.customizedTheme.breakpoints.down('lg'));
  const pluralize = subject => {
    return subject + 's';
  };
  const CounterNumberRender = props => {
    return (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      alignItems: 'center',
      justifyContent: 'center',
      direction: 'column',
      className: classes.counterSection,
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'h1',
          color: 'primary',
          style: {
            textTransform: "uppercase"
          },
          children: props.value
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'h6',
          style: {
            textTransform: "uppercase"
          },
          children: props.value !== 1 ? pluralize(props.units) : props.units
        })
      })]
    });
  };
  const renderer = date => {
    if (date.completed) {
      // Render a complete state
      return (0, _jsxRuntime.jsx)(Completionist, {});
    } else {
      // Render a countdown
      return (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        spacing: smDown ? 1 : 6,
        justifyContent: 'center',
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 6,
          md: 3,
          container: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(CounterNumberRender, {
            value: date.days,
            units: 'day'
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 6,
          md: 3,
          container: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(CounterNumberRender, {
            value: date.hours,
            units: 'hour'
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 6,
          md: 3,
          container: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(CounterNumberRender, {
            value: date.minutes,
            units: 'minute'
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 6,
          md: 3,
          container: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(CounterNumberRender, {
            value: date.seconds,
            units: 'second'
          })
        })]
      });
    }
  };
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    item: true,
    container: true,
    justifyContent: 'center',
    children: (0, _jsxRuntime.jsx)(_reactCountdown.default, {
      date: props.launchDate,
      renderer: renderer
    })
  });
};
var _default = exports.default = CountdownToLaunch;