"use strict";

require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/es.error.cause.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
var _ColorPalette = require("../../../theme/common/ColorPalette");
var _FirebaseContext = _interopRequireDefault(require("../../../common/firebase/firebase-context/FirebaseContext"));
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  marketingBackground: props => ({
    backgroundRepeat: 'no-repeat',
    backgroundImage: "url('".concat(props.heroBaseImageUrl, "'), url('").concat(props.heroOverlay, "')"),
    backgroundSize: 'cover, contain',
    maxHeight: '321px',
    backgroundColor: _ColorPalette.COLORS.DARKGRAY,
    position: "relative"
  }),
  contentSection: {
    height: '310px',
    marginTop: '16px',
    backgroundColor: 'transparent'
  },
  contentBullets: {
    borderLeft: "4px solid ".concat(theme.palette.primary.main),
    paddingLeft: '26px'
  }
}));
const MMHeroContentSection = props => {
  var _sanityContext$urlFor, _props$sectionData$ct;
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  let classParameters = {
    heroBaseImageUrl: (_sanityContext$urlFor = sanityContext.urlFor(props.sectionData.heroImage).url()) !== null && _sanityContext$urlFor !== void 0 ? _sanityContext$urlFor : ''
  };
  if (props.sectionData.heroImageBackground) {
    classParameters = _objectSpread(_objectSpread({}, classParameters), {}, {
      heroOverlay: sanityContext.urlFor(props.sectionData.heroImageBackground).url()
    });
  }
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const classes = useStyles(classParameters);
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    className: classes.marketingBackground,
    children: (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      direction: 'column',
      style: {
        zIndex: 2
      },
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          className: classes.contentSection,
          item: true,
          xs: 11,
          sm: 9,
          md: 6,
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            direction: 'column',
            style: {
              paddingLeft: "40px",
              paddingTop: "80px"
            },
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              style: {
                marginBottom: "30px"
              },
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'h1',
                color: 'primary',
                style: {
                  fontFamily: "Oswald"
                },
                children: props.sectionData.contentTitle
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Button, {
                color: 'primary',
                variant: 'text',
                onClick: () => {
                  firebaseContext.ctaClick && firebaseContext.ctaClick("hero-section", props.sectionData.ctaButtonTitle, pageContext.analyticsId);
                },
                href: (_props$sectionData$ct = props.sectionData.ctaButtonLink) !== null && _props$sectionData$ct !== void 0 ? _props$sectionData$ct : "",
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'button',
                  color: 'secondary',
                  children: props.sectionData.ctaButtonTitle
                })
              })
            })]
          })
        })
      })
    })
  });
};
var _default = exports.default = MMHeroContentSection;