"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.promise.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.string.search.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _SearchContext = _interopRequireDefault(require("./SearchContext"));
var _reactRouterDom = require("react-router-dom");
var _ExternalCocktailClient = _interopRequireDefault(require("../search-box/ExternalCocktailClient"));
var _FirebaseContext = _interopRequireDefault(require("../../../../common/firebase/firebase-context/FirebaseContext"));
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const initialState = {
  cocktails: [],
  allCocktails: [],
  additionalResults: [],
  loading: true,
  searchString: "",
  cardCounter: 0,
  isFlipped: false,
  currentCard: undefined,
  searchFilters: [],
  ingredientFilters: [],
  isAndSearch: false
};
var BOOLEAN_FILTERS;
(function (BOOLEAN_FILTERS) {
  BOOLEAN_FILTERS["IS_NON_ALCOHOLIC"] = "non-alcholic";
})(BOOLEAN_FILTERS || (BOOLEAN_FILTERS = {}));
const SearchProvider = props => {
  // useEffect(() => {
  //     if(props.allCocktails){
  //         dispatch({type:'INITIAL', payload: {allCocktails: props.allCocktails, searchString: props.searchString}})
  //     }
  //     }, [props.allCocktails, props.searchString])
  const reducer = (state, action) => {
    var _action$payload$searc, _state$cardCounter2, _state$cocktails$leng2, _state$cocktails2;
    switch (action.type) {
      case 'INITIAL':
        return _objectSpread(_objectSpread({}, initialState), {}, {
          allCocktails: [...action.payload.allCocktails],
          cocktails: [...action.payload.allCocktails],
          currentCard: action.payload.allCocktails[0],
          searchString: action.payload.searchString,
          cardCounter: 0,
          loading: false
        });
      case 'RESET_SEARCH_RESULTS':
        return _objectSpread(_objectSpread({}, state), {}, {
          searchString: "",
          loading: false,
          cocktails: state.allCocktails ? state.allCocktails : [],
          additionalResults: [],
          currentCard: state.allCocktails ? state.allCocktails[0] : undefined
        });
      case 'UPDATE_SEARCH_RESULTS':
        return _objectSpread(_objectSpread({}, state), {}, {
          cocktails: [...action.payload.cocktails],
          loading: false,
          additionalResults: action.payload.additionalResults ? action.payload.additionalResults : state.additionalResults,
          cardCount: 0,
          currentCard: action.payload.cocktails[0],
          searchString: (_action$payload$searc = action.payload.searchString) !== null && _action$payload$searc !== void 0 ? _action$payload$searc : state.searchString
        });
      case 'ADD_SEARCH_FILTER':
        return _objectSpread(_objectSpread({}, state), {}, {
          searchFilters: state.searchFilters.concat(action.payload.searchFilterToAdd),
          isNonAlcoholic: action.payload.isNonAlcoholic ? action.payload.isNonAlcoholic : undefined
        });
      case 'REMOVE_SEARCH_FILTER':
        return _objectSpread(_objectSpread({}, state), {}, {
          searchFilters: state.searchFilters.filter(searchFilter => {
            if (searchFilter === action.payload.filterToRemove) return false;
            return true;
          }),
          isNonAlcoholic: action.payload.isNonAlcoholic ? action.payload.isNonAlcoholic : undefined
        });
      case 'ADD_INGREDIENT_FILTER':
        return _objectSpread(_objectSpread({}, state), {}, {
          ingredientFilters: state.ingredientFilters.concat(action.payload.ingredientFilterToAdd)
        });
      case 'REMOVE_INGREDIENT_FILTER':
        return _objectSpread(_objectSpread({}, state), {}, {
          ingredientFilters: state.ingredientFilters.filter(ingredientFilter => {
            if (ingredientFilter === action.payload.filterToRemove) return false;
            return true;
          })
        });
      case 'HANDLE_AND_SEARCH_CHANGE':
        return _objectSpread(_objectSpread({}, state), {}, {
          isAndSearch: action.payload.checked
        });
      case 'FLIP_CARD':
        return _objectSpread(_objectSpread({}, state), {}, {
          isFlipped: !state.isFlipped
        });
      case 'PREV_CARD':
        let updatedCount = state.cardCounter;
        if (updatedCount === 0) {
          var _state$cocktails$leng, _state$cocktails;
          updatedCount = ((_state$cocktails$leng = (_state$cocktails = state.cocktails) === null || _state$cocktails === void 0 ? void 0 : _state$cocktails.length) !== null && _state$cocktails$leng !== void 0 ? _state$cocktails$leng : 1) - 1;
        } else {
          var _state$cardCounter;
          updatedCount = ((_state$cardCounter = state.cardCounter) !== null && _state$cardCounter !== void 0 ? _state$cardCounter : 1) - 1;
        }
        const currentCard = state.cocktails && state.cocktails[updatedCount];
        return _objectSpread(_objectSpread({}, state), {}, {
          cardCounter: updatedCount,
          currentCard
        });
      case 'NEXT_CARD':
        let updatedNextCount = (_state$cardCounter2 = state.cardCounter) !== null && _state$cardCounter2 !== void 0 ? _state$cardCounter2 : 0;
        if (updatedNextCount >= ((_state$cocktails$leng2 = (_state$cocktails2 = state.cocktails) === null || _state$cocktails2 === void 0 ? void 0 : _state$cocktails2.length) !== null && _state$cocktails$leng2 !== void 0 ? _state$cocktails$leng2 : 1) - 1) {
          updatedNextCount = 0;
        } else {
          var _state$cardCounter3;
          updatedNextCount = ((_state$cardCounter3 = state.cardCounter) !== null && _state$cardCounter3 !== void 0 ? _state$cardCounter3 : 0) + 1;
        }
        const nextCard = state.cocktails && state.cocktails[updatedNextCount];
        return _objectSpread(_objectSpread({}, state), {}, {
          cardCounter: updatedNextCount,
          currentCard: nextCard
        });
      default:
        console.log("Action error:", action);
        throw new Error();
    }
  };
  const [state, dispatch] = (0, _react.useReducer)(reducer, initialState);
  const getExternalResults = async terms => {
    if (terms === "") {
      return state.allCocktails;
    }
    const tokenizedTerms = terms.split(" ");
    const allSearchResults = tokenizedTerms.map(term => {
      return _ExternalCocktailClient.default.search(term);
    });
    return Promise.all(allSearchResults).then(allResults => {
      const flatResults = [].concat(...allResults);
      return flatResults;
    });
  };
  const dedupArr = arr => {
    return arr.filter((c, index) => {
      return arr.indexOf(c) === index;
    });
  };
  const searchAppCocktails = async terms => {
    var _state$allCocktails;
    if (terms === "") {
      // console.log(" all the cocktails", state.allCocktails)
      return state.allCocktails;
    }
    const tokenizedTerms = terms.split(" ");
    const inAppCocktails = (_state$allCocktails = state.allCocktails) === null || _state$allCocktails === void 0 ? void 0 : _state$allCocktails.reduce((accumulated, cocktail) => {
      const partialResults = tokenizedTerms.reduce((accumulatedIn, term) => {
        if (cocktail.title.toLowerCase().includes(term.toLowerCase())) {
          accumulatedIn.push(cocktail);
        }
        return accumulatedIn;
      }, []);
      if (partialResults) {
        accumulated.push(...partialResults);
      }
      return dedupArr(accumulated);
    }, []);
    return inAppCocktails;
  };
  // const {
  //     data,
  //     isLoading,
  //     refetch
  // } = useQuery(
  //     ["search-cocktails-by-criteria", state.searchString, state.searchFilters],
  //     apiClient.getProduct
  // );
  //
  //
  //
  //
  // useEffect(() => {
  //     console.log("Fetched searched and filtered cocktails", data, state)
  //     if (data) {
  //         dispatch({type: "INITIAL", payload: {allCocktails: data}})
  //     }
  // }, [data])
  const submitSearch = async terms => {
    if (terms && terms.length > 0) {
      const appResults = await searchAppCocktails(terms);
      const externalResults = await getExternalResults(terms);
      // console.log(externalResults)
      dispatch({
        type: "UPDATE_SEARCH_RESULTS",
        payload: {
          cocktails: appResults,
          additionalResults: externalResults && externalResults[0].drinks,
          searchString: terms
        }
      });
    } else if (!terms || terms && terms.length === 0) {
      dispatch({
        type: "RESET_SEARCH_RESULTS"
      });
    }
  };
  // const getUniqueTagsFromResults = (searchResults: SanityCocktailType[]) => {
  //     const tagCategories = searchResults.reduce((accumulated: string[], productResult: SanityCocktailType) => {
  //         console.log(productResult)
  //         const newTags = accumulated
  //
  //         // TODO: Add tags to Sanity cocktail schema
  //         // productResult.tags?.forEach((tag:string) => {
  //         //     newTags.push(tag)
  //         // })
  //         return newTags
  //     }, [])
  //
  //     //de-dup the genres
  //     const uniqueGenres: Set<string> = new Set(tagCategories)
  //
  //     return uniqueGenres
  // }
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  //update the search when the user changes the string
  (0, _react.useEffect)(() => {
    var _state$searchFilters, _state$ingredientFilt;
    if (sanityContext.getMyProduct) sanityContext.getMyProduct(state.searchString, (_state$searchFilters = state.searchFilters) !== null && _state$searchFilters !== void 0 ? _state$searchFilters : [], (_state$ingredientFilt = state.ingredientFilters) !== null && _state$ingredientFilt !== void 0 ? _state$ingredientFilt : [], state.isAndSearch).then(results => {
      dispatch({
        type: "UPDATE_SEARCH_RESULTS",
        payload: {
          cocktails: results
        }
      });
    });
  }, [state.searchString, state.searchFilters, state.ingredientFilters, state.isAndSearch]);
  const location = (0, _reactRouterDom.useLocation)();
  const addFilter = newFilter => {
    var _state$searchFilters2;
    // if it's non alcoholic
    let isNonAlcoholic = false;
    if (newFilter === BOOLEAN_FILTERS.IS_NON_ALCOHOLIC) {
      isNonAlcoholic = true;
    }
    if (!((_state$searchFilters2 = state.searchFilters) !== null && _state$searchFilters2 !== void 0 && _state$searchFilters2.includes(newFilter))) dispatch({
      type: "ADD_SEARCH_FILTER",
      payload: {
        searchFilterToAdd: newFilter,
        isNonAlcoholic: isNonAlcoholic
      }
    });
  };
  const removeFilter = filterToRemove => {
    let isNonAlcoholic = false;
    if (filterToRemove === BOOLEAN_FILTERS.IS_NON_ALCOHOLIC) {
      isNonAlcoholic = false;
    }
    if (filterToRemove) dispatch({
      type: "REMOVE_SEARCH_FILTER",
      payload: {
        filterToRemove: filterToRemove,
        isNonAlcoholic: isNonAlcoholic
      }
    });
  };
  const addIngredientFilter = newFilter => {
    var _state$ingredientFilt2;
    if (!((_state$ingredientFilt2 = state.ingredientFilters) !== null && _state$ingredientFilt2 !== void 0 && _state$ingredientFilt2.includes(newFilter))) dispatch({
      type: "ADD_INGREDIENT_FILTER",
      payload: {
        ingredientFilterToAdd: newFilter
      }
    });
  };
  const removeIngredientFilter = filterToRemove => {
    if (filterToRemove) dispatch({
      type: "REMOVE_INGREDIENT_FILTER",
      payload: {
        filterToRemove: filterToRemove
      }
    });
  };
  // const [filteredCocktails, setFilteredCocktails] = useState<SanityCocktailType[] | undefined>([])
  // const newlyFilteredResults = apiClient.useFetchFilteredCocktails()
  // useEffect(() => {
  //     if (newlyFilteredResults.data) {
  //
  //         const remainingCocktails = state.cocktails?.filter((cocktail: SanityCocktailType) => {
  //             const foundCocktailInFiltered = newlyFilteredResults.data.find((filteredResult: SanityCocktailType) => {
  //                 if (filteredResult._id === cocktail._id) {
  //                     return true
  //                 }
  //                 return false
  //             })
  //
  //             if (foundCocktailInFiltered) return true
  //             return false
  //         })
  //
  //         if (remainingCocktails) {
  //             console.log("remaining", remainingCocktails)
  //             dispatch({type: 'FILTER_RESULTS', payload: {filteredCocktails: remainingCocktails}})
  //         } else {
  //             console.log("state.cocktails", state.cocktails)
  //             dispatch({type: 'FILTER_RESULTS', payload: {filteredCocktails: state.cocktails}})
  //         }
  //     }
  // }, [newlyFilteredResults.data])
  // useEffect(() => {
  //     console.log("Search filters changed", state.searchFilters)
  //     refetch()
  // }, [state.searchFilters])
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  (0, _react.useEffect)(() => {
    if (state.cardCounter && state.cocktails && state.cocktails[state.cardCounter]) state.cardCounter && firebaseContext.analyticsPageView && firebaseContext.analyticsPageView(location.pathname, location.search, "".concat(state.cocktails[state.cardCounter].title, " | Cocktail"));
  }, [state.cardCounter]);
  const nextCard = async () => {
    return dispatch({
      type: "NEXT_CARD"
    });
  };
  const prevCard = async () => {
    return dispatch({
      type: "PREV_CARD"
    });
  };
  const handleFlip = e => {
    e.preventDefault();
    dispatch({
      type: "FLIP_CARD"
    });
  };
  const isIngredientIncluded = ingredient => {
    var _state$ingredientFilt3;
    const foundIngredient = (_state$ingredientFilt3 = state.ingredientFilters) === null || _state$ingredientFilt3 === void 0 ? void 0 : _state$ingredientFilt3.find(currentIngredient => {
      if (currentIngredient === ingredient._id) {
        return true;
      }
      return false;
    });
    if (foundIngredient) {
      return true;
    }
    return false;
  };
  const isFilterIncluded = filter => {
    var _state$searchFilters3;
    const foundFilter = (_state$searchFilters3 = state.searchFilters) === null || _state$searchFilters3 === void 0 ? void 0 : _state$searchFilters3.find(currentFilter => {
      if (currentFilter === filter) {
        return true;
      }
      return false;
    });
    if (foundFilter) {
      return true;
    }
    return false;
  };
  const handleIsAndSearchChange = event => {
    dispatch({
      type: "HANDLE_AND_SEARCH_CHANGE",
      payload: {
        checked: event.target.checked
      }
    });
  };
  return (0, _jsxRuntime.jsx)(_SearchContext.default.Provider, {
    value: {
      searchString: state.searchString,
      searchResults: state.cocktails,
      additionalResults: state.additionalResults,
      tagFilters: state.tagFilters,
      ingredientFilters: state.ingredientFilters,
      isIngredientIncluded,
      isFlipped: state.isFlipped,
      isFilterIncluded,
      nextCard,
      prevCard,
      currentCard: state.currentCard,
      cardCounter: state.cardCounter,
      handleFlip,
      searchFilters: state.searchFilters,
      addFilter,
      removeFilter,
      isNonAlcoholic: state.isNonAlcoholic,
      loading: state.loading,
      submitSearch,
      addIngredientFilter,
      removeIngredientFilter,
      isAndSearch: state.isAndSearch,
      handleIsAndSearchChange
    },
    children: props.children
  });
};
var _default = exports.default = SearchProvider;