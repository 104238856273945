"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _CssFadeToColor = _interopRequireDefault(require("../css-fade-to-color/CssFadeToColor"));
var _ImageWithButtonOverlayAligmentEnum = require("./ImageWithButtonOverlayAligmentEnum");
var _PageContext = _interopRequireDefault(require("../page-context/PageContext"));
var _FirebaseContext = _interopRequireDefault(require("../../common/firebase/firebase-context/FirebaseContext"));
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  contentBullets: {
    marginBottom: "40px"
  }
}));
const ImageWIthButtonOverlay = props => {
  var _props$ctaButtonLink;
  const getButtonAlignment = () => {
    switch (props.buttonAlignment) {
      case _ImageWithButtonOverlayAligmentEnum.ImageWithButtonOverlayAligmentEnum.RIGHT:
        return 'flex-end';
      case _ImageWithButtonOverlayAligmentEnum.ImageWithButtonOverlayAligmentEnum.LEFT:
        return 'flex-start';
      case _ImageWithButtonOverlayAligmentEnum.ImageWithButtonOverlayAligmentEnum.CENTER:
      default:
        return 'center';
    }
  };
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const [displayImageUrl, setDisplayImageUrl] = (0, _react.useState)();
  (0, _react.useEffect)(() => {
    if (props.imageUrl) {
      setDisplayImageUrl(props.imageUrl);
    }
    if (props.imageSrc) {
      var _sanityContext$urlFor, _props$imageSrc;
      setDisplayImageUrl((_sanityContext$urlFor = sanityContext.urlFor((_props$imageSrc = props.imageSrc) !== null && _props$imageSrc !== void 0 ? _props$imageSrc : "").height(props.height).url()) !== null && _sanityContext$urlFor !== void 0 ? _sanityContext$urlFor : '');
    } else {
      var _props$placeholderWid;
      setDisplayImageUrl("https://placehold.co/".concat((_props$placeholderWid = props.placeholderWidth) !== null && _props$placeholderWid !== void 0 ? _props$placeholderWid : props.height, "x").concat(props.height));
    }
  }, []);
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  return (0, _jsxRuntime.jsx)(_material.Button, {
    fullWidth: true,
    onClick: () => {
      props.source && firebaseContext.ctaClick && firebaseContext.ctaClick(props.source, 'image-button', pageContext.analyticsId);
    },
    variant: 'text',
    href: props.learnMoreLink,
    style: {
      padding: 0
    },
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      container: true,
      direction: 'column',
      style: {
        position: "relative",
        cursor: "pointer"
      },
      children: [props.toColor && props.direction !== undefined && (0, _jsxRuntime.jsx)(_CssFadeToColor.default, {
        toColor: props.toColor,
        direction: props.direction,
        isResponsive: props.isResponsive
      }), props.tooltip ? (0, _jsxRuntime.jsx)(_material.Tooltip, {
        title: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'subtitle1',
          style: {
            fontWeight: "normal"
          },
          children: props.tooltip
        }),
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          style: {
            backgroundImage: "url(".concat(displayImageUrl, ")"),
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: props.height
          }
        })
      }) : (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        style: {
          backgroundImage: "url(".concat(displayImageUrl, ")"),
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: props.height
        }
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        style: {
          position: "absolute",
          bottom: 32,
          left: 0,
          paddingRight: "32px"
        },
        justifyContent: getButtonAlignment(),
        children: props.ctaButtonLink && (0, _jsxRuntime.jsx)(_material.Button, {
          onClick: () => {
            props.source && props.ctaButtonText && firebaseContext.ctaClick && firebaseContext.ctaClick(props.source, props.ctaButtonText, pageContext.analyticsId);
          },
          component: 'div',
          variant: props.variant ? props.variant : 'outlined',
          color: props.buttonColor ? props.buttonColor : 'primary',
          href: (_props$ctaButtonLink = props.ctaButtonLink) !== null && _props$ctaButtonLink !== void 0 ? _props$ctaButtonLink : '',
          style: {
            color: "#FAFAFA"
          },
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'button',
            color: 'secondary',
            children: props.ctaButtonText
          })
        })
      })]
    })
  });
};
var _default = exports.default = ImageWIthButtonOverlay;