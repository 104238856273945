"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.promise.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/web.url.js");
require("core-js/modules/web.url.to-json.js");
require("core-js/modules/web.url-search-params.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
const getPDFLink = async () => {
  return fetch("/get-resume-pdf", {
    method: 'POST'
  }).then(pdfDoc => {
    return getDownloadLink(pdfDoc);
  }).catch(e => {
    // console.error(LOG, 'ERROR', 'error', e);
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      attempt: Error(e)
    });
  });
};
const getDownloadLink = async pdfDoc => {
  return pdfDoc.blob().then(blob => {
    // Creating new object of PDF file
    const fileURL = window.URL.createObjectURL(blob);
    // console.log("returned from server", fileURL)
    // Setting various property values
    let alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = "SamplePDF.pdf";
    return fileURL;
    // alink.click();
  });
};
var _default = exports.default = {
  getPDFLink
};