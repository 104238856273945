"use strict";

require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.promise.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _AmenityContext = _interopRequireDefault(require("./AmenityContext"));
var _material = require("@mui/material");
var _uuid = require("uuid");
var _ToolTipWrap = _interopRequireDefault(require("../templates/transform-hw/ToolTipWrap"));
var _PageContext = _interopRequireDefault(require("../page-context/PageContext"));
var _ColoredPng = _interopRequireDefault(require("../colored-png/ColoredPng"));
var _SnackbarContext = _interopRequireDefault(require("../modal-context/SnackbarContext"));
var _FirebaseContext = _interopRequireDefault(require("../../common/firebase/firebase-context/FirebaseContext"));
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const initialState = {};
const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIAL':
      return initialState;
    case 'LOAD_SERVICE_ID':
      return _objectSpread(_objectSpread({}, state), {}, {
        serviceId: action.payload.serviceId
      });
    // case 'LOAD_AMENITIES':
    //     state.elements && delete state.elements
    //     return {
    //         ...state,
    //         currentAmenities: action.payload.currentAmenities,
    //         elements: undefined
    //     };
    // case 'LOAD_ELEMENTS':
    //     // state.currentAmenities && delete state.currentAmenities
    //     return {
    //         ...state,
    //         elements: action.payload.elements,
    //     };
    case 'ADD_ELEMENTS':
      // state.currentAmenities && delete state.currentAmenities
      return _objectSpread(_objectSpread({}, state), {}, {
        elementsObj: _objectSpread(_objectSpread({}, state.elementsObj), {}, {
          [action.payload.serviceId]: action.payload.elements
        })
      });
    case 'CLEAR_AMENITIES':
      state.currentAmenities && delete state.currentAmenities;
      state.elements && delete state.elements;
      state.elementsObj && delete state.elementsObj;
      return _objectSpread(_objectSpread({}, state), {}, {
        currentAmenities: undefined,
        elements: undefined,
        elementsObj: undefined,
        serviceId: action.payload.serviceId
      });
    default:
      throw new Error();
  }
};
const AmenityProvider = props => {
  var _pageContext$page2;
  const [state, dispatch] = (0, _react.useReducer)(reducer, initialState);
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  (0, _react.useEffect)(() => {
    var _pageContext$page;
    (_pageContext$page = pageContext.page) === null || _pageContext$page === void 0 || (_pageContext$page = _pageContext$page.servicesAvailable) === null || _pageContext$page === void 0 || _pageContext$page.map(service => {
      const serviceElements = generateAmenitiesElement(service.serviceAmenities, service.slug.current, service.contentTitle);
      // console.log("Generated amenties", serviceElements, service.slug.current)
      dispatch({
        type: "ADD_ELEMENTS",
        payload: {
          serviceId: service.slug.current,
          elements: serviceElements
        }
      });
    });
  }, [(_pageContext$page2 = pageContext.page) === null || _pageContext$page2 === void 0 ? void 0 : _pageContext$page2.servicesAvailable]);
  const generateAmenitiesElement = (amenities, _serviceSlug, serviceTitle) => {
    // console.log(" generate", serviceSlug, state.serviceId)
    // if(serviceSlug === state.serviceId)
    const newElements = amenities === null || amenities === void 0 ? void 0 : amenities.map(serviceAmenity => {
      return (0, _jsxRuntime.jsx)(_material.ListItem, {
        style: {
          cursor: 'pointer',
          height: "140px",
          width: "100px",
          backgroundColor: "whitesmoke",
          zIndex: 11
        },
        children: (0, _jsxRuntime.jsx)(_ToolTipWrap.default, {
          serviceTitle: serviceTitle,
          amenity: serviceAmenity,
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            direction: 'column',
            style: {
              marginBottom: "24px",
              width: "100%"
            },
            alignItems: 'center',
            alignContent: 'center',
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              justifyContent: 'center',
              children: (0, _jsxRuntime.jsx)(_material.ListItemIcon, {
                style: {
                  minHeight: "32px",
                  minWidth: "32px",
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundImage: "url(".concat(serviceAmenity.imageSrc ? sanityContext.urlFor(serviceAmenity.imageSrc).width(32).height(32).url() : "https://placehold.co/32x32", ")"),
                  backgroundRepeat: "no-repeat"
                }
              }, (0, _uuid.v4)())
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              justifyContent: 'center',
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'subtitle2',
                  children: serviceAmenity.title
                })
              })
            })]
          })
        })
      }, (0, _uuid.v4)());
    });
    return newElements;
  };
  const init = async slug => {
    await dispatch({
      type: "LOAD_SERVICE_ID",
      payload: {
        serviceId: slug
      }
    });
  };
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const getElements = slug => {
    var _ref;
    return (_ref = state.elementsObj && state.elementsObj[slug]) !== null && _ref !== void 0 ? _ref : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
  };
  const snackbarContext = (0, _react.useContext)(_SnackbarContext.default);
  const openSnackbar = (serviceTitle, amenity) => {
    pageContext.analyticsId && firebaseContext.amenityTooltipShown && firebaseContext.amenityTooltipShown(serviceTitle, amenity.title, pageContext.analyticsId);
    const snack = (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      style: {
        minWidth: "200px"
      },
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        xs: 12,
        justifyContent: 'flex-end',
        alignItems: 'center',
        spacing: 1,
        style: {
          marginBottom: "8px"
        },
        children: (0, _jsxRuntime.jsxs)(_material.Typography, {
          gutterBottom: true,
          variant: 'subtitle2',
          color: 'textSecondary',
          children: [serviceTitle, " Amenity"]
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        xs: 12,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'body2',
          color: 'textSecondary',
          gutterBottom: true,
          children: amenity.title
        })
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        spacing: 2,
        alignContent: 'center',
        alignItems: 'stretch',
        wrap: "nowrap",
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          style: {
            maxWidth: "72px"
          },
          item: true,
          xs: 2,
          container: true,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          children: (0, _jsxRuntime.jsx)(_ColoredPng.default, {
            size: 48,
            maskAsset: amenity.imageSrc,
            color: "white"
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          alignItems: 'center',
          alignContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            gutterBottom: true,
            variant: 'body1',
            color: 'textSecondary',
            style: {
              fontWeight: "normal"
            },
            children: amenity.description
          })
        })]
      })]
    });
    snackbarContext.openSnackbar && snackbarContext.openSnackbar(snack);
  };
  const newValue = (0, _react.useMemo)(() => ({
    getElements,
    serviceId: state.serviceId,
    init,
    openSnackbar
  }), [getElements, state.serviceId, init, openSnackbar]);
  return (0, _jsxRuntime.jsx)(_AmenityContext.default.Provider, {
    value: newValue,
    children: props.children
  });
};
var _default = exports.default = AmenityProvider;