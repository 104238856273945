"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.promise.js");
require("core-js/modules/es.promise.finally.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _styles = require("@mui/styles");
var _LiquorBarFilter = _interopRequireDefault(require("./LiquorBarFilter"));
var _iconsMaterial = require("@mui/icons-material");
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = (0, _styles.makeStyles)(() => ({
  drawer: {
    // "& .MuiDrawer-paper": {
    //     backgroundColor: "transparent"
    // }
  },
  listItem: {
    "&.MuiListItem-gutters": {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0
    }
  }
}));
const FiltersMenu = _ref => {
  let {
    anchor
  } = _ref;
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = (0, _react.useState)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const [isBarIngredientsLoading, setIsBarIngredientsLoading] = (0, _react.useState)(false);
  const [myBarIngredients, setMyBarIngredients] = (0, _react.useState)([]);
  const toggleDrawer = (_anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };
  const pageContext = (0, _react.useContext)(_PageContext.default);
  (0, _react.useEffect)(() => {
    setIsBarIngredientsLoading(true);
    sanityContext.fetchMyBarIngredients(pageContext.barInventorySlug).then(barIngredientResponse => {
      setMyBarIngredients(barIngredientResponse);
    }).catch(e => {
      console.log("ERROR loading bar ingredients: config - barInventorySlug: ".concat(pageContext.barInventorySlug), e);
    }).finally(() => {
      setIsBarIngredientsLoading(false);
    });
  }, []);
  const list = () => (0, _jsxRuntime.jsxs)(_material.Grid, {
    xs: 12,
    container: true,
    item: true,
    role: "presentation",
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'center',
      children: "Search the Bar"
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: (0, _jsxRuntime.jsxs)(_material.List, {
        style: {
          width: "100%"
        },
        children: [(0, _jsxRuntime.jsx)(_material.ListItem, {
          children: myBarIngredients && (0, _jsxRuntime.jsx)(_LiquorBarFilter.default, {
            entireBar: myBarIngredients
          })
        }), (0, _jsxRuntime.jsx)(_material.Divider, {})]
      })
    })]
  });
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    item: true,
    children: [(0, _jsxRuntime.jsx)(_material.Button, {
      onClick: toggleDrawer(anchor, true),
      children: (0, _jsxRuntime.jsx)(_iconsMaterial.FilterList, {})
    }), (0, _jsxRuntime.jsxs)(_material.Drawer, {
      className: classes.drawer,
      anchor: anchor,
      open: isDrawerOpen,
      onClose: toggleDrawer(anchor, false),
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        alignItems: 'center',
        justifyContent: 'space-between',
        style: {
          position: "absolute",
          zIndex: 1000,
          overflow: "hidden"
          // paddingLeft: theme.spacing(4),
          // paddingRight: theme.spacing(6),
        },
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 3
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Button, {
            onClick: () => {
              setIsDrawerOpen(false);
            },
            children: (0, _jsxRuntime.jsx)(_iconsMaterial.Close, {
              color: 'primary',
              fontSize: 'large'
            })
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        justifyContent: 'center',
        children: isBarIngredientsLoading ? (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.CircularProgress, {})
          })
        }) : list()
      })]
    })]
  });
};
var _default = exports.default = FiltersMenu;