"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
var _dateUtils = _interopRequireDefault(require("../../../../utils/dateUtils"));
var _ResumeSkillTooltipWrapper = _interopRequireDefault(require("../resume-skills-section/ResumeSkillTooltipWrapper"));
var _textProcessingUtils = _interopRequireDefault(require("../../../../utils/textProcessingUtils"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = (0, _makeStyles.default)(() => ({
  hover: {
    "&:hover": {
      backgroundColor: "whitesmoke"
    }
  },
  imageList: {
    maxWidth: "800px",
    minWidth: "300px"
  }
}));
const PortfolioItemModal = props => {
  var _props$currentItem, _props$currentItem2, _props$currentItem3, _textProcessingUtils$, _props$currentItem4, _props$currentItem5;
  const theme = (0, _material.useTheme)();
  const classes = useStyles();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const mdDown = (0, _material.useMediaQuery)(theme.breakpoints.down('md'));
  const xsDown = (0, _material.useMediaQuery)(theme.breakpoints.down('sm'));
  const [isOpen, setIsOpen] = (0, _react.useState)(false);
  const [isPhotoModalOpen, setIsPhotoModalOpen] = (0, _react.useState)(false);
  const setOpenWrapper = theValue => {
    setIsOpen(theValue);
    // props.setIsOpen(theValue)
  };
  (0, _react.useEffect)(() => {
    setIsOpen(!!props.isOpen);
  }, [props.isOpen]);
  const handleClickOpen = () => {
    setIsPhotoModalOpen(state => !state);
  };
  const handleClose = () => {
    setIsPhotoModalOpen(state => !state);
  };
  const [isTooltipOpen, setIsToolTipOpen] = (0, _react.useState)();
  const [selectedItem, setSelectedItem] = (0, _react.useState)();
  const [imageColumn, setImageColumn] = (0, _react.useState)();
  (0, _react.useEffect)(() => {
    if (xsDown) setImageColumn(1);else if (mdDown) setImageColumn(2);else setImageColumn(3);
  }, [mdDown, xsDown]);
  return (0, _jsxRuntime.jsx)(_material.Modal, {
    open: isOpen,
    sx: {
      paddingBottom: 4,
      overflow: "scroll"
    },
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      style: {
        position: "relative"
      },
      children: [(0, _jsxRuntime.jsx)(_material.IconButton, {
        className: classes.hover,
        style: {
          position: "absolute",
          top: 0,
          right: 0,
          width: "64px",
          height: "64px",
          backgroundColor: "white"
        },
        onClick: () => setIsOpen(false),
        size: "large",
        children: (0, _jsxRuntime.jsx)(_iconsMaterial.Close, {
          fontSize: 'large'
        })
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        sm: 10,
        item: true,
        style: {
          backgroundColor: "white",
          padding: theme.spacing(4),
          minHeight: "600px"
          // overflowY: "scroll",
        },
        spacing: 2,
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          direction: 'column',
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'h3',
              children: (_props$currentItem = props.currentItem) === null || _props$currentItem === void 0 ? void 0 : _props$currentItem.detailTitle
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'primary',
              variant: 'body2',
              children: _dateUtils.default.MonthYear((_props$currentItem2 = props.currentItem) === null || _props$currentItem2 === void 0 || (_props$currentItem2 = _props$currentItem2.inceptionDate) === null || _props$currentItem2 === void 0 ? void 0 : _props$currentItem2.toString())
            })
          })]
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            children: (_props$currentItem3 = props.currentItem) === null || _props$currentItem3 === void 0 ? void 0 : _props$currentItem3.detailDescription
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          spacing: 1,
          children: props.currentItem && props.currentItem.skillsHighlighted && ((_textProcessingUtils$ = _textProcessingUtils.default.sortByTitle(props.currentItem.skillsHighlighted)) === null || _textProcessingUtils$ === void 0 ? void 0 : _textProcessingUtils$.map((skill, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            onClick: () => {
              setIsToolTipOpen(index);
            },
            children: (0, _jsxRuntime.jsx)(_ResumeSkillTooltipWrapper.default, {
              resumeSkill: skill,
              isTipOpen: index === isTooltipOpen,
              children: (0, _jsxRuntime.jsx)(_material.Chip, {
                color: 'primary',
                label: skill.title
              })
            })
          }, index)))
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsxs)(_material.Grid, {
              item: true,
              container: true,
              justifyContent: 'center',
              children: [(0, _jsxRuntime.jsx)(_material.ImageList, {
                rowHeight: 500,
                className: classes.imageList,
                cols: imageColumn,
                children: (_props$currentItem4 = props.currentItem) !== null && _props$currentItem4 !== void 0 && _props$currentItem4.imageGallery ? props.currentItem.imageGallery.map((item, index) => {
                  return (0, _jsxRuntime.jsx)(_material.Box, {
                    margin: '4px',
                    border: '1px solid #d5d5d5',
                    position: 'relative',
                    children: (0, _jsxRuntime.jsxs)(_material.ImageListItem, {
                      style: {
                        cursor: "pointer"
                      },
                      onClick: () => {
                        // firebaseContext.analytics.albumImageClick(item.title, item.subtitle, pageContext.analyticsId || "no-id")
                        setSelectedItem(item);
                        handleClickOpen();
                      },
                      children: [(0, _jsxRuntime.jsx)("img", {
                        alt: 'imageGalleryEntry',
                        src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(item, 200, 200),
                        width: "100%"
                      }), (0, _jsxRuntime.jsx)(_material.Box, {
                        component: 'div',
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        sx: {
                          opacity: .2,
                          backgroundColor: "rgba(0,0,0, .5)"
                        },
                        zIndex: 9999
                      })]
                    })
                  }, index);
                }) : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
              }), (0, _jsxRuntime.jsx)(_material.Dialog, {
                onClick: () => handleClose(),
                "aria-labelledby": "simple-dialog-title",
                open: isPhotoModalOpen,
                children: (0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  item: true,
                  justifyContent: 'center',
                  children: (0, _jsxRuntime.jsx)(_material.Grid, {
                    container: true,
                    item: true,
                    sx: {
                      backgroundColor: "black"
                    },
                    xs: 10,
                    children: (0, _jsxRuntime.jsx)("img", {
                      src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(selectedItem, 480, 480),
                      alt: "",
                      width: '100%'
                    })
                  })
                })
              })]
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsxs)(_material.ButtonGroup, {
            fullWidth: true,
            style: {
              marginTop: theme.spacing(4)
            },
            children: [(0, _jsxRuntime.jsx)(_material.Button, {
              style: {
                border: "1px solid whitesmoke"
              },
              variant: 'contained',
              color: 'secondary',
              onClick: () => setOpenWrapper(false),
              children: "Back to Resume"
            }), (0, _jsxRuntime.jsx)(_material.Button, {
              variant: 'contained',
              color: "primary",
              href: (_props$currentItem5 = props.currentItem) === null || _props$currentItem5 === void 0 ? void 0 : _props$currentItem5.linkToProd,
              children: "Go to this Project"
            })]
          })
        })]
      })]
    })
  });
};
var _default = exports.default = PortfolioItemModal;