"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _react = require("react");
var _Styles = _interopRequireDefault(require("../Styles"));
var _Logo = _interopRequireDefault(require("../../../../logo/Logo"));
var _CustomizedThemeContext = _interopRequireDefault(require("../../../../customized-theme-provider/CustomizedThemeContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  root: {
    width: '100vw'
    // backgroundColor: "whitesmoke"
  }
}));
const LoadingPage = () => {
  const theme = (0, _material.useTheme)();
  const globalClasses = (0, _Styles.default)(theme);
  const customTheme = (0, _react.useContext)(_CustomizedThemeContext.default);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    className: globalClasses.fullscreen,
    children: [customTheme.logoSrc ? (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsx)("img", {
        src: customTheme.logoSrc,
        width: 400
      })
    }) : (0, _jsxRuntime.jsx)(_Logo.default, {
      isCenter: true,
      height: 200
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      container: true,
      justifyContent: 'center',
      spacing: 3,
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        justifyContent: 'center',
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          color: 'primary',
          align: 'center',
          variant: 'h6',
          style: {
            fontFamily: "Raleway"
          },
          children: "Loading..."
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.CircularProgress, {
          size: 40
        })
      })]
    })]
  });
};
var _default = exports.default = LoadingPage;