"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.string.replace-all.js");
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _ThwCommonStyles = _interopRequireDefault(require("../../../../common/sanityIo/ThwCommonStyles"));
var _ColorPalette = require("../../../../theme/common/ColorPalette");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const ResumeEducationSection = props => {
  var _props$sectionData$ed;
  const globalClasses = (0, _ThwCommonStyles.default)();
  const theme = (0, _material.useTheme)();
  const xsOnly = (0, _material.useMediaQuery)(theme.breakpoints.only('xs'));
  const smDown = (0, _material.useMediaQuery)(theme.breakpoints.down('sm'));
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    style: {
      padding: theme.spacing(4, smDown ? 1 : 4)
    },
    className: globalClasses.resumeSection,
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      spacing: 3,
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        md: 4,
        alignContent: 'flex-start',
        spacing: 1,
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          container: true,
          children: [(0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            children: props.sectionData.title
          }), (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            color: 'primary',
            display: 'inline',
            children: "."
          })]
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            children: props.sectionData.introduction
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        md: 8,
        spacing: 2,
        justifyContent: xsOnly ? 'center' : 'flex-start',
        children: (_props$sectionData$ed = props.sectionData.educationExperiences) === null || _props$sectionData$ed === void 0 ? void 0 : _props$sectionData$ed.map((experience, index2) => {
          var _props$sectionData$ed2, _props$sectionData$ed3, _experience$dateStart, _experience$dateEnd;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            item: true,
            container: true,
            alignContent: 'flex-start',
            style: {
              borderBottom: "1px solid ".concat(index2 >= ((_props$sectionData$ed2 = (_props$sectionData$ed3 = props.sectionData.educationExperiences) === null || _props$sectionData$ed3 === void 0 ? void 0 : _props$sectionData$ed3.length) !== null && _props$sectionData$ed2 !== void 0 ? _props$sectionData$ed2 : 0) - 2 ? "transparent" : _ColorPalette.COLORS.LIGHTGRAY)
              // padding: theme.spacing(1.75, 0)
            },
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              spacing: 2,
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  role: 'educationheader',
                  display: 'inline',
                  variant: 'body2',
                  children: experience.institutionName
                })
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              spacing: 2,
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  display: 'inline',
                  variant: 'body1',
                  children: experience.qualification
                })
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                item: true,
                md: 4,
                children: [(0, _jsxRuntime.jsx)(_material.Typography, {
                  display: 'inline',
                  variant: 'body1',
                  children: (_experience$dateStart = experience.dateStart) === null || _experience$dateStart === void 0 ? void 0 : _experience$dateStart.toString().replaceAll('-', '.')
                }), experience.dateEnd ? (0, _jsxRuntime.jsx)(_material.Typography, {
                  display: 'inline',
                  variant: 'body1',
                  style: {
                    margin: theme.spacing(0, 1)
                  },
                  children: "\u2014"
                }) : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {}), (0, _jsxRuntime.jsx)(_material.Typography, {
                  display: 'inline',
                  variant: 'body1',
                  children: (_experience$dateEnd = experience.dateEnd) === null || _experience$dateEnd === void 0 ? void 0 : _experience$dateEnd.toString().replaceAll('-', '.')
                })]
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'body1',
                gutterBottom: true,
                children: experience.description
              })
            })]
          }, index2);
        })
      })]
    })
  });
};
var _default = exports.default = ResumeEducationSection;