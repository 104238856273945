"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.reduce.js");
var _jsxRuntime = require("react/jsx-runtime");
var _uuid = require("uuid");
var _material = require("@mui/material");
var _HeaderMenuItemButton = _interopRequireDefault(require("../templates/transform-hw/HeaderMenuItemButton"));
var _PopupStateWrapper = _interopRequireDefault(require("./PopupStateWrapper"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// type HeaderMenuButtonType = {
//     group?: SanityMenuGroup,
//     item?: SanityMenuItem,
//     index: number
//     popup?: (popupState: any) => JSX.Element
//     button: any
// }
const FilteredMenuItems = _ref => {
  let {
    subMenus,
    onlyButtons,
    includeMenuItems,
    includeMenuGroups,
    textStyle,
    contentJustification
  } = _ref;
  const theme = (0, _material.useTheme)();
  const mdDown = (0, _material.useMediaQuery)(theme.breakpoints.down('md'));
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    item: true,
    container: true,
    wrap: 'nowrap',
    justifyContent: contentJustification ? contentJustification : mdDown ? 'flex-start' : 'flex-end',
    alignItems: 'stretch',
    alignContent: 'center',
    children: subMenus === null || subMenus === void 0 ? void 0 : subMenus.reduce((accumulated, menuButton) => {
      if ((menuButton === null || menuButton === void 0 ? void 0 : menuButton._type) === "menuItem" && (includeMenuItems || onlyButtons && (menuButton.isOutlinedButton || menuButton.isContainedButton || menuButton.isModalButton))) {
        return accumulated.concat([(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_HeaderMenuItemButton.default, {
            textStyle: textStyle,
            menuItem: menuButton
          })
        }, (0, _uuid.v4)())]);
      } else if ((menuButton === null || menuButton === void 0 ? void 0 : menuButton._type) === "menuGroup" && includeMenuGroups) {
        return accumulated.concat([(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_PopupStateWrapper.default, {
            menuGroup: menuButton
          })
        }, (0, _uuid.v4)())]);
      }
      return accumulated;
    }, [])
  });
};
var _default = exports.default = FilteredMenuItems;