"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _ColorPalette = require("../../../theme/common/ColorPalette");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  marketingBackground: {
    backgroundColor: _ColorPalette.COLORS.AQUA,
    minHeight: '100px',
    position: "relative",
    padding: theme.spacing(3, 8)
  },
  contentSection: {
    marginTop: '16px',
    padding: theme.spacing(8),
    backgroundColor: 'transparent'
  }
}));
const WebDevStatsCounterSection = props => {
  var _props$sectionData;
  const classes = useStyles();
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    className: classes.marketingBackground,
    children: (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      spacing: 3,
      children: (_props$sectionData = props.sectionData) === null || _props$sectionData === void 0 || (_props$sectionData = _props$sectionData.stats) === null || _props$sectionData === void 0 ? void 0 : _props$sectionData.map((particularStat, index) => (0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        sm: 6,
        md: 3,
        container: true,
        spacing: 1,
        alignItems: 'center',
        alignContent: 'center',
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 4,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h4',
            align: 'right',
            color: 'textPrimary',
            style: {
              fontFamily: "Elaine Sans"
            },
            children: particularStat.statValue
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 8,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            color: 'textPrimary',
            style: {
              fontFamily: "Elaine Sans"
            },
            children: particularStat.statContent
          })
        })]
      }, index))
    })
  });
};
var _default = exports.default = WebDevStatsCounterSection;