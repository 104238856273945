"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _HTMLUtils = require("../HTMLUtils");
var _StepFormValidationCodes = _interopRequireDefault(require("../enums/StepFormValidationCodes.enum"));
var _BallType = require("../enums/BallType.enum");
var _AWTextField = _interopRequireDefault(require("../aw-text-field/AWTextField"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const BallDate = props => {
  var _props$newBallToAdd2, _props$newBallToAdd4, _props$newBallToAdd6, _props$newBallToAdd8, _props$newBallToAdd9;
  const [ballDateState, setBallDateState] = (0, _react.useState)(_objectSpread({}, props.newBallToAdd));
  const updateBallFormParams = event => {
    setBallDateState(state => _objectSpread(_objectSpread({}, state), {}, {
      [event.target.name]: event.target.value
    }));
  };
  (0, _react.useEffect)(() => {
    setBallDateState(state => {
      var _props$newBallToAdd;
      return _objectSpread(_objectSpread({}, state), {}, {
        functionStartTime: (_props$newBallToAdd = props.newBallToAdd) === null || _props$newBallToAdd === void 0 ? void 0 : _props$newBallToAdd.functionStartTime
      });
    });
  }, [(_props$newBallToAdd2 = props.newBallToAdd) === null || _props$newBallToAdd2 === void 0 ? void 0 : _props$newBallToAdd2.functionStartTime]);
  (0, _react.useEffect)(() => {
    setBallDateState(state => {
      var _props$newBallToAdd3;
      return _objectSpread(_objectSpread({}, state), {}, {
        functionStartDate: (_props$newBallToAdd3 = props.newBallToAdd) === null || _props$newBallToAdd3 === void 0 ? void 0 : _props$newBallToAdd3.functionStartDate
      });
    });
  }, [(_props$newBallToAdd4 = props.newBallToAdd) === null || _props$newBallToAdd4 === void 0 ? void 0 : _props$newBallToAdd4.functionStartDate]);
  (0, _react.useEffect)(() => {
    setBallDateState(state => {
      var _props$newBallToAdd5;
      return _objectSpread(_objectSpread({}, state), {}, {
        functionEndDate: (_props$newBallToAdd5 = props.newBallToAdd) === null || _props$newBallToAdd5 === void 0 ? void 0 : _props$newBallToAdd5.functionEndDate
      });
    });
  }, [(_props$newBallToAdd6 = props.newBallToAdd) === null || _props$newBallToAdd6 === void 0 ? void 0 : _props$newBallToAdd6.functionEndDate]);
  (0, _react.useEffect)(() => {
    setBallDateState(state => {
      var _props$newBallToAdd7;
      return _objectSpread(_objectSpread({}, state), {}, {
        functionEndTime: (_props$newBallToAdd7 = props.newBallToAdd) === null || _props$newBallToAdd7 === void 0 ? void 0 : _props$newBallToAdd7.functionEndTime
      });
    });
  }, [(_props$newBallToAdd8 = props.newBallToAdd) === null || _props$newBallToAdd8 === void 0 ? void 0 : _props$newBallToAdd8.functionEndTime]);
  (0, _react.useEffect)(() => {
    var _ballDateState$functi, _ballDateState$functi2, _ballDateState$functi3, _ballDateState$functi4, _validateEndDate$stat;
    const areDatesValidResponse = ballDateState !== null && ballDateState !== void 0 && ballDateState.functionEndTime ? areDatesValid() : false;
    const stepCompleteArg = {
      functionStartDate: ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionStartDate,
      functionStartTime: ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionStartTime,
      functionEndDate: ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionEndDate,
      functionEndTime: ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionEndTime
    };
    props.validateStep({
      areDatesValid: areDatesValidResponse
    });
    if (((_ballDateState$functi = ballDateState.functionStartTime) === null || _ballDateState$functi === void 0 ? void 0 : _ballDateState$functi.length) === 5 && ((_ballDateState$functi2 = ballDateState.functionEndTime) === null || _ballDateState$functi2 === void 0 ? void 0 : _ballDateState$functi2.length) === 5 && ((_ballDateState$functi3 = ballDateState.functionStartDate) === null || _ballDateState$functi3 === void 0 ? void 0 : _ballDateState$functi3.length) === 10 && ((_ballDateState$functi4 = ballDateState.functionEndDate) === null || _ballDateState$functi4 === void 0 ? void 0 : _ballDateState$functi4.length) === 10 && ((_validateEndDate$stat = validateEndDate(ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionEndDate).status) === null || _validateEndDate$stat === void 0 ? void 0 : _validateEndDate$stat.statusCode) === _StepFormValidationCodes.default.VALID) {
      props.stepComplete(stepCompleteArg);
    }
  }, [ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionStartDate, ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionEndDate, ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionStartTime, ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionEndTime]);
  const areDatesValid = () => {
    var _ballDateState$functi5, _ballDateState$functi6, _ballDateState$functi7;
    let response = false;
    if (((_ballDateState$functi5 = ballDateState.functionStartTime) === null || _ballDateState$functi5 === void 0 ? void 0 : _ballDateState$functi5.length) === 5 && ((_ballDateState$functi6 = ballDateState.functionStartDate) === null || _ballDateState$functi6 === void 0 ? void 0 : _ballDateState$functi6.length) === 10 && ((_ballDateState$functi7 = ballDateState.functionEndDate) === null || _ballDateState$functi7 === void 0 ? void 0 : _ballDateState$functi7.length) === 10) {
      const startDate = new Date((0, _HTMLUtils.combineDateAndTime)(ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionStartDate, ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionStartTime));
      const endDate = new Date((0, _HTMLUtils.combineDateAndTime)(ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionEndDate, ballDateState !== null && ballDateState !== void 0 && ballDateState.functionEndTime ? ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionEndTime : '23:59'));
      response = startDate < endDate;
    }
    return response;
  };
  const validateEndDate = functionEndDate => {
    let response = {
      value: functionEndDate
    };
    let dateValid = areDatesValid();
    if (!(ballDateState !== null && ballDateState !== void 0 && ballDateState.functionStartTime) && !(ballDateState !== null && ballDateState !== void 0 && ballDateState.functionStartDate) && !(ballDateState !== null && ballDateState !== void 0 && ballDateState.functionEndTime) && !(ballDateState !== null && ballDateState !== void 0 && ballDateState.functionEndDate)) {
      dateValid = true;
    }
    if (!dateValid) {
      response = _objectSpread(_objectSpread({}, response), {}, {
        status: {
          messageText: "End Date must come after ".concat(ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionStartDate, " ").concat(ballDateState.functionStartTime),
          statusCode: _StepFormValidationCodes.default.END_DATE_BEFORE_START_DATE
        }
      });
      return response;
    }
    response = _objectSpread(_objectSpread({}, response), {}, {
      status: {
        statusCode: _StepFormValidationCodes.default.VALID
      }
    });
    return response;
  };
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    "data-testid": 'add-ball-date-step',
    direction: 'column',
    spacing: 1,
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: (0, _jsxRuntime.jsxs)(_material.Typography, {
        variant: 'h5',
        color: 'textSecondary',
        children: ["When is the ", (0, _BallType.renderBallType)((_props$newBallToAdd9 = props.newBallToAdd) === null || _props$newBallToAdd9 === void 0 ? void 0 : _props$newBallToAdd9.ballType), "?"]
      })
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      style: {
        paddingTop: theme.spacing(6.25)
      },
      spacing: 5,
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        direction: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        spacing: 1,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_AWTextField.default, {
            onChange: updateBallFormParams,
            value: ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionStartDate,
            testId: 'ball-start-date',
            fieldLabel: 'Start Date',
            fieldName: 'functionStartDate',
            type: 'date'
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_AWTextField.default, {
            onChange: updateBallFormParams,
            value: ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionStartTime,
            testId: 'ball-start-time',
            fieldLabel: 'Start Time',
            fieldName: 'functionStartTime',
            type: 'time'
          })
        })]
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        direction: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        spacing: 2,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_AWTextField.default, {
            onChange: updateBallFormParams,
            value: ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionEndDate,
            dependendentFieldValue: ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionEndTime,
            testId: 'ball-end-date',
            fieldLabel: 'End Date',
            fieldName: 'functionEndDate',
            type: 'date',
            validate: validateEndDate
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_AWTextField.default, {
            onChange: updateBallFormParams,
            value: ballDateState === null || ballDateState === void 0 ? void 0 : ballDateState.functionEndTime,
            testId: 'ball-end-time',
            fieldLabel: 'End Time',
            fieldName: 'functionEndTime',
            type: 'time',
            validate: validateEndDate
          })
        })]
      })]
    })]
  });
};
var _default = exports.default = BallDate;