"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.promise.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.string.search.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _react = require("react");
var _reactRouterDom = require("react-router-dom");
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
var _FirebaseContext = _interopRequireDefault(require("../../../../common/firebase/firebase-context/FirebaseContext"));
var _styles = require("@mui/material/styles");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _Routes = require("../enums/Routes.enum");
var _iconsMaterial = require("@mui/icons-material");
var _HTMLUtils = require("../HTMLUtils");
var _ClosedCategory = _interopRequireDefault(require("../ball-form-steps/AddCategories/ClosedCategory"));
var _BallMapComponent = _interopRequireDefault(require("../ball-form-steps/BallMapComponent"));
var _PageContext = _interopRequireDefault(require("../../../page-context/PageContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 *
 * BallPage
 *
 */

const useStyles = (0, _makeStyles.default)(awTheme => ({
  heroImage: {
    minWidth: '540px',
    minHeight: '360px'
  },
  favoritesShareContainer: {
    borderStyle: 'solid',
    borderColor: '#DBDAE3',
    borderWidth: '1px 0',
    backgroundColor: 'whitesmoke'
  },
  ballDescription: {
    whiteSpace: 'pre-wrap'
  },
  leftContent: {
    paddingTop: "16px",
    paddingLeft: "8px",
    [awTheme.breakpoints.up('md')]: {
      paddingLeft: "".concat(awTheme.spacing(12), "px !important")
    }
  },
  littleFaves: {
    [awTheme.breakpoints.up('sm')]: {
      marginRight: '10%'
    },
    [awTheme.breakpoints.down('xs')]: {
      marginRight: '45%'
    },
    height: '78px',
    position: 'relative',
    top: 38,
    borderRadius: '36px',
    backgroundColor: 'whitesmoke'
  },
  dateText: {
    marginLeft: awTheme.spacing(2)
  }
}));
const BallPage = props => {
  var _ball$flyer, _ball$flyer2, _ball$functionStartDa, _ball$website, _ball$host, _ball$location, _ball$location2, _ball$location3, _ball$location4, _ball$location5, _ball$description, _ball$categories, _ball$location6, _ball$location7, _ball$location8, _ball$location9, _ball$location10, _ball$location11, _ball$location12, _ball$location13, _ball$location14;
  const theme = (0, _styles.useTheme)();
  const classes = useStyles();
  const location = (0, _reactRouterDom.useLocation)();
  const urlParams = (0, _reactRouterDom.useParams)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const [ball, setBall] = (0, _react.useState)();
  const getBallBySlug = slug => {
    return slug ? sanityContext.theSanityClient.getBallBySlug(slug).then(returnedBall => {
      setBall(returnedBall);
      return returnedBall;
    }) : Promise.resolve({});
  };
  (0, _react.useEffect)(() => {
    if (ball && firebaseContext.analyticsViewBall) {
      firebaseContext.analyticsViewBall(ball);
    }
  }, [ball]);
  (0, _react.useEffect)(() => {
    if (!props.slug) {
      getBallBySlug(urlParams.slug).then(retrievedBall => {
        console.log('retrieved from sanity', retrievedBall);
        if (location.state) {
          const {
            ballToPreview
          } = location.state;
          if (ballToPreview) {
            // translate to a Sanity Ball
            const sanityBall = {
              ballTitle: ballToPreview.ballTitle,
              ballType: ballToPreview.ballType.toString(),
              flyer: ballToPreview.flyer,
              // flyer?: SanityImage,
              host: ballToPreview.host,
              categories: ballToPreview.categories,
              description: ballToPreview.ballDescription,
              functionStartDate: ballToPreview.functionStartDate,
              functionEndDate: ballToPreview.functionEndDate,
              source: ballToPreview.source.toString(),
              location: {
                locationName: ballToPreview.location.locationName,
                url: ballToPreview.location.url,
                country: ballToPreview.location.country,
                city: ballToPreview.location.city,
                street1: ballToPreview.location.street1,
                street2: ballToPreview.location.street2,
                state: ballToPreview.location.state
              },
              website: ballToPreview.website,
              notifyEmail: ballToPreview.notifyEmail,
              notifyName: ballToPreview.notifyName,
              notifyOnApproval: ballToPreview.notifyOnApproval
            };
            setBall(sanityBall);
          }
        }
      });
    }
  }, [props.slug]);
  (0, _react.useEffect)(() => {
    const ballToPreview = props.ball;
    if (ballToPreview) {
      var _ballToPreview$ballTy, _ballToPreview$source, _ballToPreview$locati, _ballToPreview$locati2, _ballToPreview$locati3, _ballToPreview$locati4, _ballToPreview$locati5, _ballToPreview$locati6, _ballToPreview$locati7;
      // translate to a Sanity Ball
      const sanityBall = {
        ballTitle: ballToPreview.ballTitle,
        ballType: (_ballToPreview$ballTy = ballToPreview.ballType) === null || _ballToPreview$ballTy === void 0 ? void 0 : _ballToPreview$ballTy.toString(),
        flyer: ballToPreview.flyer,
        // flyer?: SanityImage,
        host: ballToPreview.host,
        categories: ballToPreview.categories,
        description: ballToPreview.description,
        functionStartDate: ballToPreview.functionStartDate,
        functionEndDate: ballToPreview.functionEndDate,
        source: (_ballToPreview$source = ballToPreview.source) === null || _ballToPreview$source === void 0 ? void 0 : _ballToPreview$source.toString(),
        location: {
          locationName: (_ballToPreview$locati = ballToPreview.location) === null || _ballToPreview$locati === void 0 ? void 0 : _ballToPreview$locati.locationName,
          url: (_ballToPreview$locati2 = ballToPreview.location) === null || _ballToPreview$locati2 === void 0 ? void 0 : _ballToPreview$locati2.url,
          country: (_ballToPreview$locati3 = ballToPreview.location) === null || _ballToPreview$locati3 === void 0 ? void 0 : _ballToPreview$locati3.country,
          city: (_ballToPreview$locati4 = ballToPreview.location) === null || _ballToPreview$locati4 === void 0 ? void 0 : _ballToPreview$locati4.city,
          street1: (_ballToPreview$locati5 = ballToPreview.location) === null || _ballToPreview$locati5 === void 0 ? void 0 : _ballToPreview$locati5.street1,
          street2: (_ballToPreview$locati6 = ballToPreview.location) === null || _ballToPreview$locati6 === void 0 ? void 0 : _ballToPreview$locati6.street2,
          state: (_ballToPreview$locati7 = ballToPreview.location) === null || _ballToPreview$locati7 === void 0 ? void 0 : _ballToPreview$locati7.state
        },
        website: ballToPreview.website,
        notifyEmail: ballToPreview.notifyEmail,
        notifyName: ballToPreview.notifyName,
        notifyOnApproval: ballToPreview.notifyOnApproval
      };
      setBall(sanityBall);
    }
  }, [props.ball]);
  // useEffect(() => {
  //     console.log("this is the ball", ball)
  // }, [ball])
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const displayDateRange = (startDate, endDate) => {
    let dateRangeString = "".concat((0, _HTMLUtils.getPrettyDateStr)(startDate, true), ", ").concat((0, _HTMLUtils.getPrettyTimeStr)(startDate));
    if (endDate) {
      dateRangeString += "- ".concat((0, _HTMLUtils.getPrettyDateStr)(endDate, true), ", ").concat((0, _HTMLUtils.getPrettyTimeStr)(endDate));
    }
    return dateRangeString;
  };
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    children: [(0, _jsxRuntime.jsx)(_material.Hidden, {
      mdDown: true,
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        lg: 4,
        xl: 4
      })
    }), props.ball && (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      xs: 12,
      children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
        children: ball && (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          spacing: 2,
          children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              "data-testid": ball !== null && ball !== void 0 && (_ball$flyer = ball.flyer) !== null && _ball$flyer !== void 0 && _ball$flyer.asset.url ? 'flyer-image' : '',
              item: true,
              xs: 12,
              lg: 8,
              className: classes.heroImage,
              style: {
                backgroundImage: "url(".concat(ball === null || ball === void 0 || (_ball$flyer2 = ball.flyer) === null || _ball$flyer2 === void 0 ? void 0 : _ball$flyer2.asset.url, ")"),
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover'
              },
              children: !props.isHideBackButton && (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                justifyContent: 'space-between',
                children: (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  container: true,
                  children: (0, _jsxRuntime.jsx)(_material.Button, {
                    sx: {
                      paddingX: theme.spacing(4),
                      backgroundColor: 'rgba(0,0,0,.5)'
                    },
                    href: "/".concat(pageContext.baseRoute, "/").concat(_Routes.RoutesEnum.SEARCH + "/" + location.search),
                    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                      container: true,
                      item: true,
                      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                        item: true,
                        children: (0, _jsxRuntime.jsx)(_iconsMaterial.ChevronLeft, {
                          style: {
                            color: "whitesmoke"
                          }
                        })
                      }), (0, _jsxRuntime.jsx)(_material.Grid, {
                        item: true,
                        children: (0, _jsxRuntime.jsx)(_material.Typography, {
                          color: 'white',
                          variant: 'h6',
                          sx: {
                            paddingTop: "3px"
                          },
                          children: "Goto Search"
                        })
                      })]
                    })
                  })
                })
              })
            }), (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              direction: 'column',
              justifyContent: 'space-between',
              item: true,
              xs: 12,
              md: 4,
              // spacing={2}
              paddingTop: 2,
              paddingLeft: 2,
              paddingBottom: 2,
              children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                item: true,
                direction: 'column',
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsxs)(_material.Typography, {
                    color: '#c1bebe',
                    variant: 'subtitle2',
                    children: [(0, _HTMLUtils.getDayFromDate)(ball === null || ball === void 0 ? void 0 : ball.functionStartDate).toUpperCase(), " \u2022 ", (0, _HTMLUtils.getYearFromDate)(ball === null || ball === void 0 ? void 0 : ball.functionStartDate).toUpperCase()]
                  })
                }), (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    color: 'textSecondary',
                    variant: 'h6',
                    children: (0, _HTMLUtils.getMonthFromDate)(ball === null || ball === void 0 ? void 0 : ball.functionStartDate).toUpperCase()
                  })
                }), (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    color: 'textSecondary',
                    variant: 'h5',
                    children: ball === null || ball === void 0 || (_ball$functionStartDa = ball.functionStartDate) === null || _ball$functionStartDa === void 0 ? void 0 : _ball$functionStartDa.substring(8, 10)
                  })
                })]
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  children: (0, _jsxRuntime.jsx)(_material.Link, {
                    href: (_ball$website = ball === null || ball === void 0 ? void 0 : ball.website) !== null && _ball$website !== void 0 ? _ball$website : "",
                    children: ball === null || ball === void 0 ? void 0 : ball.ballTitle
                  })
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  children: "by ".concat(ball === null || ball === void 0 || (_ball$host = ball.host) === null || _ball$host === void 0 ? void 0 : _ball$host.replace(/&amp;/g, '&'))
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_material.Hidden, {
            mdUp: true,
            children: (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              item: true,
              paddingBottom: 2,
              style: {
                borderBottom: '1px solid #DBDAE3'
              },
              children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                item: true,
                wrap: 'nowrap',
                style: {
                  paddingLeft: "16px",
                  paddingBottom: "8px"
                },
                spacing: 2,
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_iconsMaterial.CalendarToday, {
                    color: 'primary'
                  })
                }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                  container: true,
                  direction: 'column',
                  item: true,
                  children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      variant: 'body2',
                      children: "Date and time"
                    })
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: displayDateRange(ball === null || ball === void 0 ? void 0 : ball.functionStartDate, ball === null || ball === void 0 ? void 0 : ball.functionEndDate)
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                item: true,
                wrap: 'nowrap',
                style: {
                  paddingLeft: "16px"
                },
                spacing: 2,
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_iconsMaterial.GpsFixed, {
                    color: 'primary'
                  })
                }), (0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  direction: 'column',
                  item: true,
                  children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                    container: true,
                    item: true,
                    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                      item: true,
                      children: (0, _jsxRuntime.jsx)(_material.Typography, {
                        color: 'textSecondary',
                        variant: 'body2',
                        children: "Location"
                      })
                    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                      container: true,
                      direction: 'column',
                      item: true,
                      children: [(0, _jsxRuntime.jsx)(_material.Typography, {
                        color: 'textSecondary',
                        children: ball === null || ball === void 0 || (_ball$location = ball.location) === null || _ball$location === void 0 ? void 0 : _ball$location.locationName
                      }), (0, _jsxRuntime.jsx)(_material.Typography, {
                        color: 'textSecondary',
                        children: ball === null || ball === void 0 || (_ball$location2 = ball.location) === null || _ball$location2 === void 0 ? void 0 : _ball$location2.street1
                      }), (0, _jsxRuntime.jsx)(_material.Typography, {
                        color: 'textSecondary',
                        children: ball === null || ball === void 0 || (_ball$location3 = ball.location) === null || _ball$location3 === void 0 ? void 0 : _ball$location3.street2
                      }), (0, _jsxRuntime.jsx)(_material.Typography, {
                        color: 'textSecondary',
                        children: "".concat(ball === null || ball === void 0 || (_ball$location4 = ball.location) === null || _ball$location4 === void 0 ? void 0 : _ball$location4.city, ", ").concat(ball === null || ball === void 0 || (_ball$location5 = ball.location) === null || _ball$location5 === void 0 ? void 0 : _ball$location5.state)
                      })]
                    })]
                  })
                })]
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              direction: 'column',
              item: true,
              xs: 12,
              md: 8,
              className: classes.leftContent,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                style: {
                  marginBottom: theme.spacing(2)
                },
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'h5',
                  color: 'textSecondary',
                  children: "About the Function"
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  component: 'div',
                  children: (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    className: classes.ballDescription,
                    children: ball === null || ball === void 0 || (_ball$description = ball.description) === null || _ball$description === void 0 ? void 0 : _ball$description.toString()
                  })
                })
              }), (ball === null || ball === void 0 || (_ball$categories = ball.categories) === null || _ball$categories === void 0 ? void 0 : _ball$categories.length) > 0 && (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                style: {
                  margin: theme.spacing(2, 0)
                },
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  variant: 'h6',
                  children: "Categories"
                })
              }), ball === null || ball === void 0 ? void 0 : ball.categories.map((category, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                padding: .5,
                children: (0, _jsxRuntime.jsx)(_ClosedCategory.default, {
                  showDescription: true,
                  category: category,
                  keyValue: index
                })
              }, index))]
            }), (0, _jsxRuntime.jsx)(_material.Hidden, {
              mdDown: true,
              children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                direction: 'column',
                item: true,
                xs: 4,
                padding: 2,
                spacing: 3,
                children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
                  container: true,
                  direction: 'column',
                  item: true,
                  children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      variant: 'body2',
                      fontWeight: 600,
                      children: "Date and time"
                    })
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    container: true,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: displayDateRange(ball === null || ball === void 0 ? void 0 : ball.functionStartDate, ball === null || ball === void 0 ? void 0 : ball.functionEndDate)
                    })
                  })]
                }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                  container: true,
                  direction: 'column',
                  item: true,
                  children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      variant: 'body2',
                      fontWeight: 600,
                      children: "Location"
                    })
                  }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                    container: true,
                    direction: 'column',
                    item: true,
                    children: [(0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: ball === null || ball === void 0 || (_ball$location6 = ball.location) === null || _ball$location6 === void 0 ? void 0 : _ball$location6.locationName
                    }), (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: "".concat(ball === null || ball === void 0 || (_ball$location7 = ball.location) === null || _ball$location7 === void 0 ? void 0 : _ball$location7.street1)
                    }), (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: "".concat(ball === null || ball === void 0 || (_ball$location8 = ball.location) === null || _ball$location8 === void 0 ? void 0 : _ball$location8.street2)
                    }), (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: "".concat(ball === null || ball === void 0 || (_ball$location9 = ball.location) === null || _ball$location9 === void 0 ? void 0 : _ball$location9.city, ", ").concat(ball === null || ball === void 0 || (_ball$location10 = ball.location) === null || _ball$location10 === void 0 ? void 0 : _ball$location10.state)
                    })]
                  })]
                }), (0, _jsxRuntime.jsx)(_material.Hidden, {
                  mdDown: true,
                  children: (0, _jsxRuntime.jsx)(_material.Grid, {
                    container: true,
                    justifyContent: 'center',
                    item: true
                  })
                })]
              })
            })]
          }), (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            direction: 'column',
            item: true,
            columnSpacing: 2,
            children: [(ball === null || ball === void 0 ? void 0 : ball.location) && (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: 'center',
              children: (0, _jsxRuntime.jsx)(_BallMapComponent.default, {
                location: ball === null || ball === void 0 ? void 0 : ball.location
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: 'center',
              paddingTop: 3,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: 'textSecondary',
                variant: 'h6',
                children: ball === null || ball === void 0 ? void 0 : ball.ballTitle
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: 'center',
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: 'textSecondary',
                children: "at"
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: 'center',
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: 'textSecondary',
                fontStyle: 'oblique',
                children: ball === null || ball === void 0 || (_ball$location11 = ball.location) === null || _ball$location11 === void 0 ? void 0 : _ball$location11.locationName
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: 'center',
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: 'textSecondary',
                children: "".concat(ball === null || ball === void 0 || (_ball$location12 = ball.location) === null || _ball$location12 === void 0 ? void 0 : _ball$location12.street1, " ").concat(ball === null || ball === void 0 || (_ball$location13 = ball.location) === null || _ball$location13 === void 0 ? void 0 : _ball$location13.city, ", ").concat(ball === null || ball === void 0 || (_ball$location14 = ball.location) === null || _ball$location14 === void 0 ? void 0 : _ball$location14.state)
              })
            })]
          }), (0, _jsxRuntime.jsx)(_material.Hidden, {
            lgUp: true,
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              justifyContent: 'center',
              item: true,
              style: {
                padding: theme.spacing(3)
              }
            })
          }), ball.slug && (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              children: "Comments"
            })
          })]
        })
      })
    })]
  });
};
var _default = exports.default = BallPage;