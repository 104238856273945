"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.search.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _BlockContentLayoutContainer = _interopRequireDefault(require("../../../BlockContentLayoutContainer"));
var _reactRouter = require("react-router");
var _HeaderBlockContentLayoutContainer = _interopRequireDefault(require("../../../HeaderBlockContentLayoutContainer"));
var _FooterBlockContentLayoutContainer = _interopRequireDefault(require("../../../FooterBlockContentLayoutContainer"));
var _BusinessCard = _interopRequireDefault(require("../../../BusinessCard"));
var _Styles = _interopRequireDefault(require("./Styles"));
var _clsx = _interopRequireDefault(require("clsx"));
var _FirebaseContext = _interopRequireDefault(require("../../../../common/firebase/firebase-context/FirebaseContext"));
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
var _ColorPalette = require("../../../../theme/common/ColorPalette");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const PageLayout = props => {
  var _props$homePage$backg, _props$homePage$backg2;
  const location = (0, _reactRouter.useLocation)();
  const theme = (0, _material.useTheme)();
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const classes = (0, _Styles.default)({
    bgImage: sanityContext.urlFor((_props$homePage$backg = props.homePage.backgroundImageSrc) !== null && _props$homePage$backg !== void 0 ? _props$homePage$backg : "")
  });
  const xsDown = (0, _material.useMediaQuery)(theme.breakpoints.down('xs'));
  (0, _react.useEffect)(() => {
    props.homePage.title && firebaseContext.analyticsPageView && firebaseContext.analyticsPageView(location.pathname, location.search, "".concat(props.homePage.title));
  }, []);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    style: {
      width: "100vw",
      minHeight: "100vh"
    },
    children: [props.homePage.isFabActivated && (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      style: {
        position: "fixed",
        bottom: 0,
        right: 0,
        zIndex: 9999
      },
      children: (0, _jsxRuntime.jsx)(_BusinessCard.default, {
        homePage: props.homePage,
        anchor: 'bottom'
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: props.homePage.headerContent && (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        children: (0, _jsxRuntime.jsx)(_HeaderBlockContentLayoutContainer.default, {
          content: props.homePage.headerContent.content
        })
      })
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      container: true,
      style: {
        position: "relative",
        backgroundSize: "cover",
        backgroundImage: "url(".concat(sanityContext.urlFor((_props$homePage$backg2 = props.homePage.backgroundImageSrc) !== null && _props$homePage$backg2 !== void 0 ? _props$homePage$backg2 : ""), ")")
      },
      children: [props.homePage.pageContent && (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        style: {
          zIndex: 1000
        },
        children: (0, _jsxRuntime.jsx)(_BlockContentLayoutContainer.default, {
          homePage: props.homePage,
          content: props.homePage.pageContent.content
        })
      }), props.homePage.backgroundImageSrc ? (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        className: (0, _clsx.default)(xsDown ? classes.fullSection : classes.fullSection, classes.fullSectionOverlay)
      }) : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})]
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: props.homePage.footerContent && (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        children: (0, _jsxRuntime.jsx)(_FooterBlockContentLayoutContainer.default, {
          content: props.homePage.footerContent.content
        })
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      alignContent: 'center',
      alignItems: 'center',
      style: {
        backgroundColor: props.homePage.copyRightBackgroundColor ? (0, _ColorPalette.convertToHexCode)(props.homePage.copyRightBackgroundColor) : "whitesmoke",
        position: "sticky",
        bottom: 0,
        zIndex: 10,
        padding: theme.spacing(.5, 3, .5)
      }
    })]
  });
};
var _default = exports.default = PageLayout;