"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.promise.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _createStyles = _interopRequireDefault(require("@mui/styles/createStyles"));
var _iconsMaterial = require("@mui/icons-material");
var _SocialMediaBlock = _interopRequireDefault(require("./templates/my-digital-resume/social-media-block/SocialMediaBlock"));
var _MailTo = _interopRequireDefault(require("./mail-to/MailTo"));
var _QrCodeContext = _interopRequireDefault(require("./qr-code-context/QrCodeContext"));
var _SnackbarContext = _interopRequireDefault(require("./modal-context/SnackbarContext"));
var _BusinessCardSubmitEmail = _interopRequireDefault(require("./templates/transform-hw/pages/BusinessCardSubmitEmail"));
var _reactRouter = require("react-router");
var _ColorPalette = require("../theme/common/ColorPalette");
var _FirebaseContext = _interopRequireDefault(require("../common/firebase/firebase-context/FirebaseContext"));
var _SanityContext = _interopRequireDefault(require("../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const useStyles = (0, _makeStyles.default)(() => (0, _createStyles.default)({
  drawer: {
    "& .MuiDrawer-paper": {
      backgroundColor: "transparent"
    }
  },
  listItem: {
    "&.MuiListItem-gutters": {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0
    }
  }
}));
const BusinessCard = _ref => {
  let {
    anchor,
    homePage
  } = _ref;
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const [isDrawerOpen, setIsDrawerOpen] = (0, _react.useState)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const location = (0, _reactRouter.useLocation)();
  const toggleDrawer = (_anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (open) {
      var _location$pathname;
      firebaseContext.ctaClick && firebaseContext.ctaClick((_location$pathname = location.pathname) !== null && _location$pathname !== void 0 ? _location$pathname : "", "Open Business Card");
    }
    setIsDrawerOpen(open);
  };
  const snackbarContext = (0, _react.useContext)(_SnackbarContext.default);
  const classes = useStyles();
  const theme = (0, _material.useTheme)();
  const [userBio, setUserBio] = (0, _react.useState)();
  (0, _react.useEffect)(() => {
    //find the bio in pagecontent
    const bioSection = homePage.pageContent.content.filter(content => {
      if (content._type === "ResumeBioSection") {
        return true;
      }
      return false;
    });
    setUserBio(bioSection[0]);
  }, [homePage.pageContent]);
  const qrCodeContext = (0, _react.useContext)(_QrCodeContext.default);
  const share = async url => {
    qrCodeContext.openSnackbar && qrCodeContext.openSnackbar(url);
  };
  const list = () => {
    var _homePage$businessCon, _homePage$businessCon2, _homePage$businessCon3, _homePage$businessCon4;
    return (0, _jsxRuntime.jsxs)(_material.Grid, {
      xs: 12,
      md: 6,
      container: true,
      item: true,
      role: "presentation",
      style: {
        backgroundColor: _ColorPalette.COLORS.LIGHTGRAY,
        maxWidth: 350
      },
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        alignItems: 'center',
        justifyContent: 'space-between',
        style: {
          position: "absolute",
          zIndex: 1000,
          paddingLeft: theme.spacing(1),
          paddingTop: theme.spacing(.5)
        },
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 1,
          children: (0, _jsxRuntime.jsx)(_material.IconButton, {
            style: {
              color: "white"
            },
            onClick: () => {
              setIsDrawerOpen(false);
            },
            children: (0, _jsxRuntime.jsx)(_iconsMaterial.Close, {
              color: 'inherit',
              fontSize: 'medium'
            })
          })
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        alignContent: 'flex-end',
        justifyContent: 'center',
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          style: {
            marginBottom: theme.spacing(4),
            backgroundRepeat: "none",
            minHeight: 500,
            maxWidth: 350,
            backgroundSize: "cover",
            overflow: "visible",
            position: "relative",
            backgroundImage: "url(".concat(sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(homePage.businessCardImageSrc, 350, 500), ")")
          },
          justifyContent: 'center',
          alignContent: 'flex-end',
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            style: {
              position: "relative",
              bottom: -45,
              height: "max-content",
              padding: theme.spacing(2, 3)
            },
            children: (0, _jsxRuntime.jsx)(_SocialMediaBlock.default, _objectSpread({
              isHoverColor: true,
              isCentered: true,
              spacing: 1,
              iconColor: 'white',
              bgColor: true,
              theBackgroundColor: theme.palette.primary.main
            }, homePage.businessContact))
          })
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        children: (0, _jsxRuntime.jsxs)(_material.List, {
          style: {
            width: "100%"
          },
          children: [(0, _jsxRuntime.jsx)(_material.ListItem, {
            children: (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                justifyContent: 'center',
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'primary',
                  variant: 'h6',
                  textAlign: 'center',
                  children: userBio === null || userBio === void 0 ? void 0 : userBio.name
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                justifyContent: 'center',
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'body2',
                  textAlign: 'center',
                  children: userBio === null || userBio === void 0 ? void 0 : userBio.careerTitle
                })
              })]
            })
          }), (0, _jsxRuntime.jsx)(_material.ListItem, {
            children: (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                xs: 3,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'body2',
                  children: "Phone:"
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                xs: 9,
                container: true,
                justifyContent: 'flex-end',
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'body2',
                  children: (_homePage$businessCon = homePage.businessContact) === null || _homePage$businessCon === void 0 ? void 0 : _homePage$businessCon.phone
                })
              })]
            })
          }), (0, _jsxRuntime.jsx)(_material.Divider, {}), (0, _jsxRuntime.jsxs)(_material.ListItem, {
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              xs: 3,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'body2',
                children: "Email:"
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              xs: 9,
              container: true,
              justifyContent: 'flex-end',
              children: (0, _jsxRuntime.jsx)(_MailTo.default, {
                color: theme.palette.primary.main,
                email: (_homePage$businessCon2 = (_homePage$businessCon3 = homePage.businessContact) === null || _homePage$businessCon3 === void 0 ? void 0 : _homePage$businessCon3.email) !== null && _homePage$businessCon2 !== void 0 ? _homePage$businessCon2 : "",
                subject: "Information Request",
                body: "",
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textPrimary',
                  variant: 'button',
                  align: 'right',
                  children: (_homePage$businessCon4 = homePage.businessContact) === null || _homePage$businessCon4 === void 0 ? void 0 : _homePage$businessCon4.email
                })
              })
            })]
          }), (0, _jsxRuntime.jsx)(_material.Divider, {}), (0, _jsxRuntime.jsx)(_material.ListItem, {
            children: (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
                item: true,
                xs: 12,
                container: true,
                alignContent: 'flex-end',
                children: [(0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'h6',
                  gutterBottom: true,
                  children: "Website"
                }), (0, _jsxRuntime.jsx)(_material.Button, {
                  variant: 'outlined',
                  size: 'small',
                  fullWidth: true,
                  color: 'primary',
                  href: homePage.website,
                  children: (0, _jsxRuntime.jsx)(_material.Grid, {
                    style: {
                      height: "48px"
                    },
                    container: true,
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    children: (0, _jsxRuntime.jsx)(_material.Grid, {
                      item: true,
                      children: (0, _jsxRuntime.jsx)(_material.Typography, {
                        variant: 'subtitle1',
                        align: 'center',
                        children: homePage.website
                      })
                    })
                  })
                })]
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                xs: 12,
                container: true,
                children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                  container: true,
                  item: true,
                  justifyContent: 'center',
                  children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    xs: 6,
                    container: true,
                    justifyContent: 'flex-end',
                    children: (0, _jsxRuntime.jsx)(_material.Button, {
                      style: {
                        height: "80px"
                      },
                      variant: 'contained',
                      color: 'primary',
                      fullWidth: true,
                      onClick: () => {
                        var _homePage$website;
                        navigator.clipboard.writeText((_homePage$website = homePage.website) !== null && _homePage$website !== void 0 ? _homePage$website : "").then(() => {
                          const snack = (0, _jsxRuntime.jsx)(_material.Grid, {
                            container: true,
                            item: true,
                            children: "Copied!"
                          });
                          snackbarContext.openSnackbar && snackbarContext.openSnackbar(snack, 15000);
                        });
                      },
                      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                        item: true,
                        children: [(0, _jsxRuntime.jsx)(_iconsMaterial.FileCopy, {
                          style: {
                            height: "42px"
                          }
                        }), (0, _jsxRuntime.jsx)(_material.Typography, {
                          variant: 'subtitle1',
                          children: "Copy URL"
                        })]
                      })
                    })
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    xs: 6,
                    children: (0, _jsxRuntime.jsx)(_material.Button, {
                      variant: 'contained',
                      color: 'primary',
                      fullWidth: true,
                      style: {
                        height: "80px"
                      },
                      onClick: () => {
                        var _homePage$website2;
                        return share((_homePage$website2 = homePage.website) !== null && _homePage$website2 !== void 0 ? _homePage$website2 : "");
                      },
                      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                        item: true,
                        children: [(0, _jsxRuntime.jsx)("img", {
                          alt: 'website QR code',
                          height: 42,
                          src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(homePage.websiteQrCode, 42, 42)
                        }), (0, _jsxRuntime.jsx)(_material.Typography, {
                          variant: 'subtitle1',
                          children: "Qr"
                        })]
                      })
                    })
                  })]
                })
              })]
            })
          }), (0, _jsxRuntime.jsx)(_material.Divider, {}), (0, _jsxRuntime.jsx)(_material.ListItem, {
            children: (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
                item: true,
                xs: 12,
                container: true,
                alignContent: 'flex-end',
                children: [(0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'h6',
                  gutterBottom: true,
                  children: "Virtual Meeting"
                }), (0, _jsxRuntime.jsx)(_material.Button, {
                  variant: 'outlined',
                  size: 'small',
                  fullWidth: true,
                  color: 'primary',
                  href: homePage.bookAppointmentLink,
                  children: (0, _jsxRuntime.jsx)(_material.Grid, {
                    style: {
                      height: "48px"
                    },
                    container: true,
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    children: (0, _jsxRuntime.jsx)(_material.Grid, {
                      item: true,
                      children: (0, _jsxRuntime.jsx)(_material.Typography, {
                        variant: 'subtitle1',
                        align: 'center',
                        children: homePage.bookAppointmentLink
                      })
                    })
                  })
                })]
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                xs: 12,
                container: true,
                children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                  container: true,
                  item: true,
                  justifyContent: 'center',
                  children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    xs: 6,
                    container: true,
                    justifyContent: 'flex-end',
                    children: (0, _jsxRuntime.jsx)(_material.Button, {
                      style: {
                        height: "80px"
                      },
                      variant: 'contained',
                      color: 'primary',
                      fullWidth: true,
                      onClick: () => {
                        var _homePage$bookAppoint;
                        navigator.clipboard.writeText((_homePage$bookAppoint = homePage.bookAppointmentLink) !== null && _homePage$bookAppoint !== void 0 ? _homePage$bookAppoint : "").then(() => {
                          const snack = (0, _jsxRuntime.jsx)(_material.Grid, {
                            container: true,
                            item: true,
                            children: "Copied!"
                          });
                          snackbarContext.openSnackbar && snackbarContext.openSnackbar(snack, 15000);
                        });
                      },
                      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                        item: true,
                        children: [(0, _jsxRuntime.jsx)(_iconsMaterial.FileCopy, {
                          style: {
                            height: "42px"
                          }
                        }), (0, _jsxRuntime.jsx)(_material.Typography, {
                          variant: 'subtitle1',
                          children: "Copy URL"
                        })]
                      })
                    })
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    xs: 6,
                    children: (0, _jsxRuntime.jsx)(_material.Button, {
                      style: {
                        height: "80px"
                      },
                      variant: 'contained',
                      color: 'primary',
                      fullWidth: true,
                      onClick: () => {
                        var _homePage$bookAppoint2;
                        return share((_homePage$bookAppoint2 = homePage.bookAppointmentLink) !== null && _homePage$bookAppoint2 !== void 0 ? _homePage$bookAppoint2 : "");
                      },
                      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                        item: true,
                        children: [(0, _jsxRuntime.jsx)("img", {
                          alt: 'make apppointment qr code',
                          height: 42,
                          src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(homePage.bookAppointmentQrCode, 42, 42)
                        }), (0, _jsxRuntime.jsx)(_material.Typography, {
                          variant: 'subtitle1',
                          children: "Qr"
                        })]
                      })
                    })
                  })]
                })
              })]
            })
          }), (0, _jsxRuntime.jsx)(_material.Divider, {}), (0, _jsxRuntime.jsx)(_material.ListItem, {
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              xs: 12,
              children: (0, _jsxRuntime.jsx)(_BusinessCardSubmitEmail.default, {
                source: 'Business Card',
                emailFieldText: 'Email Address',
                emailButtonText: 'Submit',
                subscribeText: 'Get an email with my contact information below'
              })
            })
          })]
        })
      })]
    });
  };
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    item: true,
    children: [!isDrawerOpen && (0, _jsxRuntime.jsx)(_material.Fab, {
      sx: {
        position: "absolute",
        right: 32,
        bottom: 32
      },
      color: 'primary',
      onClick: toggleDrawer(anchor, true),
      children: (0, _jsxRuntime.jsx)(_material.Avatar, {
        style: {
          backgroundColor: "whitesmoke"
        },
        src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(homePage.headerContent.content[0].headerMenuRef.logoImageSrc)
      })
    }), (0, _jsxRuntime.jsx)(_material.Drawer, {
      className: classes.drawer,
      anchor: anchor,
      open: isDrawerOpen,
      onClose: () => {
        // setIsDrawerOpen(false)
        toggleDrawer(anchor, false);
      },
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        justifyContent: 'center',
        children: list()
      })
    })]
  });
};
var _default = exports.default = BusinessCard;