"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  root: {
    height: '430px',
    paddingBottom: theme.spacing(5)
  },
  contentBullets: {
    marginBottom: theme.spacing(5)
  }
}));
const HolidayHeadlineSection = props => {
  var _props$sectionData, _props$sectionData2;
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    justifyContent: 'center',
    item: true,
    alignContent: 'center',
    alignItems: 'center',
    style: {
      padding: "40px"
    },
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      xs: 12,
      sm: 2,
      container: true,
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsx)("img", {
        height: 156,
        width: 156,
        alt: props.sectionData.slug.current + " Icon",
        src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage((_props$sectionData = props.sectionData) === null || _props$sectionData === void 0 ? void 0 : _props$sectionData.holidayIconLeft, 156, 156)
      })
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      xs: 12,
      sm: 8,
      container: true,
      justifyContent: 'center',
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'h2',
          color: 'primary',
          gutterBottom: true,
          align: 'center',
          children: props.sectionData.contentText
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'body1',
          color: 'textPrimary',
          align: 'center',
          children: props.sectionData.contentSubtext
        })
      })]
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      xs: 12,
      sm: 2,
      container: true,
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsx)(_material.Box, {
        sx: {
          display: {
            xs: 'none',
            sm: 'block'
          }
        },
        children: (0, _jsxRuntime.jsx)("img", {
          height: 156,
          width: 156,
          alt: props.sectionData.slug.current + " Icon",
          src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage((_props$sectionData2 = props.sectionData) === null || _props$sectionData2 === void 0 ? void 0 : _props$sectionData2.holidayIconRight, 156, 156)
        })
      })
    })]
  });
};
var _default = exports.default = HolidayHeadlineSection;