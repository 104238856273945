"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const ColoredPng = props => {
  const [imageUrl, setImageUrl] = (0, _react.useState)();
  const [imageSize, setImageSize] = (0, _react.useState)(100);
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  (0, _react.useEffect)(() => {
    if (props.size) {
      setImageSize(props.size);
    }
    if (props.maskUrl) {
      setImageUrl(props.maskUrl);
    }
    if (props.maskAsset) {
      setImageUrl(sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.maskAsset, props.size, props.size));
    }
  }, []);
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    item: true,
    container: true,
    style: {
      WebkitMaskImage: "url(".concat(imageUrl, ")"),
      maskImage: "url(".concat(imageUrl, ")"),
      WebkitMaskRepeat: "none",
      maskRepeat: "none",
      backgroundPosition: "center",
      opacity: .55555555555,
      height: "".concat(imageSize, "px"),
      width: "".concat(imageSize, "px"),
      backgroundColor: props.color,
      WebkitMaskSize: "cover",
      maskSize: "cover"
      // marginBottom: TransformHWTheme.spacing(2)
    }
  });
};
var _default = exports.default = ColoredPng;