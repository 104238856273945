"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.promise.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
var _dateUtils = _interopRequireDefault(require("../../../../utils/dateUtils"));
var _CommonStyles = require("../../../../common/sanityIo/CommonStyles");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const LinkedPortfolioEntries = props => {
  var _portfolioEntries$len;
  const classes = (0, _CommonStyles.useCommonStyles)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const [portfolioEntries, setPortfolioEntries] = (0, _react.useState)();
  const getPortfolioItems = async resumeSkill => {
    if (sanityContext.fetchPortfolioItems) return sanityContext.fetchPortfolioItems(resumeSkill);else return undefined;
  };
  (0, _react.useEffect)(() => {
    getPortfolioItems(props.resumeSkill).then(response => {
      if (response) {
        setPortfolioEntries(response);
      }
    });
  }, []);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    paddingBottom: 1,
    children: [((_portfolioEntries$len = portfolioEntries === null || portfolioEntries === void 0 ? void 0 : portfolioEntries.length) !== null && _portfolioEntries$len !== void 0 ? _portfolioEntries$len : 0) > 0 && (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: (0, _jsxRuntime.jsx)(_material.Typography, {
        color: 'whitesmoke',
        variant: 'caption',
        children: "Portfolio"
      })
    }), portfolioEntries ? portfolioEntries.map((portfolioEntry, index) => {
      return (0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 3,
          children: (0, _jsxRuntime.jsx)(_material.Link, {
            href: "#" + portfolioEntry._id,
            className: classes.toolTiplink,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'caption',
              color: 'whitesmoke',
              children: _dateUtils.default.YearNumeric(portfolioEntry.inceptionDate)
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 9,
          children: (0, _jsxRuntime.jsx)(_material.Link, {
            href: "#" + portfolioEntry._id,
            className: classes.toolTiplink,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              lineHeight: .5,
              variant: 'caption',
              color: 'whitesmoke',
              children: portfolioEntry.title
            })
          })
        })]
      }, index);
    }) : (0, _jsxRuntime.jsx)(_material.CircularProgress, {})]
  });
};
var _default = exports.default = LinkedPortfolioEntries;