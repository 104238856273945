"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _SearchContext = _interopRequireDefault(require("../search-context/SearchContext"));
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = (0, _makeStyles.default)(() => ({
  textField: props => ({
    "& .MuiFilledInput-input": {
      color: props.isDarkMode ? "whitesmoke" : "black"
    },
    "& .MuiInputLabel-root": {
      "& :hover": {
        color: props.isDarkMode ? "whitesmoke" : "black"
      },
      color: props.isDarkMode ? "whitesmoke" : "black"
    }
  })
}));
const SearchBox = props => {
  const classes = useStyles(props);
  const [searchTerms, setSearchTerms] = (0, _react.useState)('');
  const searchContext = (0, _react.useContext)(_SearchContext.default);
  const onSearchTermsChange = newTerms => {
    setSearchTerms(newTerms);
    if (newTerms === "") {
      (searchContext === null || searchContext === void 0 ? void 0 : searchContext.submitSearch) && (searchContext === null || searchContext === void 0 ? void 0 : searchContext.submitSearch(undefined));
    } else {
      (searchContext === null || searchContext === void 0 ? void 0 : searchContext.submitSearch) && (searchContext === null || searchContext === void 0 ? void 0 : searchContext.submitSearch(searchTerms));
    }
  };
  const clearSearch = () => {
    setSearchTerms('');
    (searchContext === null || searchContext === void 0 ? void 0 : searchContext.submitSearch) && (searchContext === null || searchContext === void 0 ? void 0 : searchContext.submitSearch(undefined));
  };
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    spacing: 1,
    justifyContent: 'center',
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsx)(_material.TextField, {
        className: classes.textField,
        variant: 'filled',
        fullWidth: true,
        helperText: "Enter Search Terms here",
        label: "Search",
        id: "search",
        name: "search",
        type: "text",
        value: searchTerms,
        onChange: e => onSearchTermsChange(e.target.value)
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsx)(_material.Button, {
        onClick: clearSearch,
        children: (0, _jsxRuntime.jsx)(_iconsMaterial.Close, {})
      })
    })]
  });
};
var _default = exports.default = SearchBox;