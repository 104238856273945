"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _BallType = require("../enums/BallType.enum");
var _iconsMaterial = require("@mui/icons-material");
var _StyledTextField = _interopRequireDefault(require("../styled-text-field/StyledTextField"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const AddBallFlyer = props => {
  var _addBallFlyerState$fl, _addBallFlyerState$fl2, _addBallFlyerState$fl3;
  const flyerInputRef = (0, _react.useRef)();
  const [addBallFlyerState, setAddBallFlyerState] = (0, _react.useState)({});
  (0, _react.useEffect)(() => {
    if (addBallFlyerState.flyer) {
      props.stepComplete(addBallFlyerState);
    }
  }, [addBallFlyerState.flyer]);
  (0, _react.useEffect)(() => {
    var _props$newBallToAdd, _props$newBallToAdd2;
    if ((_props$newBallToAdd = props.newBallToAdd) !== null && _props$newBallToAdd !== void 0 && _props$newBallToAdd.fileUploaded && !((_props$newBallToAdd2 = props.newBallToAdd) !== null && _props$newBallToAdd2 !== void 0 && _props$newBallToAdd2.flyer)) {
      var _props$newBallToAdd3;
      console.log('FileUploaded', (_props$newBallToAdd3 = props.newBallToAdd) === null || _props$newBallToAdd3 === void 0 ? void 0 : _props$newBallToAdd3.fileUploaded);
      // uploadFile(null, props.newBallToAdd?.fileUploaded)
      setAddBallFlyerState(state => _objectSpread(_objectSpread({}, state), {}, {
        flyer: {
          asset: {
            url: props.newBallToAdd.fileUploaded[0]
          }
        },
        fileUploaded: props.newBallToAdd.fileUploaded[0]
      }));
    }
  }, [props.newBallToAdd.fileUploaded]);
  (0, _react.useEffect)(() => {
    setAddBallFlyerState(state => _objectSpread(_objectSpread({}, state), {}, {
      flyer: props.newBallToAdd.flyer
    }));
  }, [props.newBallToAdd.flyer]);
  const fileUploadToBlob = blob => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(blob);
    fileReader.addEventListener('load', function (progressEvent) {
      var _progressEvent$target, _progressEvent$target2;
      console.log((_progressEvent$target = progressEvent.target) === null || _progressEvent$target === void 0 ? void 0 : _progressEvent$target.result);
      const newState = {
        fileUploaded: blob,
        flyer: {
          asset: {
            url: (_progressEvent$target2 = progressEvent.target) === null || _progressEvent$target2 === void 0 || (_progressEvent$target2 = _progressEvent$target2.result) === null || _progressEvent$target2 === void 0 ? void 0 : _progressEvent$target2.toString()
          }
        }
      };
      setAddBallFlyerState(state => _objectSpread(_objectSpread({}, state), newState));
    });
  };
  const uploadFile = event => {
    if (event) {
      fileUploadToBlob(event.target.files[0]);
    }
  };
  const updateBallFormParams = event => {
    if (event.target.files.length === 0) {
      const newState = {
        flyer: {
          asset: {
            url: ''
          }
        }
      };
      setAddBallFlyerState(state => _objectSpread(_objectSpread({}, state), newState));
    } else {
      uploadFile(event);
    }
  };
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    "data-testid": 'add-ball-flyer-step',
    direction: 'column',
    spacing: 1,
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: (0, _jsxRuntime.jsx)(_material.Typography, {
        color: 'textSecondary',
        variant: 'h5',
        noWrap: true,
        children: "Upload a flyer for the ".concat((0, _BallType.renderBallType)(props.newBallToAdd.ballType), ":")
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: (0, _jsxRuntime.jsx)(_material.Typography, {
        variant: 'h5',
        color: 'textSecondary',
        children: "Select a file below."
      })
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      justifyContent: 'center',
      container: true,
      item: true,
      spacing: 1,
      style: {
        paddingTop: theme.spacing(3.25)
      },
      alignItems: 'center',
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        xs: 12,
        children: (0, _jsxRuntime.jsx)(_material.FormGroup, {
          style: {
            width: "100%"
          },
          children: (0, _jsxRuntime.jsx)(_material.FormControlLabel, {
            style: {
              alignItems: 'start'
            },
            control: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
              required: true,
              //@ts-ignore
              ref: flyerInputRef,
              fullWidth: true,
              variant: 'outlined',
              onChange: updateBallFormParams,
              name: 'flyer',
              type: 'file',
              inputProps: {
                'data-testid': 'flyer-file-input',
                accept: 'image/png, image/jpeg'
              }
            }),
            label: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'textSecondary',
              display: 'inline',
              variant: 'h6',
              noWrap: true,
              children: "".concat((0, _BallType.renderBallType)(props.newBallToAdd.ballType), " Flyer")
            }),
            labelPlacement: 'top'
          })
        })
      }), ((_addBallFlyerState$fl = (_addBallFlyerState$fl2 = addBallFlyerState.flyer) === null || _addBallFlyerState$fl2 === void 0 || (_addBallFlyerState$fl2 = _addBallFlyerState$fl2.asset) === null || _addBallFlyerState$fl2 === void 0 || (_addBallFlyerState$fl2 = _addBallFlyerState$fl2.url) === null || _addBallFlyerState$fl2 === void 0 ? void 0 : _addBallFlyerState$fl2.length) !== null && _addBallFlyerState$fl !== void 0 ? _addBallFlyerState$fl : -1) > 0 ? (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        xs: 8,
        justifyContent: 'center',
        children: (0, _jsxRuntime.jsx)("img", {
          "data-testid": 'flyer-image-preview',
          alt: 'Flyer for the Event',
          src: (_addBallFlyerState$fl3 = addBallFlyerState.flyer) === null || _addBallFlyerState$fl3 === void 0 ? void 0 : _addBallFlyerState$fl3.asset.url,
          width: '350'
        })
      }) : (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        justifyContent: 'center',
        children: (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          xs: 6,
          style: {
            minHeight: '400px',
            border: "1px solid #6B6B6B"
          },
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_iconsMaterial.Image, {
              fontSize: 'large',
              style: {
                color: theme.palette.text.secondary
              }
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'textSecondary',
              variant: 'subtitle1',
              fontWeight: 400,
              children: "Image Preview"
            })
          })]
        })
      })]
    })]
  });
};
var _default = exports.default = AddBallFlyer;