"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _ThwCommonStyles = _interopRequireDefault(require("../../../../common/sanityIo/ThwCommonStyles"));
var _ResumePortfolioEntry = _interopRequireDefault(require("./ResumePortfolioEntry"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const ResumePortfolioSection = props => {
  var _props$sectionData$po;
  const globalClasses = (0, _ThwCommonStyles.default)();
  const theme = (0, _material.useTheme)();
  const smDown = (0, _material.useMediaQuery)(theme.breakpoints.down('sm'));
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    style: {
      padding: theme.spacing(4, smDown ? 1 : 4)
    },
    className: globalClasses.resumeSection,
    justifyContent: 'center',
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      spacing: 3,
      justifyContent: 'center',
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        alignContent: 'flex-start',
        spacing: 1,
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          container: true,
          children: [(0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            children: props.sectionData.title
          }), (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            color: 'primary',
            display: 'inline',
            children: "."
          })]
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            children: props.sectionData.introduction
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        justifyContent: 'center',
        xs: 11,
        justifySelf: 'center',
        spacing: 1,
        children: (_props$sectionData$po = props.sectionData.portfolioEntries) === null || _props$sectionData$po === void 0 ? void 0 : _props$sectionData$po.map((portfolioItem, index2) => {
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            item: true,
            xs: 12,
            sm: 6,
            lg: 4,
            xl: 4,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: portfolioItem._id,
              underline: "hover",
              position: 'relative',
              top: -90
            }), (0, _jsxRuntime.jsx)(_ResumePortfolioEntry.default, {
              portfolioItem: portfolioItem
            })]
          }, index2);
        })
      })]
    })
  });
};
var _default = exports.default = ResumePortfolioSection;