"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.BallSourceTitleEnum = void 0;
var BallSourceEnum;
(function (BallSourceEnum) {
  BallSourceEnum["SPECTATOR"] = "SPECTATOR";
  BallSourceEnum["PROMOTER"] = "PROMOTER";
})(BallSourceEnum || (BallSourceEnum = {}));
var BallSourceTitleEnum;
(function (BallSourceTitleEnum) {
  BallSourceTitleEnum["SPECTATOR"] = "Spectator";
  BallSourceTitleEnum["PROMOTER"] = "Ball Organizer";
})(BallSourceTitleEnum || (exports.BallSourceTitleEnum = BallSourceTitleEnum = {}));
var _default = exports.default = BallSourceEnum;