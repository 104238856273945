"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _styles = require("@mui/material/styles");
var _material = require("@mui/material");
var _ThwCommonStyles = _interopRequireDefault(require("../../../../common/sanityIo/ThwCommonStyles"));
var _ResumeSkillSetItem = _interopRequireDefault(require("./ResumeSkillSetItem"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const ResumeSkillsSection = props => {
  var _props$sectionData, _props$sectionData2, _props$sectionData3;
  // const classes = useStyles()
  const globalClasses = (0, _ThwCommonStyles.default)();
  const theme = (0, _styles.useTheme)();
  const smDown = (0, _material.useMediaQuery)(theme.breakpoints.down('sm'));
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    style: {
      padding: theme.spacing(4, smDown ? 1 : 4)
    },
    className: globalClasses.resumeSection,
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      spacing: 3,
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        md: 4,
        alignContent: 'flex-start',
        spacing: 1,
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          container: true,
          children: [(0, _jsxRuntime.jsx)(_material.Typography, {
            component: 'div',
            variant: 'h6',
            children: (_props$sectionData = props.sectionData) === null || _props$sectionData === void 0 ? void 0 : _props$sectionData.title
          }), (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            color: 'primary',
            display: 'inline',
            children: "."
          })]
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            children: (_props$sectionData2 = props.sectionData) === null || _props$sectionData2 === void 0 ? void 0 : _props$sectionData2.introduction
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        md: 8,
        spacing: 2,
        justifyContent: 'space-between',
        children: (_props$sectionData3 = props.sectionData) === null || _props$sectionData3 === void 0 || (_props$sectionData3 = _props$sectionData3.skillsets) === null || _props$sectionData3 === void 0 ? void 0 : _props$sectionData3.map((skillset, index2) => {
          return (0, _jsxRuntime.jsx)(_ResumeSkillSetItem.default, {
            skillset: skillset
          }, index2);
        })
      })]
    })
  });
};
var _default = exports.default = ResumeSkillsSection;