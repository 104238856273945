"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.search.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _react = require("react");
var _SnackbarContext = _interopRequireDefault(require("../../modal-context/SnackbarContext"));
var _reactRouter = require("react-router");
var _SearchContext = _interopRequireDefault(require("./search-context/SearchContext"));
var _FirebaseContext = _interopRequireDefault(require("../../../common/firebase/firebase-context/FirebaseContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const CocktailDbResults = () => {
  var _searchContext$additi;
  const snackBar = (0, _react.useContext)(_SnackbarContext.default);
  const location = (0, _reactRouter.useLocation)();
  const searchContext = (0, _react.useContext)(_SearchContext.default);
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const getIngredientsFromCocktailDbCocktail = cocktailDbCocktail => {
    const numIngredients = [...Array(10).keys()];
    return numIngredients.map((ingredientNumber, index) => {
      return (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        spacing: 1,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: "subtitle2",
            children: cocktailDbCocktail["strMeasure" + ingredientNumber]
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: "subtitle2",
            children: cocktailDbCocktail["strIngredient" + ingredientNumber]
          })
        })]
      }, index);
    });
  };
  const openNotification = cocktailDbResult => {
    var _cocktailDbResult$str;
    const snack = (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      spacing: 2,
      sx: {
        color: "white"
      },
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        xs: 12,
        children: (0, _jsxRuntime.jsx)("img", {
          alt: cocktailDbResult.strDrink,
          src: cocktailDbResult.strDrinkThumb,
          height: 200
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        xs: 12,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: "body2",
          color: 'inherit',
          children: cocktailDbResult.strDrink
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        xs: 12,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: "subtitle2",
          children: cocktailDbResult.strGlass
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        xs: 12,
        children: getIngredientsFromCocktailDbCocktail(cocktailDbResult)
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        xs: 12,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: "subtitle2",
          children: cocktailDbResult.strInstructions
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        xs: 12,
        children: (0, _jsxRuntime.jsx)(_material.Chip, {
          size: 'medium',
          color: 'secondary',
          label: cocktailDbResult.strCategory
        })
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        xs: 12,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'caption',
            children: "Tags"
          })
        }), (_cocktailDbResult$str = cocktailDbResult.strTags) === null || _cocktailDbResult$str === void 0 || (_cocktailDbResult$str = _cocktailDbResult$str.split(',')) === null || _cocktailDbResult$str === void 0 ? void 0 : _cocktailDbResult$str.map((tag, index) => {
          return (0, _jsxRuntime.jsx)(_material.Chip, {
            size: 'small',
            color: 'secondary',
            label: tag
          }, index);
        })]
      })]
    });
    snackBar.openSnackbar && snackBar.openSnackbar(snack);
    cocktailDbResult && firebaseContext.analyticsPageView && firebaseContext.analyticsPageView(location.pathname, location.search, "".concat(cocktailDbResult.strDrink, " | Cocktail from cocktailDb"));
  };
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    item: true,
    container: true,
    spacing: 1,
    children: (_searchContext$additi = searchContext.additionalResults) === null || _searchContext$additi === void 0 ? void 0 : _searchContext$additi.map((cocktailDbResult, index2) => {
      return (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Chip, {
          color: 'secondary',
          onClick: () => openNotification(cocktailDbResult),
          label: cocktailDbResult.strDrink
        })
      }, index2);
    })
  });
};
var _default = exports.default = CocktailDbResults;