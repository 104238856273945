"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.promise.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _CustomizedThemeContext = _interopRequireDefault(require("./CustomizedThemeContext"));
var _material = require("@mui/material");
var _styles = require("@mui/material/styles");
var _PageContext = _interopRequireDefault(require("../page-context/PageContext"));
var _Theme = _interopRequireDefault(require("../../theme/Theme"));
var _getThemeFromSanity = _interopRequireDefault(require("./getThemeFromSanity"));
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const CustomizedThemeProvider = props => {
  var _pageContext$page3;
  const [customizedTheme, setCustomizedTheme] = (0, _react.useState)(_Theme.default);
  // const fonts = `"Raleway", "Oswald"`
  const pageContext = (0, _react.useContext)(_PageContext.default);
  (0, _react.useEffect)(() => {
    var _pageContext$page;
    if ((_pageContext$page = pageContext.page) !== null && _pageContext$page !== void 0 && _pageContext$page.theme) {
      var _pageContext$page2;
      const theCustomizedTheme = (0, _getThemeFromSanity.default)((_pageContext$page2 = pageContext.page) === null || _pageContext$page2 === void 0 ? void 0 : _pageContext$page2.theme);
      setCustomizedTheme(theCustomizedTheme);
    } else {
      setCustomizedTheme(_Theme.default);
    }
    // eslint-disable-next-line
  }, [(_pageContext$page3 = pageContext.page) === null || _pageContext$page3 === void 0 ? void 0 : _pageContext$page3.theme]);
  (0, _react.useEffect)(() => {
    setLogoSrc(props.logoSrc);
  }, [props.logoSrc]);
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const getThemeBySlug = async slug => {
    const theRetrievedTheme = await sanityContext.fetchMuiTheme(slug);
    return (0, _getThemeFromSanity.default)(theRetrievedTheme);
  };
  const [logoSrc, setLogoSrc] = (0, _react.useState)();
  const newValue = (0, _react.useMemo)(() => ({
    customizedTheme,
    getThemeBySlug,
    logoSrc
  }), [customizedTheme, getThemeBySlug, logoSrc]);
  return (0, _jsxRuntime.jsx)(_CustomizedThemeContext.default.Provider, {
    value: newValue,
    children: (0, _jsxRuntime.jsxs)(_styles.ThemeProvider, {
      theme: customizedTheme,
      children: [(0, _jsxRuntime.jsx)(_material.CssBaseline, {}), props.children]
    })
  });
};
var _default = exports.default = CustomizedThemeProvider;