"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.promise.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _framerMotion = require("framer-motion");
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
var _uuid = require("uuid");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const AnimatedPortfolioItem = props => {
  var _props$portfolioItem, _props$portfolioItem2, _props$portfolioItem3, _props$portfolioItem4;
  const theme = (0, _material.useTheme)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const controls = (0, _framerMotion.useAnimationControls)();
  const animateServiceHover = async () => {
    controls.start({
      scale: 1.01,
      opacity: 1
    }, {
      duration: .5
    });
  };
  const animateServiceNoHover = async () => {
    controls.start({
      scale: 1,
      opacity: .9
    }, {
      duration: .25
    });
  };
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    role: 'portfoliobutton',
    container: true,
    item: true,
    xs: 12,
    md: 6,
    lg: 4,
    alignContent: 'flex-start',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    children: (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
      initial: {
        scale: 1
      },
      animate: controls,
      style: {
        width: "100%"
      },
      onHoverStart: async () => {
        animateServiceHover();
      },
      onHoverEnd: async () => {
        animateServiceNoHover();
      },
      children: (0, _jsxRuntime.jsx)(_material.Button, {
        style: {
          backgroundImage: "url(".concat(sanityContext.placeholderOrImage && sanityContext.placeholderOrImage((_props$portfolioItem = props.portfolioItem) === null || _props$portfolioItem === void 0 ? void 0 : _props$portfolioItem.coverImage, 300, 250), ")"),
          backgroundSize: "cover",
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          margin: "8px",
          height: "500px"
        },
        fullWidth: true,
        onClick: () => props.action(props.portfolioItem),
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          style: {
            position: "relative",
            height: "100%"
          },
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            sx: {
              position: "absolute",
              bottom: -20
            },
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsxs)(_material.Grid, {
              item: true,
              xs: 11,
              container: true,
              justifyContent: 'space-between',
              sx: {
                backgroundColor: theme.palette.background.paper,
                marginBottom: "16px",
                padding: theme.spacing(4, 3),
                borderRadius: "4px"
              },
              children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                item: true,
                xs: 10,
                children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
                  container: true,
                  item: true,
                  alignItems: 'center',
                  children: [(0, _jsxRuntime.jsx)(_material.Box, {
                    sx: {
                      height: 3,
                      width: 26,
                      marginRight: "2px",
                      backgroundColor: theme.palette.primary.main
                    }
                  }), (0, _jsxRuntime.jsx)(_material.Typography, {
                    fontSize: 'large',
                    textTransform: 'uppercase',
                    variant: 'h6',
                    align: 'left',
                    children: (_props$portfolioItem2 = props.portfolioItem) === null || _props$portfolioItem2 === void 0 ? void 0 : _props$portfolioItem2.preTitle
                  })]
                }), (0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    color: 'textPrimary',
                    fontSize: 'large',
                    variant: 'body2',
                    align: 'left',
                    gutterBottom: true,
                    children: (_props$portfolioItem3 = props.portfolioItem) === null || _props$portfolioItem3 === void 0 ? void 0 : _props$portfolioItem3.title
                  })
                }), (0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  item: true,
                  style: {
                    paddingRight: "8px"
                  },
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    fontSize: 'medium',
                    color: 'textPrimary',
                    variant: 'body1',
                    align: 'left',
                    gutterBottom: true,
                    children: (_props$portfolioItem4 = props.portfolioItem) === null || _props$portfolioItem4 === void 0 ? void 0 : _props$portfolioItem4.detailDescription
                  })
                })]
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                xs: 2,
                sm: 2,
                justifyContent: 'flex-end',
                alignContent: 'center',
                alignItems: 'center',
                children: (0, _jsxRuntime.jsx)(_material.IconButton, {
                  color: 'primary',
                  sx: {
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark
                    },
                    width: "50px",
                    height: "50px",
                    border: "1px solid black"
                  },
                  children: (0, _jsxRuntime.jsx)(_iconsMaterial.ArrowRightAlt, {
                    color: 'secondary'
                  })
                })
              })]
            })
          })
        })
      })
    })
  }, (0, _uuid.v4)());
};
var _default = exports.default = AnimatedPortfolioItem;