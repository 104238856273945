"use strict";

require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCommonStyles = void 0;
require("core-js/modules/es.error.cause.js");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const useCommonStyles = exports.useCommonStyles = (0, _makeStyles.default)(theme => ({
  layoutContainer: {
    marginBottom: '32px'
  },
  primaryTextColor: {
    color: theme.palette.primary.main
  },
  secondaryTextColor: {
    color: theme.palette.secondary.main
  },
  bold: {
    fontWeight: 700
  },
  bodyText: {
    // fontFamily: 'DTL Documenta',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '28px',
    letterSpacing: '0.5px',
    color: '#333333',
    padding: theme.spacing(1, 0)
  },
  callToAction: {
    borderTop: '1px solid rgba(0,0,0,.12)',
    borderBottom: '1px solid rgba(0,0,0,.12)',
    width: '100%',
    padding: theme.spacing(3, 0)
  },
  root: {
    width: '1050px',
    padding: '40px',
    overflow: 'visible'
  },
  toolTiplink: {
    width: "100%",
    "&:hover": {
      color: "#FFFFFF",
      textDecoration: "underline #FFFFFF"
    }
  },
  homePageH3: {
    fontWeight: 300
  },
  lightWeightFont: {
    fontWeight: 300,
    letterSpacing: '-1.5px'
  },
  boldWeightFont: {
    fontWeight: 700
  },
  dropCapLetter: {
    float: 'left',
    padding: theme.spacing(1),
    fontSize: '59px',
    fontWeight: 400,
    lineHeight: '30px',
    letterSpacing: '0.5px',
    textTransform: 'capitalize'
  },
  keystroke: _objectSpread({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #6D6D6D',
    backgroundColor: '#3D3D3D',
    color: 'whitesmoke',
    borderRadius: '8px',
    // display: "inline-block",
    width: '50px !important',
    height: '50px !important'
  }, theme.typography.h4),
  hr: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    width: '100%',
    height: '1px'
  },
  bulletIcon: {
    fontSize: '10px'
  },
  bulletIconContainer: {
    marginTop: theme.spacing(1.5),
    minWidth: '20px'
  },
  orderedListIndex: {
    marginTop: theme.spacing(-1.25),
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.5px'
  },
  listItemRoot: {
    paddingLeft: '8px'
  }
}));