"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _MainMenu = _interopRequireDefault(require("./MainMenu"));
var _FilteredMenuItems = _interopRequireDefault(require("../../../filtered-menu-items/FilteredMenuItems"));
var _Logo = _interopRequireDefault(require("../../../logo/Logo"));
var _iconsMaterial = require("@mui/icons-material");
var _FullTextSearch = _interopRequireDefault(require("./FullTextSearch"));
var _AppBarWrapper = _interopRequireDefault(require("./AppBarWrapper"));
var _ColorPalette = require("../../../../theme/common/ColorPalette");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _PageContext = _interopRequireDefault(require("../../../page-context/PageContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  hover: {
    "&:hover": {
      backgroundColor: "lightgray !important",
      color: "#383838"
    }
  },
  hoverSearch: {
    "&:hover": {
      color: theme.palette.primary.main
    }
  }
}));
const Header = props => {
  var _props$pageHeader, _pageContext$page, _props$pageHeader2, _props$pageHeader3, _props$pageHeader4, _props$businessContac, _props$businessContac2, _props$businessContac3, _props$pageHeader$sub, _props$pageHeader5;
  const classes = useStyles();
  const theme = (0, _material.useTheme)();
  const xsDown = (0, _material.useMediaQuery)(theme.breakpoints.down('xs'));
  const smDown = (0, _material.useMediaQuery)(theme.breakpoints.down('sm'));
  const mdDown = (0, _material.useMediaQuery)(theme.breakpoints.down('md'));
  const [isSearchOpen, setIsSearchOpen] = (0, _react.useState)(false);
  const pageContext = (0, _react.useContext)(_PageContext.default);
  return (0, _jsxRuntime.jsxs)(_AppBarWrapper.default, {
    backgroundColor: props.backgroundColor,
    isAppBar: props.isAppBar,
    isEnhanced: props.isEnhanced,
    children: [(_props$pageHeader = props.pageHeader) !== null && _props$pageHeader !== void 0 && _props$pageHeader.title ? (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      container: true,
      alignContent: 'center',
      alignItems: 'center',
      style: {
        height: "100%"
      },
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        xs: 8,
        md: 4,
        wrap: 'nowrap',
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 8,
          sm: 7,
          container: true,
          sx: {
            minWidth: "190px"
          },
          children: (0, _jsxRuntime.jsx)(_material.Link, {
            sx: {
              width: "100%"
            },
            href: (_pageContext$page = pageContext.page) === null || _pageContext$page === void 0 ? void 0 : _pageContext$page.website,
            children: (0, _jsxRuntime.jsx)(_Logo.default, {
              logoImageSrc: (_props$pageHeader2 = props.pageHeader) === null || _props$pageHeader2 === void 0 ? void 0 : _props$pageHeader2.logoImageSrc,
              noWrap: true,
              logoText: (_props$pageHeader3 = props.pageHeader) === null || _props$pageHeader3 === void 0 ? void 0 : _props$pageHeader3.logoText,
              logoAccentText: (_props$pageHeader4 = props.pageHeader) === null || _props$pageHeader4 === void 0 ? void 0 : _props$pageHeader4.logoAccentText
            })
          })
        }), props.pageHeader.isShowSocialMedia && !smDown ? (0, _jsxRuntime.jsxs)(_material.Grid, {
          xs: 4,
          sm: 5,
          container: true,
          item: true,
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: xsDown ? 'center' : "flex-start",
          wrap: 'nowrap',
          children: [(0, _jsxRuntime.jsx)(_material.IconButton, {
            href: "https://facebook.com/".concat((_props$businessContac = props.businessContact) === null || _props$businessContac === void 0 ? void 0 : _props$businessContac.facebook),
            color: 'primary',
            children: (0, _jsxRuntime.jsx)(_iconsMaterial.Facebook, {})
          }), (0, _jsxRuntime.jsx)(_material.IconButton, {
            href: "https://instagram.com/".concat((_props$businessContac2 = props.businessContact) === null || _props$businessContac2 === void 0 ? void 0 : _props$businessContac2.instagram),
            color: 'primary',
            children: (0, _jsxRuntime.jsx)(_iconsMaterial.Instagram, {})
          }), (0, _jsxRuntime.jsx)(_material.IconButton, {
            href: "https://twitter.com/".concat((_props$businessContac3 = props.businessContact) === null || _props$businessContac3 === void 0 ? void 0 : _props$businessContac3.twitter),
            color: 'primary',
            children: (0, _jsxRuntime.jsx)(_iconsMaterial.Twitter, {})
          })]
        }) : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})]
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        xs: 4,
        md: 8,
        justifyContent: 'flex-end',
        wrap: 'nowrap',
        children: [!mdDown && (0, _jsxRuntime.jsx)(_material.Grid, {
          xs: 4,
          md: 10,
          lg: 10,
          container: true,
          item: true,
          justifyContent: 'flex-end',
          alignItems: 'center',
          alignContent: 'center',
          sx: {
            height: "100%",
            paddingRight: mdDown ? theme.spacing(0) : theme.spacing(4)
          },
          children: (0, _jsxRuntime.jsx)(_FilteredMenuItems.default
          // bgColor={!mdDown ? TransformHWTheme.palette.primary.main : COLORS.TRANSPARENTWHITE}
          , {
            // bgColor={!mdDown ? TransformHWTheme.palette.primary.main : COLORS.TRANSPARENTWHITE}
            subMenus: (_props$pageHeader$sub = (_props$pageHeader5 = props.pageHeader) === null || _props$pageHeader5 === void 0 ? void 0 : _props$pageHeader5.subMenus) !== null && _props$pageHeader$sub !== void 0 ? _props$pageHeader$sub : [],
            onlyButtons: mdDown,
            includeMenuItems: !mdDown,
            includeMenuGroups: !mdDown
          })
        }), mdDown && (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 5,
          sm: 4,
          container: true,
          justifyContent: 'flex-end',
          children: props.pageHeader && (0, _jsxRuntime.jsx)(_MainMenu.default, {
            menu: props.pageHeader,
            anchor: 'top'
          })
        }), props.isSearch && (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          maxWidth: 96,
          // style={{backgroundColor: "blue"}}
          justifyContent: 'flex-end',
          alignContent: 'center',
          alignItems: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Box, {
              sx: {
                // maxWidth: "64px",
                paddingLeft: "16px",
                paddingRight: mdDown ? "16px" : "8px",
                borderLeft: "1px solid ".concat(theme.palette.primary.main)
              },
              children: (0, _jsxRuntime.jsx)(_material.IconButton, {
                onClick: () => {
                  setIsSearchOpen(state => !state);
                },
                color: 'secondary',
                sx: {
                  // marginLeft: "32px",
                  // marginRight: "32px",
                  color: theme.palette.text.secondary,
                  backgroundColor: theme.palette.primary.main
                },
                className: classes.hoverSearch,
                children: (0, _jsxRuntime.jsx)(_iconsMaterial.Search, {
                  color: 'inherit',
                  fontSize: 'large'
                })
              })
            })
          })
        })]
      })]
    }) : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {}), (0, _jsxRuntime.jsx)(_material.Modal, {
      open: isSearchOpen,
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        style: {
          height: "100%"
        },
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'flex-end',
          children: (0, _jsxRuntime.jsx)(_material.IconButton, {
            sx: {
              marginTop: "4px",
              marginRight: "4px",
              width: "64px",
              height: "64px",
              backgroundColor: _ColorPalette.COLORS.WHITESMOKE
            },
            onClick: () => {
              setIsSearchOpen(false);
            },
            className: classes.hover,
            children: (0, _jsxRuntime.jsx)(_iconsMaterial.Close, {
              sx: {
                marginX: "16px",
                marginY: "16px"
              },
              fontSize: "large",
              color: 'secondary'
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          paddingX: '8px',
          alignContent: 'flex-start',
          alignItems: 'flex-start',
          children: (0, _jsxRuntime.jsx)(_FullTextSearch.default, {})
        })]
      })
    })]
  });
};
var _default = exports.default = Header;