"use strict";

require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.promise.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _PageContext = _interopRequireDefault(require("./PageContext"));
var _SnackbarContext = _interopRequireDefault(require("../modal-context/SnackbarContext"));
var _uuid = require("uuid");
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const initialState = {
  loading: false,
  page: undefined,
  isRefetching: false,
  isPageError: false,
  error: undefined,
  pageSlug: "home",
  allServices: [],
  pageHeader: undefined,
  pageFooter: undefined,
  analyticsId: (0, _uuid.v4)().toString(),
  baseRoute: "",
  googleMapsApiKey: "",
  barInventorySlug: ""
};
const reducer = (state, action) => {
  var _action$payload$page, _action$payload$page2;
  switch (action.type) {
    case 'INITIAL':
      return initialState;
    case 'START_PAGE_LOAD':
      // console.log("in page load switch", action.payload.pageSlug)
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        pageSlug: action.payload.pageSlug
      });
    case 'FETCH_DOCUMENT':
      // console.log(`in page context fetch document fetching document type`)
      return _objectSpread(_objectSpread({}, state), {}, {
        documentType: action.payload.documentType,
        documentSlug: action.payload.documentSlug
      });
    case 'SET_BASE_ROUTE':
      // console.log(`in page context update the base Route ${action.payload.baseRoute}`)
      return _objectSpread(_objectSpread({}, state), {}, {
        baseRoute: action.payload.baseRoute
      });
    case 'SET_GOOGLE_MAPS_API_KEY':
      return _objectSpread(_objectSpread({}, state), {}, {
        googleMapsApiKey: action.payload.googleMapsApiKey
      });
    case 'SET_BAR_INVENTORY_SLUG':
      console.log("in page context provider/reducer update the bar inventory slug ".concat(action.payload.barInventorySlug));
      return _objectSpread(_objectSpread({}, state), {}, {
        barInventorySlug: action.payload.barInventorySlug
      });
    case 'LOAD_PAGE_COMPONENTS':
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        page: action.payload.page,
        pageHeader: (_action$payload$page = action.payload.page) === null || _action$payload$page === void 0 ? void 0 : _action$payload$page.headerMenuRef,
        pageFooter: (_action$payload$page2 = action.payload.page) === null || _action$payload$page2 === void 0 ? void 0 : _action$payload$page2.footerMenuRef
      });
    case 'LOAD_SERVICES':
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        allServices: action.payload.allServices
      });
    case 'PAGE_LOADING':
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: action.payload.loading
      });
    case "ERROR":
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        isError: action.payload.isError,
        pageError: action.payload.error
      });
    default:
      throw new Error();
  }
};
const PageProvider = props => {
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const [state, dispatch] = (0, _react.useReducer)(reducer, initialState);
  const [pageData, setPageData] = (0, _react.useState)();
  const [documentData, setDocumentData] = (0, _react.useState)();
  const [isLoading] = (0, _react.useState)(true);
  // const loadedPageQuery = sanityContext.useFetchPageBySlugQuery(state.pageSlug)
  (0, _react.useEffect)(() => {
    sanityContext.fetchDocumentByTypeAndSlugQuery(state.documentType, state.documentSlug).then(result => {
      // console.log(`in page context fetch document fetching document type: ${state.documentType} with slug: ${state.documentSlug}`)
      setDocumentData(result);
    }).catch(e => {
      console.log("ERROR: in page context fetch document fetching document type: ".concat(state.documentType, " with slug: ").concat(state.documentSlug, " "), e);
    });
  }, [state.documentType, state.documentSlug, sanityContext.theSanityClient]);
  (0, _react.useEffect)(() => {
    if (!props.page && state.pageSlug && state.pageSlug.length > 0) {
      sanityContext.fetchPageBySlugQuery(state.pageSlug).then(result => {
        setPageData(result);
      }).catch(e => {
        console.log("ERROR: ", e);
      });
    }
  }, [state.pageSlug, sanityContext.theSanityClient]);
  (0, _react.useEffect)(() => {
    if (props.googleApiKey) {
      updateGoogleApiKey(props.googleApiKey);
      // dispatch({
      //     type: "SET_GOOGLE_MAPS_API_KEY",
      //     payload: {
      //         googleMapsApiKey: props.googleApiKey,
      //     }
      // })
    }
  }, [props.googleApiKey]);
  (0, _react.useEffect)(() => {
    if (props.barInventorySlug) {
      updateBarInventorySlug(props.barInventorySlug);
      // dispatch({
      //     type: "SET_BAR_INVENTORY_SLUG",
      //     payload: {
      //         barInventorySlug: props.barInventorySlug,
      //     }
      // })
    }
  }, [props.barInventorySlug]);
  (0, _react.useEffect)(() => {
    if (props.page && !state.page) {
      console.log("page came in from storybook or test", props.page);
      dispatch({
        type: "LOAD_PAGE_COMPONENTS",
        payload: {
          page: props.page
        }
      });
    }
  }, [props.page, state.page]);
  (0, _react.useEffect)(() => {
    if (!props.page && pageData) {
      dispatch({
        type: "LOAD_PAGE_COMPONENTS",
        payload: {
          page: pageData
        }
      });
    }
  }, [pageData]);
  (0, _react.useEffect)(() => {
    dispatch({
      type: "PAGE_LOADING",
      payload: {
        loading: isLoading
      }
    });
  }, [isLoading]);
  (0, _react.useEffect)(() => {
    dispatch({
      type: "PAGE_LOADING",
      payload: {
        loading: isLoading
      }
    });
  }, [isLoading]);
  const fetchPage = async pageSlug => {
    // console.log("Fetching the page", pageSlug)
    dispatch({
      type: "START_PAGE_LOAD",
      payload: {
        pageSlug: pageSlug
      }
    });
  };
  const updateBaseRoute = baseRoute => {
    // console.log(`updating baseRoute to ${baseRoute} in pageProvider`)
    dispatch({
      type: "SET_BASE_ROUTE",
      payload: {
        baseRoute: baseRoute
      }
    });
  };
  const updateGoogleApiKey = apiKey => {
    dispatch({
      type: "SET_GOOGLE_MAPS_API_KEY",
      payload: {
        googleMapsApiKey: apiKey
      }
    });
  };
  const updateBarInventorySlug = barInventorySlug => {
    dispatch({
      type: "SET_BAR_INVENTORY_SLUG",
      payload: {
        barInventorySlug: barInventorySlug
      }
    });
  };
  const fetchDocument = async (documentType, documentSlug) => {
    // console.log(`"Fetching the document type:${documentType} that has slug:${documentSlug} `)
    dispatch({
      type: "FETCH_DOCUMENT",
      payload: {
        documentType: documentType,
        documentSlug: documentSlug
      }
    });
  };
  // useEffect(() => {
  //     if (allServicesQuery.data) {
  //         console.log("context services data", allServicesQuery.data)
  //         dispatch({
  //             type: "LOAD_SERVICES",
  //             payload: {
  //                 allServices: allServicesQuery.data
  //             }
  //         })
  //     }
  // }, [allServicesQuery.data])
  // useEffect(() => {
  //     dispatch({
  //         type: "PAGE_LOADING",
  //         payload: {
  //             loading: allServicesQuery.isLoading
  //         }
  //     })
  // }, [allServicesQuery.isLoading])
  // useEffect(() => {
  //     dispatch({
  //         type: "PAGE_LOADING",
  //         payload: {
  //             loading: allServicesQuery.isRefetching
  //         }
  //     })
  // }, [allServicesQuery.isRefetching])
  // useEffect(() => {
  //     if (allServicesQuery.isError) {
  //         dispatch({
  //             type: "ERROR",
  //             payload: {
  //                 isError: allServicesQuery.isError,
  //                 pageError: allServicesQuery.error
  //             }
  //         })
  //     }
  // }, [allServicesQuery.isError])
  // useEffect(() => {
  //     if (!headerMenuQuery.isPreviousData && headerMenuQuery.data) {
  //         console.log("context header data", headerMenuQuery.data)
  //         dispatch({
  //             type: "LOAD_HEADER",
  //             payload: {
  //                 pageHeader: headerMenuQuery.data
  //             }
  //         })
  //     }
  // }, [headerMenuQuery.data])
  // useEffect(() => {
  //     dispatch({
  //         type: "PAGE_LOADING",
  //         payload: {
  //             loading: headerMenuQuery.isLoading
  //         }
  //     })
  // }, [headerMenuQuery.isLoading])
  // useEffect(() => {
  //     dispatch({
  //         type: "PAGE_LOADING",
  //         payload: {
  //             loading: headerMenuQuery.isRefetching
  //         }
  //     })
  // }, [headerMenuQuery.isRefetching])
  // useEffect(() => {
  //     if (headerMenuQuery.isError) {
  //         dispatch({
  //             type: "ERROR",
  //             payload: {
  //                 isError: headerMenuQuery.isError,
  //                 pageError: headerMenuQuery.error
  //             }
  //         })
  //     }
  // }, [headerMenuQuery.isError])
  //
  //
  // useEffect(() => {
  //     if (!footerMenuQuery.isPreviousData && footerMenuQuery.data) {
  //         console.log("context footer data", footerMenuQuery.data)
  //         dispatch({
  //             type: "LOAD_FOOTER",
  //             payload: {
  //                 pageFooter: footerMenuQuery.data
  //             }
  //         })
  //     }
  // }, [footerMenuQuery.data])
  // useEffect(() => {
  //     dispatch({
  //         type: "PAGE_LOADING",
  //         payload: {
  //             loading: footerMenuQuery.isLoading
  //         }
  //     })
  // }, [footerMenuQuery.isLoading])
  // useEffect(() => {
  //     dispatch({
  //         type: "PAGE_LOADING",
  //         payload: {
  //             loading: footerMenuQuery.isRefetching
  //         }
  //     })
  // }, [footerMenuQuery.isRefetching])
  // useEffect(() => {
  //     if (footerMenuQuery.isError) {
  //         dispatch({
  //             type: "ERROR",
  //             payload: {
  //                 isError: footerMenuQuery.isError,
  //                 pageError: footerMenuQuery.error
  //             }
  //         })
  //     }
  // }, [footerMenuQuery.isError])
  const snackbarContext = (0, _react.useContext)(_SnackbarContext.default);
  (0, _react.useEffect)(() => {
    if (state.error) {
      snackbarContext.openSnackbar && snackbarContext.openSnackbar(state.error);
    }
  }, [state.error, state.isPageError]);
  (0, _react.useEffect)(() => {
    dispatch({
      type: "PAGE_LOADING",
      payload: {
        loading: !!state.page
      }
    });
  }, [isLoading]);
  const getOtherServices = pageSlug => {
    var _state$allServices$fi, _state$allServices;
    return (_state$allServices$fi = (_state$allServices = state.allServices) === null || _state$allServices === void 0 ? void 0 : _state$allServices.filter(service => {
      return pageSlug !== service.slug.current;
    })) !== null && _state$allServices$fi !== void 0 ? _state$allServices$fi : [];
  };
  const newValue = (0, _react.useMemo)(() => ({
    page: state.page,
    slug: state.pageSlug,
    pageHeader: state.pageHeader,
    pageFooter: state.pageFooter,
    isPageLoading: state.loading,
    isRefetching: state.isRefetching,
    isPageError: state.isPageError,
    fetchPage,
    allServices: state.allServices,
    getOtherServices,
    analyticsId: state.analyticsId,
    fetchDocument,
    documentData,
    updateBaseRoute,
    baseRoute: state.baseRoute,
    updateGoogleApiKey,
    googleMapsApiKey: state.googleMapsApiKey
  }), [state.page, state.pageSlug, state.pageHeader, state.pageFooter, state.loading, state.isRefetching, state.isPageError, fetchPage, state.allServices, getOtherServices, state.analyticsId, state.baseRoute, state.googleMapsApiKey]);
  return (0, _jsxRuntime.jsx)(_PageContext.default.Provider, {
    value: newValue,
    children: props.children
  });
};
var _default = exports.default = PageProvider;