"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
const extractImageExtensionFromFile = aFile => {
  if (aFile) {
    const fileTypeStr = aFile.type;
    if (fileTypeStr) console.log('teststring to extract extension', fileTypeStr);
    if ((fileTypeStr === null || fileTypeStr === void 0 ? void 0 : fileTypeStr.length) > 0) {
      return fileTypeStr.includes('png') ? 'png' : 'jpg';
    }
  }
  return '';
};
const extractImageExtensionFromFileUrl = aFile => {
  if (aFile) {
    const fileTypeStr = aFile.type;
    if (fileTypeStr) console.log('teststring to extract extension', fileTypeStr);
    if ((fileTypeStr === null || fileTypeStr === void 0 ? void 0 : fileTypeStr.length) > 0) {
      return fileTypeStr.includes('png') ? 'png' : 'jpg';
    }
  }
  return '';
};
var _default = exports.default = {
  extractImageExtensionFromFile,
  extractImageExtensionFromFileUrl
};