"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _styles = require("@mui/material/styles");
var _material = require("@mui/material");
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const AWHouseInfoSection = () => {
  const theme = (0, _styles.useTheme)();
  const [allHouses, setAllHouses] = (0, _react.useState)([]);
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  (0, _react.useEffect)(() => {
    sanityContext.fetchVerifiedHouses && sanityContext.fetchVerifiedHouses().then(verifiedHouses => {
      console.log("The Verified houses", verifiedHouses);
      setAllHouses(verifiedHouses);
    });
  }, []);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    // alignItems="center"
    item: true,
    justifyContent: "center",
    style: {
      padding: theme.spacing(8.5, 4)
    },
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        justifyContent: 'center',
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: "h3",
          color: "textSecondary",
          children: "House Info"
        })
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      xs: 10,
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.List, {
          children: allHouses.map((theHouse, index) => {
            return (0, _jsxRuntime.jsx)(_material.ListItem, {
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: "body1",
                color: "textSecondary",
                gutterBottom: true,
                children: theHouse.houseName
              })
            }, index);
          })
        })
      })
    })]
  });
};
var _default = exports.default = AWHouseInfoSection;