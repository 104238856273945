"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _ColoredPng = _interopRequireDefault(require("../../../colored-png/ColoredPng"));
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
var _framerMotion = require("framer-motion");
var _iconsMaterial = require("@mui/icons-material");
var _LinkedExperiences = _interopRequireDefault(require("./LinkedExperiences"));
var _LinkedPortfolioEntries = _interopRequireDefault(require("./LinkedPortfolioEntries"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const ResumeSkillTooltipWrapper = props => {
  var _props$resumeSkill$pr, _props$resumeSkill$pr2, _props$resumeSkill$pr3, _props$resumeSkill$pr4;
  const customizedThemeContext = (0, _material.useTheme)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const [isTipOpen, setIsTipOpen] = (0, _react.useState)(false);
  const closeTip = () => {
    setIsTipOpen(false);
  };
  const openTip = () => {
    setIsTipOpen(true);
  };
  (0, _react.useEffect)(() => {
    if (isTipOpen !== props.isTipOpen) {
      setIsTipOpen(props.isTipOpen);
    }
  }, [props.isTipOpen]);
  // const smDown = useMediaQuery(customizedThemeContext.breakpoints.down('sm'))
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    children: props.resumeSkill.description ? (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
      whileHover: {
        color: customizedThemeContext.palette.primary.main
      },
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20
      },
      children: (0, _jsxRuntime.jsx)(_material.Tooltip, {
        open: isTipOpen,
        onClose: closeTip,
        onOpen: openTip,
        componentsProps: {
          tooltip: {
            style: {
              color: customizedThemeContext.palette.text.secondary,
              backgroundColor: customizedThemeContext.palette.primary.main
            }
          }
        },
        // disableHoverListener={smDown}
        title: (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
          style: {
            backgroundColor: customizedThemeContext.palette.primary.main,
            padding: "16px",
            borderRadius: "2px",
            overflow: "hidden"
          },
          whileHover: {
            scale: 1.5,
            padding: "16px",
            color: customizedThemeContext.palette.primary.main
          },
          transition: {
            type: "spring",
            stiffness: 260,
            damping: 20
          },
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            paddingTop: "2px",
            paddingBottom: "4px",
            position: 'relative',
            spacing: 1,
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              color: 'white',
              position: 'absolute',
              top: -20,
              right: -22,
              width: '100%',
              paddingTop: customizedThemeContext.spacing(1.5),
              paddingRight: customizedThemeContext.spacing(.75),
              justifyContent: 'flex-end',
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.IconButton, {
                  size: 'small',
                  color: 'inherit',
                  onClick: () => {
                    closeTip();
                  },
                  children: (0, _jsxRuntime.jsx)(_iconsMaterial.Close, {
                    color: 'inherit'
                  })
                })
              })
            }), (0, _jsxRuntime.jsxs)(_material.Grid, {
              item: true,
              xs: 12,
              container: true,
              justifyContent: 'space-between',
              marginTop: 1,
              children: [props.resumeSkill.iconPngSrc && (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_ColoredPng.default, {
                  size: 56,
                  color: 'white',
                  maskUrl: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.resumeSkill.iconPngSrc, 56, 56)
                })
              }), props.resumeSkill.proficiency && (0, _jsxRuntime.jsxs)(_material.Grid, {
                item: true,
                justifyContent: 'center',
                justifyItems: 'center',
                alignItems: 'center',
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  container: true,
                  justifyContent: 'center',
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    variant: 'subtitle2',
                    color: 'white',
                    children: "Proficiency"
                  })
                }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                  item: true,
                  container: true,
                  justifyContent: 'center',
                  position: 'relative',
                  children: [(0, _jsxRuntime.jsx)(_material.Box, {
                    position: 'absolute',
                    top: "10px",
                    left: "16px",
                    children: (0, _jsxRuntime.jsxs)(_material.Typography, {
                      variant: 'caption',
                      color: 'white',
                      children: [((_props$resumeSkill$pr = (_props$resumeSkill$pr2 = props.resumeSkill.proficiency) === null || _props$resumeSkill$pr2 === void 0 ? void 0 : _props$resumeSkill$pr2.valueOf()) !== null && _props$resumeSkill$pr !== void 0 ? _props$resumeSkill$pr : 0) * 100, "%"]
                    })
                  }), (0, _jsxRuntime.jsx)(_material.CircularProgress, {
                    style: {
                      color: "white"
                    },
                    variant: 'determinate',
                    value: ((_props$resumeSkill$pr3 = (_props$resumeSkill$pr4 = props.resumeSkill.proficiency) === null || _props$resumeSkill$pr4 === void 0 ? void 0 : _props$resumeSkill$pr4.valueOf()) !== null && _props$resumeSkill$pr3 !== void 0 ? _props$resumeSkill$pr3 : 0) * 100
                  })]
                })]
              })]
            }), (0, _jsxRuntime.jsxs)(_material.Grid, {
              item: true,
              xs: 12,
              container: true,
              alignItems: 'center',
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                  item: true,
                  children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      variant: 'body1',
                      color: 'white',
                      fontWeight: 600,
                      children: props.resumeSkill.title
                    })
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    container: true,
                    children: (0, _jsxRuntime.jsx)(_LinkedExperiences.default, {
                      resumeSkill: props.resumeSkill
                    })
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      variant: 'subtitle1',
                      color: 'whitesmoke',
                      children: props.resumeSkill.description
                    })
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    container: true,
                    children: (0, _jsxRuntime.jsx)(_LinkedPortfolioEntries.default, {
                      resumeSkill: props.resumeSkill
                    })
                  })]
                })
              }), props.resumeSkill.versions && props.resumeSkill.versions.length > 0 && props.resumeSkill.versions[0].length > 0 && (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                children: (0, _jsxRuntime.jsxs)(_material.Box, {
                  style: {
                    borderTop: "1px solid #d2d2d2",
                    marginTop: '8px',
                    paddingTop: '8px',
                    width: "100%"
                  },
                  children: [(0, _jsxRuntime.jsx)(_material.Typography, {
                    display: 'inline',
                    variant: 'subtitle1',
                    color: 'white',
                    children: "Versions: "
                  }), props.resumeSkill.versions.map((versionNumber, index) => {
                    var _props$resumeSkill$ve, _props$resumeSkill$ve2;
                    return (0, _jsxRuntime.jsxs)(_material.Typography, {
                      display: 'inline',
                      variant: 'subtitle1',
                      color: 'white',
                      children: [versionNumber, index <= ((_props$resumeSkill$ve = (_props$resumeSkill$ve2 = props.resumeSkill.versions) === null || _props$resumeSkill$ve2 === void 0 ? void 0 : _props$resumeSkill$ve2.length) !== null && _props$resumeSkill$ve !== void 0 ? _props$resumeSkill$ve : -1) - 2 ? ' | ' : '']
                    }, index);
                  })]
                })
              })]
            })]
          })
        }),
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          onClick: openTip,
          children: props.children
        })
      })
    }) : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
      children: props.children
    })
  });
};
var _default = exports.default = ResumeSkillTooltipWrapper;