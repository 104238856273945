"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _FlashCardFront = _interopRequireDefault(require("./FlashCardFront"));
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _SearchContext = _interopRequireDefault(require("../search-context/SearchContext"));
var _material = require("@mui/material");
var _DrinkCardBottom = _interopRequireDefault(require("./DrinkCardBottom"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  root: {
    width: "100%",
    // height: 550,
    // minWidth: "423px",
    border: "1px solid black",
    padding: theme.spacing(6, 4)
  }
}));
const DrinkCard = props => {
  const classes = useStyles();
  const searchContext = (0, _react.useContext)(_SearchContext.default);
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    style: {
      minWidth: "423px"
      // backgroundSize:"600px 600px", backgroundImage:`url('${paperBackground}')`
    },
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      className: classes.root,
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        alignContent: 'center',
        children: (0, _jsxRuntime.jsx)(_FlashCardFront.default, {
          isDarkMode: props.isDarkMode,
          currentCard: searchContext.currentCard
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        alignContent: 'center',
        children: (0, _jsxRuntime.jsx)(_DrinkCardBottom.default, {
          isDarkMode: props.isDarkMode,
          currentCard: searchContext.currentCard
        })
      })]
    })
  });
};
var _default = exports.default = DrinkCard;