"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _ResumeSkillTooltipWrapper = _interopRequireDefault(require("./ResumeSkillTooltipWrapper"));
var _uuid = require("uuid");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const ResumeSkillSetItem = props => {
  var _props$skillset$skill;
  const [isToolTipOpen, setIsToolTipOpen] = (0, _react.useState)();
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    item: true,
    container: true,
    xs: 11,
    sm: 6,
    md: 6,
    alignContent: 'flex-start',
    role: 'skilldivider',
    style: {
      // borderBottom: `1px solid ${index2 >= (props.sectionData?.skillsets?.length ?? 0) - 2 ? 'transparent' : COLORS.LIGHTGRAY}`
    },
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: (0, _jsxRuntime.jsx)(_material.Typography, {
        display: 'inline',
        role: 'skillheader',
        variant: 'body2',
        children: props.skillset.title
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: (_props$skillset$skill = props.skillset.skills) === null || _props$skillset$skill === void 0 ? void 0 : _props$skillset$skill.map((skill, index) => {
        var _props$skillset$skill2, _props$skillset$skill3;
        return (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          display: 'inline',
          onClick: () => {
            setIsToolTipOpen(index);
          },
          children: (0, _jsxRuntime.jsx)(_ResumeSkillTooltipWrapper.default, {
            isTipOpen: index === isToolTipOpen,
            resumeSkill: skill,
            children: (0, _jsxRuntime.jsxs)(_material.Typography, {
              role: 'subskill',
              display: 'inline',
              variant: 'body1',
              children: [skill.title, index !== ((_props$skillset$skill2 = (_props$skillset$skill3 = props.skillset.skills) === null || _props$skillset$skill3 === void 0 ? void 0 : _props$skillset$skill3.length) !== null && _props$skillset$skill2 !== void 0 ? _props$skillset$skill2 : 0) - 1 ? ',' : '', "\u00A0"]
            }, index)
          })
        }, index);
      })
    })]
  }, (0, _uuid.v4)());
};
var _default = exports.default = ResumeSkillSetItem;