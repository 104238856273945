"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _react = require("react");
var _ColorPalette = require("../../theme/common/ColorPalette");
const AlternatingText = props => {
  const [textArray, setTextArray] = (0, _react.useState)([]);
  (0, _react.useEffect)(() => {
    if (props.logoText) {
      setTextArray(props.logoText.split(' '));
    }
  }, [props.logoText]);
  return (0, _jsxRuntime.jsxs)(_material.Typography, {
    display: 'inline',
    component: 'div',
    align: 'center',
    children: [textArray.map((textArrayItem, index) => {
      return (0, _jsxRuntime.jsxs)(_material.Typography, {
        display: 'inline',
        align: 'center',
        color: 'primary',
        style: {
          fontFamily: "Oswald",
          fontWeight: "300",
          color: index % 2 !== 1 ? _ColorPalette.COLORS.DARKERGRAY : 'textPrimary'
        },
        variant: props.isLarge ? 'h4' : 'h5',
        children: [" ", textArrayItem]
      }, index);
    }), (0, _jsxRuntime.jsx)(_material.Typography, {
      display: 'inline',
      style: {
        fontFamily: "Oswald",
        fontWeight: "300"
      },
      variant: props.isLarge ? 'h4' : 'h5',
      color: 'primary',
      children: props.logoAccentText
    })]
  });
};
var _default = exports.default = AlternatingText;