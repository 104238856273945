"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.json.stringify.js");
require("core-js/modules/es.regexp.to-string.js");
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _BallType = require("../enums/BallType.enum");
const FinishAddBall = props => {
  var _props$status, _props$status2, _props$status3, _props$status4;
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    "data-testid": 'add-ball-finish-step',
    direction: 'column',
    children: ((_props$status = props.status) === null || _props$status === void 0 || (_props$status = _props$status.status) === null || _props$status === void 0 ? void 0 : _props$status.statusCode) === 200 ? (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsx)(_material.Typography, {
        variant: 'h5',
        align: 'center',
        children: (0, _jsxRuntime.jsx)(_material.Box, {
          color: theme.palette.success.main,
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            direction: 'column',
            spacing: 5,
            children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              item: true,
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_iconsMaterial.CheckCircle, {
                  style: {
                    marginRight: theme.spacing(1.25)
                  },
                  fontSize: 'large'
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'h5',
                  display: 'inline',
                  children: "Add Ball Success!!"
                })
              })]
            }), (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              item: true,
              spacing: 2,
              direction: 'column',
              alignItems: 'center',
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'h6',
                  align: 'center',
                  children: "The ".concat((0, _BallType.renderBallTypeChoice)(props.newBallToAdd.ballType), " was submitted for approval!")
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  variant: 'body1',
                  style: {
                    width: '400px'
                  },
                  children: "The ball details have been submitted. We thank you for the information and will approve it as soon as possible."
                })
              })]
            }), props.newBallToAdd.notifyOnApproval && (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: 'center',
              children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                item: true,
                style: {
                  width: '800px'
                },
                children: [(0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'primary',
                  variant: 'body1',
                  display: 'inline',
                  style: {
                    margin: theme.spacing(0, .5)
                  },
                  children: props.newBallToAdd.notifyName
                }), (0, _jsxRuntime.jsx)(_material.Typography, {
                  display: 'inline',
                  component: 'div',
                  color: 'textPrimary',
                  variant: 'body1',
                  children: 'will receive updates about'
                }), (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'primary',
                  variant: 'body1',
                  display: 'inline',
                  style: {
                    margin: theme.spacing(0, 0, 0, .5)
                  },
                  children: "".concat((0, _BallType.renderBallTypeChoice)(props.newBallToAdd.ballType), ":")
                }), (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'primary',
                  variant: 'body1',
                  display: 'inline',
                  style: {
                    textTransform: 'capitalize',
                    margin: theme.spacing(0, .5, 0, .5)
                  },
                  children: props.newBallToAdd.ballTitle
                }), (0, _jsxRuntime.jsx)(_material.Typography, {
                  display: 'inline',
                  component: 'div',
                  color: 'textPrimary',
                  variant: 'body1',
                  children: 'at the email you provided'
                }), (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'primary',
                  variant: 'body1',
                  display: 'inline',
                  style: {
                    margin: theme.spacing(0, .5)
                  },
                  children: props.newBallToAdd.notifyEmail
                })]
              })
            })]
          })
        })
      })
    }) : (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsx)(_material.Typography, {
        variant: 'h5',
        align: 'center',
        children: (0, _jsxRuntime.jsx)(_material.Box, {
          color: theme.palette.error.main,
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            direction: 'column',
            spacing: 5,
            children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              item: true,
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_iconsMaterial.CloseOutlined, {
                  style: {
                    marginRight: theme.spacing(1.25)
                  },
                  fontSize: 'large'
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'h5',
                  display: 'inline',
                  children: "Add Ball Error!!"
                })
              })]
            }), (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              item: true,
              spacing: 2,
              direction: 'column',
              alignItems: 'center',
              style: {
                width: '800px'
              },
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'h6',
                  align: 'center',
                  children: "Submission of the ".concat((0, _BallType.renderBallTypeChoice)(props.newBallToAdd.ballType), " ran into an Error!")
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'h6',
                  align: 'center',
                  children: ((_props$status2 = props.status) === null || _props$status2 === void 0 || (_props$status2 = _props$status2.status) === null || _props$status2 === void 0 ? void 0 : _props$status2.messageText) && (0, _jsxRuntime.jsx)(_material.Typography, {
                    variant: 'body1',
                    color: 'error',
                    children: "".concat((_props$status3 = props.status) === null || _props$status3 === void 0 || (_props$status3 = _props$status3.status) === null || _props$status3 === void 0 ? void 0 : _props$status3.messageText, "...")
                  })
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                justifyContent: 'center',
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'body2',
                  color: 'error',
                  align: 'center',
                  children: (_props$status4 = props.status) === null || _props$status4 === void 0 || (_props$status4 = _props$status4.value) === null || _props$status4 === void 0 ? void 0 : _props$status4.toString()
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                justifyContent: 'center',
                children: (0, _jsxRuntime.jsxs)(_material.Typography, {
                  variant: 'body2',
                  color: 'error',
                  align: 'center',
                  style: {
                    width: '800px'
                  },
                  children: ["Ball Submission: ", JSON.stringify(props.newBallToAdd)]
                })
              })]
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: 'center',
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                component: 'div',
                color: 'textSecondary',
                variant: 'body1',
                style: {
                  width: '400px'
                },
                children: "Please contact the Anybody Walking Administrator at admin@anybodywalking.com and include everything above in red."
              })
            })]
          })
        })
      })
    })
  });
};
var _default = exports.default = FinishAddBall;