"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CssFadeToColorDirectionEnum = void 0;
var CssFadeToColorDirectionEnum;
(function (CssFadeToColorDirectionEnum) {
  CssFadeToColorDirectionEnum[CssFadeToColorDirectionEnum["RIGHT"] = 0] = "RIGHT";
  CssFadeToColorDirectionEnum[CssFadeToColorDirectionEnum["LEFT"] = 1] = "LEFT";
  CssFadeToColorDirectionEnum[CssFadeToColorDirectionEnum["TOP"] = 2] = "TOP";
  CssFadeToColorDirectionEnum[CssFadeToColorDirectionEnum["BOTTOM"] = 3] = "BOTTOM";
})(CssFadeToColorDirectionEnum || (exports.CssFadeToColorDirectionEnum = CssFadeToColorDirectionEnum = {}));