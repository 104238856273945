"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _PageContext = _interopRequireDefault(require("../page-context/PageContext"));
var _FirebaseContext = _interopRequireDefault(require("../../common/firebase/firebase-context/FirebaseContext"));
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const HeadlineCTASection = props => {
  var _props$sectionData$ct;
  const theme = (0, _material.useTheme)();
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const smDown = (0, _material.useMediaQuery)(theme.breakpoints.down('sm'));
  const mdDown = (0, _material.useMediaQuery)(theme.breakpoints.down('md'));
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    sx: {
      backgroundColor: theme.palette.secondary.main,
      backgroundImage: "url(".concat(sanityContext.urlFor(props.sectionData.backgroundImgSrc).url(), ")"),
      backgroundSize: "40%, 40%",
      // backgroundRepeat: "no-repeat",
      // borderRadius: mdDown?0:1,
      border: mdDown || props.sectionData.isHideBorder ? 'none' : "1px solid ".concat(theme.palette.primary.main),
      marginLeft: mdDown ? 0 : props.sectionData.insetLeft,
      marginRight: mdDown ? 0 : props.sectionData.insetRight,
      marginTop: mdDown ? 0 : props.sectionData.insetTop,
      marginBottom: mdDown ? 0 : props.sectionData.insetBottom
    },
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      alignItems: 'center',
      alignContent: 'center',
      item: true,
      justifyContent: smDown ? 'center' : 'flex-start',
      style: {
        padding: "40px"
      },
      sm: 8,
      children: (0, _jsxRuntime.jsx)(_material.Typography, {
        variant: 'h4',
        color: 'primary',
        children: props.sectionData.contentText
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      style: {
        padding: theme.spacing(!smDown ? 5 : 0, 5, 5, 5)
      },
      container: true,
      item: true,
      justifyContent: smDown ? 'center' : 'flex-end',
      alignItems: 'center',
      alignContent: 'center',
      sm: 4,
      children: (0, _jsxRuntime.jsx)(_material.Button, {
        color: 'primary',
        variant: 'contained',
        onClick: () => {
          firebaseContext.ctaClick && firebaseContext.ctaClick("hero-section", props.sectionData.ctaButtonText, pageContext.analyticsId);
        },
        href: (_props$sectionData$ct = props.sectionData.ctaButtonLink) !== null && _props$sectionData$ct !== void 0 ? _props$sectionData$ct : "",
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'button',
          color: 'secondary',
          children: props.sectionData.ctaButtonText
        })
      })
    })]
  });
};
var _default = exports.default = HeadlineCTASection;