"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useIsHorizontalOverflow = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _react = _interopRequireDefault(require("react"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useIsHorizontalOverflow = (ref, callback) => {
  const [isOverflow, setIsOverflow] = _react.default.useState(undefined);
  _react.default.useEffect(() => {
    if (callback) callback(isOverflow);
  }, [isOverflow]);
  _react.default.useLayoutEffect(() => {
    const {
      current
    } = ref;
    const trigger = () => {
      const hasOverflow = current.scrollWidth > current.clientWidth;
      setIsOverflow(hasOverflow);
      if (callback) callback(hasOverflow);
    };
    let construct;
    if (current) {
      if ('ResizeObserver' in window) {
        construct = new ResizeObserver(trigger);
        construct.observe(current);
      }
      trigger();
    }
    return () => {
      if (!!construct) {
        construct.disconnect();
      }
    };
  }, [ref, callback]);
  return isOverflow;
};
exports.useIsHorizontalOverflow = useIsHorizontalOverflow;