"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _PageContext = _interopRequireDefault(require("../page-context/PageContext"));
var _Styles = _interopRequireDefault(require("../templates/mackenzies-mind/pages/Styles"));
var _clsx = _interopRequireDefault(require("clsx"));
var _iconsMaterial = require("@mui/icons-material");
var _framerMotion = require("framer-motion");
var _FirebaseContext = _interopRequireDefault(require("../../common/firebase/firebase-context/FirebaseContext"));
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  contentSection: {
    height: '700px',
    // marginTop: '16px',
    backgroundColor: 'transparent'
    // minWidth:"350px"
  },
  divContainer: {
    width: '65vmin',
    height: '65vmin'
    //box-shadow: 0 0 0 1px;
  },
  // octagon: {overflow: 'hidden'},
  inner: {
    transform: 'rotate(45deg)',
    background: '#ee8c25'
  }
}));
const HeroAnimatedContentSection = props => {
  var _contentSlide$ctaButt2;
  const classes = useStyles();
  const globalClasses = (0, _Styles.default)({});
  const [pageNumber, setPageNumber] = (0, _react.useState)(0);
  const [contentSlide, setContentSlide] = (0, _react.useState)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const theme = (0, _material.useTheme)();
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  (0, _react.useEffect)(() => {
    if (props.sectionData.contentSlides[pageNumber]) setContentSlide(props.sectionData.contentSlides[pageNumber]);
  }, [props.sectionData.contentSlides, pageNumber]);
  const mdDown = (0, _material.useMediaQuery)(theme.breakpoints.down('md'));
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    style: {
      overflow: "hidden",
      paddingTop: mdDown ? theme.mixins.toolbar.height : "148px"
    },
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      style: {
        backgroundRepeat: 'no-repeat',
        backgroundImage: contentSlide !== null && contentSlide !== void 0 && contentSlide.heroImage ? "url('".concat(sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(contentSlide === null || contentSlide === void 0 ? void 0 : contentSlide.heroImage, 200, 100), "'), url('").concat(sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(contentSlide === null || contentSlide === void 0 ? void 0 : contentSlide.heroImageBackground, 200, 100), "')") : "url('".concat(sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(contentSlide === null || contentSlide === void 0 ? void 0 : contentSlide.heroImage, 500, 700), "')"),
        backgroundSize: 'cover, contain',
        backgroundPosition: "center",
        minHeight: '700px',
        backgroundColor: 'transparent',
        position: "relative"
      },
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        className: (0, _clsx.default)(globalClasses.fullSection, globalClasses.fullSectionOverlay)
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        wrap: 'nowrap',
        justifyContent: 'space-between',
        style: {
          zIndex: 2
        },
        alignItems: 'center',
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.IconButton, {
            color: 'secondary',
            onClick: () => {
              if (pageNumber === 0) {
                setPageNumber(props.sectionData.contentSlides.length - 1);
              } else {
                setPageNumber(state => state - 1);
              }
              firebaseContext.ctaClick && firebaseContext.ctaClick("hero-section-slider", "back slide", pageContext.analyticsId);
            },
            children: (0, _jsxRuntime.jsx)(_iconsMaterial.ChevronLeft, {
              fontSize: 'large'
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 10,
          children: (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
            animate: {
              scale: 1
            },
            initial: {
              scale: 1.1
            },
            transition: {
              // ease: "linear",
              duration: 2
              // scale: {duration: 3},
              // opacity: {duration: .5}
            },
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                className: classes.contentSection,
                item: true,
                children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                  container: true,
                  justifyContent: 'center',
                  alignContent: 'center',
                  children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    container: true,
                    justifyContent: 'center'
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    children: (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
                      animate: {
                        y: 0,
                        opacity: 1
                      },
                      initial: {
                        y: -400,
                        opacity: 0
                      },
                      transition: {
                        // ease: "linear",
                        duration: 2,
                        y: {
                          duration: 1
                        }
                        // opacity: {duration: .5}
                      },
                      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                        container: true,
                        item: true,
                        justifyContent: 'center',
                        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
                          item: true,
                          container: true,
                          justifyContent: 'center',
                          spacing: 1,
                          wrap: 'nowrap',
                          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                            item: true,
                            children: (0, _jsxRuntime.jsx)("img", {
                              width: 12,
                              src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(contentSlide === null || contentSlide === void 0 ? void 0 : contentSlide.heroBullet, 100, 200)
                            })
                          }), (0, _jsxRuntime.jsx)(_material.Grid, {
                            item: true,
                            children: (0, _jsxRuntime.jsx)(_material.Typography, {
                              variant: 'body1',
                              alignContent: 'center',
                              noWrap: true,
                              style: {
                                textTransform: "uppercase",
                                color: theme.palette.text.secondary,
                                fontWeight: "700",
                                letterSpacing: 4.3
                              },
                              children: contentSlide === null || contentSlide === void 0 ? void 0 : contentSlide.contentWelcomeMessage
                            })
                          })]
                        }), (0, _jsxRuntime.jsx)(_material.Grid, {
                          item: true,
                          container: true,
                          justifyContent: 'center',
                          children: (0, _jsxRuntime.jsx)(_material.Typography, {
                            variant: 'h1',
                            align: 'center',
                            style: {
                              // marginBottom: "32px",
                              fontWeight: "700",
                              lineHeight: ".98em",
                              maxWidth: "350px"
                              // fontFamily: themeContext.customizedTheme.typography.fontFamily.split(',')[1]
                            },
                            color: 'textSecondary',
                            children: contentSlide === null || contentSlide === void 0 ? void 0 : contentSlide.contentTitle
                          })
                        })]
                      })
                    }, pageNumber)
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    style: {
                      marginTop: "32px"
                    },
                    children: (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
                      animate: {
                        y: 0,
                        opacity: 1
                      },
                      initial: {
                        y: 400,
                        opacity: 0
                      },
                      transition: {
                        // ease: "linear",
                        duration: 2,
                        y: {
                          duration: 1
                        }
                        // opacity: {duration: .5}
                      },
                      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                        container: true,
                        item: true,
                        justifyContent: 'center',
                        spacing: 4,
                        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                          container: true,
                          item: true,
                          justifyContent: 'center',
                          children: (0, _jsxRuntime.jsx)(_material.Typography, {
                            variant: 'body2',
                            align: 'center',
                            color: 'textSecondary',
                            children: contentSlide === null || contentSlide === void 0 ? void 0 : contentSlide.contentText
                          })
                        }), (0, _jsxRuntime.jsx)(_material.Grid, {
                          item: true,
                          container: true,
                          justifyContent: 'center',
                          children: (0, _jsxRuntime.jsx)(_material.Button, {
                            color: 'primary',
                            variant: 'contained',
                            style: {
                              height: "48px",
                              border: "0",
                              padding: theme.spacing(3.5, 8)
                            },
                            onClick: () => {
                              var _contentSlide$ctaButt;
                              firebaseContext.ctaClick && firebaseContext.ctaClick("hero-section", (_contentSlide$ctaButt = contentSlide === null || contentSlide === void 0 ? void 0 : contentSlide.ctaButtonTitle) !== null && _contentSlide$ctaButt !== void 0 ? _contentSlide$ctaButt : "", pageContext.analyticsId);
                            },
                            href: (_contentSlide$ctaButt2 = contentSlide === null || contentSlide === void 0 ? void 0 : contentSlide.ctaButtonLink) !== null && _contentSlide$ctaButt2 !== void 0 ? _contentSlide$ctaButt2 : "",
                            children: (0, _jsxRuntime.jsx)(_material.Typography, {
                              variant: 'button',
                              alignContent: 'center',
                              align: 'center',
                              color: 'textSecondary',
                              children: contentSlide === null || contentSlide === void 0 ? void 0 : contentSlide.ctaButtonTitle
                            })
                          })
                        })]
                      })
                    }, pageNumber)
                  })]
                })
              })
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.IconButton, {
              color: 'secondary',
              onClick: () => {
                if (pageNumber === props.sectionData.contentSlides.length - 1) {
                  setPageNumber(0);
                } else {
                  setPageNumber(state => state + 1);
                }
                firebaseContext.ctaClick && firebaseContext.ctaClick("hero-section-slider", "next slide", pageContext.analyticsId);
              },
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.ChevronRight, {
                fontSize: 'large'
              })
            })
          })
        })]
      })]
    })
  });
};
var _default = exports.default = HeroAnimatedContentSection;