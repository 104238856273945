"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _styles = require("@mui/material/styles");
var _material = require("@mui/material");
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
var _Routes = require("./enums/Routes.enum");
var _StyledTextField = _interopRequireDefault(require("./styled-text-field/StyledTextField"));
var _LoadingButton = _interopRequireDefault(require("../../loading-button/LoadingButton"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const useStyles = exports.useStyles = (0, _makeStyles.default)(awTheme => ({
  root: {
    height: '100%'
    // background: 'linear-gradient(84deg, rgba(2,0,36,1) 0%, rgba(21,20,36,1) 52%, rgba(3,3,32,1) 100%)'
  },
  cssLabel: {
    // color: 'white'
  },
  cssOutlinedInput: {
    // borderColor: `${awTheme.palette.secondary.main}`
  },
  cssFocused: {},
  underline: {
    // borderBottom: '2px solid white',
    // '&:after': {
    //   // The MUI source seems to use this but it doesn't work
    //   borderBottom: '2px solid white'
    // }
  },
  notchedOutline: {
    borderWidth: '1px'
    // borderColor: `${awTheme.palette.secondary.main}`
  },
  checkboxContainer: {
    position: 'absolute',
    top: 0
  },
  submitButton: {
    width: '120px',
    height: '40px',
    padding: awTheme.spacing(2, 4)
  },
  outlined: {
    '&:hover': {
      backgroundColor: '#FAFAFA',
      color: '#383838'
    },
    '&$disabled': {
      borderColor: '#FFA091',
      color: '#FFA091'
    }
  }
}));
const AWContactUs = props => {
  var _contactUsFormState$f, _contactUsFormState$l, _contactUsFormState$c, _contactUsFormState$e, _contactUsFormState$p, _contactUsFormState$c2;
  const classes = useStyles();
  const theme = (0, _styles.useTheme)();
  const [contactUsFormState, setContactUsFormState] = (0, _react.useState)(_objectSpread({}, props.contactUsPrefill ? props.contactUsPrefill : {}));
  const [loading, setLoading] = (0, _react.useState)(false);
  const [status, setStatus] = (0, _react.useState)(undefined);
  const [checkBoxes, setCheckBoxes] = (0, _react.useState)(props.contactUsPrefill ? props.contactUsPrefill : {
    general: false,
    press: false,
    publicEvents: false,
    other: false,
    privateEvents: false,
    clickHere: false,
    iAgree: false
  });
  const updateContactUsFormParams = event => {
    event.persist();
    setContactUsFormState(state => _objectSpread(_objectSpread({}, state), {}, {
      [event.target.name]: event.target.value
    }));
  };
  const updateCheckboxes = event => {
    event.persist();
    console.log('event', event.target.name, event.target.value);
    setCheckBoxes(state => _objectSpread(_objectSpread({}, state), {}, {
      [event.target.name]: event.target.value === 'true'
    }));
  };
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const createContactUs = () => {
    setLoading(true);
    const contactUs = _objectSpread(_objectSpread({}, checkBoxes), contactUsFormState);
    sanityContext.createContactUs(contactUs).then(() => {
      setStatus(true);
      setLoading(false);
      // setTimeout(() =>{
      // history.push(RoutesEnum.LANDING)
      // }, 1000)
    });
  };
  return (0, _jsxRuntime.jsx)(_material.Grid
  // className={classes.root}
  , {
    // className={classes.root}
    container: true,
    direction: "column",
    alignItems: "center",
    item: true,
    justifyContent: "center",
    style: {
      padding: theme.spacing(14, 2)
    },
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      direction: "column",
      spacing: 5,
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: "h3",
          color: "textSecondary",
          children: "Contact Us"
        })
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        direction: "column",
        spacing: 3,
        md: 8,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: "body1",
            color: "textSecondary",
            children: "Do you have an event that you need help producting? Drop us a line and we will begin to create a proposal catered to fit your needs."
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
            fullWidth: true,
            color: "primary",
            name: "firstName",
            label: "First Name",
            value: (_contactUsFormState$f = contactUsFormState.firstName) !== null && _contactUsFormState$f !== void 0 ? _contactUsFormState$f : "",
            InputLabelProps: {
              classes: {
                root: classes.cssLabel
              }
            },
            InputProps: {
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                underline: classes.underline
              }
            },
            onChange: updateContactUsFormParams
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
            fullWidth: true,
            color: "primary",
            name: "lastName",
            label: "Last Name",
            value: (_contactUsFormState$l = contactUsFormState.lastName) !== null && _contactUsFormState$l !== void 0 ? _contactUsFormState$l : "",
            InputLabelProps: {
              classes: {
                root: classes.cssLabel
              }
            },
            InputProps: {
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                underline: classes.underline
              }
            },
            onChange: updateContactUsFormParams
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
            fullWidth: true,
            color: "primary",
            value: (_contactUsFormState$c = contactUsFormState.companyName) !== null && _contactUsFormState$c !== void 0 ? _contactUsFormState$c : "",
            label: "Company Name/House Name",
            name: "companyName",
            InputLabelProps: {
              classes: {
                root: classes.cssLabel
              }
            },
            InputProps: {
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                underline: classes.underline
              }
            },
            onChange: updateContactUsFormParams
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
            fullWidth: true,
            value: (_contactUsFormState$e = contactUsFormState.email) !== null && _contactUsFormState$e !== void 0 ? _contactUsFormState$e : "",
            label: "Email",
            name: "email",
            color: "primary",
            InputLabelProps: {
              classes: {
                root: classes.cssLabel
              }
            },
            InputProps: {
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                underline: classes.underline
              }
            },
            onChange: updateContactUsFormParams
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
            fullWidth: true,
            color: "primary",
            value: (_contactUsFormState$p = contactUsFormState.phone) !== null && _contactUsFormState$p !== void 0 ? _contactUsFormState$p : "",
            label: "Phone",
            name: "phone",
            InputLabelProps: {
              classes: {
                root: classes.cssLabel
              }
            },
            InputProps: {
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                underline: classes.underline
              }
            },
            onChange: updateContactUsFormParams
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          direction: "column",
          children: (0, _jsxRuntime.jsxs)(_material.FormControl, {
            component: "fieldset",
            children: [(0, _jsxRuntime.jsx)(_material.FormLabel, {
              component: "legend",
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: "textSecondary",
                children: "Interests:"
              })
            }), (0, _jsxRuntime.jsxs)(_material.FormGroup, {
              style: {
                marginLeft: '24px'
              },
              children: [(0, _jsxRuntime.jsx)(_material.FormControlLabel, {
                control: (0, _jsxRuntime.jsx)(_material.Checkbox, {
                  color: "primary",
                  name: "general",
                  checked: checkBoxes.general,
                  onChange: updateCheckboxes,
                  value: !checkBoxes.general
                }),
                label: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: "textSecondary",
                  children: "General"
                })
              }), (0, _jsxRuntime.jsx)(_material.FormControlLabel, {
                control: (0, _jsxRuntime.jsx)(_material.Checkbox, {
                  color: "primary",
                  name: "press",
                  checked: checkBoxes.press,
                  onChange: updateCheckboxes,
                  value: !checkBoxes.press
                }),
                label: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: "textSecondary",
                  children: "Press/Media"
                })
              }), (0, _jsxRuntime.jsx)(_material.FormControlLabel, {
                control: (0, _jsxRuntime.jsx)(_material.Checkbox, {
                  color: "primary",
                  name: "publicEvents",
                  checked: checkBoxes.publicEvents,
                  onChange: updateCheckboxes,
                  value: !checkBoxes.publicEvents
                }),
                label: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: "textSecondary",
                  children: "Public Events"
                })
              }), (0, _jsxRuntime.jsx)(_material.FormControlLabel, {
                control: (0, _jsxRuntime.jsx)(_material.Checkbox, {
                  color: "primary",
                  name: "privateEvents",
                  checked: checkBoxes.privateEvents,
                  onChange: updateCheckboxes,
                  value: !checkBoxes.privateEvents
                }),
                label: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: "textSecondary",
                  children: "Private Events"
                })
              }), (0, _jsxRuntime.jsx)(_material.FormControlLabel, {
                control: (0, _jsxRuntime.jsx)(_material.Checkbox, {
                  color: "primary",
                  name: "other",
                  checked: checkBoxes.other,
                  value: !checkBoxes.other,
                  onChange: updateCheckboxes
                }),
                label: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: "textSecondary",
                  children: "Other"
                })
              })]
            })]
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
            fullWidth: true,
            rows: 6,
            multiline: true,
            variant: "outlined",
            color: "primary",
            value: (_contactUsFormState$c2 = contactUsFormState.comment) !== null && _contactUsFormState$c2 !== void 0 ? _contactUsFormState$c2 : "",
            label: "Comment",
            name: "comment",
            InputLabelProps: {
              classes: {
                root: classes.cssLabel
              }
            },
            InputProps: {
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline
              }
            },
            onChange: updateContactUsFormParams
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.FormControl, {
            component: "fieldset",
            style: {
              position: 'relative'
            },
            children: (0, _jsxRuntime.jsxs)(_material.FormGroup, {
              children: [(0, _jsxRuntime.jsx)(_material.FormControlLabel, {
                style: {
                  paddingLeft: theme.spacing(4)
                },
                // classes={{root:classes.checkboxContainer}}
                control: (0, _jsxRuntime.jsx)(_material.Checkbox, {
                  color: "primary",
                  className: classes.checkboxContainer,
                  name: "clickHere",
                  checked: checkBoxes.clickHere,
                  onChange: updateCheckboxes,
                  value: !checkBoxes.clickHere
                }),
                label: (0, _jsxRuntime.jsxs)(_material.Box, {
                  style: {
                    paddingLeft: theme.spacing(6),
                    paddingTop: theme.spacing(.5)
                  },
                  children: [(0, _jsxRuntime.jsx)(_material.Typography, {
                    variant: "h6",
                    style: {
                      display: 'inline',
                      marginRight: "4px"
                    },
                    color: 'textSecondary',
                    children: "Click here"
                  }), (0, _jsxRuntime.jsx)(_material.Typography, {
                    style: {
                      display: 'inline'
                    },
                    color: 'textSecondary',
                    children: "to receive the latest news, special offers, early access to events, and other awesome updates. Anybody Walking is committed to protecting and respecting your privacy, and we\u2019ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you."
                  })]
                })
              }), (0, _jsxRuntime.jsx)(_material.FormControlLabel, {
                style: {
                  paddingLeft: theme.spacing(4),
                  position: 'relative'
                },
                // classes={{root:classes.checkboxContainer}}
                control: (0, _jsxRuntime.jsx)(_material.Checkbox, {
                  color: "primary",
                  className: classes.checkboxContainer,
                  name: "iAgree",
                  checked: checkBoxes.iAgree,
                  onChange: updateCheckboxes,
                  value: !checkBoxes.iAgree
                }),
                label: (0, _jsxRuntime.jsxs)(_material.Box, {
                  color: theme.palette.text.secondary,
                  style: {
                    paddingLeft: theme.spacing(6),
                    paddingTop: theme.spacing(.5)
                  },
                  children: [(0, _jsxRuntime.jsx)(_material.Typography, {
                    variant: "h6",
                    style: {
                      display: 'inline',
                      marginRight: "4px"
                    },
                    color: 'textSecondary',
                    children: "I agree"
                  }), (0, _jsxRuntime.jsx)(_material.Typography, {
                    style: {
                      display: 'inline'
                    },
                    color: 'textSecondary',
                    children: "to receive marketing communications from Anybody Walking. You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy. By clicking submit below, you consent to allow Anybody Walking to store and process the personal information submitted above to provide you the content requested."
                  })]
                })
              })]
            })
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          justifyContent: "center",
          item: true,
          children: status === undefined && (0, _jsxRuntime.jsx)(_LoadingButton.default, {
            isSlim: true,
            clickHandler: createContactUs,
            variant: "outlined",
            color: "primary",
            // classes={{ outlined: classes.outlined }}
            disabled: loading || !contactUsFormState.email || contactUsFormState.email === '',
            children: "Submit"
          })
        }), (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          alignItems: "center",
          item: true,
          direction: "column",
          children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
            item: true,
            children: [status === true && (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'textSecondary',
              children: "Thanks for staying in touch."
            }), status === false && (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'red',
              children: "Something went wrong. Please try again"
            })]
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: status !== undefined && (0, _jsxRuntime.jsx)(_material.Link, {
              href: _Routes.RoutesEnum.LANDING,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                children: "Home"
              })
            })
          })]
        })]
      })]
    })
  });
};
var _default = exports.default = AWContactUs;