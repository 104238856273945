"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _ThwCommonStyles = _interopRequireDefault(require("../../../../common/sanityIo/ThwCommonStyles"));
var _ResumeExperienceItem = _interopRequireDefault(require("./ResumeExperienceItem"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const ResumeExperienceSection = props => {
  var _props$sectionData$ex;
  const classes = (0, _ThwCommonStyles.default)();
  const theme = (0, _material.useTheme)();
  const smDown = (0, _material.useMediaQuery)(theme.breakpoints.down('sm'));
  const xsOnly = (0, _material.useMediaQuery)(theme.breakpoints.only('xs'));
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    style: {
      padding: theme.spacing(4, smDown ? 1 : 4)
    },
    className: classes.resumeSection,
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      spacing: 3,
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        md: 4,
        alignContent: 'flex-start',
        spacing: 1,
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          container: true,
          children: [(0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            children: props.sectionData.title
          }), (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            color: 'primary',
            display: 'inline',
            children: "."
          })]
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            children: props.sectionData.introduction
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        md: 8,
        spacing: 2,
        justifyContent: xsOnly ? 'center' : 'flex-start',
        children: (_props$sectionData$ex = props.sectionData.experiences) === null || _props$sectionData$ex === void 0 ? void 0 : _props$sectionData$ex.map((experience, index2) => {
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            item: true,
            container: true,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: experience._id,
              underline: "hover",
              style: {
                position: "relative",
                top: -90
              },
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_ResumeExperienceItem.default, {
              experience: experience
            }, index2)]
          }, index2);
        })
      })]
    })
  });
};
var _default = exports.default = ResumeExperienceSection;