"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/es.symbol.description.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _ResponsiveBullet = _interopRequireDefault(require("../../../ResponsiveBullet"));
var _LoadingButton = _interopRequireDefault(require("../../../loading-button/LoadingButton"));
var _OtherServices = _interopRequireDefault(require("./OtherServices"));
var _uuid = require("uuid");
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2, 5)
    },
    padding: theme.spacing(0, 4, 6, 6),
    minHeight: 'max-content',
    backgroundColor: '#f6f6f6'
  },
  contentBottom: {
    border: "1px solid ".concat(theme.palette.secondary.main),
    padding: '20px'
  },
  appBarSpacer: {
    paddingTop: theme.spacing(12.5)
  }
}));
const ThwServiceEducationPage = props => {
  var _props$serviceData$ex, _props$serviceData$be, _props$serviceData$be2, _props$serviceData$se, _props$serviceData$sl;
  const classes = useStyles();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const customizedThemeContext = (0, _material.useTheme)();
  const smDown = (0, _material.useMediaQuery)(customizedThemeContext.breakpoints.down('sm'));
  const xsDown = (0, _material.useMediaQuery)(customizedThemeContext.breakpoints.down('xs'));
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    className: classes.root,
    xs: 12,
    style: {
      position: "relative"
    },
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        style: {
          marginTop: customizedThemeContext.spacing(76)
        }
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        style: {
          backgroundColor: "red",
          backgroundSize: 'cover',
          width: "100vw",
          height: "600px",
          position: "absolute",
          left: 0,
          top: 0,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: "url(".concat(sanityContext.urlFor(props.serviceData.imageSrc).url(), ")")
        },
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          style: {
            top: 0,
            left: 0,
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(255,255,255,.3)"
          },
          children: " "
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            style: {
              // padding: TransformHWTheme.spacing(0, 0, 0, 4),
              // margin: TransformHWTheme.spacing(0, 1, 0, 0),
              width: "100%",
              position: "absolute",
              backgroundPosition: "center",
              top: customizedThemeContext.mixins.toolbar.height,
              // left: TransformHWTheme.spacing(-6),
              backgroundSize: 'cover'
              // backgroundImage: `url(${urlFor(props.serviceData.educationPageSlimHeroImage).height(200).url()})`
            },
            children: (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              alignItems: 'center',
              alignContent: 'center',
              style: {
                padding: customizedThemeContext.spacing(4, 4, 0, 4)
              },
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                container: true,
                justifyContent: smDown ? 'center' : "flex-start",
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'body1',
                  style: {
                    fontStyle: "italic"
                  },
                  children: "Healing & Wellness"
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                justifyContent: smDown ? 'center' : "flex-start",
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  align: xsDown ? 'center' : "left",
                  color: 'secondary',
                  variant: xsDown ? 'h3' : "h2",
                  children: props.serviceData.educationPageTitle
                })
              })]
            })
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          color: 'secondary',
          variant: 'h4',
          align: 'center',
          display: 'inline',
          children: props.serviceData.contentTitle
        })
      }), (_props$serviceData$ex = props.serviceData.extendedDescriptions) === null || _props$serviceData$ex === void 0 ? void 0 : _props$serviceData$ex.map(descriptionSegment => (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'body1',
          children: descriptionSegment
        })
      }, (0, _uuid.v4)())), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          color: 'secondary',
          variant: 'h4',
          align: 'center',
          display: 'inline',
          children: props.serviceData.benefitsOfServiceTitle
        })
      }), (_props$serviceData$be = props.serviceData.benefitsOfServiceContents) === null || _props$serviceData$be === void 0 ? void 0 : _props$serviceData$be.map(descriptionSegment => (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'body1',
          children: descriptionSegment
        })
      }, (0, _uuid.v4)())), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        spacing: 2,
        children: (_props$serviceData$be2 = props.serviceData.benefitsOfServiceBullets) === null || _props$serviceData$be2 === void 0 ? void 0 : _props$serviceData$be2.map(contentSegment => (0, _jsxRuntime.jsx)(_ResponsiveBullet.default, {
          text: contentSegment,
          bulletColor: 'secondary'
        }, (0, _uuid.v4)()))
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        spacing: 4,
        justifyContent: 'center',
        direction: 'column',
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h4',
            children: "Amenities"
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          justifyContent: 'center',
          alignItems: 'stretch',
          alignContent: 'flex-start',
          spacing: 2,
          children: (_props$serviceData$se = props.serviceData.serviceAmenities) === null || _props$serviceData$se === void 0 ? void 0 : _props$serviceData$se.map(serviceAmenity => {
            return (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              xs: 6,
              sm: 5,
              md: 4,
              lg: 3,
              xl: 3,
              style: {
                padding: xsDown ? customizedThemeContext.spacing(4, 0, 4, 0) : customizedThemeContext.spacing(6, 0, 6, 0),
                margin: xsDown ? customizedThemeContext.spacing(-.2, -.1, .05, -.1) : customizedThemeContext.spacing(-.1, -.1, -.1, -.1),
                maxWidth: "300px",
                minWidth: "230px",
                border: "1px solid ".concat(customizedThemeContext.palette.secondary.main),
                backgroundColor: customizedThemeContext.palette.background.paper
              },
              children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                item: true,
                justifyContent: 'center',
                alignContent: 'flex-start',
                spacing: 1,
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  container: true,
                  xs: 12,
                  justifyContent: 'center',
                  children: (0, _jsxRuntime.jsx)(_material.Grid, {
                    style: {
                      height: "64px",
                      width: "64px",
                      backgroundSize: 'cover',
                      // backgroundColor: "red",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundImage: "url(".concat(sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(serviceAmenity.imageSrc), ")")
                    },
                    container: true
                  })
                }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                  container: true,
                  item: true,
                  justifyContent: 'center',
                  children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    container: true,
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    style: {
                      height: "2.1em"
                      // backgroundColor: "blue"
                    },
                    xs: 10,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      variant: 'body2',
                      align: 'center',
                      color: 'secondary',
                      children: serviceAmenity.title
                    })
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    xs: 10,
                    container: true,
                    style: {
                      minHeight: "5em"
                      // backgroundColor: "green"
                    },
                    justifyContent: 'center',
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      variant: 'body1',
                      align: 'center',
                      children: serviceAmenity.description
                    })
                  })]
                })]
              })
            }, (0, _uuid.v4)());
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        alignItems: "center",
        justifyContent: "center",
        style: {
          margin: customizedThemeContext.spacing(8, 0, 6)
        },
        children: (0, _jsxRuntime.jsx)(_LoadingButton.default, {
          width: 250,
          href: props.serviceData.ctaButtonLink,
          color: "secondary",
          variant: "contained",
          children: (0, _jsxRuntime.jsxs)(_material.Typography, {
            variant: 'button',
            align: 'center',
            children: ["Book a ", props.serviceData.contentTitle, " Appointment"]
          })
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Divider, {
          style: {
            width: "100%",
            margin: customizedThemeContext.spacing(4, 0, 2, 0)
          }
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        children: (0, _jsxRuntime.jsx)(_OtherServices.default, {
          thisServiceSlug: (_props$serviceData$sl = props.serviceData.slug) === null || _props$serviceData$sl === void 0 ? void 0 : _props$serviceData$sl.current
        })
      })]
    })
  });
};
var _default = exports.default = ThwServiceEducationPage;