"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _uuid = require("uuid");
var _ThwServiceItem = _interopRequireDefault(require("../ThwServiceItem"));
var _PageContext = _interopRequireDefault(require("../../../page-context/PageContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const OtherServices = () => {
  var _pageContext$page;
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'center',
      style: {
        marginBottom: theme.spacing(4)
      },
      children: (0, _jsxRuntime.jsx)(_material.Typography, {
        variant: 'h4',
        color: 'secondary',
        children: "Other Services"
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      spacing: 3,
      justifyContent: 'center',
      children: (_pageContext$page = pageContext.page) === null || _pageContext$page === void 0 || (_pageContext$page = _pageContext$page.servicesAvailable) === null || _pageContext$page === void 0 ? void 0 : _pageContext$page.map(service => {
        return (0, _jsxRuntime.jsx)(_ThwServiceItem.default, {
          showAmenities: true,
          service: service,
          hideLearnMoreButton: true,
          hideCtaButton: true
        }, (0, _uuid.v4)());
      })
    })]
  });
};
var _default = exports.default = OtherServices;