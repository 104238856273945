"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _createStyles = _interopRequireDefault(require("@mui/styles/createStyles"));
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _iconsMaterial = require("@mui/icons-material");
var _ModalContext = _interopRequireDefault(require("../../../snackbar-context/ModalContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = (0, _makeStyles.default)(theme => (0, _createStyles.default)({
  root: {
    width: '100%'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  listItem: {
    "&.MuiListItem-gutters": {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0
    }
  }
}));
const MainMenuSubMenu = _ref => {
  var _menuGroup$links;
  let {
    menuGroup
  } = _ref;
  const classes = useStyles();
  const [open, setOpen] = (0, _react.useState)(false);
  const handleClick = e => {
    e.stopPropagation();
    setOpen(!open);
  };
  const theme = (0, _material.useTheme)();
  const modalContext = (0, _react.useContext)(_ModalContext.default);
  return (0, _jsxRuntime.jsxs)(_material.List, {
    style: {
      padding: 0
    },
    component: "nav",
    "aria-labelledby": "nested-list-subheader",
    className: classes.root,
    children: [(0, _jsxRuntime.jsxs)(_material.ListItem, {
      button: true,
      style: {
        paddingTop: theme.spacing(2.25),
        paddingBottom: theme.spacing(2.25)
      },
      onClick: handleClick,
      children: [(0, _jsxRuntime.jsx)(_material.ListItemText, {
        primary: menuGroup.menuGroupTitle
      }), open ? (0, _jsxRuntime.jsx)(_iconsMaterial.ExpandLess, {}) : (0, _jsxRuntime.jsx)(_iconsMaterial.ExpandMore, {})]
    }), (0, _jsxRuntime.jsx)(_material.Collapse, {
      in: open,
      timeout: "auto",
      unmountOnExit: true,
      children: (0, _jsxRuntime.jsx)(_material.List, {
        component: "div",
        disablePadding: true,
        children: (_menuGroup$links = menuGroup.links) === null || _menuGroup$links === void 0 ? void 0 : _menuGroup$links.map((menuLink, index) => (0, _jsxRuntime.jsx)(_material.ListItem, {
          href: menuLink.url,
          button: true,
          className: classes.listItem,
          style: {},
          children: (0, _jsxRuntime.jsx)(_material.Button, {
            variant: 'text',
            href: menuLink.url,
            onClick: menuLink.isModalButton ? () => {
              // console.log()
              if (menuLink.isModalButton) {
                modalContext.openModal && modalContext.openModal(menuLink.modalRef);
              }
            } : undefined,
            style: {
              paddingTop: theme.spacing(2.25),
              paddingLeft: theme.spacing(6),
              paddingBottom: theme.spacing(2.25),
              height: "100%",
              margin: 0
            },
            fullWidth: true,
            children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                align: 'left',
                children: menuLink.displayText
              })
            })
          })
        }, index))
      })
    }), (0, _jsxRuntime.jsx)(_material.Divider, {})]
  }, menuGroup.menuGroupTitle);
};
var _default = exports.default = MainMenuSubMenu;