"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
var _PageContext = _interopRequireDefault(require("../page-context/PageContext"));
var _ColorPalette = require("../../theme/common/ColorPalette");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  contentSection: {
    // height: '500px',
    // marginTop: '16px',
    backgroundColor: 'transparent'
  },
  contentBullets: {
    borderLeft: "4px solid ".concat(theme.palette.primary.main),
    paddingLeft: '26px'
  }
}));
const DJSpadesRulesContentSection = props => {
  var _sanityContext$urlFor, _pageContext$page;
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const pageContext = (0, _react.useContext)(_PageContext.default);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    style: {
      width: "100vw",
      backgroundColor: (0, _ColorPalette.convertToHexCode)(props.sectionData.backgroundColor),
      color: "white",
      position: "relative",
      zIndex: 2,
      border: "3px solid white",
      padding: "16px"
    },
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      style: {
        height: "100%",
        width: "100%",
        backgroundSize: "cover",
        backgroundImage: "url('".concat((_sanityContext$urlFor = sanityContext.urlFor((_pageContext$page = pageContext.page) === null || _pageContext$page === void 0 ? void 0 : _pageContext$page.backgroundImageSrc).url()) !== null && _sanityContext$urlFor !== void 0 ? _sanityContext$urlFor : "", "')"),
        opacity: .1,
        position: "absolute",
        zIndex: 1
      }
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      style: {
        zIndex: 2
      },
      justifyContent: 'center',
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        justifyContent: 'center',
        alignContent: 'center',
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'h3',
          children: props.sectionData.title
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        spacing: 1,
        justifyContent: 'center',
        sx: {
          maxWidth: "550px"
        },
        children: (0, _jsxRuntime.jsx)(_material.List, {
          sx: {
            listStyle: "decimal",
            paddingLeft: 4
          },
          children: props.sectionData.textListItems.map((aRule, index) => {
            return (0, _jsxRuntime.jsx)(_material.ListItem, {
              sx: {
                display: "list-item"
              },
              children: (0, _jsxRuntime.jsx)(_material.ListItemText, {
                primary: aRule
              })
            }, index);
          })
        })
      })]
    })]
  });
};
var _default = exports.default = DJSpadesRulesContentSection;