"use strict";

require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _StepFormValidationCodes = _interopRequireDefault(require("../enums/StepFormValidationCodes.enum"));
var _HTMLUtils = require("../HTMLUtils");
var _BallType = require("../enums/BallType.enum");
var _AWTextField = _interopRequireDefault(require("../aw-text-field/AWTextField"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const NotifyOnApproval = props => {
  var _props$newBallToAdd2, _props$newBallToAdd4, _props$newBallToAdd6, _props$newBallToAdd7;
  const [notifyOnApprovalState, setNotifyOnApprovalState] = (0, _react.useState)({});
  const isNotifyOnApprovalFormValid = () => {
    return !!(notifyOnApprovalState.notifyOnApproval && !notifyOnApprovalState.notifyName && !notifyOnApprovalState.notifyEmail || notifyOnApprovalState.notifyOnApproval && notifyOnApprovalState.notifyName && !!notifyOnApprovalState.notifyEmail);
  };
  (0, _react.useEffect)(() => {
    props.validateStep({
      isNotifyOnApprovalValid: isNotifyOnApprovalFormValid()
    });
    if (notifyOnApprovalState.notifyOnApproval && !notifyOnApprovalState.notifyName && !notifyOnApprovalState.notifyEmail) {
      props.stepComplete(notifyOnApprovalState);
    } else if (notifyOnApprovalState.notifyOnApproval && notifyOnApprovalState.notifyName && notifyOnApprovalState.notifyEmail) {
      props.stepComplete(notifyOnApprovalState);
    }
  }, [notifyOnApprovalState, notifyOnApprovalState.notifyName, notifyOnApprovalState.notifyEmail]);
  (0, _react.useEffect)(() => {
    setNotifyOnApprovalState(state => {
      var _props$newBallToAdd;
      return _objectSpread(_objectSpread({}, state), {}, {
        notifyEmail: (_props$newBallToAdd = props.newBallToAdd) === null || _props$newBallToAdd === void 0 ? void 0 : _props$newBallToAdd.notifyEmail
      });
    });
  }, [(_props$newBallToAdd2 = props.newBallToAdd) === null || _props$newBallToAdd2 === void 0 ? void 0 : _props$newBallToAdd2.notifyEmail]);
  (0, _react.useEffect)(() => {
    setNotifyOnApprovalState(state => {
      var _props$newBallToAdd3;
      return _objectSpread(_objectSpread({}, state), {}, {
        notifyName: (_props$newBallToAdd3 = props.newBallToAdd) === null || _props$newBallToAdd3 === void 0 ? void 0 : _props$newBallToAdd3.notifyName
      });
    });
  }, [(_props$newBallToAdd4 = props.newBallToAdd) === null || _props$newBallToAdd4 === void 0 ? void 0 : _props$newBallToAdd4.notifyName]);
  (0, _react.useEffect)(() => {
    setNotifyOnApprovalState(state => {
      var _props$newBallToAdd5;
      return _objectSpread(_objectSpread({}, state), {}, {
        notifyOnApproval: (_props$newBallToAdd5 = props.newBallToAdd) === null || _props$newBallToAdd5 === void 0 ? void 0 : _props$newBallToAdd5.notifyOnApproval
      });
    });
  }, [(_props$newBallToAdd6 = props.newBallToAdd) === null || _props$newBallToAdd6 === void 0 ? void 0 : _props$newBallToAdd6.notifyOnApproval]);
  const updateBallFormParams = event => {
    setNotifyOnApprovalState(state => _objectSpread(_objectSpread({}, state), {}, {
      [event.target.name]: event.target.value
    }));
  };
  const validateField = value => {
    let response = {
      value
    };
    if (value && value.length <= 1) {
      response = _objectSpread(_objectSpread({}, response), {}, {
        status: {
          messageText: 'Description must be longer than 1 character.',
          statusCode: _StepFormValidationCodes.default.BALL_DESCRIPTION_TOO_SHORT
        }
      });
    } else {
      response = _objectSpread(_objectSpread({}, response), {}, {
        status: {
          statusCode: _StepFormValidationCodes.default.VALID
        }
      });
    }
    return response;
  };
  const validateEmail = emailAddress => {
    let response = {
      value: emailAddress
    };
    if (!emailAddress || emailAddress === '') {
      response = _objectSpread(_objectSpread({}, response), {}, {
        status: {
          statusCode: _StepFormValidationCodes.default.VALID
        }
      });
      return response;
    }
    const isValidURL = (0, _HTMLUtils.urlPatternValidation)(emailAddress);
    if (!isValidURL) {
      response = _objectSpread(_objectSpread({}, response), {}, {
        status: {
          messageText: 'The URL is formatted incorrectly. Try something like http://www.website.com',
          statusCode: _StepFormValidationCodes.default.URL_INVALID
        }
      });
      return response;
    }
    response = _objectSpread(_objectSpread({}, response), {}, {
      status: {
        statusCode: _StepFormValidationCodes.default.VALID
      }
    });
    return response;
  };
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    "data-testid": 'add-ball-notifyOnApproval-step',
    direction: 'column',
    spacing: 1,
    style: {
      height: '525px'
    },
    children: !notifyOnApprovalState.notifyOnApproval ? (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      spacing: 3,
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        justifyContent: 'center',
        alignItems: 'flex-end',
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'h5',
          color: 'primary',
          align: 'center',
          style: {
            width: '550px'
          },
          children: "Does someone need to be\n            notified when\n            this ".concat((0, _BallType.renderBallType)((_props$newBallToAdd7 = props.newBallToAdd) === null || _props$newBallToAdd7 === void 0 ? void 0 : _props$newBallToAdd7.ballType), " is approved by Anybody Walking?")
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        justifyContent: 'space-around',
        alignItems: 'center',
        style: {
          height: '300px'
        },
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Button, {
            "data-testid": 'notify-on-approval-button',
            style: {
              minWidth: '500px',
              padding: theme.spacing(2, 4)
            },
            variant: 'contained',
            color: 'primary',
            onClick: () => setNotifyOnApprovalState(state => _objectSpread(_objectSpread({}, state), {}, {
              notifyOnApproval: true
            })),
            fullWidth: true,
            children: 'Yes, Enter Contact Details!'
          })
        })
      })]
    }) : (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      direction: 'column',
      alignContent: 'space-between',
      justifyContent: 'space-between',
      spacing: 4,
      style: {
        height: '445px'
      },
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        spacing: 3,
        justifyContent: 'space-between',
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          children: (0, _jsxRuntime.jsxs)(_material.Typography, {
            variant: 'h6',
            color: 'primary',
            children: ["Who would like to be contacted when this ", (0, _BallType.renderBallType)(props.newBallToAdd.ballType), " is approved by Anybody Walking?"]
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h5',
            color: 'textSecondary',
            children: "Enter contact information below:"
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        children: (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          direction: 'column',
          spacing: 6,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_AWTextField.default, {
              onChange: updateBallFormParams,
              value: notifyOnApprovalState.notifyName,
              testId: 'notification-name',
              fieldLabel: 'Name',
              fieldName: 'notifyName',
              validate: validateField
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_AWTextField.default, {
              onChange: updateBallFormParams,
              value: notifyOnApprovalState.notifyEmail,
              testId: 'notification-email',
              fieldLabel: 'Email',
              fieldName: 'notifyEmail',
              validate: validateEmail
            })
          })]
        })
      })]
    })
  });
};
var _default = exports.default = NotifyOnApproval;