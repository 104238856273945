"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _PageContext = _interopRequireDefault(require("../page-context/PageContext"));
var _AnimatedServiceItem = _interopRequireDefault(require("./AnimatedServiceItem"));
var _BulletedHeader = _interopRequireDefault(require("./BulletedHeader"));
var _AbstractShapesBackground = _interopRequireDefault(require("./abstract-shapes-background/AbstractShapesBackground"));
var _iconsMaterial = require("@mui/icons-material");
var _framerMotion = require("framer-motion");
var _FirebaseContext = _interopRequireDefault(require("../../common/firebase/firebase-context/FirebaseContext"));
var _SanityContext = _interopRequireDefault(require("../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  root: {
    paddingTop: '56px',
    paddingBottom: '64px',
    overflow: "hidden",
    minWidth: "350px"
  }
}));
const AnimatedServicesSection = props => {
  var _props$sectionData, _props$sectionData2, _props$sectionData3, _props$sectionData$se, _props$sectionData4, _props$sectionData$ct, _props$sectionData5, _props$sectionData6, _theme$palette, _props$sectionData7;
  const classes = useStyles();
  const theme = (0, _material.useTheme)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const smDown = (0, _material.useMediaQuery)(theme.breakpoints.down('sm'));
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      className: classes.root,
      xs: 12,
      alignItems: 'center',
      style: {
        position: "relative"
      },
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        style: {
          padding: theme === null || theme === void 0 ? void 0 : theme.spacing(8, 6),
          zIndex: 10
        },
        spacing: 2,
        justifyContent: 'center',
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          xs: 12,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (0, _jsxRuntime.jsx)(_BulletedHeader.default, {
              isCenter: true,
              color: 'secondary',
              textContent: (_props$sectionData = props.sectionData) === null || _props$sectionData === void 0 ? void 0 : _props$sectionData.contentPreTitle,
              heroBullet: (_props$sectionData2 = props.sectionData) === null || _props$sectionData2 === void 0 ? void 0 : _props$sectionData2.heroBullet
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              justifyContent: 'center',
              maxWidth: 800,
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  style: {
                    fontSize: "48px"
                  },
                  gutterBottom: true,
                  variant: 'h2',
                  align: 'center',
                  children: props.sectionData.contentTitle
                })
              })
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              justifyContent: 'center',
              maxWidth: 640,
              children: (_props$sectionData3 = props.sectionData) === null || _props$sectionData3 === void 0 || (_props$sectionData3 = _props$sectionData3.contentTexts) === null || _props$sectionData3 === void 0 ? void 0 : _props$sectionData3.map((segment, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  align: 'center',
                  variant: 'body1',
                  gutterBottom: true,
                  children: segment
                })
              }, "content-text-".concat(index)))
            })
          })]
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          justifyContent: 'center',
          spacing: 2,
          children: (_props$sectionData$se = props.sectionData.servicesList) === null || _props$sectionData$se === void 0 ? void 0 : _props$sectionData$se.map((service, index) => {
            return (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              sm: 6,
              children: (0, _jsxRuntime.jsx)(_AnimatedServiceItem.default, {
                service: service
              })
            }, "service-item-".concat(index));
          })
        }), (0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          container: true,
          justifyContent: 'center',
          spacing: 2,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            justifyContent: 'center',
            style: {
              marginTop: "32px"
            },
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              maxWidth: 800,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                style: {
                  fontSize: "48px"
                },
                gutterBottom: true,
                color: 'textSecondary',
                variant: 'h2',
                align: 'center',
                children: props.sectionData.contentSummaryTitle
              })
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            justifyContent: 'center',
            children: (_props$sectionData4 = props.sectionData) === null || _props$sectionData4 === void 0 || (_props$sectionData4 = _props$sectionData4.contentSummaryTexts) === null || _props$sectionData4 === void 0 ? void 0 : _props$sectionData4.map((segment, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              maxWidth: 640,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: 'textSecondary',
                align: 'center',
                variant: 'body1',
                gutterBottom: true,
                children: segment
              })
            }, index))
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_material.Button, {
              onClick: () => {
                props.sectionData.ctaButtonText && firebaseContext.ctaClick && firebaseContext.ctaClick("animated-services-section", props.sectionData.ctaButtonText, pageContext.analyticsId);
              },
              component: 'div',
              variant: 'contained',
              color: 'primary',
              href: (_props$sectionData$ct = props.sectionData.ctaButtonLink) !== null && _props$sectionData$ct !== void 0 ? _props$sectionData$ct : '',
              style: {
                marginTop: "18px",
                border: "1px solid #FAFAFA"
              },
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'button',
                color: 'secondary',
                children: props.sectionData.ctaButtonText
              })
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(_AbstractShapesBackground.default, {
        imagesArray: props.sectionData.imagesArray
      })]
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      style: {
        position: "relative",
        backgroundRepeat: "repeat-x",
        backgroundSize: "256px",
        backgroundImage: "url(".concat(sanityContext.placeholderOrImage && sanityContext.placeholderOrImage((_props$sectionData5 = props.sectionData) === null || _props$sectionData5 === void 0 ? void 0 : _props$sectionData5.videoPreviewSectionBackgroundImageSrc, 230, 265), ")")
      },
      justifyContent: 'center',
      alignContent: 'center',
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        xs: 12,
        justifyContent: 'center',
        style: {
          top: -64,
          position: "relative"
        },
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 12,
          sm: 10,
          sx: {
            height: "100%",
            position: "relative"
          },
          children: (0, _jsxRuntime.jsx)("img", {
            height: "100%",
            width: "100%",
            src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage((_props$sectionData6 = props.sectionData) === null || _props$sectionData6 === void 0 ? void 0 : _props$sectionData6.videoPreviewImageSrc, 300, 500)
          })
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        xs: 12,
        sm: 8,
        sx: {
          position: "absolute",
          height: "100%"
        },
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        children: (0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          container: true,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
            item: true,
            container: true,
            sx: {
              position: "relative"
            },
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            children: [(0, _jsxRuntime.jsx)(_material.IconButton, {
              sx: {
                width: 56,
                height: 56,
                position: "relative",
                zIndex: 10,
                backgroundColor: theme === null || theme === void 0 || (_theme$palette = theme.palette) === null || _theme$palette === void 0 ? void 0 : _theme$palette.primary.main
              },
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.PlayArrow, {
                style: {
                  fontSize: "2.8rem"
                },
                color: 'secondary'
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              style: {
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: 1
              },
              container: true,
              justifyContent: 'center',
              alignContent: 'center',
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
                  initial: {
                    scale: 1
                  },
                  animate: {
                    scale: 5,
                    opacity: 0
                  },
                  transition: {
                    repeat: Infinity,
                    duration: 5
                  },
                  children: (0, _jsxRuntime.jsx)(_material.Box, {
                    sx: {
                      borderRadius: "50%",
                      width: 24,
                      height: 24,
                      backgroundColor: "transparent",
                      border: "1px solid ".concat("white")
                    }
                  })
                }, "play-circle-1")
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              justifyContent: 'center',
              alignContent: 'center',
              item: true,
              style: {
                position: "absolute",
                width: "100%",
                height: "100%"
              },
              container: true,
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
                  initial: {
                    scale: 1
                  },
                  animate: {
                    scale: 5,
                    opacity: 0
                  },
                  transition: {
                    repeat: Infinity,
                    duration: 5
                  },
                  children: (0, _jsxRuntime.jsx)(_material.Box, {
                    sx: {
                      borderRadius: "50%",
                      width: 32,
                      height: 32,
                      backgroundColor: "transparent",
                      border: "1px solid ".concat("white")
                    }
                  })
                }, "play-circle-2")
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              justifyContent: 'center',
              alignContent: 'center',
              style: {
                position: "absolute",
                width: "100%",
                height: "100%"
              },
              container: true,
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
                  initial: {
                    scale: 1
                  },
                  animate: {
                    scale: 5,
                    opacity: 0
                  },
                  transition: {
                    repeat: Infinity,
                    duration: 5
                  },
                  children: (0, _jsxRuntime.jsx)(_material.Box, {
                    sx: {
                      borderRadius: "50%",
                      width: 42,
                      height: 42,
                      backgroundColor: "transparent",
                      border: "1px solid ".concat("white")
                    }
                  })
                }, "play-circle-3")
              })
            })]
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            justifyContent: 'center',
            style: {
              marginTop: smDown ? "12px" : "24px"
            },
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'secondary',
              variant: 'h3',
              gutterBottom: true,
              align: 'center',
              children: (_props$sectionData7 = props.sectionData) === null || _props$sectionData7 === void 0 ? void 0 : _props$sectionData7.videoPreviewText
            })
          })]
        })
      })]
    })]
  });
};
var _default = exports.default = AnimatedServicesSection;