"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const DrinkCardBottom = _ref => {
  var _sanityContext$cockta, _currentCard$mixingGl, _currentCard$mixingGl2, _currentCard$mixingGl3, _currentCard$mixingGl4, _currentCard$mixingGl5, _currentCard$glassPre, _currentCard$instruct;
  let {
    currentCard,
    isDarkMode
  } = _ref;
  const getGlassPrep = prepString => {
    switch (prepString) {
      case 'ICE':
        return "ADD ice to the glass.";
      case 'CHILLED':
        return "Chill the glass with water and ice.";
      case 'STRAWBERRY_ICE':
        return "Add strawberry ice cubes to the glass.";
      case 'SALT_RIM':
        return "Salt the rim of the glass.";
      case 'CHILI_SALT_RIM':
        return "Chili salt the rim of the glass.";
      case 'SUGAR_RIM':
        return "Sugar the rim of the glass";
      case 'RED_SUGAR_RIM':
        return "Red sugar the rim of the glass";
      case 'CARAMEL_STRIPE':
        return "Caramel Stripe the inside of the glass";
      case 'CHOC_STRIPE':
        return "Chocolate Stripe the inside of the glass";
      case 'GREEN_SUGAR_RIM':
        return "Green sugar the rim of the glass";
      default:
        return prepString;
    }
  };
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    sx: {
      color: isDarkMode ? "#dddddd" : theme.palette.text.primary
    },
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      justifyContent: 'center',
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        xs: 2,
        children: (currentCard === null || currentCard === void 0 ? void 0 : currentCard.mixingGlass[0]._type) === "MixingGlass" && (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)("img", {
              alt: 'drink type',
              height: 42,
              src: (_sanityContext$cockta = sanityContext.cocktailUrlFor((_currentCard$mixingGl = (_currentCard$mixingGl2 = currentCard.mixingGlass[0].ingredient.liquorType) === null || _currentCard$mixingGl2 === void 0 ? void 0 : _currentCard$mixingGl2.imageSrc) !== null && _currentCard$mixingGl !== void 0 ? _currentCard$mixingGl : "").url()) !== null && _sanityContext$cockta !== void 0 ? _sanityContext$cockta : ""
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'inherit',
              variant: 'subtitle1',
              textAlign: 'center',
              children: currentCard.mixingGlass[0].ingredient.title
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'inherit',
              variant: 'subtitle2',
              children: (_currentCard$mixingGl3 = currentCard.mixingGlass[0].ingredient.liquorType) === null || _currentCard$mixingGl3 === void 0 ? void 0 : _currentCard$mixingGl3.name
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        xs: 5,
        children: [currentCard === null || currentCard === void 0 || (_currentCard$mixingGl4 = currentCard.mixingGlass) === null || _currentCard$mixingGl4 === void 0 ? void 0 : _currentCard$mixingGl4.map((mixin, index) => {
          if ((mixin === null || mixin === void 0 ? void 0 : mixin._type) === "MixingGlass") {
            var _mixin$ingredient;
            return (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: 'center',
              children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                item: true,
                spacing: 1,
                justifyContent: 'center',
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    variant: 'subtitle2',
                    children: "".concat(mixin === null || mixin === void 0 ? void 0 : mixin.amount, "oz")
                  })
                }), (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    variant: 'subtitle2',
                    children: mixin === null || mixin === void 0 || (_mixin$ingredient = mixin.ingredient) === null || _mixin$ingredient === void 0 ? void 0 : _mixin$ingredient.title
                  })
                })]
              })
            }, "mixin-" + index);
          } else {
            return (0, _jsxRuntime.jsx)("div", {}, "blank-mixin-" + index);
          }
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          children: currentCard === null || currentCard === void 0 || (_currentCard$mixingGl5 = currentCard.mixingGlassGarnishes) === null || _currentCard$mixingGl5 === void 0 ? void 0 : _currentCard$mixingGl5.map((mixin, index) => {
            if (mixin) {
              return (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                item: true,
                children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
                  item: true,
                  container: true,
                  xs: 2,
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  alignContent: 'center',
                  children: [(0, _jsxRuntime.jsx)(_material.Typography, {}), " ", (0, _jsxRuntime.jsx)(_iconsMaterial.Close, {
                    fontSize: 'small'
                  })]
                }), (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    children: mixin === null || mixin === void 0 ? void 0 : mixin.title.toLowerCase()
                  })
                })]
              }, index);
            } else {
              return (0, _jsxRuntime.jsx)("div", {}, "blank-mixin-garnish-" + index);
            }
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        xs: 5,
        children: (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          xs: 11,
          children: [(currentCard === null || currentCard === void 0 ? void 0 : currentCard.glassPrep) && (currentCard === null || currentCard === void 0 ? void 0 : currentCard.glassPrep.length) > 0 && (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: currentCard === null || currentCard === void 0 || (_currentCard$glassPre = currentCard.glassPrep) === null || _currentCard$glassPre === void 0 ? void 0 : _currentCard$glassPre.map((prep, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'subtitle2',
                children: getGlassPrep(prep)
              })
            }, "prep" + index))
          }), currentCard === null || currentCard === void 0 || (_currentCard$instruct = currentCard.instructions) === null || _currentCard$instruct === void 0 ? void 0 : _currentCard$instruct.map((mixin, index) => {
            var _mixin$mixingGlass, _mixin$mixingGlassGar;
            return (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              item: true,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                container: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'subtitle2',
                  children: "".concat(mixin === null || mixin === void 0 ? void 0 : mixin.action, " ").concat(mixin !== null && mixin !== void 0 && mixin.instruction ? mixin === null || mixin === void 0 ? void 0 : mixin.instruction : "")
                })
              }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                item: true,
                container: true,
                children: [mixin === null || mixin === void 0 || (_mixin$mixingGlass = mixin.mixingGlass) === null || _mixin$mixingGlass === void 0 ? void 0 : _mixin$mixingGlass.map((mixin, index) => {
                  if ((mixin === null || mixin === void 0 ? void 0 : mixin._type) === "MixingGlass") {
                    var _mixin$ingredient2;
                    return (0, _jsxRuntime.jsxs)(_material.Grid, {
                      container: true,
                      item: true,
                      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                        item: true,
                        children: (0, _jsxRuntime.jsxs)(_material.Typography, {
                          variant: 'subtitle2',
                          children: [mixin === null || mixin === void 0 ? void 0 : mixin.amount, "oz"]
                        })
                      }), (0, _jsxRuntime.jsx)(_material.Grid, {
                        item: true,
                        children: (0, _jsxRuntime.jsx)(_material.Typography, {
                          variant: 'subtitle2',
                          children: mixin === null || mixin === void 0 || (_mixin$ingredient2 = mixin.ingredient) === null || _mixin$ingredient2 === void 0 ? void 0 : _mixin$ingredient2.title
                        })
                      })]
                    }, "mixingglass" + index);
                  }
                  return (0, _jsxRuntime.jsx)("div", {}, "blank-mixing-glass-entry-" + index);
                }), mixin === null || mixin === void 0 || (_mixin$mixingGlassGar = mixin.mixingGlassGarnishes) === null || _mixin$mixingGlassGar === void 0 ? void 0 : _mixin$mixingGlassGar.map((mixin, index) => {
                  if (mixin) {
                    return (0, _jsxRuntime.jsx)(_material.Grid, {
                      container: true,
                      item: true,
                      children: (0, _jsxRuntime.jsx)(_material.Grid, {
                        item: true,
                        children: (0, _jsxRuntime.jsx)(_material.Typography, {
                          variant: 'subtitle2',
                          children: mixin.title
                        })
                      })
                    }, "garnish" + index);
                  } else {
                    return (0, _jsxRuntime.jsx)("div", {}, "blank-mixing-glass-garnish-" + index);
                  }
                })]
              })]
            }, 'mixin-instruction-' + index);
          })]
        })
      })]
    })
  });
};
var _default = exports.default = DrinkCardBottom;