"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _dateUtils = _interopRequireDefault(require("../../../../utils/dateUtils"));
var _ResumeSkillTooltipWrapper = _interopRequireDefault(require("../resume-skills-section/ResumeSkillTooltipWrapper"));
var _textProcessingUtils = _interopRequireDefault(require("../../../../utils/textProcessingUtils"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const BartenderExperienceItem = props => {
  var _props$experience$dat, _textProcessingUtils$;
  const theme = (0, _material.useTheme)();
  const [isTooltipOpen, setIsToolTipOpen] = (0, _react.useState)();
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    item: true,
    container: true,
    alignContent: 'flex-start',
    role: 'experiencedivider',
    xs: 12,
    sx: {
      color: "white"
    },
    children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      role: 'experienceheader',
      alignContent: 'center',
      alignItems: 'center',
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        xs: 12,
        container: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          display: 'inline',
          variant: 'body2',
          color: 'primary',
          children: props.experience.companyName
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        xs: 12,
        container: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'body1',
          children: props.experience.title
        })
      })]
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        children: (0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          children: [(0, _jsxRuntime.jsx)(_material.Typography, {
            display: 'inline',
            variant: 'body1',
            fontWeight: 'bold',
            children: _dateUtils.default.YearMonth(new Date(props.experience.dateStart))
          }), (0, _jsxRuntime.jsx)(_material.Typography, {
            fontWeight: 'bold',
            display: 'inline',
            variant: 'body1',
            style: {
              margin: theme.spacing(0, 1)
            },
            children: "\u2014"
          }), !props.experience.isPresentPosition ? (0, _jsxRuntime.jsx)(_material.Typography, {
            fontWeight: 'bold',
            display: 'inline',
            variant: 'body1',
            children: _dateUtils.default.YearMonth(new Date(props.experience.dateEnd))
          }) : (0, _jsxRuntime.jsx)(_material.Typography, {
            fontWeight: 'bold',
            display: 'inline',
            variant: 'body1',
            children: "present"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        xs: 6,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'body1',
          fontStyle: 'italic',
          children: _dateUtils.default.getLengthOfTime(new Date((_props$experience$dat = props.experience.dateStart) !== null && _props$experience$dat !== void 0 ? _props$experience$dat : ""), !props.experience.isPresentPosition && props.experience.dateEnd ? new Date(props.experience.dateEnd) : new Date()).result
        })
      })]
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: (0, _jsxRuntime.jsx)(_material.Typography, {
        variant: 'body1',
        gutterBottom: true,
        children: props.experience.description
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      spacing: 1,
      style: {
        overflowX: "scroll",
        paddingBottom: theme.spacing(1)
      },
      wrap: 'nowrap',
      children: props.experience && props.experience.skillsUsed && ((_textProcessingUtils$ = _textProcessingUtils.default.sortByTitle(props.experience.skillsUsed)) === null || _textProcessingUtils$ === void 0 ? void 0 : _textProcessingUtils$.map((skill, index) => {
        return (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          onClick: () => {
            setIsToolTipOpen(index);
          },
          children: (0, _jsxRuntime.jsx)(_ResumeSkillTooltipWrapper.default, {
            resumeSkill: skill,
            isTipOpen: index === isTooltipOpen,
            children: (0, _jsxRuntime.jsx)(_material.Chip, {
              role: 'experienceskill',
              size: 'small',
              color: 'primary',
              label: skill.title
            })
          })
        }, index);
      }))
    })]
  });
};
var _default = exports.default = BartenderExperienceItem;