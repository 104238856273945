"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ButtonGroupMemberEnum = void 0;
var ButtonGroupMemberEnum;
(function (ButtonGroupMemberEnum) {
  ButtonGroupMemberEnum[ButtonGroupMemberEnum["LEFT"] = 0] = "LEFT";
  ButtonGroupMemberEnum[ButtonGroupMemberEnum["CENTER"] = 1] = "CENTER";
  ButtonGroupMemberEnum[ButtonGroupMemberEnum["RIGHT"] = 2] = "RIGHT";
})(ButtonGroupMemberEnum || (exports.ButtonGroupMemberEnum = ButtonGroupMemberEnum = {}));