"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _FilteredIngredients = _interopRequireDefault(require("./FilteredIngredients"));
var _material = require("@mui/material");
var _SearchContext = _interopRequireDefault(require("./search-context/SearchContext"));
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const LiquorBarFilter = props => {
  var _props$entireBar;
  const [liquorTypes, setLiquorTypes] = (0, _react.useState)([]);
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const {
    data
  } = sanityContext.useFetchAllLiquorTypes();
  (0, _react.useEffect)(() => {
    if (data) {
      setLiquorTypes(data);
    }
  }, [data]);
  // useEffect(() => {
  //     if (props.entireBar) {
  //         const justLiquorFromTheBar = props.entireBar.filter((ingredient: SanityCocktailIngredient) => {
  //             return ingredient.isLiquor
  //         })
  //         setTheLiquor(justLiquorFromTheBar)
  //     }
  // }, [props.entireBar])
  const searchContext = (0, _react.useContext)(_SearchContext.default);
  const processFilter = filter => {
    if (searchContext.isFilterIncluded && !searchContext.isFilterIncluded(filter)) searchContext.addFilter && searchContext.addFilter(filter !== null && filter !== void 0 ? filter : "");else searchContext.removeFilter && searchContext.removeFilter(filter !== null && filter !== void 0 ? filter : "");
  };
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsxs)(_material.Typography, {
        children: ["Store(", (_props$entireBar = props.entireBar) === null || _props$entireBar === void 0 ? void 0 : _props$entireBar.length, ")"]
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      style: {
        height: "180px",
        overflowX: "scroll",
        overflowY: "hidden"
      },
      direction: 'column',
      children: liquorTypes.map((liquorTypes, index) => {
        var _liquorTypes$_id, _sanityContext$cockta, _liquorTypes$imageSrc;
        return (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 5,
          style: {
            minWidth: "170px",
            marginRight: "8px"
          },
          children: (0, _jsxRuntime.jsx)(_material.Button, {
            fullWidth: true,
            color: searchContext.isFilterIncluded && searchContext.isFilterIncluded((_liquorTypes$_id = liquorTypes === null || liquorTypes === void 0 ? void 0 : liquorTypes._id) !== null && _liquorTypes$_id !== void 0 ? _liquorTypes$_id : "") ? "secondary" : "primary",
            variant: "contained",
            onClick: () => {
              var _liquorTypes$_id2;
              return processFilter((_liquorTypes$_id2 = liquorTypes._id) !== null && _liquorTypes$_id2 !== void 0 ? _liquorTypes$_id2 : "");
            },
            children: (0, _jsxRuntime.jsxs)(_material.Grid, {
              container: true,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                justifyContent: 'center',
                children: (0, _jsxRuntime.jsx)("img", {
                  height: 100,
                  src: (_sanityContext$cockta = sanityContext.cocktailUrlFor((_liquorTypes$imageSrc = liquorTypes.imageSrc) !== null && _liquorTypes$imageSrc !== void 0 ? _liquorTypes$imageSrc : "").url()) !== null && _sanityContext$cockta !== void 0 ? _sanityContext$cockta : ""
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                justifyContent: 'center',
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  align: 'center',
                  children: liquorTypes.title
                })
              })]
            })
          })
        }, "liquorbarfilter" + index);
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: (0, _jsxRuntime.jsx)(_FilteredIngredients.default, {})
    })]
  });
};
var _default = exports.default = LiquorBarFilter;