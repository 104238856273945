"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/es.promise.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _LoadingButton = _interopRequireDefault(require("../../../loading-button/LoadingButton"));
var _ButtonGroupMemberEnum = require("../../../loading-button/ButtonGroupMemberEnum");
var _iconsMaterial = require("@mui/icons-material");
var _CustomizedThemeContext = _interopRequireDefault(require("../../../customized-theme-provider/CustomizedThemeContext"));
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _ResumeExperienceItem = _interopRequireDefault(require("../../my-digital-resume/resume-experience-section/ResumeExperienceItem"));
var _ColorPalette = require("../../../../theme/common/ColorPalette");
var _ResumeSkillReferences = _interopRequireDefault(require("../../my-digital-resume/resume-skills-section/ResumeSkillReferences"));
var _PageContext = _interopRequireDefault(require("../../../page-context/PageContext"));
var _ResumeBioSection = _interopRequireDefault(require("../../my-digital-resume/resume-bio-section/ResumeBioSection"));
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  endAdornedInput: {
    "& .MuiFilledInput-adornedEnd": {
      border: "1px solid red",
      // marginRight: '-12px',
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius
    },
    "& .MuiOutlinedInput-adornedEnd": {
      border: "1px solid white",
      // paddingRight: 0,
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius
    },
    "& .MuiInputBase-input": {
      borderRightWidth: 0,
      "&:hover": {
        borderBottomColor: "white"
      }
    },
    "& .MuiButton-containedSecondary": {
      border: 0,
      borderLeft: '1px solid white'
    }
  }
}));
const FullTextSearch = () => {
  var _results$length;
  const customizedTheme = (0, _react.useContext)(_CustomizedThemeContext.default);
  const [searchText, setSearchText] = (0, _react.useState)();
  const myClasses = useStyles(customizedTheme);
  const [results, setResults] = (0, _react.useState)();
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const searchCMS = async () => {
    // console.log("about to search full text")
    if (searchText) {
      var _pageContext$page$_id, _pageContext$page;
      const cmsResponse = await sanityContext.fullTextSearch(searchText, (_pageContext$page$_id = (_pageContext$page = pageContext.page) === null || _pageContext$page === void 0 ? void 0 : _pageContext$page._id) !== null && _pageContext$page$_id !== void 0 ? _pageContext$page$_id : "");
      // console.log("results", cmsResponse)
      setResults(cmsResponse);
    }
  };
  const updateSearchText = event => {
    setSearchText(event.target.value);
  };
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: (0, _jsxRuntime.jsx)(_material.TextField, {
        fullWidth: true,
        autoFocus: true,
        label: (0, _jsxRuntime.jsx)(_material.Typography, {
          children: "Search"
        }),
        variant: 'outlined',
        style: {
          paddingRight: "0",
          height: "60px"
        },
        type: 'search',
        onKeyDown: async e => {
          if (e.key === "Enter") {
            await searchCMS();
          }
        },
        value: searchText !== null && searchText !== void 0 ? searchText : "",
        onChange: updateSearchText,
        className: myClasses.endAdornedInput,
        InputProps: {
          sx: {
            backgroundColor: "white"
          },
          // notched: true,
          endAdornment: (0, _jsxRuntime.jsx)(_LoadingButton.default, {
            width: 64,
            // isLoading={isLoading}
            groupiness: _ButtonGroupMemberEnum.ButtonGroupMemberEnum.RIGHT,
            // disabled={!!(email.length === 0 || data || isError || (email && (email.length > 0) && !isEmail(email)))}
            clickHandler: searchCMS,
            color: 'primary',
            variant: 'contained',
            children: (0, _jsxRuntime.jsx)(_iconsMaterial.Search, {})
          })
        }
      }, 'full-text-search-field')
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      style: {
        overflow: "scroll",
        height: "800px"
      },
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (results === null || results === void 0 ? void 0 : results.length) && (0, _jsxRuntime.jsxs)(_material.Typography, {
          color: 'textSecondary',
          children: [results === null || results === void 0 ? void 0 : results.length, " ", ((_results$length = results === null || results === void 0 ? void 0 : results.length) !== null && _results$length !== void 0 ? _results$length : 1) > 1 ? "results" : "result"]
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        sx: {
          paddingX: "16px"
        },
        children: results === null || results === void 0 ? void 0 : results.map((theResult, resultIndex) => {
          switch (theResult === null || theResult === void 0 ? void 0 : theResult._type) {
            case "ServiceItem":
              const convertedServiceItem = theResult;
              return (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                sx: {
                  marginBottom: "16px"
                },
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    color: 'textSecondary',
                    children: "Service We Provide:"
                  })
                }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                  item: true,
                  sx: {
                    paddingLeft: "16px"
                  },
                  children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                    container: true,
                    item: true,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      fontWeight: 'bold',
                      children: convertedServiceItem.contentTitle
                    })
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    container: true,
                    item: true,
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      color: 'textSecondary',
                      children: convertedServiceItem.contentText
                    })
                  })]
                })]
              }, resultIndex);
            case "HeroAnimatedContentSection":
              const convertedAnimatedHeroSection = theResult;
              return (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                sx: {
                  marginBottom: "16px"
                },
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    color: 'textSecondary',
                    children: "Animated Slide Show:"
                  })
                }), (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  sx: {
                    paddingLeft: "16px"
                  },
                  container: true,
                  children: convertedAnimatedHeroSection.contentSlides.map((slide, slideIdx) => {
                    return (0, _jsxRuntime.jsxs)(_material.Grid, {
                      container: true,
                      item: true,
                      sx: {
                        borderLeft: "1px solid whitesmoke",
                        marginBottom: "8px",
                        paddingLeft: "8px"
                      },
                      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                        item: true,
                        container: true,
                        children: (0, _jsxRuntime.jsx)(_material.Typography, {
                          color: 'textSecondary',
                          fontWeight: 'bold',
                          children: "".concat(slide.contentWelcomeMessage, " - ").concat(slide.contentTitle)
                        })
                      }), (0, _jsxRuntime.jsx)(_material.Grid, {
                        item: true,
                        container: true,
                        children: (0, _jsxRuntime.jsx)(_material.Typography, {
                          color: 'textSecondary',
                          variant: 'body1',
                          children: slide.contentText
                        })
                      })]
                    }, slideIdx);
                  })
                })]
              }, "hero-animated-content-section-" + resultIndex);
            case "AnimatedServicesSection":
              const convertedAnimatedServicesSection = theResult;
              return (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                sx: {
                  marginBottom: "16px"
                },
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    color: 'textSecondary',
                    children: "Services:"
                  })
                }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                  item: true,
                  sx: {
                    paddingLeft: "16px"
                  },
                  container: true,
                  children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                    container: true,
                    item: true,
                    children: (0, _jsxRuntime.jsxs)(_material.Typography, {
                      color: 'textSecondary',
                      children: [convertedAnimatedServicesSection.contentPreTitle, "-", convertedAnimatedServicesSection.contentTitle]
                    })
                  }), convertedAnimatedServicesSection.contentTexts.map(textContent => {
                    return (0, _jsxRuntime.jsx)(_material.Grid, {
                      container: true,
                      item: true,
                      sx: {
                        borderLeft: "1px solid whitesmoke",
                        marginBottom: "8px",
                        paddingLeft: "8px"
                      },
                      children: (0, _jsxRuntime.jsx)(_material.Grid, {
                        item: true,
                        container: true,
                        children: (0, _jsxRuntime.jsx)(_material.Typography, {
                          color: 'textSecondary',
                          fontWeight: 'bold',
                          children: textContent
                        })
                      })
                    });
                  })]
                })]
              }, "animated-services-section-" + resultIndex);
            case "ResumeExperience":
              const convertedResumeExperience = theResult;
              return (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                sx: {
                  marginBottom: "16px",
                  backgroundColor: _ColorPalette.COLORS.LIGHTGRAY,
                  padding: "16px"
                },
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  children: (0, _jsxRuntime.jsxs)(_material.Typography, {
                    variant: 'h6',
                    gutterBottom: true,
                    color: 'primary',
                    children: [convertedResumeExperience.title, " Experience"]
                  })
                }), (0, _jsxRuntime.jsx)(_ResumeExperienceItem.default, {
                  experience: convertedResumeExperience
                })]
              }, "resume-experience-" + resultIndex);
            case "ResumeSkill":
              const convertedResumeSkill = theResult;
              return (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                sx: {
                  marginBottom: "16px",
                  backgroundColor: _ColorPalette.COLORS.LIGHTGRAY,
                  padding: "16px"
                },
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  children: (0, _jsxRuntime.jsxs)(_material.Typography, {
                    variant: 'h6',
                    gutterBottom: true,
                    color: 'primary',
                    children: ["Experience with ", convertedResumeSkill.title]
                  })
                }), (0, _jsxRuntime.jsx)(_ResumeSkillReferences.default, {
                  skill: convertedResumeSkill
                })]
              }, "resume-skill-" + resultIndex);
            case "ResumeBioSection":
              const resumeBioSectionObj = theResult;
              return (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                sx: {
                  marginBottom: "16px",
                  backgroundColor: _ColorPalette.COLORS.LIGHTGRAY,
                  padding: "16px"
                },
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  children: (0, _jsxRuntime.jsxs)(_material.Typography, {
                    variant: 'h6',
                    gutterBottom: true,
                    color: 'primary',
                    children: ["My Bio - ", resumeBioSectionObj.title]
                  })
                }), (0, _jsxRuntime.jsx)(_ResumeBioSection.default, {
                  isHideButtons: true,
                  isHideEmail: true,
                  sectionData: resumeBioSectionObj,
                  homePage: pageContext.page
                })]
              }, "resume-bio-section-" + resultIndex);
            default:
              return (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                sx: {
                  marginBottom: "16px"
                },
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  children: theResult === null || theResult === void 0 ? void 0 : theResult._type
                })
              }, (theResult === null || theResult === void 0 ? void 0 : theResult._type) + resultIndex);
          }
        })
      })]
    })]
  });
};
var _default = exports.default = FullTextSearch;