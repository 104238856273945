"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.array.sort.js");
require("core-js/modules/es.promise.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _BallDataTiles = _interopRequireDefault(require("./ball-data-tiles/BallDataTiles"));
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
var _BallSearchProviderWrapper = _interopRequireDefault(require("./BallSearchProviderWrapper"));
var _styles = require("@mui/styles");
var _FirebaseContext = _interopRequireDefault(require("../../../common/firebase/firebase-context/FirebaseContext"));
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _styles.makeStyles)(theme => ({
  ballSection: {
    border: '2px solid #e8e8e8',
    padding: theme.spacing(0, 4, 6, 4),
    margin: theme.spacing(4, 6)
  }
}));
const AWBallSummarySection = props => {
  var _pageContext$page;
  const classes = useStyles();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const [featuredSetOfBalls, setFeaturedSetOfBalls] = (0, _react.useState)([]);
  const [upcomingSetOfBalls, setUpcomingSetOfBalls] = (0, _react.useState)([]);
  const [remainingSetOfBalls, setRemainingSetOfBalls] = (0, _react.useState)([]);
  const [featuredLoading, setFeaturedLoading] = (0, _react.useState)(false);
  const [upcomingLoading, setUpcomingLoading] = (0, _react.useState)(false);
  const [remainingLoading, setRemainingLoading] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    console.log(featuredLoading, upcomingLoading, remainingLoading);
  }, [featuredLoading, upcomingLoading, remainingLoading]);
  const getBallData = async () => {
    // Filter featured
    const featured = props.balls ? props.balls.slice(0, 3) : await sanityContext.fetchAllApprovedBalls(' && featured == true', 3);
    console.log('featured', featured);
    setFeaturedSetOfBalls(featured);
    //
    // // Filter upcoming
    const today = new Date();
    const fromNow30Days = new Date();
    fromNow30Days.setDate(today.getDate() + 30);
    const filterQueryString = " && functionStartDate > \"".concat(today.toISOString(), "\" && functionStartDate < \"").concat(fromNow30Days.toISOString(), "\"");
    const upcoming = props.balls ? props.balls.slice(0, 3) : await sanityContext.fetchAllApprovedBalls(filterQueryString);
    console.log('upcoming', upcoming);
    setUpcomingSetOfBalls(upcoming === null || upcoming === void 0 ? void 0 : upcoming.slice(0, 3));
    // Filter rest
    const remaingBallsQueryString = " && functionStartDate >= \"".concat(today.toISOString(), "\"");
    const remainingBalls = props.balls ? props.balls : await sanityContext.fetchAllApprovedBalls(remaingBallsQueryString);
    const slugs = featured.concat(upcoming).map(ball => {
      var _ball$slug;
      return (_ball$slug = ball.slug) === null || _ball$slug === void 0 ? void 0 : _ball$slug.current;
    });
    const theRest = remainingBalls.filter(ball => {
      var _ball$slug2;
      return !slugs.includes((_ball$slug2 = ball.slug) === null || _ball$slug2 === void 0 ? void 0 : _ball$slug2.current);
    }
    // && (new Date(ball.functionStartDate ?? '') > new Date())
    ).sort(() => .5 - Math.random());
    setRemainingSetOfBalls(theRest);
    console.log('the balls remaining', slugs, theRest);
    return await Promise.all([featured, upcoming, remainingBalls]);
  };
  const refreshSearchResults = () => {
    setFeaturedLoading(true);
    setUpcomingLoading(true);
    setRemainingLoading(true);
    return getBallData().then(() => {
      setFeaturedLoading(false);
      setUpcomingLoading(false);
      setRemainingLoading(false);
    });
  };
  (0, _react.useEffect)(() => {
    refreshSearchResults().then();
  }, []);
  return (0, _jsxRuntime.jsx)(_BallSearchProviderWrapper.default, {
    results: props.balls,
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      justifyContent: 'center',
      children: [(0, _jsxRuntime.jsx)(_material.Toolbar, {
        sx: {
          height: (_pageContext$page = pageContext.page) === null || _pageContext$page === void 0 || (_pageContext$page = _pageContext$page.theme) === null || _pageContext$page === void 0 ? void 0 : _pageContext$page.appBarHeight
        }
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        style: {
          borderBottom: "1px solid #333333",
          paddingTop: "16px",
          paddingLeft: "16px",
          paddingBottom: "16px"
        },
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h4',
            color: 'textSecondary',
            fontWeight: 500,
            children: "Featured Balls"
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          style: {
            paddingTop: "16px"
          },
          children: (0, _jsxRuntime.jsx)(_BallDataTiles.default, {
            tileClickAnalytics: tileSlug => {
              firebaseContext.ctaClick && firebaseContext.ctaClick('featured-ball-tile-click', tileSlug, pageContext.analyticsId);
            },
            tiles: featuredSetOfBalls
          })
        })]
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        className: classes.ballSection,
        spacing: 2,
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          container: true,
          children: [(0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h4',
            color: 'textSecondary',
            fontWeight: 500,
            children: "Upcoming Balls"
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'body1',
              children: "(Last 30 days)"
            })
          }), " "]
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          children: (0, _jsxRuntime.jsx)(_BallDataTiles.default, {
            isAgoOn: true,
            tileClickAnalytics: tileSlug => {
              firebaseContext.ctaClick && firebaseContext.ctaClick('upcoming-ball-tile-click', tileSlug, pageContext.analyticsId);
            },
            tiles: upcomingSetOfBalls
          })
        })]
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        className: classes.ballSection,
        spacing: 2,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h4',
            color: 'textSecondary',
            fontWeight: 500,
            children: "More Balls..."
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            justifyContent: 'center',
            children: (0, _jsxRuntime.jsx)(_BallDataTiles.default, {
              tileClickAnalytics: tileSlug => {
                firebaseContext.ctaClick && firebaseContext.ctaClick('uncategorized-ball-tile-click', tileSlug, pageContext.analyticsId);
              },
              tiles: remainingSetOfBalls
            })
          })
        })]
      })]
    })
  });
};
var _default = exports.default = AWBallSummarySection;