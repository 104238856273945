"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _react = require("react");
var _clsx = _interopRequireDefault(require("clsx"));
var _LoadingButton = _interopRequireDefault(require("../../../../loading-button/LoadingButton"));
var _Styles = _interopRequireDefault(require("../../../mackenzies-mind/pages/Styles"));
var _reactRouterDom = require("react-router-dom");
var _CustomizedThemeContext = _interopRequireDefault(require("../../../../customized-theme-provider/CustomizedThemeContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const FourOhFour = () => {
  const classes = (0, _Styles.default)({
    bgImage: undefined
  });
  const history = (0, _reactRouterDom.useNavigate)();
  const theme = (0, _material.useTheme)();
  const customizedThemeContext = (0, _react.useContext)(_CustomizedThemeContext.default);
  const xsDown = (0, _material.useMediaQuery)(customizedThemeContext.customizedTheme.breakpoints.down('xs'));
  const smDown = (0, _material.useMediaQuery)(customizedThemeContext.customizedTheme.breakpoints.down('sm'));
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    className: (0, _clsx.default)(xsDown ? classes.fullscreenPlus : classes.fullscreen, classes.fullScreenImage),
    style: {
      position: "relative"
    },
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      className: xsDown ? classes.fullscreenPlus : classes.fullscreen,
      style: {
        position: 'relative'
      },
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        style: {
          position: "absolute",
          bottom: 0,
          height: '120px',
          backgroundImage: smDown ? "linear-gradient(180deg, transparent, rgba(111,111,111,1)" : 'transparent'
        }
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      className: (0, _clsx.default)(xsDown ? classes.fullscreenPlus : classes.fullscreen, classes.fullscreenOverlay)
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      container: true,
      className: (0, _clsx.default)(classes.fullscreen),
      style: {
        position: 'absolute',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10)
      },
      justifyContent: 'center',
      alignItems: 'center',
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        xs: 11,
        justifyContent: 'center',
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          align: 'center',
          color: 'primary',
          style: {
            fontSize: smDown ? "150px" : "250px",
            lineHeight: 1,
            fontWeight: "bolder"
          },
          children: "404"
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        xs: 11,
        justifyContent: 'center',
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'h3',
          align: 'center',
          color: 'textSecondary',
          children: "How Embarrassing! That page can\u2019t be found"
        })
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        xs: 11,
        sm: 9,
        justifyContent: 'center',
        children: [(0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'h6',
          align: 'center',
          color: 'textSecondary',
          children: "Sorry, but the page you are looking for does not exist here at Transformative Healing and Wellness please contact the administrator below if this persists."
        }), (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'h3',
          align: 'center',
          color: 'primary',
          children: "hello@thehandsomestnerd.com"
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        xs: 11,
        justifyContent: 'center',
        children: (0, _jsxRuntime.jsx)(_LoadingButton.default
        // width={200}
        // isLoading={isLoading}
        // groupiness={ButtonGroupMemberEnum.RIGHT}
        , {
          // width={200}
          // isLoading={isLoading}
          // groupiness={ButtonGroupMemberEnum.RIGHT}
          clickHandler: () => history('/'),
          color: 'primary',
          variant: 'contained',
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            align: 'center',
            color: 'textSecondary',
            variant: 'button',
            children: "Go to Homepage"
          })
        })
      })]
    })]
  });
};
var _default = exports.default = FourOhFour;