"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const FlashCardFront = props => {
  var _props$currentCard, _props$currentCard2, _sanityContext$cockta, _props$currentCard3;
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const theme = (0, _material.useTheme)();
  const smDown = (0, _material.useMediaQuery)(theme.breakpoints.down('sm'));
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    spacing: 1,
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      alignContent: 'center',
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'h2',
          fontFamily: 'Covered By Your Grace',
          align: 'center',
          sx: {
            lineHeight: .7,
            zIndex: 1000,
            position: "relative",
            color: props.isDarkMode ? "#dddddd" : theme.palette.text.primary
          },
          children: (_props$currentCard = props.currentCard) === null || _props$currentCard === void 0 ? void 0 : _props$currentCard.title
        })
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: ((_props$currentCard2 = props.currentCard) === null || _props$currentCard2 === void 0 ? void 0 : _props$currentCard2.imageSrc) && (0, _jsxRuntime.jsx)("img", {
        alt: props.currentCard.title,
        style: {
          zIndex: 900,
          position: "relative"
        },
        src: (_sanityContext$cockta = sanityContext.cocktailUrlFor((_props$currentCard3 = props.currentCard) === null || _props$currentCard3 === void 0 ? void 0 : _props$currentCard3.imageSrc).url()) !== null && _sanityContext$cockta !== void 0 ? _sanityContext$cockta : "",
        width: smDown ? 250 : 350,
        height: smDown ? 250 : 350
      })
    })]
  });
};
var _default = exports.default = FlashCardFront;