"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _FirebaseContext = _interopRequireDefault(require("./FirebaseContext"));
var _uuid = require("uuid");
var _app = require("firebase/app");
var _analytics = require("firebase/analytics");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// import firebase from "firebase/compat";
// import Analytics = firebase.analytics.Analytics;
const analyticsMock = {
  logEvent: () => {},
  setCurrentScreen: () => {},
  setUserId: () => {}
};
const FirebaseProvider = props => {
  const [app, setApp] = (0, _react.useState)();
  const [analytics, setAnalytics] = (0, _react.useState)();
  const [firebaseConfig, setFirebaseConfig] = (0, _react.useState)();
  (0, _react.useEffect)(() => {
    if (firebaseConfig) {
      // console.log("firebaseconfig", firebaseConfig)
      setApp((0, _app.initializeApp)(firebaseConfig));
    }
  }, [firebaseConfig]);
  (0, _react.useEffect)(() => {
    (0, _analytics.isSupported)().then(result => {
      if (result && app) {
        // console.log("Using really analytics", app)
        const theAnalytics = (0, _analytics.getAnalytics)(app);
        setAnalytics(theAnalytics);
      } else {
        // console.log("Using mocked analytics", app)
        setAnalytics(analyticsMock);
      }
    });
  }, [app]);
  const initFirebase = (apiKey, authDomain, databaseURL, projectId, storageBucket, messagingSenderId, appId, measurementId) => {
    setFirebaseConfig({
      apiKey: apiKey,
      authDomain: authDomain,
      databaseURL: databaseURL,
      projectId: projectId,
      storageBucket: storageBucket,
      messagingSenderId: messagingSenderId,
      appId: appId,
      measurementId: measurementId
    });
  };
  const analyticsPageView = (pathname, search, title) => {
    if (analytics) {
      try {
        (0, _analytics.logEvent)(analytics, 'page_view', {
          page_path: pathname + search,
          page_title: title
        });
      } catch (e) {
        console.warn('error with page view event', e);
      }
    } else {
      // console.error('This better be an automated test');
    }
  };
  const utils = {
    logEventWithData: (eventName, data) => {
      // console.log("Logging GA event", eventName, data)
      if (analytics) {
        (0, _analytics.logEvent)(analytics, eventName, data);
      }
    }
  };
  const ctaClick = (location, ctaText, userId) => {
    console.log(" Bout to analytic", location, ctaText, userId);
    utils.logEventWithData('cta_click', {
      userId: userId !== null && userId !== void 0 ? userId : (0, _uuid.v4)(),
      location,
      ctaText
    });
  };
  const setAppUserId = userId => {
    if (analytics) {
      (0, _analytics.setUserId)(analytics, userId);
      (0, _analytics.setUserProperties)(analytics, {
        isBlackCardValid: false
      });
    }
  };
  const reportVital = (vitalName, vitalMetric) => {
    utils.logEventWithData('web_vital_report', {
      name: vitalName,
      metric: vitalMetric
    });
  };
  const amenityTooltipShown = (serviceName, amenityName, analyticsId) => {
    utils.logEventWithData('amenityTooltipShown', {
      analyticsId,
      serviceName,
      amenityName
    });
  };
  const qrCodeShown = (qrCodeValue, analyticsId) => {
    utils.logEventWithData('qrCodeShown', {
      analyticsId,
      qrCodeValue
    });
  };
  const albumImageClick = (imageName, imageCaption, analyticsId) => {
    utils.logEventWithData('album_image_clicked', {
      analyticsId,
      imageName,
      imageCaption
    });
  };
  const utmCodes = (source, medium, campaign, id) => {
    utils.logEventWithData('utm_codes_received', {
      source,
      medium,
      campaign,
      id
    });
  };
  const analyticsViewBall = ball => {
    console.log('TODO GA ballView ', ball);
    // utils.logEventWithData('ball_view', {
    //     ball_slug: ball.slug?.current ?? 'no-slug',
    //     ball_title: ball.ballTitle ?? 'no-ball-title',
    // })
  };
  const newValue = (0, _react.useMemo)(() => ({
    initFirebase,
    analyticsPageView,
    ctaClick,
    reportVital,
    setAppUserId,
    amenityTooltipShown,
    qrCodeShown,
    albumImageClick,
    utmCodes,
    analyticsViewBall
  }), [firebaseConfig, app, analytics]);
  return (0, _jsxRuntime.jsx)(_FirebaseContext.default.Provider, {
    value: newValue,
    children: props.children
  });
};
var _default = exports.default = FirebaseProvider;