"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _AmenityContext = _interopRequireDefault(require("../../amenity-context/AmenityContext"));
var _useIsHorizontalOverflow = require("../../../utils/useIsHorizontalOverflow");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const AmenitiesSection = props => {
  const ref = (0, _react.useRef)(null);
  const isOverflow = (0, _useIsHorizontalOverflow.useIsHorizontalOverflow)(ref, () => {});
  const amenityContext = (0, _react.useContext)(_AmenityContext.default);
  const [elements, setElements] = (0, _react.useState)();
  const theme = (0, _material.useTheme)();
  (0, _react.useEffect)(() => {
    const newElements = amenityContext.getElements && amenityContext.getElements(props.service.slug.current);
    if (newElements) {
      setElements(newElements);
    }
  }, [amenityContext.getElements && amenityContext.getElements(props.service.slug.current)]);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    justifyContent: 'center',
    style: {
      // minHeight: "max-content",
      position: "relative"
    },
    alignItems: 'stretch',
    children: [isOverflow ? (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      xs: 3,
      alignItems: 'center',
      alignContent: 'center',
      item: true,
      style: {
        position: "absolute",
        left: 16,
        height: "100%",
        zIndex: "1000",
        pointerEvents: 'none'
      },
      children: (0, _jsxRuntime.jsx)(_iconsMaterial.ArrowLeft, {})
    }) : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {}), (0, _jsxRuntime.jsx)(_material.Grid, {
      xs: 10,
      item: true,
      container: true,
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        justifyContent: 'flex-start',
        children: (0, _jsxRuntime.jsx)(_material.List, {
          style: {
            paddingTop: theme.spacing(2),
            width: "100%",
            height: "130px"
          },
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            ref: ref,
            direction: 'column',
            alignItems: 'center',
            alignContent: 'flex-start',
            style: {
              paddingLeft: isOverflow ? theme.spacing(2) : theme.spacing(0),
              overflowY: "hidden",
              overflowX: "scroll",
              height: "100%",
              width: "100%"
            },
            children: elements
          })
        })
      })
    }), isOverflow ? (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      xs: 3,
      item: true,
      style: {
        position: "absolute",
        right: 16,
        height: "100%",
        zIndex: "1000",
        pointerEvents: 'none'
      },
      justifyContent: 'flex-end',
      alignContent: 'center',
      children: (0, _jsxRuntime.jsx)(_iconsMaterial.ArrowRight, {})
    }) : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})]
  });
};
var _default = exports.default = AmenitiesSection;