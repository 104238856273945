"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _ThwCommonStyles = _interopRequireDefault(require("../../../../common/sanityIo/ThwCommonStyles"));
var _ColorPalette = require("../../../../theme/common/ColorPalette");
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const ResumeFeedbackSection = props => {
  var _props$sectionData$fe;
  const globalClasses = (0, _ThwCommonStyles.default)();
  const theme = (0, _material.useTheme)();
  const customizedThemeContext = (0, _material.useTheme)();
  const smDown = (0, _material.useMediaQuery)(theme.breakpoints.down('sm'));
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const xsOnly = (0, _material.useMediaQuery)(customizedThemeContext.breakpoints.only('xs'));
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    style: {
      padding: theme.spacing(4, smDown ? 1 : 4)
    },
    className: globalClasses.resumeSection,
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      spacing: 3,
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        md: 4,
        alignContent: 'flex-start',
        spacing: 1,
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          container: true,
          children: [(0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            children: props.sectionData.title
          }), (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            color: 'primary',
            display: 'inline',
            children: "."
          })]
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            children: props.sectionData.introduction
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        md: 8,
        spacing: 2,
        justifyContent: xsOnly ? "center" : "flex-start",
        children: (_props$sectionData$fe = props.sectionData.feedbackEntries) === null || _props$sectionData$fe === void 0 ? void 0 : _props$sectionData$fe.map((feedbackEntry, index2) => {
          var _props$sectionData$fe2, _props$sectionData$fe3, _sanityContext$urlFor, _feedbackEntry$imageS;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            item: true,
            container: true,
            alignContent: 'flex-start',
            style: {
              borderBottom: "1px solid ".concat(index2 >= ((_props$sectionData$fe2 = (_props$sectionData$fe3 = props.sectionData.feedbackEntries) === null || _props$sectionData$fe3 === void 0 ? void 0 : _props$sectionData$fe3.length) !== null && _props$sectionData$fe2 !== void 0 ? _props$sectionData$fe2 : 0) - 2 ? "transparent" : _ColorPalette.COLORS.LIGHTGRAY)
              // padding: theme.spacing(1.75, 0)
            },
            xs: 12,
            spacing: 2,
            justifyContent: 'flex-start',
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              md: 3,
              lg: 2,
              xl: 2,
              container: true,
              children: (0, _jsxRuntime.jsx)("img", {
                alt: feedbackEntry.name,
                src: (_sanityContext$urlFor = sanityContext.urlFor((_feedbackEntry$imageS = feedbackEntry.imageSrc) !== null && _feedbackEntry$imageS !== void 0 ? _feedbackEntry$imageS : "").url()) !== null && _sanityContext$urlFor !== void 0 ? _sanityContext$urlFor : "",
                height: 50,
                style: {
                  maxWidth: "100%"
                }
              })
            }), (0, _jsxRuntime.jsxs)(_material.Grid, {
              item: true,
              md: 9,
              lg: 10,
              xl: 10,
              container: true,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  display: 'inline',
                  variant: 'body2',
                  children: feedbackEntry.customerName
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                  item: true,
                  children: [(0, _jsxRuntime.jsx)(_material.Typography, {
                    display: 'inline',
                    variant: 'subtitle1',
                    children: feedbackEntry.customerTitle
                  }), "@", (0, _jsxRuntime.jsx)(_material.Typography, {
                    display: 'inline',
                    variant: 'subtitle1',
                    children: feedbackEntry.companyName
                  })]
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                children: (0, _jsxRuntime.jsxs)(_material.Typography, {
                  variant: 'body1',
                  gutterBottom: true,
                  children: ["\"", feedbackEntry.quote, "\""]
                })
              })]
            })]
          }, index2);
        })
      })]
    })
  });
};
var _default = exports.default = ResumeFeedbackSection;