"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.promise.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _ColoredPng = _interopRequireDefault(require("../colored-png/ColoredPng"));
var _framerMotion = require("framer-motion");
var _reactIntersectionObserver = require("react-intersection-observer");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const AnimatedServiceItem = props => {
  var _props$service$iconIm;
  const controls = (0, _framerMotion.useAnimationControls)();
  const theme = (0, _material.useTheme)();
  const animateShrinkIcon = async () => {
    await controls.start({
      scale: 1
    });
  };
  const animateGrowIcon = async () => {
    await controls.start({
      scale: 1.1
    });
  };
  const shapeControls = (0, _framerMotion.useAnimation)();
  const [shapeRef, shapeInView] = (0, _reactIntersectionObserver.useInView)();
  (0, _react.useEffect)(() => {
    if (shapeInView) {
      shapeControls.start("onScreen");
    } else {
      shapeControls.start("offScreen");
    }
  }, [shapeControls, shapeInView]);
  const variants = {
    onScreen: {
      opacity: 1,
      transition: {
        duration: 2.5
      }
    },
    offScreen: {
      opacity: 0,
      transition: {
        duration: 1
      }
    }
  };
  return (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
    ref: shapeRef,
    variants: variants,
    initial: "offScreen",
    animate: shapeControls,
    onHoverStart: async () => {
      await animateShrinkIcon();
    },
    onHoverEnd: () => {
      animateGrowIcon();
    },
    children: (0, _jsxRuntime.jsx)(_material.Card, {
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        padding: theme.spacing(3, 2),
        style: {
          backgroundColor: "#000000",
          position: "relative"
        },
        justifyContent: 'center',
        children: [(0, _jsxRuntime.jsx)(_material.Card, {
          sx: {
            display: {
              sm: 'none',
              md: 'block'
            },
            position: "absolute",
            bottom: -180,
            width: "200px",
            height: "200px",
            backgroundColor: "#294856",
            borderRadius: "50%"
          }
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          alignItems: 'center',
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            item: true,
            style: {
              position: "relative"
            },
            children: [(0, _jsxRuntime.jsx)(_material.Card, {
              style: {
                position: "absolute",
                bottom: -8,
                right: -8,
                opacity: .5,
                width: "36px",
                height: "36px",
                backgroundColor: "#294856",
                borderRadius: "50%"
              }
            }), (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
              animate: controls,
              initial: {
                scale: 1.15
              },
              // whileHover={{scale: 1}}
              transition: {
                duration: .5
              },
              children: (0, _jsxRuntime.jsx)(_ColoredPng.default, {
                size: 64,
                color: '#01ecfd',
                maskAsset: (_props$service$iconIm = props.service.iconImageSrc) !== null && _props$service$iconIm !== void 0 ? _props$service$iconIm : ""
              })
            })]
          })
        }), (0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          style: {
            // backgroundSize: "contain",
            // backgroundRepeat: "no-repeat",
            // backgroundPosition: "right",
            // backgroundImage: `url(${urlFor(props.service.backgroundImageSrc ?? "").url() ?? imagePlaceholderClient.placeholderOrImage(props.service.backgroundImageSrc, 300, 500)})`,
          },
          justifyContent: 'center',
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              fontSize: 24,
              color: 'textSecondary',
              style: {
                marginTop: "16px",
                marginBottom: "16px"
              },
              variant: 'body2',
              align: 'center',
              children: props.service.contentTitle
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'textSecondary',
              variant: 'body1',
              align: 'center',
              maxWidth: 350,
              children: props.service.contentText
            })
          })]
        })]
      })
    })
  });
};
var _default = exports.default = AnimatedServiceItem;