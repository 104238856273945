"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _PageContext = _interopRequireDefault(require("../../../page-context/PageContext"));
var _reactRouter = require("react-router");
var _LoadingPage = _interopRequireDefault(require("./loading-page/LoadingPage"));
var _FourOhFour = _interopRequireDefault(require("../../transform-hw/pages/error-page/FourOhFour"));
var _UnderConstruction = _interopRequireDefault(require("../../transform-hw/pages/under-construction-page/UnderConstruction"));
var _PageLayout = _interopRequireDefault(require("./PageLayout"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const PageMux = props => {
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const urlParams = (0, _reactRouter.useParams)();
  (0, _react.useEffect)(() => {
    if (urlParams.documentSlug && urlParams.documentType) {
      // console.log("Page Mux reading URL Params", urlParams)
      pageContext.fetchDocument && pageContext.fetchDocument(urlParams.documentType, urlParams.documentSlug);
    }
    if (urlParams.pageSlug) {
      // console.log("found a slug in the mux", urlParams)
      pageContext.fetchPage && pageContext.fetchPage(urlParams.pageSlug);
    }
  }, []);
  (0, _react.useEffect)(() => {
    if (props.baseRoute && pageContext.updateBaseRoute) pageContext.updateBaseRoute(props.baseRoute);
  }, [props.baseRoute]);
  const PageContents = () => {
    if (!pageContext.page || pageContext.isPageLoading || !pageContext.page.theme) return (0, _jsxRuntime.jsx)(_LoadingPage.default, {});
    if (pageContext.page && !pageContext.page.isUnderConstruction) {
      return (0, _jsxRuntime.jsx)(_PageLayout.default, {
        homePage: pageContext.page
      });
    } else if (pageContext.page && pageContext.page.underConstructionPageRef) {
      var _pageContext$page;
      return (0, _jsxRuntime.jsx)(_UnderConstruction.default, {
        underConstructionPageRef: pageContext.page.underConstructionPageRef,
        email: (_pageContext$page = pageContext.page) === null || _pageContext$page === void 0 || (_pageContext$page = _pageContext$page.businessContact) === null || _pageContext$page === void 0 ? void 0 : _pageContext$page.email
      });
    }
    if (pageContext.isPageError) {
      return (0, _jsxRuntime.jsx)(_FourOhFour.default, {});
    }
    return (0, _jsxRuntime.jsx)(_FourOhFour.default, {});
  };
  return (0, _jsxRuntime.jsx)(PageContents, {});
};
var _default = exports.default = PageMux;