"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _SnackbarContext = _interopRequireDefault(require("../../../modal-context/SnackbarContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const SocialMediaBlockButton = props => {
  var _props$iconColor;
  const snackbarContext = (0, _react.useContext)(_SnackbarContext.default);
  const customizedThemeContext = (0, _material.useTheme)();
  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.socialMediaLink).then(() => {
      const snack = (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'body1',
          children: "Copied!"
        })
      });
      snackbarContext.openSnackbar && snackbarContext.openSnackbar(snack, 1500);
    });
  };
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: props.socialMediaName && (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      children: (0, _jsxRuntime.jsx)(_material.Tooltip, {
        arrow: true,
        componentsProps: {
          arrow: {
            style: {
              color: customizedThemeContext.palette.primary.main
            }
          },
          tooltip: {
            style: {
              backgroundColor: customizedThemeContext.palette.primary.main
            }
          }
        },
        title: (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          alignItems: 'center',
          spacing: 1,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            xs: 10,
            container: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'caption',
              style: {
                minWidth: "max-content"
              },
              children: props.socialMediaLink
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            xs: 2,
            style: {
              color: "white"
            },
            children: (0, _jsxRuntime.jsx)(_material.IconButton, {
              color: 'inherit',
              onClick: copyToClipboard,
              children: (0, _jsxRuntime.jsx)(_iconsMaterial.CopyAll, {
                fontSize: 'small'
              })
            })
          })]
        }),
        children: (0, _jsxRuntime.jsx)(_material.IconButton, {
          sx: {
            "&:hover": {
              backgroundColor: props.isHoverColor ? customizedThemeContext.palette.primary.dark : 'transparent'
            },
            borderRadius: 40,
            padding: customizedThemeContext.spacing(2),
            backgroundColor: props.bgColor ? props.theBackgroundColor : 'transparent',
            color: "".concat((_props$iconColor = props.iconColor) !== null && _props$iconColor !== void 0 ? _props$iconColor : customizedThemeContext.palette.primary.main, " !important")
          },
          href: props.socialMediaLink,
          size: props.size ? props.size : "small",
          children: props.iconButtonIcon
        })
      })
    })
  });
};
var _default = exports.default = SocialMediaBlockButton;