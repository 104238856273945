"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.combineDateAndTime = combineDateAndTime;
exports.combineDateTime = combineDateTime;
exports.emailPatternValidation = void 0;
exports.enumCreateSelectOptions = enumCreateSelectOptions;
exports.enumCreateSelectOptionsFromType = enumCreateSelectOptionsFromType;
exports.getDateInputValue = getDateInputValue;
exports.getDayFromDate = getDayFromDate;
exports.getFirebaseDateFormatString = getFirebaseDateFormatString;
exports.getFirebaseTimeFormatString = getFirebaseTimeFormatString;
exports.getMonthFromDate = getMonthFromDate;
exports.getPrettyDateStr = getPrettyDateStr;
exports.getPrettyTimeStr = getPrettyTimeStr;
exports.getTimeInputValue = getTimeInputValue;
exports.getYearFromDate = getYearFromDate;
exports.objectCreateSelectOptions = objectCreateSelectOptions;
exports.tokenizeInputDateValue = tokenizeInputDateValue;
exports.tokenizeInputTimeValue = tokenizeInputTimeValue;
exports.trailOffAfter = trailOffAfter;
exports.urlPatternValidation = void 0;
require("core-js/modules/es.parse-int.js");
var _validator = _interopRequireDefault(require("validator"));
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// This was a direct import into the db from the old one
const firebaseDateFormatStr = 'YYYY-MM-DD';
const firebaseTimeFormatStrLong = 'hh:mm a';
const firebaseTimeFormatStr = 'hh:mm a';
const prettyDateFormatStr = 'MMMM DD, YYYY';
const prettyDateFormatStrLong = 'MMMM DD';
const prettyDateFormatStrShort = 'M/DD/YYYY';
/**
 * This is how times are stored in Firebase
 */
function getFirebaseTimeFormatString() {
  return firebaseTimeFormatStr;
}
/**
 * Get the Month from Date String
 * @param date
 */
function getMonthFromDate(date) {
  return new Date(date).toLocaleString('default', {
    month: 'short'
  });
}
/**
 * Get the Month from Date String
 * @param date
 */
function getDayFromDate(date) {
  return new Date(date).toLocaleString('default', {
    weekday: 'long'
  });
}
/**
 * Get the Month from Date String
 * @param date
 */
function getYearFromDate(date) {
  return new Date(date).toLocaleString('default', {
    year: 'numeric'
  });
}
/**
 * Print pretty a date from firebase date string
 * @param firebaseDate
 */
function getPrettyDateStr(firebaseDate, long, short) {
  const momentDate = (0, _momentTimezone.default)(firebaseDate);
  let formatString = prettyDateFormatStr;
  if (long) {
    formatString = prettyDateFormatStrLong;
  } else if (short) {
    formatString = prettyDateFormatStrShort;
  }
  return momentDate.format(formatString);
}
/**
 * Print pretty a time from time string
 * @param date string
 */
function getPrettyTimeStr(date, long) {
  const momentTime = (0, _momentTimezone.default)(date);
  return momentTime.format(long ? firebaseTimeFormatStrLong : firebaseTimeFormatStr);
}
/**
 * Print pretty a date from date string
 * @param date string
 */
function getDateInputValue(date) {
  if (!date || date === '' || date === '0000-00-00') {
    return '0000-00-00';
  }
  const momentTime = (0, _momentTimezone.default)(date);
  return momentTime.format(firebaseDateFormatStr);
}
/**
 * Print pretty a date from time string
 * @param date string
 */
function getTimeInputValue(time) {
  if (time === '' || time === '00:00' || time === '0000-00-00T00:00Z') {
    return '00:00';
  }
  const momentTime = (0, _momentTimezone.default)(time, firebaseTimeFormatStr).tz('America/New_York');
  return momentTime.format(firebaseTimeFormatStr);
}
/**
 * combine date and time into one string the datetime sanity component understands
 * @param date string
 */
function combineDateTime(date, time) {
  const dateTokenized = date !== undefined ? date.split('-') : [];
  const timeTokenized = time !== undefined ? time.split(':') : [];
  // const tz = new Date().toLocaleString('en', {timeZoneName: 'short'}).split(' ').pop();
  if (dateTokenized.length === 3) {
    const year = parseInt(dateTokenized[0], 10);
    const month = parseInt(dateTokenized[1], 10) - 1;
    const day = parseInt(dateTokenized[2], 10);
    const hours = parseInt(timeTokenized[0], 10);
    const minutes = parseInt(timeTokenized[1], 10);
    const returned = !!hours && !!minutes ? new Date(year, month, day, hours, minutes) : new Date(year, month, day);
    return returned.toISOString();
  }
  return '';
}
function combineDateAndTime(date, time) {
  const dateTokenized = date !== undefined ? date.split('-') : [];
  const timeTokenized = time !== undefined ? time.split(':') : [];
  // const tz = new Date().toLocaleString('en', {timeZoneName: 'short'}).split(' ').pop();
  if (dateTokenized.length === 3) {
    const year = parseInt(dateTokenized[0], 10);
    const month = parseInt(dateTokenized[1], 10) - 1;
    const day = parseInt(dateTokenized[2], 10);
    const hours = parseInt(timeTokenized[0], 10);
    const minutes = parseInt(timeTokenized[1], 10);
    const returned = !!hours && !!minutes ? new Date(year, month, day, hours, minutes) : new Date(year, month, day);
    return returned.toISOString();
  }
  return '';
}
function tokenizeInputDateValue(inputDateValue) {
  const dateTokenized = inputDateValue !== undefined ? inputDateValue.split('-') : [];
  return {
    year: parseInt(dateTokenized[0], 10),
    month: parseInt(dateTokenized[1], 10) - 1,
    day: parseInt(dateTokenized[2], 10)
  };
}
function tokenizeInputTimeValue(inputTimeValue) {
  const timeTokenized = inputTimeValue !== undefined ? inputTimeValue.split(':') : [];
  const result = {
    hours: parseInt(timeTokenized[0], 10),
    minutes: parseInt(timeTokenized[1], 10),
    modality: 'am'
  };
  if (result.hours > 12) {
    result.hours -= 12;
    result.modality = 'pm';
  }
  return result;
}
/**
 * This is how dates are stored in Firebase
 */
function getFirebaseDateFormatString() {
  return firebaseDateFormatStr;
}
/**
 * Takes a long string and adds ellipses to the end to give the illusion that it has trailed off.
 * @param longString
 * @param numCharactersBeforeEllipse
 *
 * @returns {shortString: the shortened string, tooltip: the complete string for tooltip or link purposes}
 */
function trailOffAfter(longString, numCharactersBeforeEllipse) {
  const isPrintEllipse = longString.length > numCharactersBeforeEllipse;
  return {
    shortString: longString ? longString.substr(0, numCharactersBeforeEllipse).concat(isPrintEllipse ? '...' : '') : longString,
    tooltip: longString
  };
}
/**
 * Create Select menu option Object Array
 *
 * @param values
 * @param titles
 * @param isEmptyOption
 */
function enumCreateSelectOptions(values, titles, isEmptyOption) {
  const options = Object.keys(values).map(value => ({
    value,
    label: titles[value]
  }));
  return isEmptyOption ? [{
    value: '',
    label: ''
  }].concat(options) : options;
}
/**
 * Create Select menu option Object Array
 *
 * @param values
 * @param titles
 * @param isEmptyOption
 */
function enumCreateSelectOptionsFromType(values, isEmptyOption) {
  const options = Object.keys(values).map(value => ({
    value,
    label: values[value].valueOf()
  }));
  return isEmptyOption === true ? [{
    value: '',
    label: ''
  }].concat(options) : options;
}
/**
 * Create Select menu option Object Array from enum as object(REGIONS)
 *
 * @param values
 * @param titles
 * @param isEmptyOption
 */
function objectCreateSelectOptions(values, titles, isEmptyOption) {
  const options = Object.keys(values).map(value => ({
    value,
    label: titles[value]
  }));
  return isEmptyOption ? [{
    value: '',
    label: ''
  }].concat(options) : options;
}
const urlPatternValidation = URL => _validator.default.isURL(URL);
exports.urlPatternValidation = urlPatternValidation;
const emailPatternValidation = email => _validator.default.isEmail(email);
exports.emailPatternValidation = emailPatternValidation;