"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _styles = require("@mui/styles");
var _react = require("react");
var _BallType = require("../enums/BallType.enum");
var _material = require("@mui/material");
const useStyles = exports.useStyles = (0, _styles.makeStyles)(() => ({
  ballTypeButton: {
    height: '100px',
    minWidth: '100px'
  }
}));
const BasicBallInfo = props => {
  var _props$newBallToAdd2;
  const classes = useStyles();
  const [activeBallType, setActiveBallType] = (0, _react.useState)();
  function renderBallType() {
    return (0, _BallType.renderBallTypeChoice)(activeBallType);
  }
  (0, _react.useEffect)(() => {
    if (activeBallType != null) {
      props.stepComplete({
        ballType: activeBallType
      });
    }
  }, [activeBallType]);
  (0, _react.useEffect)(() => {
    var _props$newBallToAdd;
    if ((_props$newBallToAdd = props.newBallToAdd) !== null && _props$newBallToAdd !== void 0 && _props$newBallToAdd.ballType) {
      setActiveBallType(props.newBallToAdd.ballType);
    }
  }, [(_props$newBallToAdd2 = props.newBallToAdd) === null || _props$newBallToAdd2 === void 0 ? void 0 : _props$newBallToAdd2.ballType]);
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    "data-testid": 'add-ball-basic-info-step',
    direction: 'column',
    spacing: 2,
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsxs)(_material.Typography, {
          display: 'inline',
          variant: 'h5',
          color: 'textSecondary',
          children: ["What type of ball do you wish to add? ", (0, _jsxRuntime.jsx)(_material.Box, {
            sx: {
              color: theme.palette.primary.main,
              display: "inline"
            },
            children: renderBallType()
          })]
        })
      })
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      spacing: 3,
      style: {
        paddingTop: theme.spacing(2.25),
        height: '450px'
      },
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'h6',
          style: {
            textTransform: 'uppercase'
          },
          color: 'textSecondary',
          children: "Choose the type of ball below:"
        })
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        spacing: 2,
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          alignItems: 'center',
          justifyContent: 'space-around',
          direction: 'column',
          spacing: 2,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 6,
            children: (0, _jsxRuntime.jsx)(_material.Button, {
              className: classes.ballTypeButton,
              // style={{...theme.typography.h6}}
              fullWidth: true,
              "data-testid": 'ball-type-majorball-button',
              variant: "".concat(activeBallType === _BallType.BallTypeEnum.BALL ? 'contained' : 'outlined'),
              color: 'primary',
              onClick: () => setActiveBallType(_BallType.BallTypeEnum.BALL),
              children: "Major Ball"
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 6,
            children: (0, _jsxRuntime.jsx)(_material.Button, {
              className: classes.ballTypeButton,
              // style={{...theme.typography.h6}}
              fullWidth: true,
              "data-testid": 'ball-type-miniball-button',
              variant: "".concat(activeBallType === _BallType.BallTypeEnum.MINI_BALL ? 'contained' : 'outlined'),
              color: 'primary',
              onClick: () => setActiveBallType(_BallType.BallTypeEnum.MINI_BALL),
              children: "Mini-Ball"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          alignItems: 'center',
          justifyContent: 'space-around',
          direction: 'column',
          spacing: 2,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 6,
            children: (0, _jsxRuntime.jsx)(_material.Button, {
              className: classes.ballTypeButton,
              // style={{...theme.typography.h6}}
              fullWidth: true,
              "data-testid": 'ball-type-kikiball-button',
              variant: "".concat(activeBallType === _BallType.BallTypeEnum.KIKI_BALL ? 'contained' : 'outlined'),
              color: 'primary',
              onClick: () => setActiveBallType(_BallType.BallTypeEnum.KIKI_BALL),
              children: "Kiki Ball"
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            xs: 6,
            children: (0, _jsxRuntime.jsx)(_material.Button, {
              className: classes.ballTypeButton,
              // style={{...theme.typography.h6}}
              fullWidth: true,
              "data-testid": 'ball-type-miniballdeluxe-button',
              variant: "".concat(activeBallType === _BallType.BallTypeEnum.MINI_BALL_DELUXE ? 'contained' : 'outlined'),
              color: 'primary',
              onClick: () => setActiveBallType(_BallType.BallTypeEnum.MINI_BALL_DELUXE),
              children: "Mini-Ball Deluxe"
            })
          })]
        })]
      })]
    })]
  });
};
var _default = exports.default = BasicBallInfo;