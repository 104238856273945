"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/es.symbol.description.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _clsx = _interopRequireDefault(require("clsx"));
var _Styles = _interopRequireDefault(require("../mackenzies-mind/pages/Styles"));
var _TheOtherSideLogo = _interopRequireDefault(require("./TheOtherSideLogo"));
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  preroot: {
    minHeight: '221px',
    color: "white",
    paddingTop: theme.spacing(9)
  }
}));
const TheDrinkeryOtherSideSection = props => {
  var _pageContext$page, _props$sectionData$th;
  const classes = (0, _Styles.default)({
    bgImage: undefined
  });
  const theClasses = useStyles();
  const theme = (0, _material.useTheme)();
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    className: theClasses.preroot,
    children: (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      className: (0, _clsx.default)(classes.fullSection),
      justifyContent: 'center',
      alignItems: 'center',
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        children: (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            item: true,
            spacing: 2,
            children: (0, _jsxRuntime.jsxs)(_material.Grid, {
              item: true,
              container: true,
              children: [props.sectionData.isLogo && (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                justifyContent: 'center',
                children: (0, _jsxRuntime.jsx)(_TheOtherSideLogo.default, {
                  isCenter: true,
                  logoImageSrc: (_pageContext$page = pageContext.page) === null || _pageContext$page === void 0 ? void 0 : _pageContext$page.businessCardImageSrc
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                justifyContent: 'center',
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'h5',
                  children: props.sectionData.contentTitle
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                justifyContent: 'center',
                children: (0, _jsxRuntime.jsx)(_material.Button, {
                  variant: props.sectionData.isLink ? "outlined" : 'text',
                  href: props.sectionData.isLink ? '/the-drinkery/theOtherSide' : undefined,
                  style: {
                    marginTop: 32,
                    marginBottom: 32
                  },
                  children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                    container: true,
                    item: true,
                    justifyContent: 'center',
                    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                      item: true,
                      container: true,
                      style: {
                        marginTop: theme.spacing(2),
                        width: "200px",
                        height: "200px",
                        backgroundImage: "url(".concat(sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.sectionData.imageSrc, 200, 200), ")"),
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"
                      }
                    }), props.sectionData.isLink && (0, _jsxRuntime.jsx)(_material.Grid, {
                      item: true,
                      container: true,
                      justifyContent: 'center',
                      children: (0, _jsxRuntime.jsx)(_material.Typography, {
                        variant: 'body1',
                        style: {
                          color: "white"
                        },
                        children: "(click to enter)"
                      })
                    })]
                  })
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                justifyContent: 'center',
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'body2',
                  gutterBottom: true,
                  style: {
                    color: "white",
                    minWidth: "340px",
                    maxWidth: "540px",
                    fontWeight: 400
                  },
                  align: 'center',
                  children: props.sectionData.description
                })
              }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                item: true,
                justifyContent: 'center',
                children: [props.sectionData.isShowMenu && (0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  item: true,
                  justifyContent: 'center',
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    variant: 'h6',
                    gutterBottom: true,
                    style: {
                      color: "white",
                      width: "340px",
                      paddingTop: 24
                    },
                    align: 'center',
                    children: "Spirit Menu"
                  })
                }), props.sectionData.isShowMenu && ((_props$sectionData$th = props.sectionData.theLiquors) === null || _props$sectionData$th === void 0 ? void 0 : _props$sectionData$th.map((aLiquor, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  item: true,
                  justifyContent: 'center',
                  xs: 4,
                  sm: 3,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    variant: 'body1',
                    style: {
                      color: "white",
                      width: "340px"
                    },
                    align: 'center',
                    children: aLiquor
                  })
                }, index)))]
              })]
            })
          })
        })
      })
    })
  });
};
var _default = exports.default = TheDrinkeryOtherSideSection;