"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ColorPalette = require("../../../../theme/common/ColorPalette");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useCustomStyles = (0, _makeStyles.default)(theme => {
  var _theme$palette;
  return {
    // transparentBacking: {
    //     borderLeft: `4px solid ${MixedFeelingsByTTheme.palette.primary.main}`,
    //     borderRight: `4px solid ${MixedFeelingsByTTheme.palette.primary.main}`,
    //     padding: MixedFeelingsByTTheme.spacing(2,0),
    //     backgroundColor:COLORS.TRANSPARENTWHITE
    // },
    fullscreen: {
      width: 'calc(100vw)',
      height: 'calc(100vh)',
      position: "relative"
    },
    fullscreenPlus: {
      width: 'calc(100vw)',
      height: 'calc(100vh)',
      position: "relative"
    },
    endAdornedInput: {
      "& .MuiFilledInput-adornedEnd": {
        border: "1px solid black !important",
        paddingRight: 0,
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px"
      },
      "& .MuiOutlinedInput-adornedEnd": {
        border: "1px solid black !important",
        paddingRight: 0,
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px"
      },
      "& .MuiInputBase-input": {
        borderRightWidth: 0,
        "&.Mui-hover": {
          borderBottomColor: "black !important"
        }
      },
      "& .MuiButton-containedSecondary": {
        border: 0,
        borderLeft: '1px solid black !important'
      }
    },
    spacer: {
      marginBottom: "40px"
    },
    fullscreenOverlay: {
      position: "absolute",
      backgroundColor: "rgba(0, 0, 0, .5)"
    },
    fullscreenWhiteOverlay: {
      position: "absolute",
      backgroundColor: "rgba(255, 255, 255, 0.3)"
    },
    fullScreenImage: {
      position: "relative",
      backgroundImage: props => "url(".concat(props.bgImage, ")"),
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundColor: theme === null || theme === void 0 || (_theme$palette = theme.palette) === null || _theme$palette === void 0 || (_theme$palette = _theme$palette.background) === null || _theme$palette === void 0 ? void 0 : _theme$palette.default
    },
    fullSection: {
      width: 'calc(100vw)',
      height: '100%',
      position: "relative",
      zIndex: 0
    },
    fullSectionOverlay: {
      position: "absolute",
      backgroundColor: "rgba(0, 0, 0, .4)",
      minHeight: '512px',
      height: '100%',
      width: "100%",
      zIndex: 1
    },
    fullContainer: {
      width: '100%',
      height: '100%'
    },
    resumeSection: {
      borderBottom: "1px solid ".concat(_ColorPalette.COLORS.LIGHTGRAY)
    }
  };
});
var _default = exports.default = useCustomStyles;