"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _clsx = _interopRequireDefault(require("clsx"));
var _Styles = _interopRequireDefault(require("../mackenzies-mind/pages/Styles"));
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  preroot: {
    minHeight: '321px',
    // backgroundColor: "black",
    color: "white"
    // paddingLeft: -theme.spacing(-5),
  }
}));
const TheDrinkeryStaffSection = props => {
  // const classes = useCustomStyles({bgImage: bgImage})
  const classes = (0, _Styles.default)({
    bgImage: undefined
  });
  const theClasses = useStyles();
  const theme = (0, _material.useTheme)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    className: theClasses.preroot,
    style: {
      position: "relative",
      color: "white"
    },
    children: (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      className: (0, _clsx.default)(classes.fullSection),
      style: {
        // position: 'absolute',
        // paddingBottom: smDown ? 0 : theme.spacing(10)
      },
      justifyContent: 'center',
      alignItems: 'center',
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        justifyContent: 'center',
        style: {
          paddingTop: theme.spacing(2)
        },
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h6',
            align: 'center',
            gutterBottom: true,
            children: props.sectionData.contentTitle
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          justifyContent: 'center',
          xs: 12,
          sm: 9,
          md: 7,
          lg: 5,
          xl: 5,
          children: props.sectionData.teamList.map((teamMember, index) => (0, _jsxRuntime.jsxs)(_material.Grid, {
            item: true,
            justifyContent: 'center',
            xs: 6,
            sm: 4,
            alignItems: 'center',
            alignContent: 'center',
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: 'center',
              children: (0, _jsxRuntime.jsx)(_material.Avatar, {
                src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(teamMember.image, 40, 40),
                variant: 'rounded',
                style: {
                  width: theme.spacing(18),
                  height: theme.spacing(18)
                }
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: 'center',
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'body1',
                children: teamMember.title
              })
            })]
          }, index))
        })]
      })
    })
  });
};
var _default = exports.default = TheDrinkeryStaffSection;