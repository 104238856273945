"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.symbol.description.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _AmenityContext = _interopRequireDefault(require("../../amenity-context/AmenityContext"));
var _CustomizedThemeContext = _interopRequireDefault(require("../../customized-theme-provider/CustomizedThemeContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const ToolTipWrap = props => {
  const amenityContext = (0, _react.useContext)(_AmenityContext.default);
  const customizedThemeContext = (0, _react.useContext)(_CustomizedThemeContext.default);
  const smDown = (0, _material.useMediaQuery)(customizedThemeContext.customizedTheme.breakpoints.down('sm'));
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    xs: 6,
    item: true,
    onClick: () => {
      amenityContext.openSnackbar && amenityContext.openSnackbar(props.serviceTitle, props.amenity);
    },
    children: (0, _jsxRuntime.jsx)(_material.Tooltip, {
      disableHoverListener: smDown,
      title: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        style: {
          maxWidth: "160px"
        },
        children: [(0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'subtitle1',
          color: 'textSecondary',
          children: props.amenity.title
        }), (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'subtitle2',
          color: 'textSecondary',
          children: props.amenity.description
        })]
      }),
      children: props.children
    })
  });
};
var _default = exports.default = ToolTipWrap;