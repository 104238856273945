"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _clsx = _interopRequireDefault(require("clsx"));
var _Styles = _interopRequireDefault(require("../mackenzies-mind/pages/Styles"));
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
var _BallPage = _interopRequireDefault(require("./ball-page/BallPage"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const AWSingleBallPageSection = props => {
  var _ref, _pageContext$page$the, _pageContext$page;
  const classes = (0, _Styles.default)({
    bgImage: undefined
  });
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    minHeight: 521,
    sx: {
      padding: theme.spacing((_ref = ((_pageContext$page$the = (_pageContext$page = pageContext.page) === null || _pageContext$page === void 0 || (_pageContext$page = _pageContext$page.theme) === null || _pageContext$page === void 0 ? void 0 : _pageContext$page.appBarHeight) !== null && _pageContext$page$the !== void 0 ? _pageContext$page$the : 8) / 8) !== null && _ref !== void 0 ? _ref : 8.5, 0)
    },
    children: (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      className: (0, _clsx.default)(classes.fullSection),
      justifyContent: 'center',
      alignItems: 'center',
      children: (0, _jsxRuntime.jsx)(_BallPage.default, {
        ball: props.ball ? props.ball : pageContext.documentData
      })
    })
  });
};
var _default = exports.default = AWSingleBallPageSection;