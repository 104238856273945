"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.renderBallType = exports.BallTypeTitleEnum = exports.BallTypeEnum = void 0;
exports.renderBallTypeChoice = renderBallTypeChoice;
var BallTypeEnum;
(function (BallTypeEnum) {
  BallTypeEnum["MINI_BALL"] = "MINI_BALL";
  BallTypeEnum["MINI_BALL_DELUXE"] = "MINI_BALL_DELUXE";
  BallTypeEnum["KIKI_BALL"] = "KIKI_BALL";
  BallTypeEnum["BALL"] = "BALL";
  BallTypeEnum["NONE"] = " ";
})(BallTypeEnum || (exports.BallTypeEnum = BallTypeEnum = {}));
var BallTypeTitleEnum;
(function (BallTypeTitleEnum) {
  BallTypeTitleEnum["MINI_BALL"] = "Mini-Ball";
  BallTypeTitleEnum["MINI_BALL_DELUXE"] = "Mini-Ball Deluxe";
  BallTypeTitleEnum["KIKI_BALL"] = "Kiki Ball";
  BallTypeTitleEnum["BALL"] = "Major Ball";
  BallTypeTitleEnum["NONE"] = " ";
})(BallTypeTitleEnum || (exports.BallTypeTitleEnum = BallTypeTitleEnum = {}));
function renderBallTypeChoice(ballTypeChoice) {
  if (ballTypeChoice) {
    // @ts-ignore
    return BallTypeTitleEnum[ballTypeChoice];
  }
  return "";
}
const renderBallType = ballType => {
  if (ballType) return ballType === BallTypeEnum.NONE || !BallTypeTitleEnum ? 'ball' : BallTypeTitleEnum[ballType];
  return 'ball';
};
exports.renderBallType = renderBallType;