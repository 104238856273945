"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _framerMotion = require("framer-motion");
var _reactIntersectionObserver = require("react-intersection-observer");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  root: {}
}));
const AnimatedAbstractShape = props => {
  const shapeControls = (0, _framerMotion.useAnimation)();
  const [shapeRef, shapeInView] = (0, _reactIntersectionObserver.useInView)();
  (0, _react.useEffect)(() => {
    if (shapeInView) {
      shapeControls.start("onScreen");
    } else {
      shapeControls.start("offScreen");
    }
  }, [shapeControls, shapeInView]);
  return (0, _jsxRuntime.jsx)(_framerMotion.motion.div, {
    ref: shapeRef,
    style: {
      position: "absolute",
      opacity: props.opacity ? props.opacity : 1
    },
    variants: props.shapeVariants,
    initial: "offScreen",
    animate: shapeControls,
    children: (0, _jsxRuntime.jsx)("img", {
      alt: 'abstract shape',
      src: props.image
    })
  });
};
var _default = exports.default = AnimatedAbstractShape;