"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _blockContentToReact = _interopRequireDefault(require("@sanity/block-content-to-react"));
var _material = require("@mui/material");
var _BlockContentRenderer = require("../common/sanityIo/BlockContentRenderer");
var _ThwCommonStyles = _interopRequireDefault(require("../common/sanityIo/ThwCommonStyles"));
var _Footer = _interopRequireDefault(require("./templates/mackenzies-mind/footer/Footer"));
var _SanityContext = _interopRequireDefault(require("../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const FooterBlockContentLayoutContainer = props => {
  var _props$content$map, _props$content;
  const classes = (0, _ThwCommonStyles.default)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    children: (_props$content$map = props === null || props === void 0 || (_props$content = props.content) === null || _props$content === void 0 ? void 0 : _props$content.map((columnLayoutContainer, index) => {
      switch (columnLayoutContainer._type) {
        case 'column1BlockContent':
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            justifyContent: 'center',
            alignItems: 'stretch',
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Card, {
                className: classes.root,
                style: {
                  paddingTop: '80px'
                },
                children: (0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  item: true,
                  xs: 12,
                  className: classes.layoutContainer,
                  children: (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    xs: 12,
                    children: (0, _jsxRuntime.jsx)(_blockContentToReact.default, {
                      blocks: columnLayoutContainer.content,
                      serializers: _BlockContentRenderer.blockSerializers,
                      projectId: sanityContext.theSanityClient.config().projectId,
                      dataset: sanityContext.theSanityClient.config().dataset
                    })
                  })
                })
              })
            })
          }, 'column1BlockContent_header');
        case 'column2BlockContent':
          return (0, _jsxRuntime.jsx)(_material.Grid, {
            container: true,
            justifyContent: 'center',
            alignItems: 'stretch',
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Card, {
                className: classes.root,
                style: {
                  paddingTop: '80px'
                },
                children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                  container: true,
                  item: true,
                  xs: 12,
                  className: classes.layoutContainer,
                  children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    xs: 6,
                    children: (0, _jsxRuntime.jsx)(_blockContentToReact.default, {
                      blocks: columnLayoutContainer.column1.content,
                      serializers: _BlockContentRenderer.blockSerializers,
                      projectId: sanityContext.theSanityClient.config().projectId,
                      dataset: sanityContext.theSanityClient.config().dataset
                    })
                  }), (0, _jsxRuntime.jsx)(_material.Grid, {
                    item: true,
                    xs: 6,
                    children: (0, _jsxRuntime.jsx)(_blockContentToReact.default, {
                      blocks: columnLayoutContainer.column2.content,
                      serializers: _BlockContentRenderer.blockSerializers,
                      projectId: sanityContext.theSanityClient.config().projectId,
                      dataset: sanityContext.theSanityClient.config().dataset
                    })
                  })]
                })
              })
            })
          }, 'column2BlockContent_header');
        case 'FooterSection':
          const footer = columnLayoutContainer;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            xs: 12,
            children: [(0, _jsxRuntime.jsx)(_material.Link, {
              id: "BOTTOM_OF_PAGE",
              underline: "hover",
              children: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
            }), (0, _jsxRuntime.jsx)(_Footer.default, {
              topPadding: footer.topPadding,
              backgroundColor: footer.backgroundColor,
              backgroundImgSrc: footer.backgroundImgSrc,
              isSocialMediaBlock: footer.isSocialMediaBlock,
              pageFooter: footer.footerMenuRef
            })]
          }, 'BOTTOM_OF_PAGE');
        default:
          return (0, _jsxRuntime.jsxs)("span", {
            children: ["Undefined section ", columnLayoutContainer._type]
          }, index);
      }
    })) !== null && _props$content$map !== void 0 ? _props$content$map : (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {})
  });
};
var _default = exports.default = FooterBlockContentLayoutContainer;