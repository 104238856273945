"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertToHexCode = exports.COLORS = void 0;
var COLORS;
(function (COLORS) {
  COLORS["TRANSPARENT_DARKBLUE"] = "rgba(0,0,53,.85)";
  COLORS["TRANSPARENTWHITE"] = "rgba(255,255,255,0.75)";
  COLORS["TRANSPARENTERWHITE"] = "rgba(255,255,255,0.55)";
  COLORS["TRANSPARENTLIGHTGRAY"] = "rgba(244,243,245,0.87)";
  COLORS["TRANSPARENTDARKGRAY"] = "rgba(67,66,74,0.78)";
  COLORS["DARKBLUE"] = "#000035";
  COLORS["BLUE"] = "#102B88";
  // DARK_GRAY = '#A8A9AC',
  COLORS["GRAY"] = "#CFCFCF";
  COLORS["LIGHT_GRAY"] = "#949495";
  COLORS["LIGHTBLUE"] = "#2CC4D7";
  COLORS["ALMOSTPURPLE"] = "#331BAD";
  COLORS["LIGHTGRAY"] = "#F4F3F5";
  COLORS["MEDIUMGRAY"] = "#BCB9B0";
  COLORS["DARKGRAY"] = "#43424A";
  COLORS["AQUA"] = "#12b3be";
  COLORS["WHITESMOKE"] = "#f6f6f6";
  COLORS["WHITE"] = "#ffffff";
  COLORS["RED"] = "#d20027";
  COLORS["ALMOSTWHITE"] = "#dfd8d9";
  COLORS["DARKERGRAY"] = "#383838";
  COLORS["DARK_GRAY"] = "#A8A9AC";
  COLORS["LIGHTER_GRAY"] = "#E3E3E3";
  COLORS["PINK"] = "#FFA9E7";
  COLORS["DARKORANGE"] = "#462600";
  COLORS["LIGHT_GRAY2"] = "#E3E3E3";
  COLORS["ALMOST_BLACK"] = "#131313";
  COLORS["SIGMA_BLUE"] = "#19468D";
  COLORS["PURPLE"] = "#333784";
  COLORS["LUXURY_PURPLE"] = "#4B0082";
  COLORS["LIGHT_WHITE"] = "#404040";
  COLORS["DISABLED_GRAY"] = "#949495";
})(COLORS || (exports.COLORS = COLORS = {}));
const convertToHexCode = value => {
  let defaultBg = COLORS.WHITESMOKE;
  switch (value) {
    case 'WHITESMOKE':
      defaultBg = COLORS.WHITESMOKE;
      break;
    case 'DARKBLUE':
      defaultBg = COLORS.DARKBLUE;
      break;
    case 'TRANSPARENT_DARKBLUE':
      defaultBg = COLORS.TRANSPARENT_DARKBLUE;
      break;
    case 'BLUE':
      defaultBg = COLORS.BLUE;
      break;
    case 'GRAY':
      defaultBg = COLORS.GRAY;
      break;
    case 'LIGHT_GRAY':
      defaultBg = COLORS.LIGHT_GRAY;
      break;
    case 'TRANSPARENTWHITE':
      defaultBg = COLORS.TRANSPARENTWHITE;
      break;
    case 'TRANSPARENTERWHITE':
      defaultBg = COLORS.TRANSPARENTERWHITE;
      break;
    case 'LIGHTBLUE':
      defaultBg = COLORS.LIGHTBLUE;
      break;
    case 'ALMOSTPURPLE':
      defaultBg = COLORS.ALMOSTPURPLE;
      break;
    case 'LIGHTGRAY':
      defaultBg = COLORS.LIGHTGRAY;
      break;
    case 'TRANPARENTLIGHTGRAY':
      defaultBg = COLORS.TRANSPARENTLIGHTGRAY;
      break;
    case 'MEDIUMGRAY':
      defaultBg = COLORS.MEDIUMGRAY;
      break;
    case 'DARKGRAY':
      defaultBg = COLORS.DARKGRAY;
      break;
    case 'TRANPARENTDARKGRAY':
      defaultBg = COLORS.TRANSPARENTDARKGRAY;
      break;
    case 'AQUA':
      defaultBg = COLORS.AQUA;
      break;
    case 'RED':
      defaultBg = COLORS.RED;
      break;
    case 'ALMOSTWHITE':
      defaultBg = COLORS.ALMOSTWHITE;
      break;
    case 'DARKERGRAY':
      defaultBg = COLORS.DARKERGRAY;
      break;
    // case 'DARKERGRAY':
    //     defaultBg = COLORS.DARKERGRAY
    //     break;
    case 'LIGHTER_GRAY':
      defaultBg = COLORS.LIGHTER_GRAY;
      break;
    case 'DARK_GRAY':
      defaultBg = COLORS.DARK_GRAY;
      break;
    case 'ALMOST_BLACK':
      defaultBg = COLORS.ALMOST_BLACK;
      break;
    case 'SIGMA_BLUE':
      defaultBg = COLORS.SIGMA_BLUE;
      break;
    case 'PURPLE':
      defaultBg = COLORS.PURPLE;
      break;
    case 'LUXURY_PURPLE':
      defaultBg = COLORS.LUXURY_PURPLE;
      break;
    case 'LIGHT_WHITE':
      defaultBg = COLORS.LIGHT_WHITE;
      break;
    case 'DISABLED_GRAY':
      defaultBg = COLORS.DISABLED_GRAY;
      break;
    default:
      defaultBg = COLORS.WHITE;
  }
  return defaultBg;
};
exports.convertToHexCode = convertToHexCode;