"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _BallSearchContext = _interopRequireDefault(require("../ball-search-context/BallSearchContext"));
var _material = require("@mui/material");
var _iconsMaterial = require("@mui/icons-material");
var _StyledTextField = _interopRequireDefault(require("../styled-text-field/StyledTextField"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// const useStyles = makeStyles(() =>
//     createStyles({
//         textfield: {
//             "& .MuiOutlinedInput-notchedOutline": {
//                 "&:hover" : {
//                 backgroundColor: "red",
//                     borderWidth: 1,
//                     borderColor: "black",
//                 }
//                 // borderWidth: 1,
//                 // borderColor: "black",
//             }
//         },
//     }),
// );
const BallSearchBox = () => {
  var _searchContext$search, _searchContext$search2;
  // const theme =useTheme()
  // const style = {
  //     fieldset: {
  //         borderColor: "black"
  //     },
  //     "&:hover fieldset": {
  //         //borderColor: "green!important" // works
  //         borderColor: "black!important" // doesnt work
  //     }
  // };
  const searchContext = (0, _react.useContext)(_BallSearchContext.default);
  // const classes = useStyles()
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    item: true,
    container: true,
    alignItems: 'stretch',
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      xs: 11,
      children: (0, _jsxRuntime.jsx)(_StyledTextField.default, {
        style: {
          color: 'black'
        },
        // sx={style}
        color: 'primary',
        fullWidth: true,
        label: 'Search the Ball Scene',
        helperText: 'Keyword Search',
        onChange: searchContext.updateSearchParams,
        name: 'keywords',
        value: (_searchContext$search = (_searchContext$search2 = searchContext.searchParams) === null || _searchContext$search2 === void 0 ? void 0 : _searchContext$search2.keywords) !== null && _searchContext$search !== void 0 ? _searchContext$search : ''
      })
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      xs: 1,
      justifyContent: 'center',
      alignItems: 'center',
      children: (0, _jsxRuntime.jsx)(_material.Button, {
        style: {
          height: 56
        },
        onClick: searchContext.resetSearchResults,
        children: (0, _jsxRuntime.jsx)(_iconsMaterial.Close, {
          fontSize: 'large'
        })
      })
    })]
  });
};
var _default = exports.default = BallSearchBox;