"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _uuid = require("uuid");
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
var _ColoredPng = _interopRequireDefault(require("../../colored-png/ColoredPng"));
var _FirebaseContext = _interopRequireDefault(require("../../../common/firebase/firebase-context/FirebaseContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const COLOR_ROTATION = ["#cd3647", "#343656", "#333784"];
const WebDevServiceItem = props => {
  var _props$index;
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const LearnMoreButton = () => {
    var _props$service;
    return (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      xs: 6,
      children: props.service.learnMoreText && ((_props$service = props.service) === null || _props$service === void 0 ? void 0 : _props$service.learnMoreText.length) > 0 && (0, _jsxRuntime.jsx)(_material.Button, {
        fullWidth: true,
        onClick: () => {
          var _props$service$slug$c, _props$service$slug, _props$service$learnM;
          return firebaseContext.ctaClick && firebaseContext.ctaClick((_props$service$slug$c = (_props$service$slug = props.service.slug) === null || _props$service$slug === void 0 ? void 0 : _props$service$slug.current) !== null && _props$service$slug$c !== void 0 ? _props$service$slug$c : "", (_props$service$learnM = props.service.learnMoreText) !== null && _props$service$learnM !== void 0 ? _props$service$learnM : "", pageContext.analyticsId);
        },
        color: 'primary',
        href: props.service.learnMoreLink,
        variant: 'contained',
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          variant: 'button',
          noWrap: true,
          children: props.service.learnMoreText
        })
      })
    });
  };
  const theme = (0, _material.useTheme)();
  // const mediaContext = useContext(MediaQueriesContext)
  // const mdDown = mediaContext.mdDown
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    md: 6,
    style: {
      backgroundColor: COLOR_ROTATION[((_props$index = props.index) !== null && _props$index !== void 0 ? _props$index : 0) % 3],
      padding: theme.spacing(4)
    },
    alignContent: 'center',
    alignItems: 'center',
    children: (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      direction: 'column',
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        spacing: 2,
        alignContent: 'center',
        alignItems: 'center',
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          alignContent: 'center',
          justifyContent: 'space-between',
          alignItems: 'center',
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            xs: 6,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'h3',
              style: {
                fontFamily: "Elaine Sans"
              },
              color: 'primary',
              children: props.service.contentTitle
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_ColoredPng.default, {
              size: 64,
              maskAsset: props.service.imageSrc,
              color: 'white'
            })
          })]
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            style: {
              fontFamily: "raleway"
            },
            color: 'primary',
            children: props.service.contentText
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          children: !props.hideLearnMoreButton && (0, _jsxRuntime.jsx)(LearnMoreButton, {})
        })]
      })
    })
  }, (0, _uuid.v4)());
};
var _default = exports.default = WebDevServiceItem;