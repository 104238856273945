"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _material = require("@mui/material");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _ColoredPng = _interopRequireDefault(require("../colored-png/ColoredPng"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  root: {}
}));
const BulletedHeader = props => {
  const theme = (0, _material.useTheme)();
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    item: true,
    container: true,
    justifyContent: props.isCenter ? 'center' : 'flex-start',
    alignItems: 'center',
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      style: {
        zIndex: 10,
        marginRight: "4px"
      },
      children: props.heroBullet && (0, _jsxRuntime.jsx)(_ColoredPng.default, {
        size: 11,
        maskAsset: props.heroBullet,
        color: props.color === 'secondary' ? theme.palette.secondary.light : theme.palette.primary.dark
      })
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      style: {
        position: "relative"
      },
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        style: {
          borderTop: "1px solid ".concat(props.color === 'secondary' ? theme.palette.secondary.main : theme.palette.primary.main),
          borderLeft: "1px solid ".concat(props.color === 'secondary' ? theme.palette.secondary.light : theme.palette.primary.main),
          left: -9,
          top: 0,
          position: "absolute",
          width: "106%",
          height: ".5em"
        }
      }), (0, _jsxRuntime.jsx)(_material.Typography, {
        variant: 'body1',
        color: props.color === 'secondary' ? 'textSecondary' : 'primary',
        style: {
          textTransform: "uppercase",
          // color: theme.palette.text.secondary,
          fontWeight: "700",
          letterSpacing: 1.6
        },
        children: props.textContent
      })]
    })]
  });
};
var _default = exports.default = BulletedHeader;