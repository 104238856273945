"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _ColorPalette = require("../../../theme/common/ColorPalette");
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const WebDevTestimonialsSection = props => {
  var _sanityContext$urlFor, _props$sectionData$ba, _props$sectionData, _props$sectionData2, _props$sectionData$fe;
  const theme = (0, _material.useTheme)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    style: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      backgroundImage: "url('".concat((_sanityContext$urlFor = sanityContext.urlFor((_props$sectionData$ba = props.sectionData.backgroundImage) !== null && _props$sectionData$ba !== void 0 ? _props$sectionData$ba : "").url()) !== null && _sanityContext$urlFor !== void 0 ? _sanityContext$urlFor : "", "')"),
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundColor: "#1F1F1F"
    },
    justifyContent: 'center',
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      spacing: 3,
      xs: 11,
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        item: true,
        container: true,
        alignContent: 'flex-start',
        spacing: 1,
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          container: true,
          item: true,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'subtitle2',
              color: 'secondary',
              style: {
                color: _ColorPalette.COLORS.AQUA
              },
              children: (_props$sectionData = props.sectionData) === null || _props$sectionData === void 0 ? void 0 : _props$sectionData.preTitle
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            wrap: 'nowrap',
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: 'primary',
                variant: 'h2',
                align: 'center',
                display: 'inline',
                children: (_props$sectionData2 = props.sectionData) === null || _props$sectionData2 === void 0 ? void 0 : _props$sectionData2.title
              })
            })
          })]
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            color: 'primary',
            variant: 'body1',
            style: {
              fontFamily: "Raleway"
            },
            children: props.sectionData.introduction
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        justifyContent: 'center',
        children: (_props$sectionData$fe = props.sectionData.feedbackEntries) === null || _props$sectionData$fe === void 0 ? void 0 : _props$sectionData$fe.map((feedbackEntry, index2) => {
          var _sanityContext$urlFor2, _feedbackEntry$imageS;
          return (0, _jsxRuntime.jsxs)(_material.Grid, {
            item: true,
            container: true,
            alignContent: 'space-between',
            style: {
              border: "1px solid black",
              backgroundColor: "white",
              padding: theme.spacing(4),
              margin: theme.spacing(2)
            },
            md: 5,
            spacing: 2,
            children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
              item: true,
              container: true,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'textSecondary',
                  variant: 'h6',
                  gutterBottom: true,
                  children: feedbackEntry.quoteSummary
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                item: true,
                children: (0, _jsxRuntime.jsxs)(_material.Typography, {
                  color: 'textSecondary',
                  variant: 'body1',
                  gutterBottom: true,
                  children: ["\"", feedbackEntry.quote, "\""]
                })
              })]
            }), (0, _jsxRuntime.jsxs)(_material.Grid, {
              item: true,
              container: true,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                container: true,
                xs: 3,
                children: (0, _jsxRuntime.jsx)("img", {
                  src: (_sanityContext$urlFor2 = sanityContext.urlFor((_feedbackEntry$imageS = feedbackEntry.imageSrc) !== null && _feedbackEntry$imageS !== void 0 ? _feedbackEntry$imageS : "").url()) !== null && _sanityContext$urlFor2 !== void 0 ? _sanityContext$urlFor2 : "",
                  height: 50,
                  style: {
                    maxWidth: "100%"
                  }
                })
              }), (0, _jsxRuntime.jsxs)(_material.Grid, {
                item: true,
                container: true,
                xs: 9,
                children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                  container: true,
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    display: 'inline',
                    color: 'textSecondary',
                    variant: 'body2',
                    children: feedbackEntry.customerName
                  })
                }), (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  container: true,
                  style: {
                    color: _ColorPalette.COLORS.AQUA
                  },
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    display: 'inline',
                    color: 'inherit',
                    variant: 'subtitle2',
                    children: feedbackEntry.customerTitle
                  })
                }), (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  container: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    display: 'inline',
                    color: 'textSecondary',
                    variant: 'body1',
                    children: feedbackEntry.companyName
                  })
                })]
              })]
            })]
          }, index2);
        })
      })]
    })
  });
};
var _default = exports.default = WebDevTestimonialsSection;