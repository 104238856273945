"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _ColorPalette = require("../../../theme/common/ColorPalette");
var _WebDevHowItWorksStep = _interopRequireDefault(require("./WebDevHowItWorksStep"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  root: {
    padding: theme.spacing(4, 0, 8, 0),
    minHeight: 'max-content',
    backgroundColor: '#131313'
  },
  contentBottom: {
    border: "1px solid ".concat(theme.palette.secondary.main),
    padding: '20px'
  }
}));
const WebDevHowItWorksSection = props => {
  var _props$sectionData, _props$sectionData2, _props$sectionData3, _props$sectionData4;
  const classes = useStyles();
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    className: classes.root,
    xs: 12,
    justifyContent: 'center',
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      spacing: 2,
      xs: 11,
      children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'subtitle2',
            color: 'secondary',
            style: {
              color: _ColorPalette.COLORS.AQUA
            },
            children: (_props$sectionData = props.sectionData) === null || _props$sectionData === void 0 ? void 0 : _props$sectionData.contentPreTitle
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          container: true,
          wrap: 'nowrap',
          children: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'primary',
              variant: 'h2',
              align: 'center',
              style: {
                fontFamily: "Elaine Sans"
              },
              display: 'inline',
              children: (_props$sectionData2 = props.sectionData) === null || _props$sectionData2 === void 0 ? void 0 : _props$sectionData2.contentTitle
            })
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        children: (_props$sectionData3 = props.sectionData) === null || _props$sectionData3 === void 0 || (_props$sectionData3 = _props$sectionData3.contentTexts) === null || _props$sectionData3 === void 0 ? void 0 : _props$sectionData3.map((segment, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'body1',
            color: 'primary',
            gutterBottom: true,
            fontFamily: 'Raleway',
            children: segment
          })
        }, index))
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        children: (_props$sectionData4 = props.sectionData) === null || _props$sectionData4 === void 0 || (_props$sectionData4 = _props$sectionData4.steps) === null || _props$sectionData4 === void 0 ? void 0 : _props$sectionData4.map((step, index) => {
          return (0, _jsxRuntime.jsx)(_WebDevHowItWorksStep.default, {
            showAmenities: true,
            index: index,
            step: step
          }, index);
        })
      })]
    })
  });
};
var _default = exports.default = WebDevHowItWorksSection;