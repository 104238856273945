"use strict";

require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.parse-int.js");
var _styles = require("@mui/material/styles");
var _ColorPalette = require("../../theme/common/ColorPalette");
var _colors = require("@mui/material/colors");
var _textProcessingUtils = _interopRequireDefault(require("../../utils/textProcessingUtils"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const fonts = "\"Raleway\", \"Oswald\"";
const getThemeFromSanity = theme => {
  var _theme$breakpoints$xs, _theme$breakpoints, _theme$breakpoints$sm, _theme$breakpoints2, _theme$breakpoints$md, _theme$breakpoints3, _theme$breakpoints$lg, _theme$breakpoints4, _theme$breakpoints$xl, _theme$breakpoints5, _theme$borderRadius, _theme$colorPalette, _theme$colorPalette2, _theme$colorPalette3, _theme$colorPalette4, _theme$colorPalette5, _theme$colorPalette6, _theme$colorPalette7, _theme$colorPalette8, _theme$colorPalette9, _theme$colorPalette10, _theme$colorPalette11, _theme$colorPalette12, _theme$colorPalette13, _theme$colorPalette14, _theme$appBarHeight, _theme$typography2, _theme$typography3, _theme$typography4, _theme$typography5, _theme$typography6, _theme$typography7, _theme$typography8, _theme$colorPalette15;
  const createMuiFontFace = sanityFontFace => {
    var _sanityFontFace$media, _sanityFontFace$media2;
    let processedMediaQuery = [];
    if (((_sanityFontFace$media = (_sanityFontFace$media2 = sanityFontFace.mediaQueries) === null || _sanityFontFace$media2 === void 0 ? void 0 : _sanityFontFace$media2.length) !== null && _sanityFontFace$media !== void 0 ? _sanityFontFace$media : 0) > 0) {
      var _sanityFontFace$media3;
      processedMediaQuery = (_sanityFontFace$media3 = sanityFontFace.mediaQueries) === null || _sanityFontFace$media3 === void 0 ? void 0 : _sanityFontFace$media3.map(mediaQuery => {
        const query = {};
        // @ts-ignore
        query["@media (max-width:".concat(theme.breakpoints[mediaQuery.breakpoint[0]], "px)")] = mediaQuery.typography;
        return query;
      });
    }
    return _objectSpread(_objectSpread({
      fontSize: sanityFontFace.fontSize
    }, processedMediaQuery ? processedMediaQuery[0] : []), {}, {
      fontStyle: sanityFontFace.fontStyle,
      fontWeight: sanityFontFace.fontWeight,
      lineHeight: sanityFontFace.lineHeight,
      letterSpacing: sanityFontFace.letterSpacing,
      textTransform: sanityFontFace.textTransform
    });
  };
  const extractSanityFontFace = fontFaceName => {
    var _theme$typography;
    const fontFaceFromSanity = (_theme$typography = theme.typography) === null || _theme$typography === void 0 || (_theme$typography = _theme$typography.fontFaces) === null || _theme$typography === void 0 ? void 0 : _theme$typography.find(fontFace => {
      if (fontFace.name === fontFaceName) {
        return fontFace;
      }
      return undefined;
    });
    return fontFaceFromSanity ? createMuiFontFace(fontFaceFromSanity) : undefined;
  };
  return (0, _styles.createTheme)({
    breakpoints: {
      values: {
        xs: parseInt(String((_theme$breakpoints$xs = (_theme$breakpoints = theme.breakpoints) === null || _theme$breakpoints === void 0 ? void 0 : _theme$breakpoints.xs) !== null && _theme$breakpoints$xs !== void 0 ? _theme$breakpoints$xs : 0)),
        sm: parseInt(String((_theme$breakpoints$sm = (_theme$breakpoints2 = theme.breakpoints) === null || _theme$breakpoints2 === void 0 ? void 0 : _theme$breakpoints2.sm) !== null && _theme$breakpoints$sm !== void 0 ? _theme$breakpoints$sm : 640)),
        md: parseInt(String((_theme$breakpoints$md = (_theme$breakpoints3 = theme.breakpoints) === null || _theme$breakpoints3 === void 0 ? void 0 : _theme$breakpoints3.md) !== null && _theme$breakpoints$md !== void 0 ? _theme$breakpoints$md : 980)),
        lg: parseInt(String((_theme$breakpoints$lg = (_theme$breakpoints4 = theme.breakpoints) === null || _theme$breakpoints4 === void 0 ? void 0 : _theme$breakpoints4.lg) !== null && _theme$breakpoints$lg !== void 0 ? _theme$breakpoints$lg : 1160)),
        xl: parseInt(String((_theme$breakpoints$xl = (_theme$breakpoints5 = theme.breakpoints) === null || _theme$breakpoints5 === void 0 ? void 0 : _theme$breakpoints5.xl) !== null && _theme$breakpoints$xl !== void 0 ? _theme$breakpoints$xl : 1320))
      }
    },
    shape: {
      borderRadius: parseInt(String((_theme$borderRadius = theme.borderRadius) !== null && _theme$borderRadius !== void 0 ? _theme$borderRadius : "4"))
    },
    palette: {
      background: {
        default: (_theme$colorPalette = theme.colorPalette) !== null && _theme$colorPalette !== void 0 && _theme$colorPalette.defaultBackground ? (0, _ColorPalette.convertToHexCode)((_theme$colorPalette2 = theme.colorPalette) === null || _theme$colorPalette2 === void 0 ? void 0 : _theme$colorPalette2.defaultBackground) : _ColorPalette.COLORS.WHITESMOKE,
        paper: (_theme$colorPalette3 = theme.colorPalette) !== null && _theme$colorPalette3 !== void 0 && _theme$colorPalette3.defaultPaperBackgroundColor ? (0, _ColorPalette.convertToHexCode)((_theme$colorPalette4 = theme.colorPalette) === null || _theme$colorPalette4 === void 0 ? void 0 : _theme$colorPalette4.defaultPaperBackgroundColor) : _ColorPalette.COLORS.DARKGRAY
      },
      primary: {
        main: (_theme$colorPalette5 = theme.colorPalette) !== null && _theme$colorPalette5 !== void 0 && _theme$colorPalette5.primaryColor ? (0, _ColorPalette.convertToHexCode)((_theme$colorPalette6 = theme.colorPalette) === null || _theme$colorPalette6 === void 0 ? void 0 : _theme$colorPalette6.primaryColor) : _ColorPalette.COLORS.RED
      },
      secondary: {
        main: (_theme$colorPalette7 = theme.colorPalette) !== null && _theme$colorPalette7 !== void 0 && _theme$colorPalette7.secondaryColor ? (0, _ColorPalette.convertToHexCode)((_theme$colorPalette8 = theme.colorPalette) === null || _theme$colorPalette8 === void 0 ? void 0 : _theme$colorPalette8.secondaryColor) : _ColorPalette.COLORS.ALMOSTWHITE
      },
      error: {
        main: '#840E0E',
        light: '#D79393',
        dark: '#640E0E'
      },
      success: {
        main: '#27AE60',
        light: '#93D7B0',
        dark: '#0E8433'
      },
      warning: {
        main: '#E2AB1F',
        light: '#F1D58F',
        dark: '#CF800A'
      },
      text: {
        primary: (_theme$colorPalette9 = theme.colorPalette) !== null && _theme$colorPalette9 !== void 0 && _theme$colorPalette9.primaryTextColor ? (0, _ColorPalette.convertToHexCode)((_theme$colorPalette10 = theme.colorPalette) === null || _theme$colorPalette10 === void 0 ? void 0 : _theme$colorPalette10.primaryTextColor) : _ColorPalette.COLORS.DARKGRAY,
        secondary: (_theme$colorPalette11 = theme.colorPalette) !== null && _theme$colorPalette11 !== void 0 && _theme$colorPalette11.secondaryTextColor ? (0, _ColorPalette.convertToHexCode)((_theme$colorPalette12 = theme.colorPalette) === null || _theme$colorPalette12 === void 0 ? void 0 : _theme$colorPalette12.secondaryTextColor) : _colors.grey[100],
        disabled: (_theme$colorPalette13 = theme.colorPalette) !== null && _theme$colorPalette13 !== void 0 && _theme$colorPalette13.disabledTextColor ? (0, _ColorPalette.convertToHexCode)((_theme$colorPalette14 = theme.colorPalette) === null || _theme$colorPalette14 === void 0 ? void 0 : _theme$colorPalette14.disabledTextColor) : _ColorPalette.COLORS.LIGHT_GRAY
      }
    },
    mixins: {
      toolbar: {
        height: ((_theme$appBarHeight = theme.appBarHeight) !== null && _theme$appBarHeight !== void 0 ? _theme$appBarHeight : 55) + "px"
      }
    },
    typography: {
      fontFamily: (_theme$typography2 = theme.typography) !== null && _theme$typography2 !== void 0 && _theme$typography2.fontFamily ? _textProcessingUtils.default.capitalizeArray(theme.typography.fontFamily).join(',') : fonts,
      h1: _objectSpread(_objectSpread({}, extractSanityFontFace('h1')), {}, {
        fontFamily: (_theme$typography3 = theme.typography) === null || _theme$typography3 === void 0 ? void 0 : _theme$typography3.fontFamily[1]
      }),
      h2: _objectSpread(_objectSpread({}, extractSanityFontFace('h2')), {}, {
        fontFamily: (_theme$typography4 = theme.typography) === null || _theme$typography4 === void 0 ? void 0 : _theme$typography4.fontFamily[1]
      }),
      h3: _objectSpread(_objectSpread({}, extractSanityFontFace('h3')), {}, {
        fontFamily: (_theme$typography5 = theme.typography) === null || _theme$typography5 === void 0 ? void 0 : _theme$typography5.fontFamily[1]
      }),
      h4: _objectSpread(_objectSpread({}, extractSanityFontFace('h4')), {}, {
        fontFamily: (_theme$typography6 = theme.typography) === null || _theme$typography6 === void 0 ? void 0 : _theme$typography6.fontFamily[1]
      }),
      h5: _objectSpread(_objectSpread({}, extractSanityFontFace('h5')), {}, {
        fontFamily: (_theme$typography7 = theme.typography) === null || _theme$typography7 === void 0 ? void 0 : _theme$typography7.fontFamily[1]
      }),
      h6: _objectSpread(_objectSpread({}, extractSanityFontFace('h6')), {}, {
        fontFamily: (_theme$typography8 = theme.typography) === null || _theme$typography8 === void 0 ? void 0 : _theme$typography8.fontFamily[1]
      }),
      body1: extractSanityFontFace('body1'),
      body2: extractSanityFontFace('body2'),
      button: extractSanityFontFace('button'),
      subtitle1: extractSanityFontFace('subtitle1'),
      subtitle2: extractSanityFontFace('subtitle2')
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            "& .Mui-focus": {
              borderBottom: 0
            }
          }
        }
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            marginTop: "100px",
            border: "3px solid white",
            backgroundColor: 'rgba(210,0,39,0.9) !important'
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            // color: "#383838 !important",
            // paddingTop: "4px !important",
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '-64px',
            paddingTop: "16px",
            paddingBottom: "16px"
          },
          contained: {
            boxShadow: "none"
          },
          containedPrimary: {
            border: "1px solid ".concat((_theme$colorPalette15 = theme.colorPalette) !== null && _theme$colorPalette15 !== void 0 && _theme$colorPalette15.buttonOutlineColor ? (0, _ColorPalette.convertToHexCode)(theme.colorPalette.buttonOutlineColor) : "transparent"),
            '&.Mui-disabled': {
              color: '#969284'
            }
          },
          containedSecondary: {
            border: '1px solid transparent',
            '&.Mui-disabled': {
              color: 'rgba(207, 207, 207, .5)'
            }
          },
          outlinedPrimary: {
            borderWidth: '3px',
            paddingTop: "16px",
            paddingBottom: "16px",
            paddingLeft: "64px",
            paddingRight: "64px"
          },
          outlinedSecondary: {
            borderWidth: '3px',
            paddingTop: "16px",
            paddingBottom: "16px",
            paddingLeft: "64px",
            paddingRight: "64px"
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            paddingRight: "16px",
            borderColor: 'black'
            // border: "1px solid transparent"
          }
        }
      }
    }
  });
};
var _default = exports.default = getThemeFromSanity;