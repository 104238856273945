"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RegionTitleEnum = exports.RegionEnum = void 0;
var RegionEnum;
(function (RegionEnum) {
  RegionEnum["EAST_COAST"] = "EAST_COAST";
  RegionEnum["WEST_COAST"] = "WEST_COAST";
  RegionEnum["THE_SOUTH"] = "THE_SOUTH";
  RegionEnum["THE_MIDWEST"] = "THE_MIDWEST";
  RegionEnum["CANADA"] = "CANADA";
  RegionEnum["ABROAD"] = "ABROAD";
  RegionEnum["NONE"] = "";
})(RegionEnum || (exports.RegionEnum = RegionEnum = {}));
var RegionTitleEnum;
(function (RegionTitleEnum) {
  RegionTitleEnum["EAST_COAST"] = "East Coast, US";
  RegionTitleEnum["WEST_COAST"] = "West Coast, US";
  RegionTitleEnum["THE_SOUTH"] = "The South, US";
  RegionTitleEnum["THE_MIDWEST"] = "The Midwest, US";
  RegionTitleEnum["CANADA"] = "Canada";
  RegionTitleEnum["ABROAD"] = "Abroad";
  RegionTitleEnum["NONE"] = "";
})(RegionTitleEnum || (exports.RegionTitleEnum = RegionTitleEnum = {}));