"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.promise.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _ThwCommonStyles = _interopRequireDefault(require("../../../../common/sanityIo/ThwCommonStyles"));
var _SocialMediaBlock = _interopRequireDefault(require("../social-media-block/SocialMediaBlock"));
var _BusinessCardSubmitEmail = _interopRequireDefault(require("../../transform-hw/pages/BusinessCardSubmitEmail"));
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
var _pdfClient = _interopRequireDefault(require("../../../../utils/pdfClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const ResumeBioSection = props => {
  var _props$homePage, _props$homePage2, _props$homePage3, _props$homePage4, _props$homePage5, _props$homePage6, _props$homePage7, _props$homePage8;
  const theme = (0, _material.useTheme)();
  const classes = (0, _ThwCommonStyles.default)();
  const smDown = (0, _material.useMediaQuery)(theme.breakpoints.down('sm'));
  const mdOnly = (0, _material.useMediaQuery)(theme.breakpoints.only('md'));
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const [aLinkUrl, setALinkUrl] = (0, _react.useState)(undefined);
  (0, _react.useEffect)(() => {
    _pdfClient.default.getPDFLink().then(async theLink => {
      setALinkUrl(theLink);
    });
  }, []);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    style: {
      padding: theme.spacing(4, smDown ? 1 : 4)
    },
    justifyContent: 'center',
    className: classes.resumeSection,
    spacing: 3,
    children: [!props.isHideEmail && (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      xs: 12,
      style: {
        paddingTop: "64px"
      },
      children: (0, _jsxRuntime.jsx)(_BusinessCardSubmitEmail.default, {
        source: "Bio Section",
        emailFieldText: 'Email Address',
        emailButtonText: 'Submit',
        subscribeText: 'Want a copy of my resume emailed to you?'
      })
    }), (0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      sm: 12,
      md: 7,
      lg: 12,
      justifyContent: 'center',
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        children: (0, _jsxRuntime.jsxs)(_material.Typography, {
          component: 'div',
          display: 'inline',
          variant: 'h5',
          gutterBottom: true,
          children: [props.sectionData.title, (0, _jsxRuntime.jsx)(_material.Typography, {
            variant: 'h5',
            color: 'primary',
            display: 'inline',
            children: "."
          })]
        })
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        children: (0, _jsxRuntime.jsx)(_material.Typography, {
          gutterBottom: true,
          variant: 'body1',
          children: props.sectionData.introduction
        })
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        xs: 11,
        sm: 12,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 3,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            gutterBottom: true,
            variant: 'body1',
            style: {
              textTransform: "uppercase"
            },
            children: "Phone"
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 9,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            gutterBottom: true,
            variant: 'body1',
            children: (_props$homePage = props.homePage) === null || _props$homePage === void 0 || (_props$homePage = _props$homePage.businessContact) === null || _props$homePage === void 0 ? void 0 : _props$homePage.phone
          })
        })]
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        xs: 11,
        sm: 12,
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 3,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            gutterBottom: true,
            variant: 'body1',
            style: {
              textTransform: "uppercase"
            },
            children: "Email"
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          item: true,
          xs: 9,
          children: (0, _jsxRuntime.jsx)(_material.Typography, {
            gutterBottom: true,
            variant: 'body1',
            children: (_props$homePage2 = props.homePage) === null || _props$homePage2 === void 0 || (_props$homePage2 = _props$homePage2.businessContact) === null || _props$homePage2 === void 0 ? void 0 : _props$homePage2.email
          })
        })]
      }), (0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        xs: 11,
        sm: 12,
        justifyContent: 'center',
        children: (0, _jsxRuntime.jsx)(_SocialMediaBlock.default, {
          isCentered: true,
          facebook: (_props$homePage3 = props.homePage) === null || _props$homePage3 === void 0 || (_props$homePage3 = _props$homePage3.businessContact) === null || _props$homePage3 === void 0 ? void 0 : _props$homePage3.facebook,
          twitter: (_props$homePage4 = props.homePage) === null || _props$homePage4 === void 0 || (_props$homePage4 = _props$homePage4.businessContact) === null || _props$homePage4 === void 0 ? void 0 : _props$homePage4.twitter,
          instagram: (_props$homePage5 = props.homePage) === null || _props$homePage5 === void 0 || (_props$homePage5 = _props$homePage5.businessContact) === null || _props$homePage5 === void 0 ? void 0 : _props$homePage5.instagram,
          linkedIn: (_props$homePage6 = props.homePage) === null || _props$homePage6 === void 0 || (_props$homePage6 = _props$homePage6.businessContact) === null || _props$homePage6 === void 0 ? void 0 : _props$homePage6.linkedIn,
          github: (_props$homePage7 = props.homePage) === null || _props$homePage7 === void 0 || (_props$homePage7 = _props$homePage7.businessContact) === null || _props$homePage7 === void 0 ? void 0 : _props$homePage7.github
        })
      })]
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      container: true,
      sm: 12,
      md: 5,
      justifyContent: 'center',
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        "data-testid": 'bio-image',
        container: true,
        item: true,
        style: {
          backgroundImage: "url(".concat(sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.sectionData.mainImage, 350, 500), ")"),
          backgroundSize: "cover",
          backgroundPosition: "top right",
          backgroundRepeat: "no-repeat",
          minHeight: "500px",
          maxWidth: "350px"
        }
      })
    }), !props.isHideButtons && (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      xs: 12,
      sm: 10,
      md: 12,
      lg: 6,
      spacing: 1,
      style: {
        marginTop: theme.spacing(2)
      },
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        alignItems: 'center',
        children: (0, _jsxRuntime.jsxs)(_material.ButtonGroup, {
          fullWidth: true,
          orientation: smDown || !mdOnly ? 'vertical' : "horizontal",
          children: [(0, _jsxRuntime.jsx)(_material.Button, {
            name: 'appointment',
            variant: 'contained',
            fullWidth: true,
            color: 'primary',
            href: (_props$homePage8 = props.homePage) === null || _props$homePage8 === void 0 ? void 0 : _props$homePage8.bookAppointmentLink,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: "button",
              align: 'center',
              children: "Meet with Me"
            })
          }), (0, _jsxRuntime.jsx)(_material.Button, {
            name: 'contact-me',
            variant: 'contained',
            fullWidth: true,
            color: 'primary',
            href: '#CONTACT_ME',
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: "button",
              align: 'center',
              children: props.sectionData.contactMeButtonTitle
            })
          }), aLinkUrl ? (0, _jsxRuntime.jsx)(_material.Button, {
            href: aLinkUrl,
            fullWidth: true,
            color: 'primary',
            variant: 'contained',
            children: "Download PDF"
          }) : (0, _jsxRuntime.jsx)(_material.Button, {
            disabled: true,
            variant: "contained",
            color: 'primary',
            children: (0, _jsxRuntime.jsxs)(_material.Grid, {
              spacing: .5,
              container: true,
              item: true,
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.CircularProgress, {
                  size: 14,
                  color: 'primary'
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'button',
                  color: 'primary',
                  children: "Loading PDF..."
                })
              })]
            })
          })]
        })
      })
    })]
  });
};
var _default = exports.default = ResumeBioSection;