"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
require("core-js/modules/es.promise.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _withStyles = _interopRequireDefault(require("@mui/styles/withStyles"));
var _iconsMaterial = require("@mui/icons-material");
var _clsx = _interopRequireDefault(require("clsx"));
var _Styles = _interopRequireDefault(require("../mackenzies-mind/pages/Styles"));
var _isEmail = _interopRequireDefault(require("validator/lib/isEmail"));
var _LoadingButton = _interopRequireDefault(require("../../loading-button/LoadingButton"));
var _reactQuery = require("@tanstack/react-query");
var _leadClient = _interopRequireDefault(require("./pages/under-construction-page/leadClient"));
var _reactParallax = require("react-parallax");
var _PageContext = _interopRequireDefault(require("../../page-context/PageContext"));
var _TransformHWTheme = _interopRequireDefault(require("../../../theme/TransformHWTheme"));
var _CustomizedThemeContext = _interopRequireDefault(require("../../customized-theme-provider/CustomizedThemeContext"));
var _FirebaseContext = _interopRequireDefault(require("../../../common/firebase/firebase-context/FirebaseContext"));
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  root: {
    width: '100vw',
    // minHeight: '100vh',
    // backgroundColor: '#1f1f1f',
    color: "#FAFAFA"
  },
  header: {
    fontWeight: 800,
    letterSpacing: '10px',
    lineHeight: 1.4,
    fontSize: '30px',
    textTransform: 'uppercase'
  },
  headerAccent: {
    display: 'inline-block',
    marginLeft: "8px"
  },
  formContainer: {
    // margin: 'auto',
    // height: '500px',
    // backgroundColor: '#313131',
    // boxShadow: '11px 10px 38px rgb(0 0 0 / 38%)',
    zIndex: 2
  },
  inputAdornmentContainer: {
    marginTop: "8px",
    zIndex: 3
  },
  inputAdornmentTextBlockContainer: {
    position: "relative",
    top: -34,
    zIndex: 3
  },
  formTitle: {
    marginBottom: "8px"
  },
  socialMediaContainer: {
    marginTop: "8px"
  },
  lhsContainer: {
    // width: "500px",
    // height: "650px"
  },
  formInput: {
    color: "white"
  },
  sectionTitle: {
    fontWeight: 800,
    color: "white !important"
  }
}));
const StyledTextField = (0, _withStyles.default)({
  root: {
    transition: "all 0.3s ease-in-out",
    "& label": {
      // display: "inline-block",
      // fontSize: "16px",
      // fontWeight: 700,
      position: "relative",
      top: "8px",
      left: "-14px"
    },
    "& legend": {
      maxWidth: "0px"
    },
    "& input": {
      zIndex: 2
    },
    "& textarea": {
      zIndex: 2
    },
    "& fieldset": {
      backgroundColor: "#292929"
    },
    "& .MuiOutlinedInput-root": {
      borderColor: "".concat(_TransformHWTheme.default.palette.primary.main, " !important"),
      "&.Mui-focused": {
        borderColor: "".concat(_TransformHWTheme.default.palette.primary.main, " !important"),
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "".concat(_TransformHWTheme.default.palette.primary.main, " !important")
          }
        }
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#212121 !important"
        }
      }
    }
  }
})(_material.TextField);
const ContactUs = props => {
  var _props$sectionData, _props$sectionData2, _props$sectionData3, _props$sectionData4;
  const classes = useStyles(_TransformHWTheme.default);
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const globalClasses = (0, _Styles.default)({});
  const firebaseContext = (0, _react.useContext)(_FirebaseContext.default);
  const [leadName, setleadName] = (0, _react.useState)();
  const [email, setEmail] = (0, _react.useState)();
  const [leadPhone, setLeadPhone] = (0, _react.useState)();
  const [leadMessage, setLeadMessage] = (0, _react.useState)();
  const [alignment, setAlignment] = (0, _react.useState)('right');
  const [justifyContent, setJustifyContent] = (0, _react.useState)('flex-end');
  const customizedThemeContext = (0, _react.useContext)(_CustomizedThemeContext.default);
  const smDown = (0, _material.useMediaQuery)(customizedThemeContext.customizedTheme.breakpoints.down('sm'));
  (0, _react.useEffect)(() => {
    if (smDown) {
      setAlignment('center');
      setJustifyContent('center');
    } else {
      setAlignment('right');
      setJustifyContent('flex-end');
    }
  }, [smDown]);
  const {
    isLoading,
    isError,
    data,
    refetch,
    isRefetching
  } = (0, _reactQuery.useQuery)({
    queryKey: ['submitContactUsForm'],
    queryFn: () => {
      if (email && email.length > 0 && !data && !isError) {
        return _leadClient.default.createLead({
          email,
          leadName,
          leadMessage,
          leadPhone,
          source: "Contact Us"
        }).then(response => {
          return response.response;
        });
      }
      return undefined;
    }
  });
  const getHelperText = () => {
    if (data) {
      return (0, _jsxRuntime.jsx)(_material.Typography, {
        style: {
          color: _TransformHWTheme.default.palette.success.main
        },
        variant: 'subtitle1',
        children: "Thank you for your submission!"
      });
    }
    if (isError) {
      return (0, _jsxRuntime.jsx)(_material.Typography, {
        style: {
          color: _TransformHWTheme.default.palette.error.main
        },
        variant: 'subtitle1',
        children: "Please Try your submission again later or contact jgreene@transformHW.org."
      });
    }
    return (0, _jsxRuntime.jsx)(_material.Typography, {
      variant: 'subtitle1',
      children: "\u00A0"
    });
  };
  const pageContext = (0, _react.useContext)(_PageContext.default);
  const createLead = async () => {
    firebaseContext.ctaClick && firebaseContext.ctaClick('contact-us', 'send-message', pageContext.analyticsId);
    return refetch();
  };
  return (0, _jsxRuntime.jsx)(_reactParallax.Parallax, {
    blur: 1,
    bgImage: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.sectionData.bgImageSrc, 1000, 500),
    bgImageAlt: "the cat",
    strength: 600,
    children: (0, _jsxRuntime.jsxs)(_material.Grid, {
      container: true,
      item: true,
      className: classes.root,
      style: {
        position: "relative",
        minHeight: "145px"
      },
      children: [(0, _jsxRuntime.jsx)(_material.Grid, {
        container: true,
        item: true,
        className: (0, _clsx.default)(globalClasses.fullSectionOverlay)
      }), (0, _jsxRuntime.jsxs)(_material.Grid, {
        spacing: smDown ? 0 : 4,
        container: true,
        item: true,
        style: {
          padding: _TransformHWTheme.default.spacing(0, smDown ? 2 : 8, 6)
        },
        justifyContent: "center",
        children: [(0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          md: 6,
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            direction: "column",
            item: true,
            className: classes.lhsContainer,
            justifyContent: 'center',
            style: {
              zIndex: 2,
              paddingTop: "64px"
            },
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: justifyContent,
              children: (0, _jsxRuntime.jsxs)(_material.Typography, {
                variant: "h2",
                align: alignment,
                children: [" ", props.sectionData.lhsTitle]
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: justifyContent,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                gutterBottom: true,
                variant: 'h4',
                display: 'inline',
                color: 'secondary',
                style: {
                  letterSpacing: "-.25em",
                  paddingBottom: "16px",
                  lineHeight: .2
                },
                children: "________"
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              justifyContent: justifyContent,
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                xs: 8,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  style: {
                    wordWrap: "break-word"
                  },
                  align: alignment,
                  children: props.sectionData.lhsContentText
                })
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              children: (0, _jsxRuntime.jsxs)(_material.Grid, {
                container: true,
                item: true,
                justifyContent: justifyContent,
                className: classes.socialMediaContainer,
                spacing: 1,
                children: [((_props$sectionData = props.sectionData) === null || _props$sectionData === void 0 ? void 0 : _props$sectionData.facebook) && (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.IconButton, {
                    size: "large",
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      children: (0, _jsxRuntime.jsx)(_material.Link, {
                        href: "http://facebook.com/" + props.sectionData.facebook,
                        underline: "hover",
                        children: (0, _jsxRuntime.jsx)(_iconsMaterial.Facebook, {
                          fontSize: "large"
                        })
                      })
                    })
                  })
                }), ((_props$sectionData2 = props.sectionData) === null || _props$sectionData2 === void 0 ? void 0 : _props$sectionData2.twitter) && (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.IconButton, {
                    size: "large",
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      children: (0, _jsxRuntime.jsx)(_material.Link, {
                        href: "http://twitter.com/" + props.sectionData.twitter,
                        underline: "hover",
                        children: (0, _jsxRuntime.jsx)(_iconsMaterial.Twitter, {
                          fontSize: "large"
                        })
                      })
                    })
                  })
                }), ((_props$sectionData3 = props.sectionData) === null || _props$sectionData3 === void 0 ? void 0 : _props$sectionData3.linkedIn) && (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    children: (0, _jsxRuntime.jsx)(_material.Link, {
                      href: "http://linkedIn.com/" + props.sectionData.linkedIn,
                      underline: "hover",
                      children: (0, _jsxRuntime.jsx)(_iconsMaterial.LinkedIn, {
                        fontSize: "large"
                      })
                    })
                  })
                }), ((_props$sectionData4 = props.sectionData) === null || _props$sectionData4 === void 0 ? void 0 : _props$sectionData4.youtube) && (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    children: (0, _jsxRuntime.jsx)(_material.Link, {
                      href: "http://youtube.com/" + props.sectionData.youtube,
                      underline: "hover",
                      children: (0, _jsxRuntime.jsx)(_iconsMaterial.YouTube, {
                        fontSize: "large"
                      })
                    })
                  })
                })]
              })
            })]
          })
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          xs: 12,
          sm: 11,
          md: 6,
          justifyContent: "center",
          children: (0, _jsxRuntime.jsxs)(_material.Grid, {
            container: true,
            item: true,
            className: classes.formContainer,
            spacing: 1,
            children: [(0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              style: {
                marginTop: _TransformHWTheme.default.spacing(8)
              },
              children: (0, _jsxRuntime.jsx)(StyledTextField, {
                fullWidth: true,
                id: "contact-name-input",
                value: leadName,
                onChange: e => {
                  setleadName(e.target.value);
                },
                label: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'body2',
                  style: {
                    color: "white"
                  },
                  children: "Name"
                }),
                variant: "outlined",
                InputProps: {
                  startAdornment: (0, _jsxRuntime.jsx)(_material.InputAdornment, {
                    position: "start",
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      className: classes.inputAdornmentContainer,
                      children: (0, _jsxRuntime.jsx)(_iconsMaterial.AccountCircle, {})
                    })
                  }),
                  className: classes.formInput
                }
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              children: (0, _jsxRuntime.jsx)(StyledTextField, {
                fullWidth: true,
                value: email,
                onChange: e => {
                  setEmail(e.target.value);
                },
                id: "contact-email-input",
                label: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'body2',
                  style: {
                    color: "white"
                  },
                  children: "Email"
                }),
                variant: "outlined",
                InputProps: {
                  startAdornment: (0, _jsxRuntime.jsx)(_material.InputAdornment, {
                    position: "start",
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      className: classes.inputAdornmentContainer,
                      children: (0, _jsxRuntime.jsx)(_iconsMaterial.Email, {})
                    })
                  }),
                  className: classes.formInput
                }
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              children: (0, _jsxRuntime.jsx)(StyledTextField, {
                fullWidth: true,
                value: leadPhone,
                onChange: e => {
                  setLeadPhone(e.target.value);
                },
                id: "contact-phone-input",
                label: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'body2',
                  style: {
                    color: "white"
                  },
                  children: "Phone"
                }),
                variant: "outlined",
                InputProps: {
                  startAdornment: (0, _jsxRuntime.jsx)(_material.InputAdornment, {
                    position: "start",
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      className: classes.inputAdornmentContainer,
                      children: (0, _jsxRuntime.jsx)(_iconsMaterial.Phone, {})
                    })
                  }),
                  className: classes.formInput
                }
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              children: (0, _jsxRuntime.jsx)(StyledTextField, {
                fullWidth: true,
                id: "contact-message-input",
                value: leadMessage,
                onChange: e => {
                  setLeadMessage(e.target.value);
                },
                label: (0, _jsxRuntime.jsx)(_material.Typography, {
                  variant: 'body2',
                  style: {
                    color: "white"
                  },
                  children: "Message"
                }),
                variant: "outlined",
                multiline: true,
                minRows: "4",
                InputProps: {
                  startAdornment: (0, _jsxRuntime.jsx)(_material.InputAdornment, {
                    position: "start",
                    children: (0, _jsxRuntime.jsx)(_material.Typography, {
                      className: classes.inputAdornmentTextBlockContainer,
                      children: (0, _jsxRuntime.jsx)(_iconsMaterial.Message, {})
                    })
                  }),
                  className: classes.formInput
                }
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              container: true,
              item: true,
              alignItems: "center",
              justifyContent: "center",
              style: {
                marginTop: _TransformHWTheme.default.spacing(4)
              },
              children: (0, _jsxRuntime.jsx)(_LoadingButton.default, {
                width: 200,
                isLoading: isLoading || isRefetching,
                disabled: !!(data || isError || email && email.length > 0 && !(0, _isEmail.default)(email)),
                clickHandler: createLead,
                color: "secondary",
                variant: "contained",
                children: "Send Message"
              })
            }), (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              container: true,
              justifyContent: 'center',
              children: getHelperText()
            })]
          })
        })]
      })]
    })
  });
};
var _default = exports.default = ContactUs;