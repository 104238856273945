"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryClient = void 0;
var _reactQuery = require("@tanstack/react-query");
const queryClient = exports.queryClient = new _reactQuery.QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnWindowFocus: false,
      // refetchOnMount: false,
      // refetchOnReconnect: false,
    }
  }
});