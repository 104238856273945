"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _material = require("@mui/material");
var _SanityContext = _interopRequireDefault(require("../../../../common/sanityIo/sanity-context/SanityContext"));
var _CssFadeToColor = _interopRequireDefault(require("../../../css-fade-to-color/CssFadeToColor"));
var _CssFadeToColorDirectionEnum = require("../../../css-fade-to-color/CssFadeToColorDirectionEnum");
var _iconsMaterial = require("@mui/icons-material");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(() => ({
  contactIcons: {
    paddingLeft: "16px",
    color: "gray"
  }
}));
const BartenderHeroSection = props => {
  var _props$sectionData$ba, _props$sectionData$ba2, _props$sectionData$ba3;
  const classes = useStyles();
  const theme = (0, _material.useTheme)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const mdDown = (0, _material.useMediaQuery)(theme.breakpoints.down('sm'));
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    sx: {
      backgroundColor: "black",
      position: "relative",
      minWidth: "350px"
    },
    children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
      item: true,
      style: {
        position: "relative",
        height: "500px"
      },
      children: [(0, _jsxRuntime.jsx)(_CssFadeToColor.default, {
        toColor: 'black',
        direction: _CssFadeToColorDirectionEnum.CssFadeToColorDirectionEnum.RIGHT
      }), (0, _jsxRuntime.jsx)("img", {
        height: 500,
        src: sanityContext.placeholderOrImage && sanityContext.placeholderOrImage(props.sectionData.imageSrc, 300, 500)
      })]
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      container: true,
      item: true,
      sx: {
        height: "100%"
      },
      children: (0, _jsxRuntime.jsx)(_material.Grid, {
        item: true,
        container: true,
        justifyItems: 'center',
        justifyContent: 'center',
        alignItems: mdDown ? 'flex-end' : 'center',
        alignContent: mdDown ? 'flex-end' : 'center',
        sx: {
          position: "absolute",
          right: mdDown ? 16 : 64,
          // backgroundColor:"red",
          paddingBottom: "8px",
          top: 0,
          // bottom: 0,
          width: "350px",
          height: "100%"
        },
        children: (0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          style: {
            border: "3px solid white",
            backgroundColor: "rgba(0,0,0,.35)",
            position: "relative"
          },
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            style: {
              fontSize: "125px",
              opacity: .55
            },
            children: (0, _jsxRuntime.jsx)(_iconsMaterial.LocalBar, {
              fontSize: 'inherit',
              style: {
                position: "absolute",
                bottom: 0,
                right: 0
              }
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              color: 'white',
              variant: 'h3',
              style: {
                padding: "8px"
              },
              children: props.sectionData.name
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              style: {
                border: "3px solid white",
                padding: theme.spacing(1, 3),
                position: "relative",
                left: "-3px"
              },
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: 'white',
                variant: 'h6',
                children: props.sectionData.careerTitle
              })
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              style: {
                padding: "16px"
              },
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                color: 'white',
                variant: 'caption',
                children: props.sectionData.textContent
              })
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            className: classes.contactIcons,
            children: (0, _jsxRuntime.jsxs)(_material.Grid, {
              item: true,
              alignItems: 'center',
              container: true,
              spacing: 1,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_iconsMaterial.Email, {
                  color: 'inherit'
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'white',
                  variant: 'h6',
                  gutterBottom: true,
                  children: (_props$sectionData$ba = props.sectionData.bartender) === null || _props$sectionData$ba === void 0 ? void 0 : _props$sectionData$ba.email
                })
              })]
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            className: classes.contactIcons,
            children: (0, _jsxRuntime.jsxs)(_material.Grid, {
              item: true,
              alignItems: 'center',
              container: true,
              spacing: 1,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_iconsMaterial.Phone, {
                  color: 'inherit'
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'white',
                  variant: 'h6',
                  gutterBottom: true,
                  children: (_props$sectionData$ba2 = props.sectionData.bartender) === null || _props$sectionData$ba2 === void 0 ? void 0 : _props$sectionData$ba2.phone
                })
              })]
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            className: classes.contactIcons,
            children: (0, _jsxRuntime.jsxs)(_material.Grid, {
              item: true,
              alignItems: 'center',
              container: true,
              spacing: 1,
              children: [(0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_iconsMaterial.LocationOn, {
                  color: 'inherit'
                })
              }), (0, _jsxRuntime.jsx)(_material.Grid, {
                item: true,
                children: (0, _jsxRuntime.jsx)(_material.Typography, {
                  color: 'white',
                  variant: 'h6',
                  gutterBottom: true,
                  children: (_props$sectionData$ba3 = props.sectionData.bartender) === null || _props$sectionData$ba3 === void 0 ? void 0 : _props$sectionData$ba3.address
                })
              })]
            })
          })]
        })
      })
    })]
  });
};
var _default = exports.default = BartenderHeroSection;