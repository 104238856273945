"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _makeStyles = _interopRequireDefault(require("@mui/styles/makeStyles"));
var _material = require("@mui/material");
var _WebDevSiteTheme = _interopRequireDefault(require("../../../theme/WebDevSiteTheme"));
var _ColorPalette = require("../../../theme/common/ColorPalette");
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const useStyles = exports.useStyles = (0, _makeStyles.default)(theme => ({
  root: {
    minHeight: '521px',
    backgroundColor: "#131313"
  },
  contentBullets: {
    marginBottom: theme.spacing(5)
  }
}));
const WebDevAboutUsSection = props => {
  var _props$sectionData, _props$sectionData$ct;
  const classes = useStyles(_WebDevSiteTheme.default);
  const theme = (0, _material.useTheme)();
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  return (0, _jsxRuntime.jsxs)(_material.Grid, {
    container: true,
    item: true,
    className: classes.root,
    alignItems: 'stretch',
    children: [(0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      xs: 12,
      md: 6,
      container: true,
      justifyContent: 'center',
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      style: {
        overflow: "hidden",
        minHeight: 500,
        position: "relative",
        backgroundColor: "white",
        backgroundImage: "url(".concat(sanityContext.urlFor(props.sectionData.imageSrc).url(), ")"),
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "center",
        backgroundSize: "cover"
      }
    }), (0, _jsxRuntime.jsx)(_material.Grid, {
      item: true,
      xs: 12,
      md: 6,
      container: true,
      spacing: 2,
      justifyContent: 'center',
      style: {
        padding: theme.spacing(8, 0)
      },
      children: (0, _jsxRuntime.jsxs)(_material.Grid, {
        container: true,
        item: true,
        alignContent: 'space-around',
        xs: 11,
        spacing: 3,
        children: [(0, _jsxRuntime.jsxs)(_material.Grid, {
          item: true,
          container: true,
          direction: 'column',
          spacing: 1,
          children: [(0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'subtitle2',
              style: {
                color: _ColorPalette.COLORS.AQUA
              },
              children: props.sectionData.welcomeMessage
            })
          }), (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Grid, {
              item: true,
              children: (0, _jsxRuntime.jsx)(_material.Typography, {
                variant: 'h2',
                color: 'primary',
                style: {
                  fontFamily: "Elaine Sans"
                },
                display: 'inline',
                children: props.sectionData.contentTitle
              })
            })
          })]
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          spacing: 1,
          children: (_props$sectionData = props.sectionData) === null || _props$sectionData === void 0 || (_props$sectionData = _props$sectionData.contentText) === null || _props$sectionData === void 0 ? void 0 : _props$sectionData.map((text, index) => (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            container: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              variant: 'body1',
              style: {
                fontFamily: "Raleway"
              },
              color: 'primary',
              children: text
            })
          }, index))
        }), (0, _jsxRuntime.jsx)(_material.Grid, {
          container: true,
          item: true,
          children: props.sectionData.ctaButtonText && (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Button, {
              color: 'primary',
              variant: 'outlined',
              href: (_props$sectionData$ct = props.sectionData.ctaButtonLink) !== null && _props$sectionData$ct !== void 0 ? _props$sectionData$ct : '',
              children: (0, _jsxRuntime.jsx)(_material.Grid, {
                container: true,
                alignItems: 'center',
                children: (0, _jsxRuntime.jsx)(_material.Grid, {
                  item: true,
                  children: (0, _jsxRuntime.jsx)(_material.Typography, {
                    variant: 'button',
                    color: 'primary',
                    children: props.sectionData.ctaButtonText
                  })
                })
              })
            })
          })
        })]
      })
    })]
  });
};
var _default = exports.default = WebDevAboutUsSection;