"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-collections.iterator.js");
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("react");
var _SearchContext = _interopRequireDefault(require("./search-context/SearchContext"));
var _material = require("@mui/material");
var _SanityContext = _interopRequireDefault(require("../../../common/sanityIo/sanity-context/SanityContext"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const FilteredIngredients = () => {
  const searchContext = (0, _react.useContext)(_SearchContext.default);
  const sanityContext = (0, _react.useContext)(_SanityContext.default);
  const [filteredBarIngredients, setFilteredBarIngredients] = (0, _react.useState)([]);
  const {
    data,
    refetch
  } = sanityContext.useFetchMyFilteredIngredients();
  (0, _react.useEffect)(() => {
    if (data) {
      setFilteredBarIngredients(data);
    }
  }, [data]);
  (0, _react.useEffect)(() => {
    refetch();
  }, [searchContext.searchFilters, searchContext.ingredientFilters]);
  const processIngredient = filter => {
    var _filter$_id, _filter$_id2;
    if (searchContext.isIngredientIncluded && !searchContext.isIngredientIncluded(filter)) searchContext.addIngredientFilter && searchContext.addIngredientFilter((_filter$_id = filter._id) !== null && _filter$_id !== void 0 ? _filter$_id : "");else searchContext.removeIngredientFilter && searchContext.removeIngredientFilter((_filter$_id2 = filter._id) !== null && _filter$_id2 !== void 0 ? _filter$_id2 : "");
  };
  return (0, _jsxRuntime.jsx)(_material.Grid, {
    container: true,
    item: true,
    style: {
      height: "300px"
    },
    children: filteredBarIngredients.map((liquorBarItem, index) => {
      return (0, _jsxRuntime.jsx)(_material.Button, {
        onClick: () => processIngredient(liquorBarItem),
        children: (0, _jsxRuntime.jsx)(_material.Chip, {
          color: searchContext.isIngredientIncluded && searchContext.isIngredientIncluded(liquorBarItem) ? 'secondary' : 'primary',
          label: (0, _jsxRuntime.jsx)(_material.Grid, {
            item: true,
            children: (0, _jsxRuntime.jsx)(_material.Typography, {
              children: liquorBarItem.title
            })
          })
        })
      }, index);
    })
  });
};
var _default = exports.default = FilteredIngredients;